import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import Roleta from '../../assets/icons/rollete.svg'
import Cyber from '../../assets/icons/cyberspace.gif'
import CompressRoundedIcon from '@mui/icons-material/CompressRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import Atalhos from "./../../assets/buttons/atalho.svg"
import RKTZ from '../../assets/icons/RKT_COIN.svg'
import { useState } from 'react';




function FooterMenu () {

    const [location] = useState( window.location.href)



function methodPayment() {
    var method = document.getElementById("payment")
    method.classList.toggle("hidden");
    document.getElementById("modalPagamento").classList.remove("hidden");

  }

   function openAtalhos() {
    document.querySelector(".atalho__modal").classList.toggle("mr-[-300px]");
    document.querySelector(".atalho__modal").classList.toggle("opacity-0");
    document.querySelector(".atalho--opacidade").classList.toggle("hidden");    
  }

    return ( 
       <>
        {location.indexOf("register") > -1 ? 
        <div></div>
       
        : 
        <>
        <div className='pt-14'></div>
            <div className="bg-[#1b1919cf] py-2 z-[3] border-t border-t-[#ffffff7f] backdrop-blur-sm  text-white fixed bottom-0 left-0 right-0 h-18  flex justify-around items-center ">
            <div className={location.indexOf("home") > -1 ? `bg-gray-600 rounded-md  flex justify-center flex-col w-[60px] h-[55px]` : `bg-gray-800 rounded-md  flex justify-center flex-col w-[60px] h-[55px]`}>
            <a href="/home">
                <div className="flex flex-col items-center ">
                    <HomeRoundedIcon fontSize="medium"/>
                    <p className="text-[0.5em]">Home</p>
                </div>
            </a>
            </div>


            <div className={location.indexOf("bonus") > -1 ? `bg-gray-600 rounded-md  flex justify-center flex-col w-[60px] h-[55px]` : `bg-gray-800 rounded-md  flex justify-center flex-col w-[60px] h-[55px]`}>
             <a href="/bonus">
                <div  className="flex flex-col items-center">
                <LibraryBooksRoundedIcon fontSize="medium"/> 
                    <p className="text-[0.5em]">Bônus</p>
                </div>
            </a>
            </div>
          

            <div>
            {location.indexOf("cyberspace") > -1 ? (
                        <a href="/roulletplanet" >
                          <div  className="flex flex-col items-center mt-[-10px] ">
                              <img className="w-[50px]   animate-bounce" src={Roleta} alt="" />
                               <p className="text-[12px] mt-[-5px]">Roleta</p>
                            </div>
                        </a>
         
            ): location.indexOf("roulletplanet") > -1 ?  (
                <a href="/cyberspace" >
                    <div className="flex flex-col items-center mt-[-10px] ">
                        <img className="w-[60px]  animate-pulse " src={Cyber} alt="" />
                        <p className="text-[12px] mt-[-5px]">Cyber Space</p>
                    </div>
                </a>
      
            ):location.indexOf("gamefree") > -1 ?  (
                <a href="/cyberspace" >
                    <div className="flex flex-col items-center mt-[-10px] ">
                        <img className="w-[60px]  animate-pulse " src={Cyber} alt="" />
                        <p className="text-[12px] mt-[-5px]">Dinheiro Real</p>
                    </div>
                </a>
      
            ): (
                <a href="/gamefree">
                    <div  className="flex flex-col items-center mt-[-10px] ">
                    <img className="w-[50px] animate-bounce " src={RKTZ} alt="" />
                    <p className="text-[12px] ">Jogue free</p>
                    </div>
                </a>
          
            )}
             
         
            </div>

            <div className="bg-gray-800 active:bg-gray-600 rounded-md  flex justify-center flex-col w-[60px] h-[55px]">
            <div onClick={methodPayment} className="flex flex-col items-center">
                <CurrencyExchangeRoundedIcon fontSize="medium"/>
                <p className="text-[0.5em]">Depositar</p>
            </div>
            </div>

            <div className="active:bg-gray-600 bg-gray-800 rounded-md  flex justify-center flex-col w-[60px] h-[55px]">
            <div onClick={openAtalhos} className="flex flex-col items-center">
                <img className="w-6/12" src={Atalhos}/>
                <p className="text-[0.5em] relative top-[-2px]">Atalhos</p>
            </div>
            </div>
              
            </div>
            </>
    }
  </>
     );
}

export default FooterMenu;