
import { useState } from "react";
import X from "../../assets/pagamento/x.png"
import { useEffect } from "react";
import useDeposito from "../../hooks/useDeposito";
import { Link } from "react-router-dom";




export default function ConteudoDepositoSwetAlert(props) {

   
    const {Deposito} = useDeposito();

   

    function closeModal() {
        document.getElementById('DepositoConfirmado').classList.toggle("hidden");

    }

   
  

    return (<>

        <>
            <div id="DepositoConfirmado" className="relative md:w-4/12 w-11/12 bg-[#000000d1]  z-[20] backdrop-blur-md rounded-xl border-2 flex flex-wrap border-green-700 pb-10">
                <div className="absolute right-[5px] top-[20px] text-white">
                    <img onClick={closeModal} className="w-6/12 cursor-pointer" src={X} />
                </div>

                <section className="w-full flex flex-col items-center">
                    <div className=" font-bold text-[0.8em] lg:pt-5 pt-16 text-white">
                        CONFIRMAÇÃO DE PAGAMENTO
                    </div>

                    <div className="relative bg-zinc-800 lg:min-h-[100px] min-h-[300px] rounded-xl flex flex-col items-center mt-10 w-10/12">

                        <div className="bg-verdeprimary rounded-md w-8/12 h-[60px] relative top-[-30px]">
                            <p className="text-[0.8em] font-semibold text-center">
                                Valor do depósito
                            </p>
                            <p className="text-[0.8em] uppercase font-black pl-3 bottom-[15px]  absolute ">
                            brl
                            </p>
                            <p className="text-[2.2em] relative top-[-10px] text-cinzasecondary font-black text-center">
                            {Deposito?.valor}
                            </p>

                           
                            
                        </div>





                        
                     <div className="flex flex-col justify-center h-full items-center">
                       {!Deposito?.cupom ?
                       <>
                        <p className="text-violet-500 text-md font-bold rounded-md  px-5 py-1">
                            PAGAMENTO SEM CUPOM
                        </p>  

                       </>
                       :
                       <>
                        <p className="text-white text-xl">
                            CUPOM
                        </p>
                        <p className="text-white text-xl font-bold rounded-md border-dashed border-white border px-5 py-1">
                            {Deposito?.cupom}
                        </p>     
                       </>}   


                     </div>


                     {!Deposito?.sorteio ? 
                   <>
                   </> 
                   : 
                   <>
                   <div className="flex flex-col  mt-5 items-center">
                        <p className="text-verdeprimary">
                            Premiação
                        </p>
                        <p className="text-verdeprimary relative top-[-5px] text-xl font-bold">
                            5 numeros do sorteio
                        </p>       

                     </div>
                   </>
                   }

                    

                     <div className="flex flex-col mt-5 items-center">
                       
                        {Deposito?.numeros.map((item, index) => {
                            return( 
                                <>
                                <div key={index} className="h-[20px]">
       
                                <p className="text-white font-bold text-[1.2em] Orbitron">{item}</p>
                                </div>
                                </>


                            )
                       
                        })}

                     </div>

                     <div className="w-full h-[2px] mt-10 border border-dashed border-b-white">

                     </div>

                     <div className="flex w-full  justify-center">
                        <p className="uppercase  text-md py-5 font-bold text-white">
                            Pagamento realizado!
                        </p>
                     </div>

                     
                   
                   
                   
                   
                   
                   
                   
                   
                   
                    </div>

                   {!Deposito?.sorteio ? 
                   <>
                   </> 
                   : 
                   <>
                    <div className="flex w-full relative justify-center">
                        <Link to="/centraldojogador/promocao" className="cursor-pointer uppercase animate-ping z-[1] text-3xl py-2 font-bold text-white">
                          
                            VER SORTEIO
                         
                        
                        </Link>
                        <Link to="/centraldojogador/promocao" className="absolute  uppercase  text-3xl py-2 font-bold text-green-600">
                     
                            VER SORTEIO
                      
                        </Link>

                     </div>
                   </>
                   }


                </section>







            </div>
        </>

    </>)
}