import Check from "../../../assets/icons/checkLogin.svg";
import Tudo2 from "../../../assets/icons/tudo2.svg";
import Polygon from "../../../assets/icons/polygon.svg";
import PolygonOff from "../../../assets/icons/polygonoff.svg";
import Close from "../../../assets/icons/close.svg";
import RktzCoin from "../../../assets/icons/RKT_COIN.svg";
import useRender from "../../../hooks/useRender";
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import ModalValidarCel from "../ModalValidarCel/ModalValidarCel";
import { useNavigate } from 'react-router-dom';
import ModalPadrao from "../modalPadrao/modalPadrao";


function ConteudoSteak(props) {
  const { prefixoUrl, diasArray, sincronizarSaldoComBancoDeDados } = useRender();
  const [diaLogin, setDiaLogin] = useState()
  const [index, setIndex] = useState()
  const [handleeBtn, setHandleeBtn] = useState(false)
  const [conteudoModal, setConteudoModal] = useState()
  const navigate = useNavigate();

  function closeSteak() {
    document.getElementById(props.openmodal).classList.toggle("hidden");
  }



  useEffect(() => {
    let semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
    let day = new Date();
    let diaHoje = semana[day.getDay()];
    setDiaLogin(diaHoje)
    /* Verifica o dia da semana para setar o index no  diasArray */
    setIndex(() => {
      if (diaHoje == "Domingo") {
        return 6
      }
      return semana.indexOf(diaHoje) - 1
    })

  }, []);


  function BonusFull() {
    let method = document.getElementById("BonusFinalStreak")

    setTimeout(() => {
      document.getElementById("steak2BonusFinal").classList.toggle("scale-0")
      document.getElementById("steak2BonusFinal").classList.toggle("scale-125")


      setTimeout(() => {
        document.getElementById("checkTER").classList.toggle("hidden")
        document.getElementById("checkTER").classList.toggle("streak")

        setTimeout(() => {

          document.getElementById("checkQUA").classList.toggle("hidden")
          document.getElementById("checkQUA").classList.toggle("streak")

          setTimeout(() => {
            document.getElementById("steak2BonusFinal").classList.toggle("scale-125")
            document.getElementById("steak2BonusFinal").classList.toggle("scale-100")
            document.getElementById("checkQUI").classList.toggle("hidden")
            document.getElementById("checkQUI").classList.toggle("streak")

            setTimeout(() => {
              document.getElementById("checkSEX").classList.toggle("hidden")
              document.getElementById("checkSEX").classList.toggle("streak")

              setTimeout(() => {
                document.getElementById("checkSAB").classList.toggle("hidden")
                document.getElementById("checkSAB").classList.toggle("streak")

                setTimeout(() => {
                  document.getElementById("checkDOM").classList.toggle("hidden")
                  document.getElementById("checkDOM").classList.toggle("streak")

                  setTimeout(() => {
                    document.getElementById("$Checks").classList.toggle("flex")
                    document.getElementById("$Checks").classList.toggle("hidden")


                    document.getElementById("$Premium").classList.toggle("hidden")
                    document.getElementById("$Premium").classList.toggle("flex")



                    setTimeout(() => {
                      document.getElementById("BonusFinalStreak").classList.toggle("hidden")
                      document.getElementById("$Premium").classList.toggle("flex")
                      document.getElementById("$Premium").classList.toggle("hidden")
                      document.getElementById("$Checks").classList.toggle("flex")
                      document.getElementById("$Checks").classList.toggle("hidden")
                      document.getElementById("steak2BonusFinal").classList.toggle("scale-100")
                      document.getElementById("steak2BonusFinal").classList.toggle("scale-0")

                    }, 5000);


                  }, 600);

                }, 300);
              }, 300);
            }, 300);

          }, 300);
        }, 300);
      }, 300)

    }, 600);



    method.classList.toggle("hidden");
  }



  function handleBtnColetar() {
    setHandleeBtn(true)
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/streak_login/update?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        if (dados.code == 1) { //
          sincronizarSaldoComBancoDeDados()
          closeSteak() // fechar steak após coletar bonus        
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!!!',
            text: 'Coletado com sucesso!',
          }).then(() => {
            // se completou toda a semana chama função que apresenta modal de bonus full
            if (diasArray[0].checked == true && diasArray[1].checked == true && diasArray[2].checked == true && diasArray[3].checked == true && diasArray[4].checked == true && diasArray[5].checked == true) {
              BonusFull()
            }
          })


        }

        if (dados.code > 1000) {  //já realizou 
          if (dados.code == 1001) {
            closeSteak() // fechar steak após coletar bonus
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'resgate já efetuado hoje',
            })
          } else {

            // closeSteak() // fechar steak após coletar bonus
            setConteudoModal(dados.msg)
            // document.getElementById("titleSteak").innerHTML = "Ops!"
            document.getElementById("modalPadrao").classList.toggle("hidden")
            document.getElementById("cardSteak").classList.toggle("hidden")
            // document.getElementById("tudo2Svg").classList.toggle("hidden")
            // document.getElementById("steak4").classList.toggle("hidden")


          }
        }



      })
      .catch((error) => console.log("error", error))
      

  }

  function handleePerfil() {


    // Adicione essa linha para redirecionar e passar um estado
    navigate('/centraldojogador/perfil', { state: { fromVerifyButton: true } });
  }


  return (

    <>

      <ModalPadrao conteudo={conteudoModal} title="Verificação necessária!" onClick={handleePerfil} conteudoBtn="Verificar" onClickCancelar={closeSteak} />

      <div className="flex justify-center items-center w-[full]">
        <div
          id="cardSteak"
          className="w-11/12 md:w-4/12  drop-shadow-lg  bg-[#424242]  z-[6] backdrop-blur-md  p-6 relative   "
        >

          <div className="flex justify-center items-center gap-4">
            <img id="tudo2Svg" className="h-7 w-5  " src={Tudo2} alt="" />
            <p
              id="titleSteak"
              className="text-verdesixtyn whitespace-nowrap  font-['Orbitron']  text-4xl"
            >
              Streak De Login
            </p>
          </div>

          <div id="steak1">
            <div className="flex justify-center mt-5 relative w-full ">
              <div className="relative flex justify-center items-center w-full top-7">
                <img
                  className="lg:h-[72px] lg:w-[72px] h-[62px] w-[62px]  absolute "
                  src={Polygon}
                  alt=""
                />
                <img
                  className="lg:h-[72px] lg:w-[72px] h-[62px] w-[62px]  absolute streak  "
                  src={Check}
                  alt=""
                />
              </div>
            </div>

            <div className="px-6 lg:pt-20 pt-16 ">
              <p className="text-white uppercase text-center lg:text-2xl text-xl">{diaLogin}</p>
            </div>
          </div>

          <div id="steak2" className=" flex justify-around items-center lg:space-x-[-120px] mt-3 pb-8 hidden" >


            {diasArray.map((dia, index) => {

              if (dia.checked === false) {
                return <div key={index} className=" relative flex justify-center items-center w-full ">
                  <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                  <img className="h-[20px] w-[20px] absolute   " src={Close} alt="" />
                  <p className="lg:mt-16 mt-[73px] uppercase text-white lg:text-[14px] text-sm">{dia.name.substring(0, 3)}</p>
                </div>

              } else if (dia.checked === true) {
                return <div key={index} className="cursor-pointer relative flex justify-center items-center w-full ">
                  <img className="h-[35px] w-[35px] absolute " src={Polygon} alt="" />
                  <img className="h-[35px] w-[35px] absolute   " src={Check} alt="" />
                  <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">{dia.name.substring(0, 3)}</p>
                </div>
              } else {
                return <div key={index} className="cursor-pointer relative flex justify-center items-center w-full ">
                  <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                  <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">{dia.name.substring(0, 3)}</p>
                </div>
              }
            })
            }

          </div>

          { /* Coletar  */}
          <div id="steak3" className=" hidden ">
            <div className="flex justify-center mt-3">
              <div className="bg-[#1E1E1E] w-[215px] border-x-[3px] border-verdesixtyn ">
                <div className="w-full h-[150px] leading-3 text-center text-white mt-4">
                  <div className="flex justify-center">
                    <img className="w-16 rktzCoin" src={RktzCoin} alt="" />
                  </div>
                  <div className="mt-3">
                    <p className="font-['Orbitron'] text-3xl ">{diasArray[index]?.recompensa}</p>
                    <p>rktz coins</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="flex justify-center mt-3">

              <button onClick={handleBtnColetar} className={`cursor-pointer bg-verdesixtyn hover:bg-[#0c6d40] hover:text-white duration-1000  font-bold text-sm px-5 py-2 rounded-md `}>


                {handleeBtn && <svg role="status" className="inline mr-2 w-6 h-6 text-green-200 whitespace-nowrap animate-spin dark:text-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                </svg>}
                {handleeBtn && "COLETANDO..."}
                {!handleeBtn && "COLETAR"}

              </button>

            </div>
          </div>

          { /* Modal para verificar celular */}
          <div id="steak4" className=" hidden ">
            <div className="flex justify-center mt-3">
              <div className="bg-[#1E1E1E] ] border-x-[3px] border-verdesixtyn ">
                <div className=" text-center text-white mt-4">

                  <div className="flex justify-center items-center w-full h-[150px]">
                    <p className="text-2xl text-semibold tracking-wide">{conteudoModal}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-3">

              <button onClick={handleePerfil}
                className="cursor-pointer bg-verdesixtyn hover:bg-[#0c6d40] hover:text-white duration-1000  font-bold text-sm px-5 py-2 rounded-md tracking-widest flex items-center gap-2" >

                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                </svg>
                VERIFICAR
              </button>


            </div>
          </div>


          <div id="steakbarra" className="">
            <p className="border-t-2 border-verdesixtyn mt-5"></p>
          </div>

        </div>
      </div>


    </>
  );
}

export default ConteudoSteak;
