export default function RegrasPoliticasEn() {
    return (
        <>
            <div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Privacy Policy</div>
                </div>
                <div className="mt-2 text-white font-bold">1. PRIVACY</div>
                rockeetz.com is committed to protecting your personal information. This Privacy Policy informs you about what information we collect when you use our services, why we collect this information, and how we use the collected information.
                Please note that this Privacy Policy is an agreement between you and rockeetz.com ("We," "Us," or "Our," as applicable). This Privacy Policy is an integral part of rockeetz.com's Terms and Conditions.
                The rockeetz.com website ("Casino," "Website," "Company," "We," "Us," "Our") is owned and operated by LAM Gaming Group B.V., a company registered under Curaçao Law, registration No. 163826, Address: Abraham de Veerstraat 9, Willemstad, Curaçao. rockeetz.com is licensed and regulated by the Curaçao Gaming Authority and operates under License No. 365/JAZ issued to Gaming Services Provider, authorized and regulated by the Government of Curaçao.
                We may make periodic changes to this Privacy Policy, and we will notify you of such changes by posting the modified terms on our platforms. We recommend that you review this Privacy Policy regularly.

                <div className="mt-2 text-white font-bold">2. INFORMATION COLLECTED</div>
                We consider information that can be used to identify an individual, including but not limited to name and surname, date of birth, residence or other physical address, email address, telephone number, or other relevant information as Personal Information ("Personal Information"). You may be asked to provide personal information when using our website, registering an account, or using our services. The Personal Information we collect may include information such as contact information (including phone number), shipping information, billing information, transaction history, website usage preferences, and feedback about the Services. This information is held by us on servers based in Germany and elsewhere from time to time. When you interact with the services, our servers maintain a unique activity log for you that collects certain administrative and traffic information, including: originating IP address, access time, date of access, web page(s) visited, language use, software failure reports, and type of browser used. This information is essential for the provision and quality of our services. We do not collect personal information about you without your knowledge.

                <div className="mt-2 text-white font-bold">3. MEANS OF DATA COLLECTION AND PROCESSING</div>
                We may automatically collect certain data as discussed above and receive personal information about you when you provide such information through the services or other communications and interactions on the website www.rockeetz.com. We may also receive personal information from online vendors and service providers and legally acquired customer lists from third-party vendors. Additionally, we may engage the services of third-party service providers to provide technical support, process your online transactions, and maintain your account. We will have access to any information you provide to such vendors, service providers, and third-party e-commerce services, and we will use the Personal Information as set forth in this Privacy Policy below. This information will only be disclosed to third parties outside the company in accordance with this Privacy Policy. We take steps to ensure that our agreements with third-party service providers and online vendors protect your privacy.
                <div className="mt-2 text-white font-bold">4. USE OF INFORMATION</div>
                We use the personal information we collect from you to provide our services, provide customer support, perform necessary security and identification checks, process any of your online transactions, assist your participation in third-party promotions, fulfill certain business requirements, and for any other purpose related to the operation of the Services. As such, we may share your personal information with our carefully selected partners (including any other parties with data sharing agreements with the latter).
                Your personal information may also be used by us to provide you with: (1) promotional offers and information about our products and services; and (2) promotional offers and information about products and services from our partners, to expand the range of products provided and improve our customer service. Occasionally, we may request information from you through surveys or contests. Participation in such surveys or contests is entirely voluntary, and you have the choice whether to disclose such information. The requested information may include contact information (such as name, mailing address, and telephone number) and demographic information (such as ZIP or postal code or age). By accepting any prize from a contest or winnings from us, you agree to use your name for advertising and promotional purposes without further compensation, except where prohibited by law. Unless you have opted out of receiving promotional information, we may also use your personal information (including your email address and phone number) to provide information about our products, services, and promotions, including other gaming products (including online poker, casino, betting, backgammon) and products and services from third parties carefully selected by us.


                <div className="text-white font-bold mt-3">5. CERTAIN EXCLUDED DISCLOSURES</div>
                We may disclose your Personal Information if required by law or if we believe in good faith that such action is necessary to: (1) comply with any legal process served on us, any of our websites, or services or in circumstances where we are under a substantially similar legal obligation; (2) protect and defend our rights or property; or (3) act to protect the personal safety of users of the services or the public. If, in our sole determination, it is discovered that you have deceived or attempted to defraud us, the company, or any other user of the services in any way, including, among others, game manipulation or payment fraud, or if we suspect payment fraud, including the use of stolen credit cards or any other fraudulent activity (including any chargeback or other payment reversal), or prohibited transaction (including money laundering), we reserve the right to share such information (along with your identity) with other online gaming websites, banks, credit card companies, appropriate agencies, and relevant authorities. (4) For the purposes of investigation into drug addiction prevention, the data may be anonymized and transmitted to the respective institutions.
                <div className="text-white font-bold mt-3">6. ACCESS</div>
                You may 'opt-out' of receiving any promotional communications by opting out in the account settings available on our websites or services or in an email you receive from us, or at any time by emailing or writing to us at Customer Support.
                Additionally, you may contact us if:
                <br /> 1) you would like to confirm the accuracy of the personal information we have collected about you;
                <br /> 2) you would like to update your personal information; and/or
                <br /> 3) you have any complaints about the use we make of your personal information. Upon request, we will (1) update any information you have provided to us if you prove the need for such changes or (2) mark any information to prohibit future use for marketing purposes. To avoid doubt, nothing in this Privacy Policy will prevent us from retaining your Personal Information when we are required to do so by law.

                <div className="text-white font-bold mt-3">7. COOKIES</div>
                <div className="text-white font-bold mt-3">Information Placed on Your Device</div>
                When accessing our services, we may store information on your device. This information is called cookies, which are small text files stored on your device when you visit online pages that record your preferences. We also use Local Shared Objects or 'flash cookies.' 'Flash cookies' are like browser cookies. They allow us to remember things about your visits on our websites. Neither cookies nor flash cookies can be used to access or use other information on your computer. We use these methods only to track your use of our services. Cookies help us monitor website traffic, improve our services, and make it easier and/or more relevant for your use. We use flash cookies and third-party cookies to help us display more relevant and desirable advertisements.
                <div className="text-white font-bold mt-3">Strictly Necessary Cookies</div>
                Strictly necessary cookies are essential to enable a user to navigate a website and use its features, such as accessing secure areas of the site or making financial transactions. Without these cookies, we would not be able to make our sites function efficiently.

                <div className="text-white font-bold mt-3">During the Registration Process</div>
                These cookies will retain the information collected during your registration and allow us to recognize you as a customer and provide the services you need. We may also use this data to better understand your interests while online and to enhance your visits to our platforms.

                <div className="text-white font-bold mt-3">On our Website</div>
                For visitors to our website, we use cookies to collect information. Our servers use three different types of cookies:
                Session-based cookie: This type of cookie is allocated only to your computer during your visit to our website. A session-based cookie helps you navigate our website more quickly, and if you are a registered customer, it allows us to provide more relevant information to you. This cookie automatically expires when you close your browser.

                Persistent cookie: This type of cookie will remain on your computer for a defined period for each cookie. Flash cookies are also persistent.

                Analytical cookie: This type of cookie allows us to recognize and count the number of visitors to our website and see how visitors use our services. It helps us improve the way our websites work, for example, by ensuring you can easily find what you're looking for.

                You can accept or decline cookies. Most web browsers automatically accept cookies, but if you prefer, you can usually modify your browser setting to decline cookies. The Help menu on the menu bar of most browsers will inform you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, and how to disable cookies altogether.

                <div className="text-white font-bold mt-3">Flash cookies</div>
                You can modify your Flash Player settings to prevent the use of flash cookies. The Settings Manager of your Flash Player allows you to manage your preferences. To prohibit flash cookies from any third-party content, go to the "Global Storage Settings" panel of the Settings Manager and uncheck the box "Allow third-party flash content to store information on your computer," and close the Settings Manager. Alternatively, you can also adjust your settings for specific websites you visit through the "Website Storage Settings" panel, which can also be found in the Settings Manager.
                If you are using an older version of Flash Player or an older web browser, the Settings Manager may not be available to you. We recommend that you update your Flash Player and browser to the latest available versions.

                If you choose to decline cookies, you may not be able to experience all the interactive features on our websites.


                <div className="text-white font-bold mt-3">8. Consent for Use of Electronic Service Providers</div>
                To play games with real money on our services, you will need to send and receive money from us. We may use third-party electronic payment systems to process these financial transactions. By accepting this Privacy Policy, you expressly consent to the Personal Information necessary for transaction processing, including, when necessary, the transfer of information outside of your country. We take measures to ensure that our agreements with payment systems protect your privacy.
                <div className="text-white font-bold mt-3">9. Consent for Security Analysis</div>
                We reserve the right to conduct a security review at any time to validate the registration data provided by you and verify your use of the services and your financial transactions for possible violation of our Terms and Conditions and applicable law. By using our services and thus agreeing to our Terms and Conditions, you authorize us to use your personal information and disclose your personal information to third parties for the purpose of validating the information you provide during the use of our services, including, when necessary, the transfer of information outside of your country. Security analyses may include, but are not limited to, requesting a credit report and/or verifying the information provided in third-party databases. Additionally, to facilitate these security analyses, you agree to provide the information or documentation we request.
                <div className="text-white font-bold mt-3">10. Security</div>
                We understand the importance of security and the necessary techniques to protect information. We store all personal information we receive directly from you in an encrypted, password-protected database residing on our secure network behind state-of-the-art firewall software. (Our services support SSL version 3 with 128-bit encryption.) We also take measures to ensure that our subsidiaries, agents, affiliates, and suppliers employ adequate security measures.
                <div className="text-white font-bold mt-3">11. Protection of Minors</div>
                Our services are not intended for or directed to individuals under the age of eighteen (18) (or the legal age in your respective jurisdiction). Any person who provides us with their information through any part of the services signifies to us that they are eighteen (18) years of age (or the legal age in their respective jurisdiction) or older. It is our policy to discover attempts by minors to access our services, which may involve the need to initiate a security review. If we become aware that a minor has attempted or provided personal information through our services, we will not accept their information and will take steps to delete the information from our records.
                <div className="text-white font-bold mt-3">12. International Transfers</div>
                The Personal Information collected in the services may be stored and processed in any country where we or our affiliates, suppliers, or agents maintain facilities. By using our services, you expressly consent to any transfer of information outside of your country (including to countries that may not have adequate privacy laws). However, we take measures to ensure that our agents, affiliates, and suppliers comply with our privacy standards, regardless of their location.
                <div className="text-white font-bold mt-3">13. Third-Party Practices</div>
                We cannot guarantee the protection of any information you provide to an online third-party website linked to or from the services or any information collected by third parties administering our affiliate program (if applicable) or any other program, as these online third-party websites are owned and operated independently of us. Any information collected by these third parties is governed by their privacy policy, if any.
                <div className="text-white font-bold mt-3">14. Disclaimer</div>
                The services are provided "AS IS" and "AS AVAILABLE" without any liability of any kind. We are not responsible for events beyond our direct control. Due to the complex and ever-changing nature of our technology and business, we cannot guarantee or warrant error-free performance with respect to the privacy of your personal information, and we shall not be liable for any indirect, incidental, consequential, or punitive damages related to the use or disclosure of such Personal Information.
                <div className="text-white font-bold mt-3">15. Consent to Privacy Policy</div>
                The services are provided "AS IS" and "AS AVAILABLE" without any liability of any kind. We are not responsible for events beyond our direct control. Due to the complex and ever-changing nature of our technology and business, we cannot guarantee or warrant error-free performance with respect to the privacy of your personal information, and we shall not be liable for any indirect, incidental, consequential, or punitive damages related to the use or disclosure of such Personal Information.
                <div className="text-white font-bold mt-3">16. Other Websites</div>
                Our website may contain links to other websites, which are outside of our control and are not covered by this Privacy Policy. If you access other websites using the provided links, the operators of those websites may collect information about you, which will be used by them in accordance with their privacy policy, which may differ from ours. We are not responsible. Only the operators of those websites will be responsible for their functionality or any potential errors on the linked websites.
                <br />
                17. When you play casino slots developed by PG Soft.


            </div>


        </>
    )
}