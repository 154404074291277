import {Link} from 'react-router-dom'

export default function ButtonsTop(props){

    return(
        <>
   
        <div onClick={props.click} className="w-3/12 hover:contrast-100 group cursor-pointer hover:scale-110 duration-300 border border-purple-400 bg-gradient-to-r rounded-xl from-[#1b1032] via-[#354545] to-[#403458] hover:from-[#6836a5] hover:via-[#882c98] hover:to-[#9340d8]">
        <a href={props.link}>
                   <div className="w-full h-[100%] items-center flex">
                   <div className="bg-[#344646] w-[35px] h-[35px] group-hover:bg-green-800 m-1 rounded-lg ml-2">
                   <img src={props.ico}/></div>
                   <div className="w-8/12">
                    <img src={props.conteudo}/></div>
                   </div>
         </a>
         </div>

 
        
        </>
    )
}