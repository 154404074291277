import ContainerBlur from "./blur/container"
import ConteudoConvertRkt from "./convertRKT/conteudo"


export default function ConvertRkt(){
    return(
        <>
         <ContainerBlur openmodal="convert">    {/* CONTAINER COM O BLUR ATIVADO*/}
         <ConteudoConvertRkt openmodal="convert" />
         </ContainerBlur>
        </>
    )
}