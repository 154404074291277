import { Link } from "react-router-dom";
import Voltar from "../../../assets/icons/voltar.gif";
import BtnConfirmar from "../../../components/BtnConfirmar";
import useRender from "../../../hooks/useRender";
import Swal from 'sweetalert2'
import Title from "../../../components/centraldojogador/titulo";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";

function ExcluirConta() {
  const { prefixoUrl } = useRender();
  
  async function handleClick() {

    let formData = new FormData();
   
  
    const userToken = localStorage.getItem("user_token");

    formData.append('token', userToken);
    Swal.fire({
      title: "Deseja excluir sua conta?",
      text:"Essa ação é permanente e não poderá ser desfeita",
      icon: "question",
      showCancelButton: true,
      dangerMode: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then(function (events) {
      if (events.isConfirmed) {
        fetch(`${prefixoUrl}/user/delete`, {
          method: "POST",
          body: formData,
        }).then((resposta) => resposta.json())
        .then((json) => {
    
          if (json.code === 1) {
    
            localStorage.clear();
    
            window.location.href = '/home';
    
            Swal.fire(
              "",
              json.msg,
              'success'
            )
           
          } else {
        
            Swal.fire(
              "",
              "Erro ao enviar e-mail, tente novamente mais tarde",
              'error'
            )
          }
    
        });
      }
    });
    
  }

  return (
   <TemplateCentralDoJogador title="Excluir conta">
     <main className="w-full">
  

      <div className="text-white text-justify ">
        <p className="uppercase text-center text-white text-3xl py-8">
          EXCLUSÃO DE CONTA
        </p>

        <div className="px-4">
          <p className=" ">
            Se um usuário não precisar mais de uma conta, poderá suspendê-la e
            logo após o período de seis meses a conta será automaticamente
            excluída. Caso o usuário solicite a ativação da conta no período de
            seis meses, todos os dados voltarão a atividade sem qualquer
            interferência da plataforma. Após o período de seis meses
            entabulados, todos os dados constantes na referida conta serão
            perdidos. O usuário que vier a ter a conta excluída poderá fazer
            novamente uma nova conta com os mesmos dados, entretanto, as
            campanhas promocionais já utilizadas na conta antiga não terão
            qualquer validade.
          </p>

          <li className="py-3">Todos os dados do usuário são excluídos;</li>

          <li className="py-3">
            Alguns dados serão armazenados por segurança no servidor localizado
            na California e só serão disponibilizados por decisão judicial do
            país de origem do servidor.
          </li>

          <li className="py-3">
            Seu faturamento pode ser afetado por não garantir a distribuição de
            lucros
          </li>

          <p>
            A Rockeetz é uma organização privada que entende que o usuário tem o
            direito a ser esquecido, após o período de suspensão os dados
            pessoais do usuário são irremediavelmente inacessíveis a qualquer
            pessoa ou organização. A exclusão permanente do usuário acarretará a
            perda total de qualquer métrica ou histórico na plataforma. Qualquer
            suspeita de violação da Política deve ser reportada imediatamente ao
            nosso canal de comunicação descrito na plataforma.
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center mt-5 pb-5">
        <BtnConfirmar
          texto="EXCLUIR CONTA"
          onClick={handleClick}
          altura="py-1"
          largura="w-[350px]"
          radius="rounded-full"
          sizeText="text-xl"
        />
      </div>
    </main>
   </TemplateCentralDoJogador>
  );
}

export default ExcluirConta;
