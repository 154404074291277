import Slider from "react-slick";
import CarouselItem from "./../pg/carouselitem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CandyBurst from "./../../../../assets/home/pgsoft/candyburst.png";
import TigerFortune from "./../../../../assets/home/pgsoft/fortunetiger.png";
import LeprechaunRiches from "./../../../../assets/home/pgsoft/leprechaunriches.png";
import Search from "./../../../../assets/icons/search.png";

import { useState } from "react";
import CarouselItemPg from "./../pg/carouselitem";

export default function CarrosselPg() {
  
  const [itemsSelecteds, setItemsSelecteds] = useState([]);
  function filterGame(value) {
    if (value === "") {
      setItemsSelecteds(items);
    }

    const resultado = items.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });

    setItemsSelecteds(resultado);
  }
  const items = [
    {
      image: CandyBurst,
      link: "/slot?game=pgs-slot-candyburst",
      name: "Candy Burst",
    },
    {
      image: TigerFortune,
      link: "/slot?game=pgs-slot-FortuneTiger",
      name: "Fortune Tiger",
    },
    {
      image: LeprechaunRiches,
      link: "/slot?game=pgs-slot-leprechaunRiches",
      name: "Leprechaun Riches",
    },
  ];

  useState(() => {
    setItemsSelecteds(items);
  });

  const settingsPg = {
    focusOnSelect: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    rtl: false,
  };

  const settingsPg2 = {
    focusOnSelect: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.06,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    rtl: false,
  };

  return (
    <>
      <div className="bg-cinzaprimary min-h-[100vh]">
        <div className="w-full  text-white flex justify-center">
          <div className="w-full   text-center lg:text-left lg:pl-3 text-xl lg:text-4xl mb-5 mt-5  font-bold lg:w-10/12">
            {" "}
            PG SOFT{" "}
          </div>
        </div>
        <div className="">
          {/* INPUT PESQUISAR */}
          <div className="bg-slate-900 h-[1px] mb-5 w-full"></div>

          <div className="w-full flex justify-center">
            <div className="rounded-xl border border-gray-600 items-center px-2 flex bg-cinzasecondary w-10/12">
              <div className="">
                <img src={Search} className="w-6" />
              </div>
              <input
                placeholder="Procure seu jogo"
                className="bg-cinzasecondary p-2 text-white"
                onChange={(e) => {
                  filterGame(e.target.value);
                }}
              ></input>
            </div>
          </div>

          {/* LISTAGEM DA PG*/}

          <div className="w-full flex justify-center">
            <div className="w-11/12 flex-wrap flex">
              {itemsSelecteds.map((item) => (
                <CarouselItemPg {...item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
