import Voltar from "../../../assets/icons/voltar.gif";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useRender from "../../../hooks/useRender";
import Loading from "../../../components/Loading";
import BtnCancelar from "../../../components/BtnCancelar";
import Title from "../../../components/centraldojogador/titulo";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";

function HistoricoDeSaque() {
  const { prefixoUrl, SairApi } = useRender();
  const [result, setResult] = useState();

  async function PaginacaoNext() {
    let dados = localStorage.getItem("historicoDeSaque");
    dados = JSON.parse(dados);
    if (dados) {
      if (dados.paginaAtual < dados.paginas) {
        let dados = localStorage.getItem("historicoDeSaque");
        dados = JSON.parse(dados);
        dados.paginaAtual = dados.paginaAtual + 1;
        localStorage.setItem("historicoDeSaque", JSON.stringify(dados));

        let inicio = (dados.paginaAtual - 1) * 10;
        let final = inicio + 10;
        dados.result = dados.result.slice(inicio, final);
        setResult(dados);
      }
    }
  }

  async function PaginacaoPrevius() {
    let dados = localStorage.getItem("historicoDeSaque");
    dados = JSON.parse(dados);
    if (dados) {
      if (dados.paginaAtual == 1) return;
      dados.paginaAtual = dados.paginaAtual - 1;
      localStorage.setItem("historicoDeSaque", JSON.stringify(dados));

      let inicio = (dados.paginaAtual - 1) * 10;
      let final = inicio + 10;
      dados.result = dados.result.slice(inicio, final);
      setResult(dados);
    }
  }

  async function PaginacaoLast() {
    var myHeaders = new Headers();

    var raw = "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${prefixoUrl}/centraldojogador/historicodesaques/10?token=${localStorage.getItem(
        "user_token"
      )}&page=${result.paginas}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        setResult(dados);
        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  async function HistoricoDeSaque() {
    
    let dados = localStorage.getItem("historicoDeSaque");
        dados = JSON.parse(dados);

    if(dados === null){
      await HistoricoDeSaqueApi();
    }
    else if(dados) { 
      if(dados.code === 1099){ // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }

      let inicio = (dados.paginaAtual - 1) * 10;
      let final = inicio + 10;
      dados.result = dados.result.slice(inicio, final);
      setResult(dados);
    }
 
  }

  async function HistoricoDeSaqueApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

 

    await fetch(
      `${prefixoUrl}/centraldojogador/historicodesaques/1000?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        dados.paginas = parseInt(dados.totalRegistro / 10);

        if (dados.totalRegistro % 10 > 0) {
          dados.paginas = dados.paginas + 1;
        }

        localStorage.setItem("historicoDeSaque", JSON.stringify(dados));
        setResult(dados);
        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {   
    HistoricoDeSaque();
  }, []);

  return (
   <TemplateCentralDoJogador title="Histórico de Saque">
     <main className="w-full ">
      

      {result != undefined ? (
        result.result.map((item, index) => {
          return (
            <div key={index} className="space-y-[2px]">
              <div className="w-full lg:h-[67px] p-2 flex text-white font-bold bg-[#121111]  border-b border-[#2b2828]">
                <div className="w-6/12 pl-1 flex flex-col lg:flex-row">
                <div className="w-full lg:w-6/12 flex justify-between items-center ">
                  <p className={item.statusSaque == "Confirmado" ? ` text-verdeprimary lg:text-sm uppercase` : item.statusSaque == "Pendente" ? `text-white lg:text-sm uppercase ` : `text-red-700 text-[0.6em] lg:text-sm uppercase` }
                  >
                    {item.statusSaque}
                    {item.statusSaque == "Pendente" ? (
                      <BtnCancelar
                        texto="CANCELAR SAQUE"
                        altura=" ml-4"
                        largura="w-full"
                        radius="rounded-lg"
                        sizeText="text-sm"
                      />
                    ) : (
                      ""
                    )}
                  </p>
       
                 
                </div>

                <div className="w-full lg:w-6/12 ">
                    <p className=" uppercase text-sm">{item.metodo}</p>
                    <p className=" font-normal">{item.pix}</p>
                  </div>

                </div>
                <div className="w-6/12 pr-1 flex flex-col  lg:flex-row-reverse">
                   <div className="w-full lg:w-6/12 font-normal flex justify-end">{item.data}</div>

                   <div className="w-full lg:w-6/12">
                    <p className=" text-right uppercase">QUANTIA</p>

                    {/* text-red-700 */}
                    <p
                      className={
                        item.statusSaque == "Confirmado"
                          ? ` text-verdeprimary  text-xl lg:text-xl leading-3 flex items-center justify-end `
                          : item.statusSaque == "Pendente"
                          ? `text-white  text-xl lg:text-xl leading-3 flex items-center justify-end `
                          : `text-red-700   text-xl lg:text-xl   leading-3 flex items-center justify-end `
                      }
                    >
                      <span className="font-normal mr-2 text-white">
                        BRL
                      </span>
                      {item.quantia}
                    </p>
                  </div>
                   </div>
               

              </div>
            </div>
          );
        })
      ) : (
        <Loading />
      )}

{result != undefined && result.paginas > 1 ?(
<div className="flex justify-center items-center  py-5 mb-[50px]">
        <button
          onClick={PaginacaoPrevius}
          className="uppercase bg-white text-[#1F2327]   hover:bg-[#1f9114] hover:text-white  font-bold px-8 rounded-full h-8 flex items-center gap-2  scale-90 hover:scale-100 duration-500"
        >
          Previus
        </button>
        
        
        <div className="flex items-center px-2">
          <p className="text-md  text-white">
            {result != undefined ? result.paginaAtual - 1 : ""}
          </p>
          <p className="text-3xl px-4 font-bold text-white">
            {result != undefined ? result.paginaAtual : ""}
          </p>
          <p className="text-md  text-white">
            {result != undefined && result.paginas > result.paginaAtual
              ? result.paginaAtual + 1
              : ""}
          </p>
          <p
            onClick={PaginacaoLast}
            className="text-md pl-4  text-white cursor-pointer"
          >
            {result != undefined ? `...${result.paginas}` : " "}
          </p>
        </div>

        <button
          onClick={PaginacaoNext}
          className="uppercase bg-[#1BFF07] text-[#1F2327]   hover:bg-[#1f9114] hover:text-white  font-bold px-8 rounded-full h-8 flex items-center gap-2  scale-90 hover:scale-100 duration-500"
        >
          NEXT
        </button>
      </div>
      ):""}
    </main>
   </TemplateCentralDoJogador>
  );
}

export default HistoricoDeSaque;
