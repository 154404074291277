import { useEffect, useState, useRef, useMemo } from "react";
import Capacete from "../../assets/mines/capacete.png"
import Capsblock from "../../assets/mines/capacete-blocked.png"
import Bomb from "../../assets/mines/bomb2.png"
import Bombblock from "../../assets/mines/bomb-blocked.png"
import Bombexplode from "../../assets/mines/bomb-explode.png"
import Swal from "sweetalert2";
import useSocket from "../../hooks/useSocket";
import useRender from "../../hooks/useRender";
import audioFlip from '../../assets/audio/minaFlip.mp3'
import audioBomb from '../../assets/audio/minaBomb.mp3'
import audioWin from '../../assets/audio/minaWin.mp3'
import audioWin1 from '../../assets/audio/1win.mp3'
import audioWin2 from '../../assets/audio/2win.mp3'
import audioWin3 from '../../assets/audio/3win.mp3'
import audioWin4 from '../../assets/audio/4win.mp3'
import audioWin5 from '../../assets/audio/5win.mp3'
import audioHover from '../../assets/audio/minaHover.mp3'
import audioClickButton from '../../assets/audio/minaClickButton.mp3'
import Mine1 from "../../assets/mines/mina-1.png"
import Mine2 from "../../assets/mines/mina-2.png"
import Mine3 from "../../assets/mines/mina-3.png"
import Mine4 from "../../assets/mines/mina-4.png"
import Mine5 from "../../assets/mines/mina-5.png"
import Mine6 from "../../assets/mines/mina-6.png"
import Mine7 from "../../assets/mines/mina-7.png"
import Iconalert from "../../assets/mines/icons-alert.png"

import WinMines from "./Win";

let clickBusy;


function MinesCard({ isMine, cards, setJogando }) {

  const { socketMine, status, parou, isAudioOn } = useSocket()

  const backCard = useRef(null);

  function Revelar() {
    if(clickBusy == true) return;
    clickBusy = true;

    // REVELA A POSIÇÃO QUE O USUARIO CLICOU 
    let result = {};
    result.pos = isMine // mina que foi clicada
    socketMine.emit('revelar', result);

    if (cards[isMine].mina == false) {
      const capacete = new Audio(audioFlip);
      if (isAudioOn) capacete.play()
    }


  }

  // RELEVA TODAS OS QUADROS - MINAS E CAPACETES * APOS EXPLODIR
  function ShowAllLoss() {
    if (cards[isMine].mina == true) {
      setJogando(true)


      let cardsBack = document.querySelectorAll('.back-card-mines');  // após achar uma mina revela todas as cartas para cima
      for (let i = 0; i < cardsBack.length; i++) {
        cardsBack[i].style.display = 'none';
      }
    }
  }




  function handleClick() {
    // vira a card clicada para cima
    if (!cards[isMine].revelado) {
      Revelar()
      handleAddItem()
    }
  }


  function handleAddItem() {

    // Adiciona a classe `fade-in` ao elemento do item recém-adicionado
    const newItemElement = document.getElementById(`historicoWinGG`);

    newItemElement.classList.add('fade-in');

    // Remove a classe `fade-in` após 0,3 segundos
    setTimeout(() => {
      newItemElement.classList.remove('fade-in');
    }, 300);

  }

  // Aqui você pode adicionar a lógica que deseja executar quando o mouse entra no elemento
  const handleMouseEnter = () => {
    const hover = new Audio(audioHover);
    if (isAudioOn) hover.play()

  };





  useEffect(() => {
    ShowAllLoss()

  }, [status])

  return (
    <div ref={backCard} id="card-mines" className="card-mines"  >
      <div className=" face front-card-mines" style={{ backgroundImage: `url(${cards[isMine].mina == true ? `${cards[isMine].revelado ? Bombexplode : parou == true ? Bombblock : Bomb}` : `${cards[isMine].revelado ? Capacete : Capsblock}`})` }}> </div>
      <div onClick={handleClick} onMouseEnter={handleMouseEnter}
        className="face back-card-mines hover:backdrop-brightness-100 hover:scale-110 duration-300" style={{ display: cards[isMine].revelado && 'none' }}> </div>
    </div>
  );
}






export default function MinesGame() {


  const { socketMine, setStatus, status, historicoWin,
    setHistoricoWin, nextWin, setNextWin, valor,
    setValor, setParou, isAudioOn, setIsAudioOn } = useSocket()

  const { updateSaldos } = useRender()
  const [buttonGame, setButtonGame] = useState('iniciar')
  const [loading, setLoading] = useState(false)

  const [mina, setMina] = useState((mina) => {
    let storageMina = localStorage.mina ? localStorage.mina : 1
    mina = storageMina
    return mina
  })

  const [proximoWin, setProximoWin] = useState(1.01);
  const [nextMultiplicador, setNextMultiplicador] = useState(); // VALOR DO MULTIPLICADOR
  const [nextGanho, setNextGanho] = useState(); // VALOR QUE O USUARIO GANHA MINA WIN
  const [jogando, setJogando] = useState(true)

  const [cards, setCards] = useState([
    1, 2, 3, 4, 5,
    6, 7, 8, 9, 10,
    11, 12, 13, 14, 15,
    16, 17, 18, 19, 20,
    21, 22, 23, 24, 25




  ]);

  const array_multiplicador = [0, 1.01, 1.05, 1.10, 1.15, 1.21, 1.27, 1.34, 1.43, 1.51, 1.61, 1.73, 1.86, 2.02, 2.20, 2.42, 2.69, 3.13, 3.46, 4.04, 4.85];

  const listMina = [
    { id: 1, mina: 1 },
    { id: 2, mina: 2 },
    { id: 3, mina: 3 },
    { id: 4, mina: 4 },
    { id: 5, mina: 5 },
    { id: 6, mina: 6 },
    { id: 7, mina: 7 },
    { id: 8, mina: 8 },
    { id: 9, mina: 9 },
    { id: 10, mina: 10 },
    { id: 11, mina: 11 },
    { id: 12, mina: 12 },
    { id: 13, mina: 13 },
    { id: 14, mina: 14 },
    { id: 15, mina: 15 },
    { id: 16, mina: 16 },
    { id: 17, mina: 17 },
    { id: 18, mina: 18 },
    { id: 19, mina: 19 },
    { id: 20, mina: 20 },


  ]


  const [winfirst, setWinFirst] = useState(0);
  const [countwin, setCountWin] = useState(0);

  function clickBusyFalse() {
    clickBusy = false;
    setTimeout(clickBusyFalse,600);
  }




  function Register() {

    clickBusyFalse();



    let token = localStorage.user_token // "PY5uOaNcPVlbF4mcvSpS" 
    socketMine.emit('register', { 'token': token });

    socketMine.off('atualizaSaldo');
    socketMine.on('atualizaSaldo', (result) => {
      updateSaldos(result)

    });


    socketMine.off('loss');
    socketMine.on('loss', (result) => {

      const bomb = new Audio(audioBomb);

      if (isAudioOn) bomb.play()



    });



    initiateTabuleiro()


  }


  function ResetPartida(perdeu) {
    setTimeout(() => {
      if (perdeu) {

        setJogando(true);



        setHistoricoWin([]);
        setNextMultiplicador("")

        localStorage.removeItem("mina")
        setNextWin(0)



        setProximoWin(0)

        let cardsBack = document.querySelectorAll('.back-card-mines');  // após achar uma mina revela todas as cartas para cima

        for (let i = 0; i < cardsBack.length; i++) {
          cardsBack[i].style.display = 'block';
        }

        let cardFliped = document.querySelectorAll('.rotate-y-180');  // após achar uma mina revela todas as cartas para cima

        for (let i = 0; i < cardFliped.length; i++) {
          cardFliped[i].classList.remove('rotate-y-180');

        }
        setJogando(false);


      }
    }, 1000)

  }

  const handleMouseEnter = () => {
    const hover = new Audio(audioHover);
    if (isAudioOn) hover.play()

    // Aqui você pode adicionar a lógica que deseja executar quando o mouse entra no elemento
  };

  const click = new Audio(audioClickButton);

  function Somar() {
    if (isAudioOn) click.play()
    setValor(valor * 2)
    AtualizarProximoGanho()
  }

  function Subtrair() {
    if (isAudioOn) click.play()
    setValor(valor > 0 ? Math.floor(valor / 2) : null)
    AtualizarProximoGanho()
  }

  function SetValor(valorBtn) {
    if (isAudioOn) click.play()
    setValor(valorBtn)
    // AtualizarProximoGanho()
  }

  function AtualizarProximoGanho() {
    let indexFormated = parseInt(mina)
    let soma = array_multiplicador[indexFormated] * valor
    setProximoWin(soma.toFixed(2));
  }

  function CancelarJogada() {
    if(clickBusy == true) return;
    clickBusy = true;

    if (isAudioOn) click.play()
    socketMine.emit('cancelar');
    ResetPartida(true)
    setButtonGame("cancelar")
  }



  function IniciarPartida() {
    if(clickBusy == true) return;
    clickBusy = true;

    if (valor < 1) {
      Swal.fire(
        "Ops",
        "Aposta deve ser maior que 1 real.",
        'error'
      )
      return;
    }
    initiateTabuleiro() // SOCKET



    let storageSaldo = localStorage.user
    let formated = JSON.parse(storageSaldo)
    // console.log(formated.user.saldo, "aah")


    if (valor > 0 && parseFloat(formated.user.saldo) >= valor) {

      if (isAudioOn) click.play()
      let result = {};
      result.aposta = valor; // valor da aposta
      result.minas = mina;  // quantidade de mina escolhida
      setButtonGame(`BRL ` + ` ` + nextGanho)

      socketMine.emit('iniciarPartida', result);
      let ganhoInicial = valor * nextMultiplicador
      let ganhoInicialValue = ganhoInicial.toFixed(2)
      setNextWin(ganhoInicialValue)
      document.getElementById("opacityMine").classList.add("hidden");
      document.getElementById("opacityButtons").classList.remove("hidden");
      localStorage.setItem("mina", mina)
      setParou(false)
      return
    }
    if (isAudioOn) click.play()
    Swal.fire(
      "Ops",
      "Você não tem saldo disponivel para jogar.",
      'error'
    )
  }

  function mensagemwin() {
    let winMines = document.getElementById("winMines");

    winMines.classList.remove('hidden');
    winMines.classList.add('flex');

    setTimeout(() => {
      winMines.classList.remove('scale-75')
      winMines.classList.add('scale-150')
    }, 50)
    setTimeout(() => {
      winMines.classList.remove('scale-150')
      winMines.classList.add('scale-100')
    }, 500)
    setTimeout(() => {
      winMines.classList.remove('flex');
      winMines.classList.add('hidden');
    }, 3000)
    setTimeout(() => {
      winMines.classList.remove('scale-100')
      winMines.classList.add('scale-75')
    }, 3050)


  }
  const win = new Audio(audioWin);
  const win1 = new Audio(audioWin2);
  const win2 = new Audio(audioWin2);
  const win3 = new Audio(audioWin3);
  const win4 = new Audio(audioWin4);
  const win5 = new Audio(audioWin5);

  function audiosRick() {
    // console.log("countwin =", countwin)
    // console.log("winfirst =", winfirst)
    /* a cada vitória no intervalo de 5 min.*/
    if (countwin == 0 && winfirst == 0) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)

      }

    }
    if (countwin == 1 && winfirst == 0) {
      if (isAudioOn) {
        click.play()
        win1.play()
        win.play()
        setCountWin(countwin + 1)
        setWinFirst(1)
      }

    }

    if (countwin == 1 && winfirst == 1) {

      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)

      }

    }

    if (countwin == 2) {
      if (isAudioOn) {
        click.play()
        win2.play()
        win.play()
        setCountWin(countwin + 1)
      }

    }
    if (countwin == 3) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }

    if (countwin == 4) {
      if (isAudioOn) {
        click.play()
        win3.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }
    if (countwin == 5) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }
    if (countwin == 6) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }
    if (countwin == 7) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }

    if (countwin == 8) {
      if (isAudioOn) {
        click.play()
    //  win4.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }

    if (countwin == 9) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }
    if (countwin == 10) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }
    if (countwin == 11) {
      if (isAudioOn) {
        click.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }
    if (countwin == 12) {
      if (isAudioOn) {
        click.play()
        win5.play()
        win.play()
        setCountWin(countwin + 1)
      }
    }

    if (countwin >= 12) {
      if (isAudioOn) {
        click.play()
        win.play()

      }
    }



  }

  function PararJogada() {
    if(clickBusy == true) return;
    clickBusy = true;
    
    
    if (status.perdeu == false) {
      socketMine.emit('parar');
      let cardsBack = document.querySelectorAll('.back-card-mines');  // após parar revela todas as cartas para cima

      for (let i = 0; i < cardsBack.length; i++) {
        cardsBack[i].style.display = 'none';
      }
      setCountWin(1);
      ResetPartida(true)
      setParou(true)
      setProximoWin(0)
      audiosRick()

      mensagemwin()
    }



  }

  function initiateTabuleiro() {


    socketMine.off("atualizarTabuleiro");

    socketMine.on('atualizarTabuleiro', (result) => {
      const multiplicador = result.multiplicador
      const ganho = result.ganho
      if (result.perdeu) {
        setCountWin(0);
        setWinFirst(0);
      }
      setHistoricoWin(result.multiplicadorLista)
      setCards(result.tabuleiro)
      setStatus(result)

      const nextWinValue = ganho ? ganho.toFixed(2) : "0.00";
      setNextGanho(nextWinValue)


      const NextMultiplicadorValue = multiplicador ? multiplicador.toFixed(2) : "0.00";
      setNextMultiplicador(NextMultiplicadorValue)

      let NextWinValue = multiplicador ? multiplicador.toFixed(2) * valor : "1.01";
      setNextWin(parseFloat(NextWinValue).toFixed(2))

      setProximoWin(parseFloat(NextWinValue).toFixed(2));

      // console.log(result, "tabuleiro atualizado");

      ResetPartida(result.perdeu)  // verifica se perdeu e reseta




    });
  }






  function openLogin() {
    document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
    document.querySelector(".login__modal").classList.toggle("opacity-0");
    document.querySelector(".login--opacidade").classList.toggle("hidden");
    document.getElementById("login").focus();
  }


  useEffect(() => {

    Register();
    socketMine.off('pararjogada')
    socketMine.on('pararjogada', (result,) => {
      ResetPartida(true)

    })


    socketMine.off('serverOn')
    socketMine.on('serverOn', (result) => {
      // console.log("serverOn", result)
      setJogando(false)

    })

    socketMine.off('btnStatus');
    socketMine.on("btnStatus", (btn) => {
      // console.log("btnStatus", btn);
      setJogando(!btn.status);
      setButtonGame("iniciar")
    });


    socketMine.off('displayTabuleiro');
    socketMine.on("displayTabuleiro", (result) => {
      if (result) {
        document.getElementById("opacityMine").classList.add("hidden");
        document.getElementById("opacityButtons").classList.remove("hidden");
      } else {
        document.getElementById("opacityMine").classList.remove("hidden");
        document.getElementById("opacityButtons").classList.add("hidden");
      }
    });


    setInterval(() => {
      setWinFirst(0);
    }, 18000000);




  }, [])


  useEffect(() => {

    // Verifica se a partida já foi iniciada
    if (localStorage.mina) {
      setButtonGame(`BRL ` + ` ` + nextGanho)
      document.getElementById("opacityMine").classList.add("hidden");
      document.getElementById("opacityButtons").classList.remove("hidden");


    }


  }, [])


  function toggleAudio() {

    setIsAudioOn(!isAudioOn);
    localStorage.removeItem("sound")
    if (isAudioOn) {
      click.play()
      localStorage.setItem("sound", false)
    }
  }


  // atualiza valor do proximo ganho
  useEffect(() => {
    let indexFormated = parseInt(mina)
    let soma = array_multiplicador[indexFormated] * valor
    setProximoWin(soma.toFixed(2));
  }, [valor, mina]);




  return (<>
    <WinMines ganho={nextGanho} />
    {/*
    <div className="w-full flex justify-center">
        <div className="w-10/12 lg:w-4/12 mt-5 rounded-md p-3 bg-purple-900 flex">
          <img className="w-10 mr-2  " src={Iconalert}/><div className="pl-2 border-l-2 border-white text-[0.6em] font-base text-white">Mines está em manutenção. <br/> Quinta-feira às 14h00pm no horário de brasília estará disponível para jogar novamente</div>
        </div>
    </div>
  */}

    <div className="w-full relative flex justify-center mt-4 ">

      <div className="grid lg:grid-cols-7 place-items-center lg:w-10/12 md:min-h-[364px] md:min-w-[364px] w-full bg-[#48027F] border-[8px] border-[#300750] rounded-3xl p-4 py-10 lg:py-4  " >

        <div className="lg:w-9/12 flex justify-center items-center w-full  md:min-h-[364px] md:min-w-[364px]  bg-[#260342] rounded-xl lg:col-span-3  col-span-7 relative ">
          <div id="opacityMine" className="w-full h-full absolute z-[1] bg-[#260342] rounded-xl opacity-70">

          </div>{/* text-white   duration-300 lg:absolute  lg:top-[-30px] lg:right-[-10px] hidden lg:block bg-[#4F127E]  rounded-xl p-1 cursor-pointer hover:bg-[#8A3FC6]  hover:translate-y-[-4px] */}
          <button onClick={toggleAudio} className="text-white    duration-300 absolute  top-[-35px] right-[-10px] z-[1] lg:hidden bg-[#4F127E] hover:bg-[#8A3FC6]   rounded-xl p-1  ">
            {isAudioOn ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
                <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
                <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
                <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" />
              </svg>
            )}

          </button>
          {/* Game */}

          <div className="relative grid grid-cols-5 gap-2 lg:p-4 p-2  bg-[#260342] rounded-xl   ">
            <div className="w-full  px-3 absolute top-[-10px] text-[0.8em] text-white grid-cols-5  z-[1] grid">
              <div className="text-center ">A</div>
              <div className="text-center">B</div>
              <div className="text-center">C</div>
              <div className="text-center">D</div>
              <div className="text-center">E</div>

            </div>

            <div className="py-3 text-[0.8em] h-full absolute  left-[-5px] text-white grid-rows-5  z-[1] grid">
              <div className="self-center ">1</div>
              <div className="self-center">2</div>
              <div className="self-center">3</div>
              <div className="self-center">4</div>
              <div className="self-center">5</div>

            </div>

            {cards?.map((_, index) => (
              <MinesCard
                key={index}
                cards={cards}
                isMine={index}
                setJogando={setJogando}


              />
            ))}
          </div>

        </div>


        {/* ---------- Próximo Ganho ----------- */}
        {/* PC */}
        <div className=" hidden lg:block  lg:border-x-[6px] lg:border-y-0 border-y-[3px]  border-[#300750]  lg:col-span-1  col-span-7 w-full h-full lg:w-10/12 uppercase text-white font-semibold text-center leading-4">
          <div className=" py-4 lg:bg-gradient-to-b lg:from-[#48027F] lg:via-[#48027F]  lg:to-transparent flex lg:flex-col items-center gap-1 justify-center " style={{ position: "relative", zIndex: "2" }}>
            <p className="text-sm leading-3"> próximo</p> <p className="text-sm leading-3">ganho</p>
          </div>

          <div className="  relative  ">
            <div className="border-[#00DD4C]  border-x h-5/6 mt-[-20px] w-0 mx-auto " style={{ position: "relative", zIndex: "1" }}></div>

            <div className="flex flex-col absolute top-4 left-0 right-0 gap-6 duration-700 overflow-y-hidden h-5/6 ">
              <div className={` cursor-pointer duration-700 bg-[#34DFB1] text-[#300750] rounded-md mx-2 py-1 text-[13px] font-semibold `} style={{ zIndex: "3" }}>{proximoWin}</div>
              <div className="flex flex-col-reverse gap-6 ">
                {historicoWin?.map((item, index) => {

                  return (
                    <>
                      {item.valor != nextWin ? (
                        <div key={index} id={`historicoWinGG`} className={`cursor-pointer duration-700 bg-[#B6BBBA] text-[#300750] rounded-md mx-5 py-0.5 text-[13px] font-semibold${item.valor == nextWin ? `hidden` : ` `} `} style={{ zIndex: "3" }}>{item.valor != nextWin ? item.valor.toFixed(2) : null}</div>
                      ) : " "}


                    </>
                  )
                })}
              </div>



            </div>

          </div>
        </div>

        {/* CELULAR */}
        <div className=" lg:hidden overflow-hidden    mt-4  border-y-[3px]  border-[#300750]  col-span-7 w-full h-full  uppercase text-white font-semibold text-center leading-4">
          <div className=" py-2  flex l items-center gap-1 justify-end " style={{ position: "relative" }}>
            <p> próximo</p> <p>ganho</p>
          </div>

          <div className="flex flex-row-reverse bg-[#300750] rounded-l-lg items-center relative gap-3 duration-700  ">
            <div className="bg-[#48027F] flex justify-end">
              <div className="cursor-pointer right-0  duration-700 bg-[#34DFB1] text-[#300750] rounded-md ml-3  px-5  py-1 text-[13px] font-semibold ">{proximoWin}</div></div>

            {historicoWin?.reverse().map((item) => {

              return (

                <div id={`historicoWinGG`} className="cursor-pointer duration-700 bg-[#B6BBBA] text-[#300750] rounded-md px-3 py-0.5 text-[13px] font-semibold " style={{ zIndex: "3" }}>{item.valor != nextWin ? item.valor.toFixed(2) : null}</div>


              )
            })}




          </div>
        </div>




        {/* ---------- Buttons ----------- */}
        <div className="w-full lg:px-5 h-full flex-col-reverse rounded-lg lg:col-span-3 col-span-7 mt-10 relative">
          <div id="opacityButtons" className="w-full h-4/6 left-0 bg-[#2603421a] bottom-[40px] lg:bottom-[0px] lg:top-[20px] right-0 absolute z-[2]  rounded-xl opacity-70 cursor-not-allowed hidden">

          </div>

          <button onClick={toggleAudio} className="text-white   duration-300 lg:absolute  lg:top-[-30px] lg:right-[-10px] hidden lg:block bg-[#4F127E]  rounded-xl p-1 cursor-pointer hover:bg-[#8A3FC6]  hover:translate-y-[-4px] ">
            {isAudioOn ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16">
                <path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z" />
                <path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z" />
                <path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor" class="bi bi-volume-mute-fill" viewBox="0 0 16 16">
                <path d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z" />
              </svg>
            )}

          </button>

          <div className="flex flex-col-reverse lg:flex-col">

            <div>
              <div className="bg-[#300750] mt-5 rounded-xl flex justify-center items-center h-14 text-xl font-semibold text-white relative  " >
                <div className="absolute right-[40px]    ">
                  {mina == 1 && <img src={Mine1} alt="" />}
                  {mina == 2 && <img src={Mine2} alt="" />}
                  {mina == 3 && <img src={Mine3} alt="" />}
                  {mina == 4 && <img src={Mine4} alt="" />}
                  {mina == 5 && <img src={Mine5} alt="" />}
                  {mina == 6 && <img src={Mine6} alt="" />}
                  {mina >= 7 && <img src={Mine7} alt="" />}
                </div>


                <select className="bg-transparent  z-[1] items-center  px-2 mr-3  text-center w-full flex justify-center outline-none" value={mina} onChange={(e) => { setMina(e.target.value) }}>
                  {listMina?.map((item) => (
                    <option className="bg-[#300750]  w-full  text-center" key={item.id} value={item.mina}>

                      {item.mina} {item.mina > 1 ? `MINAS` : "MINA"}</option>
                  ))}
                </select>
              </div>

              <div className="bg-[#6C10B4] rounded-xl mt-6 pb-2 ">
                <div className="flex items-center justify-between px-3 pt-1">
                  <div onMouseEnter={handleMouseEnter} onClick={Subtrair} className="bg-[#8A3FC6]  lg:w-16  lg:h-10 w-14 h-8 rounded-lg flex justify-center items-center font-black text-4xl  text-[#300750] cursor-pointer hover:bg-[#4F127E] hover:text-white duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                  </div>

                  <div className="bg-[#2d1341] w-7/12 lg:h-14 h-12 mx-4 lg:mx-0 rounded-lg text-white flex justify-center items-center uppercase text-[10px] "> <strong className=" ml-2 mr-auto">brl</strong> <input className="bg-transparent w-full  text-center  text-white pr-8  outline-none text-2xl" type="number" value={valor} onChange={(e) => { setValor(e.target.value) }} /></div>

                  <div onMouseEnter={handleMouseEnter} onClick={Somar} className="bg-[#8A3FC6] lg:w-16  lg:h-10 w-14 h-8 rounded-lg flex justify-center items-center font-semibold text-4xl  text-[#300750] cursor-pointer hover:bg-[#4F127E] hover:text-white duration-500 ">
                    +
                  </div>

                </div>

                <hr className="border border-[#4F127E] my-2 mx-4" />

                <div className="grid grid-cols-6 text-white font-bold gap-1 mx-2 text-[13px] lg:text-sm">
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(1) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px] ">1</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(2) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">2</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(5) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">5</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(10) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">10</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(20) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">20</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(25) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">25</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(50) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px] ">50</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(100) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">100</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(150) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">150</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(200) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">200</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(250) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">250</div>
                  <div onMouseEnter={handleMouseEnter} onClick={() => { SetValor(500) }} className="bg-[#4F127E] flex justify-center items-center rounded-xl h-10 cursor-pointer hover:bg-[#8A3FC6] hover:text-[#300750] duration-500 hover:translate-y-[-4px]  ">500</div>
                </div>

              </div>
            </div>

            <div>
              {localStorage.user_token ? (
                <div className="flex justify-center">
                  <button onMouseEnter={handleMouseEnter} disabled={jogando} onClick={() => {   buttonGame == "iniciar" ? IniciarPartida() : nextGanho == 0 ? CancelarJogada() : PararJogada() }} className={`disabled:opacity-40  rounded-xl 2  text-white w-full uppercase py-2 mt-2 lg:py-3 font-semibold lg:mt-14 lg:text-2xl duration-500 ${buttonGame != "iniciar" && nextGanho == 0 ? `bg-red-600 hover:bg-red-800` : `bg-[#34DFB1]  hover:bg-[#1d866a] `}  `}>{buttonGame == "iniciar" ? (<> {buttonGame} </>) : nextGanho == 0 ? "JOGO INICIADO" : <><div className="flex justify-center"> <div className="text-purple-800 mr-1">Cashout </div> <div className="w-[1px] lg:h-[35px] h-[25px] mr-1 bg-white"></div> BRL {nextGanho} </div>  </>}</button>
                </div>
              ) : (
                <div className="flex justify-center">
                  <button onMouseEnter={handleMouseEnter} disabled={jogando} onClick={openLogin} className="bg-[#34DFB1] disabled:opacity-40 hover:bg-[#1d866a] duration-500 rounded-xl 2  text-white w-full uppercase py-2 mt-8 lg:py-3 font-semibold lg:mt-14 lg:text-2xl">{buttonGame == "iniciar" ? (<> {buttonGame} </>) : <><div className="flex justify-center"> <div className="text-purple-800 mr-1">Cashout </div> <div className="w-[1px] lg:h-[35px] h-[25px] mr-1 bg-white"></div> BRL {nextGanho} </div>  </>}</button>
                </div>

              )}

            </div>
          </div>



        </div>


      </div>
    </div>


  </>
  );
}