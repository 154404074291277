  import useCupom from '../../../assets/img/USEEGANHE.png';
  import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
  import Close from '../../../assets/icons/close.svg';
  import useRender from '../../../hooks/useRender';
  import Swal from "sweetalert2";
  import ModalDepositoCupom from './ModalDeposito';
  
  
  function ConteudoCupom (props) {
    const {prefixoUrl} = useRender();

    function closeModalDepositoPendente() {
      document.getElementById("modalDepositoPendente").classList.toggle("hidden");
    }

    function closeCupom() {
        document.getElementById(props.openmodal).classList.toggle("hidden");
     
      }

      

      function handlerAtivarCupom(btn){
        let svg = btn.target;
        svg = svg.getElementsByTagName('svg');
        let cupom = document.getElementById('cupom').value;

        var requestOptions = {
          method: 'GET',
        };
        
        fetch(`${prefixoUrl}/cupom/utilizar/${cupom}?token=${localStorage.getItem("user_token")}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.code == 1){ 
              Swal.fire(
                "Parabéns",
                'Bônus ativo com sucesso.',
                'success'
              ).then(() => {
                window.location.href = '/bonus'
              });
     
            }else{
              Swal.fire(
                "Ops",
                result.msg,
                'error'
              )

            }
            
          })
          .catch(error => {
            console.log('error', error);
      

          })
          
          .finally(()=> {
            svg[0].classList.remove('animate-spin')
          });
      }


      function apiVerificarCupom(btn){
        let svg = btn.target;
        svg = svg.getElementsByTagName('svg');
        svg[0].classList.add('animate-spin');
        let cupom = document.getElementById('cupom').value;
          if(cupom){

            var myHeaders = new Headers();


            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
    
            fetch(`${prefixoUrl}/cupom/buscar/${cupom}?token=${localStorage.getItem("user_token")}`, requestOptions)
              .then(response => response.json())
              .then(result =>{
                if(result.code == 1) {
                  handlerAtivarCupom(btn)
                } else if (result.code == 2){
                  //codigo 2 => cupom ok porem precisa depositar
                  closeModalDepositoPendente()
                  openCupom()
                  svg[0].classList.remove('animate-spin')
                } else {
                  Swal.fire(
                    "Ops",
                    result.msg,
                    'error'
                  )
                  svg[0].classList.remove('animate-spin')
                }
              })
              .catch(error => console.log('error', error));
          }
          else{
            Swal.fire(
              "Ops",
              "Insira um cupom válido.",
              'error'
            )
            svg[0].classList.remove('animate-spin')
          }
              }




              function openCupom(){
                let opacity = document.getElementById("cupomOpacity")
            
                setTimeout(() => {
                  opacity.classList.toggle("opacity-0");
            
                }, 100);
            
            
                
              }


    return ( 
        <>

        <div id="cupomOpacity" className="bg-cinzafourth rounded-lg z-[6] grid grid-cols-1 justify-items-center gap-2 pb-4 p-2 opacity-0 duration-700  ">
            <div onClick={closeCupom} className="flex justify-end w-full">
                <img className="cursor-pointer hover:animate-spin duration-1000 w-4" src={Close} alt="fechar" />
            </div>
            <div>
                <img src={useCupom} alt="" className="" />
            </div>
            <div className="w-8/12">
                <p className="text-white text-sm">Digite o código de cupom para receber bônus ou recompensas</p>
            </div>

            <div>
            <input
          type="text"
          id="cupom"
          className="uppercase bg-[#242020]  w-72 h-9 text-center rounded-[10px] text-white   focus:outline-none"
          placeholder="Código do cupom"
        />
            </div>

            <button  onClick={(e) => apiVerificarCupom(e)} className="cursor-pointer lg:mt-0 mt-5 bg-verdesixtyn hover:bg-[#0c6d40] hover:text-white duration-1000  font-bold text-sm  w-72 h-9  rounded-md items-center flex justify-center gap-1 ">
            <CurrencyExchangeRoundedIcon fontSize="small"/> ATIVAR CUPOM
             </button>             
                
        </div>

          <ModalDepositoCupom/>
        </>
     );
  }
  
  export default ConteudoCupom ;