
import Instagram from "../../../../assets/popup/instagram.png";
import Telegram from "../../../../assets/popup/telegram.png";
import indicacaoImg from "../../../../assets/popup/indicacao.webp";
import Sorteio from "../../../../assets/sorteio/cupom.png";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { HashLink as Link } from "react-router-hash-link";
import useRender from "../../../../hooks/useRender";
import X from "../../../../assets/pagamento/x.png"


function ConteudoPopUp(props) {
  const [cupom] = useState("JELLY200");
  const [isNewsActive, setNewsActive] = useState(true);
  const [isRedesActive, setRedesActive] = useState(false);
  const [isBonusActive, setBonusActive] = useState(false);
  const [numberRandons, setNumbersRandons] = useState([]);
  const [isConteudo, setConteudo] = useState("news");
  const [contador, setContador] = useState({
    dias: 0,
    horas: 0,
    minutos: 0,
    segundos: 0,
  });
  const { prefixoUrl, updateSaldos, openLogin, closeLogin } = useRender();

  // useEffect(() => {
  //   const dataFinal = new Date("2023-08-01T18:00:00");
  //   const timer = setInterval(() => {
  //     const agora = new Date().getTime();
  //     const diferenca = dataFinal - agora;

  //     const horas = Math.floor(
  //       (diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
  //     const segundos = Math.floor((diferenca % (1000 * 60)) / 1000);

  //     setContador({ horas, minutos, segundos });
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, []);



  function closeCupom() {
    document.getElementById(props.openmodal).classList.toggle("hidden");
    openRedesSociais()
  }
  function openRedesSociais() {
    let method = document.getElementById("modalPopUp2")
    method.classList.toggle("hidden");
    let opacity = document.getElementById("popup--2")
    opacity.classList.add("opacity-0");

    setTimeout(() => {
        opacity.classList.toggle("opacity-0");

    }, 100);
}




  function cpPromo() {
    let textoCopiado = document.getElementById("txtPromo");
    textoCopiado.select();
    navigator.clipboard.writeText(textoCopiado.value);
    Swal.fire("Sucesso", "Cupom copiado!", "success");
  }

  function handleRedesClick() {
    setRedesActive(true);
    setBonusActive(false);
    setNewsActive(false);
    setConteudo("redes");
  }

  function handleBonusClick() {
    setRedesActive(false);
    setBonusActive(true);
    setNewsActive(false);
    setConteudo("bonus");
  }

  function handleNewsClick() {
    setRedesActive(false);
    setBonusActive(false);
    setNewsActive(true);
    setConteudo("news");
  }





  return (
    <>
      <div id="popup--1" className="bg-[#170d30] bgpopup lg:w-4/12 w-11/12 border-2 border-[#2d2934] rounded-lg z-[6]  p-2 opacity-0 duration-700 relative pb-5 "   >

        <div className="w-full font-bold flex justify-center">
          <div className="flex justify-between w-11/12 border border-white p-2 rounded-md">
            <div className={`bg-white lg:text-base text-[0.8em] flex justify-center items-center cursor-pointer mx-2 px-2 rounded-md`} >
              Novidades
            </div>
            <div onClick={closeCupom} className="cursor-pointer text-white w-6">
              <img src={X} />
            </div>

          </div>
        </div>

       
          <>
            <div className="min-h-[450px] lg:min-h-[550px]">
              <div className="w-full flex items-center p-3 flex-col">
                <img src={Sorteio} />

                <div className="relative">
                  {!localStorage.user_token
                    ?
                    <>
                      <div onClick={openLogin} className="bg-green-500 absolute hover:text-white duration-300 rounded-2xl hover:rounded-md py-2 animate-ping px-4 text-2xl font-bold hover:scale-105" >
                        SAIBA MAIS
                      </div>
                    </>
                    :
                    <>
                      <Link className="bg-green-500 absolute hover:text-white duration-300 rounded-2xl hover:rounded-md py-2 animate-ping px-4 text-2xl font-bold hover:scale-105" to="/centraldojogador/promocao">
                        SAIBA MAIS
                      </Link>
                    </>
                  }

                  <div className="bg-green-500 hover:text-white duration-300 rounded-2xl hover:rounded-md py-2  px-4 text-2xl font-bold hover:scale-105" >
                    SAIBA MAIS
                  </div>
                </div>
              </div>
            </div>
          </>
      
      </div>
    </>
  );
}

export default ConteudoPopUp;
