export default function RegrasReembolsoEn(){
    return(
    <>
      <div className="flex flex-col text-gray-400 text-[0.8em]">
      <div className="flex w-full justify-center">
    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">REFUND POLICY</div>
</div>

No refunds can be processed once the deposited funds (including bonus) have been used in the gaming process. A refund request will only be considered if it is requested within the first twenty-four (24) hours of the alleged transaction. We reserve the right to withhold any refund or reverse transaction until the identity of the User of the player account is properly established to our satisfaction. You agree to provide, upon our request, authenticated identification or any other certified identification in accordance with the applicable laws of the player's jurisdiction. If such authenticated or certified identification is not provided within five (5) days from our request, such refund or reverse transaction will not be made, your player Account will be terminated, and you will forfeit all funds in your player Account, and this decision will be final, binding, and non-appealable. A player must play using fair means in all games and must not in any way affect the outcome of such game. This includes the use of computer aids, mathematical equations, betting systems, etc.


              



            </div>
    </>
    )
}