export default function ContainerBlur(props) {
  function closemodal() {
    document.getElementById(props.openmodal).classList.toggle("hidden");
  }

  return (
    <>
      <div
        id={props.openmodal}
        className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center"
      >
        <div className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
          <div
            onClick={closemodal}
            className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "
          ></div>
          {props.children}
        </div>
      </div>
    </>
  );
}
