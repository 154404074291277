import { createContext, useState, useEffect } from "react";
import io from "socket.io-client";

export const SocketContext = createContext({});

const socketMine = io.connect("https://mina.rockeetz.com"); //https://rockeetz.com:3100

export const SocketProvider = ({ children }) => {
  const [status, setStatus] = useState()
  const [historicoWin, setHistoricoWin] = useState([])
  const [nextWin, setNextWin] = useState(0.00)
  const [valor, setValor] = useState(1)
  const [parou, setParou] = useState(false)
  const [isAudioOn, setIsAudioOn] = useState((status) => {
    let soundStorage = localStorage.sound
    if (soundStorage) {
      status = false
     return status
    } else {
      status = true
      return status
    }
  }); 










  return (
    <SocketContext.Provider
      value={{
        socketMine,
         status,
         setStatus,
         historicoWin,
         setHistoricoWin,
         nextWin, 
         setNextWin,
         valor, 
         setValor,
         parou,
         setParou,
         isAudioOn, 
         setIsAudioOn

      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
