import Loading from "../../../components/Loading";

import Paginate from 'react-paginate';

export default function TodosCadastrados(props){
    return(
        <>
         {props.isCadastrados ? 
         <>
          <section className="flex justify-center px-2 w-full">
            <div className="bg-cinzasixtyn border-t border-gray-900 h-full rounded-b-md p-3 text-white w-full">
              <div className="flex justify-between lg:p-4 lg:text-3xl lg:font-bold">
                <div>Quantidade de cadastros:</div> 
                <div>{props.indicacao?.numeroTotal || 0}</div>  </div>
              <div className="mt-2 bg-cinzasecondary w-full">
                {props.numeroTotalDeIndicados != undefined ? (
                  
                  props.numeroTotalDeIndicados?.numeroTotalDeIndicados.slice(props.currentPage * props.itemsPerPage, (props.currentPage + 1) * props.itemsPerPage).map((item, index) => {
                    return (
                      <>
                        <div className="bg-cinzaprimary h-[1px] w-full"></div>
                        <div key={index} className="grid gap-x-2 lg:text-base text-sm lg:gap-x-40 gap-y-4 grid-cols-2 items-center px-2 py-2 lg:px-5 lg:py-5 text-white">

                         

                          <div>
                            <p className="text-left lg:text-base text-purple-300 text-[0.8em]">CPF</p>
                            <p className="text-left lg:text-base text-[1em]">{item.identificacao}</p>
                          </div>

                          <div className="flex flex-col justify-end items-end">
                            <p className="text-left text-purple-300 lg:text-base text-[0.8em]">Data</p>
                            <p className="text-right lg:text-base text-[1em]">{item.data}</p>
                          </div>

      
                        </div>

                      </>
                    );
                  })
                ) : (
                  <Loading />
                )}

              </div>
              <Paginate
        previousLabel={'<'}
        nextLabel={'>'}
        nextClassName={'Orbitron font-bold text-xl relative top-[-4px]'}
        previousClassName={'Orbitron font-bold text-xl relative top-[-4px]'}
        breakLabel={'...'}
        breakClassName={'break-me text-sm px-3 py-1'}
        initialPage={1}
        pageCount={props.numeroTotalDeIndicados?.numeroTotalDeIndicados.length / props.itemsPerPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={1}
        onPageChange={({ selected }) => props.setCurrentPage(selected)}
        containerClassName={'flex justify-center py-4'}
        activeClassName={'active'}
        pageClassName={'mx-1'}
        pageLinkClassName={'px-2 py-1 transition-colors duration-200 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-400'}
        activeLinkClassName={'bg-purple-500 text-white'}
        previousLinkClassName={'px-4 py-1'}
        nextLinkClassName={'px-4 py-1 '}
 
      />

            </div>
            
          </section>
         </> :
         <></>}
        </>
    )
}