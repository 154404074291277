import { useEffect } from "react"

export default function CheckRegister(props) {

    return (
        <>
            <div className="w-full h-full hidden lg:block">
                
                <div className="w-full h-full relative top-[-40px] flex flex-col justify-center items-center">
               
                <div className="flex flex-col items-center">
                    <div className="font-bold text-white">BÔNUS DE ATÉ:</div>
                    <div className="relative">
                    <div className="text-6xl absolute z-[1] font-black text-purple-500 mb-10">BRL 5000</div>
                    <div className="relative top-[5px] text-6xl font-black text-black mb-10">BRL 5000</div>
                    </div>
                </div>
                <div>
                {props.step == "step1"
                        ?
                        <>
                            <div className="flex flex-col">
                                <div className="font-bold flex relative top-[2px]">
                                    <div className="flex-col relative">
                                        <div className="relative flex items-center justify-center left-[-5px] top-[6px]">
                                            <div className="h-[12px] w-[12px] relative  border-2 border-purple-500 rounded-xl"></div>
                                            <div className="h-[4px] w-[4px] absolute rounded-xl bg-purple-500"></div>
                                        </div>

                                        <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                    </div>
                                    <div className="font-bold text-white">Faça seu Cadastro</div>
                                </div>
                                <div className="font-bold flex relative top-[-1px]">
                                    <div className="flex-col relative">
                                        <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>
                                        <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                    </div>
                                    <div className="font-bold">Informações Pessoais</div>
                                </div>
                                <div className="font-bold flex relative top-[-2px]">
                                    <div className="flex-col relative">
                                        <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>
                                        <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                    </div>
                                    <div className="font-bold">Válidação</div>
                                </div>
                                <div className="font-bold flex relative top-[-3px]">
                                    <div className="flex-col relative">
                                        <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>

                                    </div>
                                    <div className="font-bold">Bônus Boas-Vindas!</div>
                                </div>
                            </div>
                        </>
                        : props.step == "step2" ?
                            <>
                                <div className="flex flex-col">
                                    <div className="font-bold flex relative top-[2px]">
                                        <div className="flex-col relative">
                                            <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 bg-black border-black rounded-xl"></div>

                                            <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                        </div>
                                        <div className="font-bold text-black">Faça seu Cadastro</div>
                                    </div>
                                    <div className="font-bold flex relative top-[-1px]">
                                        <div className="flex-col relative">
                                            <div className="relative flex items-center justify-center left-[-5px] top-[6px]">
                                                <div className="h-[12px] w-[12px] relative  border-2 border-purple-500 rounded-xl"></div>
                                                <div className="h-[4px] w-[4px] absolute rounded-xl bg-purple-500"></div>
                                            </div>

                                            <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                        </div>
                                        <div className="font-bold text-[1em] text-white">Informações Pessoais</div>
                                    </div>
                                    <div className="font-bold flex relative top-[-2px]">
                                        <div className="flex-col relative">
                                            <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>
                                            <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                        </div>
                                        <div className="font-bold">Válidação</div>
                                    </div>
                                    <div className="font-bold flex relative top-[-3px]">
                                        <div className="flex-col relative">
                                            <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>

                                        </div>
                                        <div className="font-bold">Bônus Boas-Vindas!</div>
                                    </div>
                                </div>
                            </>
                            : props.step == "step3" ?
                                <>
                                    <div className="flex flex-col">
                                        <div className="font-bold flex relative top-[2px]">
                                            <div className="flex-col relative">
                                                <div className="h-[12px] w-[12px] bg-black relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>

                                                <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                            </div>
                                            <div className="font-bold ">Faça seu Cadastro</div>
                                        </div>
                                        <div className="font-bold flex relative top-[-1px]">
                                            <div className="flex-col relative">
                                                <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 bg-black border-black rounded-xl"></div>

                                                <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                            </div>
                                            <div className="font-bold ">Informações Pessoais</div>
                                        </div>
                                        <div className="font-bold flex relative top-[-2px]">
                                            <div className="flex-col relative">
                                                <div className="relative flex items-center justify-center left-[-5px] top-[6px]">
                                                    <div className="h-[12px] w-[12px] relative  border-2 border-purple-500 rounded-xl"></div>
                                                    <div className="h-[4px] w-[4px] absolute rounded-xl bg-purple-500"></div>
                                                </div>


                                                <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                            </div>
                                            <div className="font-bold text-white">Válidação</div>
                                        </div>
                                        <div className="font-bold flex relative top-[-3px]">
                                            <div className="flex-col relative">
                                                <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>

                                            </div>
                                            <div className="font-bold">Bônus Boas-Vindas!</div>
                                        </div>
                                    </div>
                                </> : props.step == "step4" &&
                                <>
                                    <div className="flex flex-col">
                                        <div className="font-bold flex relative top-[2px]">
                                            <div className="flex-col relative">
                                                <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>

                                                <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                            </div>
                                            <div className="font-bold">Faça seu Cadastro</div>
                                        </div>
                                        <div className="font-bold flex relative top-[-1px]">
                                            <div className="flex-col relative">
                                                <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>

                                                <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                            </div>
                                            <div className="font-bold">Informações Pessoais</div>
                                        </div>
                                        <div className="font-bold flex relative top-[-2px]">
                                            <div className="flex-col relative">
                                                <div className="h-[12px] w-[12px] relative left-[-5px] top-[6px] border-2 border-black rounded-xl"></div>


                                                <div className="h-[39px] relative top-[5px] w-[2px] bg-black"></div>
                                            </div>
                                            <div className="font-bold">Válidação</div>
                                        </div>
                                        <div className="font-bold flex relative top-[-3px]">
                                            <div className="flex-col relative">

                                                <div className="relative flex items-center justify-center left-[-5px] top-[6px]">
                                                    <div className="h-[12px] w-[12px] relative  border-2 border-black rounded-xl"></div>
                                                    <div className="h-[4px] w-[4px] absolute rounded-xl bg-black"></div>
                                                </div>
                                            </div>
                                            <div className="font-bold">Bônus Boas-Vindas!</div>
                                        </div>
                                    </div>
                                </>
                    }
                </div>

               
                </div>
            </div>
            <div className="w-full h-full flex justify-center items-center lg:hidden">
                <div className="w-10/12 flex">
                    {props.step == "step1" ?
                        <>
                            <div className="bg-black rounded-xl h-[20px] font-bold flex justify-center items-center uppercase text-[0.5em] text-green-500 w-6/12">Confirmação de Email</div>
                            <div className="bg-black rounded-xl h-[20px] w-2/12"></div>
                            <div className="bg-black rounded-xl h-[20px] w-2/12"></div>
                            <div className="bg-black rounded-xl h-[20px] w-2/12"></div>
                        </>
                        : props.step == "step2" ? 
                        <>
                           <div className="bg-black rounded-xl h-[20px] font-bold flex justify-center items-center uppercase text-[0.5em] text-green-500 w-8/12">Informações pessoais</div>
                            
                            <div className="bg-black rounded-xl h-[20px] w-2/12"></div>
                            <div className="bg-black rounded-xl h-[20px] w-2/12"></div>
                        </>
                            : props.step == "step3" ? 
                            <>
                               <div className="bg-black rounded-xl h-[20px] font-bold flex justify-center items-center uppercase text-[0.5em] text-green-500 w-10/12">Válidação de Conta</div>
                            
                            <div className="bg-black rounded-xl h-[20px] w-2/12"></div>
                            </>
                                : props.step == "step4" && 
                                <>
                                   <div className="bg-black rounded-xl h-[20px] font-bold flex justify-center items-center uppercase text-[0.5em] text-green-500 w-full">Bônus boas-vindas!</div>
  
                                </>}
                </div>
            </div>
        </>
    )
}