export default function RegrasConteudo(){
return(
    <main className="pb-10">
 

    <div>

      <div className="flex items-center justify-center gap-3  py-6">
      <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              className="bi bi-journal-text hidden lg:block "
              viewBox="0 0 16 16"
            >
              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
            </svg>
      <p className="text-center text-3xl text-white ">
        INFORMAÇÕES GERAIS
      </p>
      </div>



      <div className="text-[#525f7f] text-justify px-3">
        <p>
          sistema operado por Prolifictrade, tendo como referência a indicação
          de valores aleatório de investimentos de riscos específicos. A
          Rockeetz aceita apenas cadastros de pessoas com mais de 18 anos de
          idade. Investir em sistemas aleatórios pode ser viciante. Jogue
          responsavelmente. A presente Política de Privacidade contém
          informações a respeito do modo como tratamos, total ou parcialmente,
          de forma automatizada ou não, os dados pessoais dos usuários que
          acessam nosso sistema. Seu objetivo é esclarecer os interessados
          acerca dos tipos de dados que são coletados, dos motivos da coleta e
          da forma como o usuário poderá atualizar, gerenciar ou excluir estas
          informações, esclarecendo que os cadastros e contas registradas no
          presente sistema não pertence aos usuários e sim a administradora do
          presente sistema. O sistema aleatório se encontra em testes, logo, a
          administradora não se responsabiliza por eventuais erros que não
          possam ser comprovados pelo usuário que o alega. Esta Política de
          Privacidade foi elaborada em conformidade com a Lei Federal n.
          12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei
          Federal n. 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção
          aos Dados - LGPD) e com o Regulamento UE n. 2016/679 de 27 de abril
          de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais -
          RGDP). Esta Política de Privacidade poderá ser atualizada em
          decorrência de eventual atualização normativa, razão pela qual se
          convida o usuário a consultar periodicamente esta seção.
        </p>

        <p className="text-center text-3xl text-white py-6">
          DIREITOS DO USUÁRIO
        </p>

        <p>
          compromete a cumprir as normas previstas no RGPD, em respeito aos
          seguintes princípios: - Os dados pessoais do usuário serão
          processados de forma lícita, leal e transparente (licitude, lealdade
          e transparência); - Os dados pessoais do usuário serão coletados
          apenas para finalidades determinadas, explícitas e legítimas, não
          podendo ser tratados posteriormente de uma forma incompatível com
          essas finalidades (limitação das finalidades); - Os dados pessoais
          do usuário serão coletados de forma adequada, pertinente e limitada
          às necessidades do objetivo para os quais eles são processados
          (minimização dos dados); - Os dados pessoais do usuário serão exatos
          e atualizados sempre que necessário, de maneira que os dados
          inexatos sejam apagados ou retificados quando possível (exatidão); -
          Os dados pessoais do usuário serão conservados de uma forma que
          permita a identificação dos titulares dos dados apenas durante o
          período necessário para as finalidades para as quais são tratados
          (limitação da conservação); - Os dados pessoais do usuário serão
          tratados de forma segura, protegidos do tratamento não autorizado ou
          ilícito e contra a sua perda, destruição ou danificação acidental,
          adotando as medidas técnicas ou organizativas adequadas (integridade
          e confidencialidade). O usuário do sistema possui os seguintes
          direitos, conferidos pela Lei de Proteção de Dados Pessoais e pelo
          RGPD: - Direito de confirmação e acesso: é o direito do usuário de
          obter do sistema a confirmação de que os dados pessoais que lhe
          digam respeito são ou não objeto de tratamento e, se for esse o
          caso, o direito de acessar os seus dados pessoais; - Direito de
          retificação: é o direito do usuário de obter do sistema, sem demora
          injustificada, a retificação dos dados pessoais inexatos que lhe
          digam respeito; - Direito à eliminação dos dados (direito ao
          esquecimento): é o direito do usuário de ter seus dados apagados do
          sistema; - Direito à limitação do tratamento dos dados: é o direito
          do usuário de limitar o tratamento de seus dados pessoais, podendo
          obtê-la quando contesta a exatidão dos dados, quando o tratamento
          for ilícito, quando o sistema não precisar mais dos dados para as
          finalidades propostas e quando tiver se oposto ao tratamento dos
          dados e em caso de tratamento de dados desnecessários; - Direito de
          oposição: é o direito do usuário de, a qualquer momento, se opor por
          motivos relacionados com a sua situação particular, ao tratamento
          dos dados pessoais que lhe digam respeito, podendo se opor ainda ao
          uso de seus dados pessoais para definição de perfil de marketing
          (profiling); - Direito de portabilidade dos dados: é o direito do
          usuário de receber os dados pessoais que lhe digam respeito e que
          tenha fornecido ao sistema, num formato estruturado, de uso corrente
          e de leitura automática, e o direito de transmitir esses dados a
          outro sistema
        </p>
       

        <p className="text-center text-3xl text-white py-6">
        POLITICA DE REEMBOLSO
        </p>
        
        <p>
        Nenhum reembolso pode ser concluído uma vez que as finanças depositadas (incluindo o bônus) tenham sido usadas no processo de jogo.

Um pedido de reembolso só será considerado se for solicitado dentro das primeiras vinte e quatro (24) horas da

suposta transação.

Reservamo-nos o direito de reter qualquer reembolso ou transação reversa até que a identidade do Usuário da conta do jogador seja adequadamente estabelecida para a nossa satisfação. Você concorda em fornecer, no caso da nossa solicitação, uma identificação autenticada ou qualquer outra identificação certificada de acordo com as leis aplicáveis ​​da jurisdição do jogador. Se tal identificação autenticada ou certificada não for fornecida dentro de cinco (5) dias a partir da nossa solicitação, tal reembolso ou transação reversa não será feita, a sua Conta de jogador será encerrada e você perderá todos os fundos na sua Conta de jogador, tal decisão será ser final, vinculante e inapelável.

Um jogador deve jogar usando um método justo em todos os jogos e não deve de forma alguma afetar o resultado desse jogo. Isso inclui o uso de auxílios de computador, equações matemáticas, sistemas de apostas, etc.
        </p>

        <p className="text-center uppercase text-3xl text-white py-6">
        Advertência de risco
        </p>
        <p>
        Você compreende que, ao participar dos jogos, corre o risco de perder o dinheiro depositado na sua conta em www.rockeetz.com;

Em algumas jurisdições, o jogo online pode ser ilegal. Você compreende e concorda que www.rockeetz.com não pode fornecer aconselhamento jurídico ou garantias sobre a legalidade do seu uso dos serviços do Website.

Não afirmamos que os serviços do Website cumprem os requisitos legais na sua jurisdição. Você usa os serviços fornecidos por www.rockeetz.com pela sua própria escolha e ao seu exclusivo critério, assumindo o risco de responsabilidade, decidindo se o uso dos serviços do Website é legal. Você faz login no Website e participa dos jogos pela sua conta e risco.

Os Websites e jogos estão disponíveis para você sem quaisquer garantias expressas ou implícitas.
        </p>

        <p className="text-center text-3xl text-white py-6">
        SAQUE e RETIRADA
        </p>
        
        <p>
        Para solicitar um saque, é necessário acumular pelo menos R$100,00 em sua conta de jogo. Essa política de valor mínimo de saque visa garantir a eficiência e segurança de nossas operações financeiras e minimizar as taxas bancárias e outras despesas que podem ser aplicadas em transações de valores menores.

Caso o jogador não tenha acumulado o valor mínimo para saque, o saldo continuará disponível em sua conta de jogo até que o valor seja atingido. Lembramos que o processamento do saque está sujeito às nossas políticas de pagamento e pode levar alguns dias úteis para ser concluído.afetar o resultado desse jogo. Isso inclui o uso de auxílios de computador, equações matemáticas, sistemas de apostas, etc.
        </p>

        <p className="text-center uppercase text-3xl text-white py-6">
        Termos e condições gerais
        </p>

        <p>
       <span className="text-white font-bold"> 1. Disposições principais</span><br/>

       <span className="text-green-900">1.1</span>. Aposta - contrato relacionado a um ganho, conforme executado entre o cliente e a empresa de apostas conforme as regras estabelecidas, neste caso, o resultado desse contrato dependerá do evento que não é possível saber se ocorrerá ou não. As apostas do cliente serão aceitas sujeitas às condições da empresa de apostas.<br/>
       <span className="text-green-900"> 1.2.</span> Resultado - resultado do evento (eventos) nos quais a aposta foi realizada. <br/>

       <span className="text-green-900">1.3.</span> Cliente - Pessoa que realiza uma aposta na empresa de apostas no resultado do evento.<br/>

       <span className="text-green-900">1.4.</span> Linha - o conjunto de eventos, os possíveis resultados desses eventos, os coeficientes dos possíveis resultados desses eventos, sua data e hora após os quais a empresa de apostas parará de aceitar apostas nos resultados dos eventos mencionados acima.<br/>

       <span className="text-green-900">1.5.</span> Cancelamento da aposta: Evento em que a liquidação e o pagamento do ganho não serão feitos. No caso de uma "cancelação da aposta", conforme as condições deste documento, o contrato entre o organizador e o cliente será considerado como não executado e um reembolso de dinheiro será feito à referida aposta.<br/>

       <span className="text-green-900">1.6.</span> Tempo regular - duração do jogo conforme as regras para realizar a competição nesse tipo de esporte, que incluirá o tempo adicionado pelo oficial. O tempo regular não incluirá tempo adicional, tempo extra, série de penalidade, grandes penalidades, etc.<br/>

       <span className="text-green-900">1.7.</span> Todas as apostas serão calculadas com base nas informações fornecidas pelo centro de processamento.<br/>

       <span className="text-green-900">1.8.</span> No caso de acumular um ganho, o participante da aposta será obrigado a verificar a precisão do ganho acumulado e, no caso de não concordar com o ganho acumulado, para notificar, respectivamente, à empresa de apostas, indicando no pedido número de sua conta, data, hora, evento, valor, resultado escolhido do evento, coeficiente e as razões para não concordar com o ganho acumulado. Todas as reivindicações relacionadas a ganhos acumulados serão aceitos dentro de 10 (dez) dias.<br/>

 <span className="text-green-900">1.9.</span>A aposta assumida pelo cliente em um resultado específico do evento estará vencedor se todos os resultados mencionados nesta aposta foram previstos corretamente.<br/>

 <span className="text-green-900">1.10.</span> As condições de aceitação das apostas (coeficientes, começo a vantagem, totais, limitações da aposta máxima, etc.) podem ser modificadas após qualquer aposta, enquanto as condições das apostas feitas acima permanecerão inalteradas. Antes da execução do contrato, o cliente deve esclarecer todas as modificações da linha atual.<br/>

 <span className="text-green-900">1.11.</span> Apostas feitas sobre os eventos cujo resultado será conhecido na época da aposta podem

ser calculadas com o coeficiente "1".<br/>

<span className="text-green-900">1.12.</span> De acordo com este Contrato, caso surjam discrepâncias entre o cliente (participante do contrato) e a empresa de apostas sobre questões relacionadas à conformidade e aplicação do contrato de apostas executado entre o cliente (participante do contrato) e a empresa de apostas, incluindo questões de pagamento de ganho, resultado dos eventos, coeficientes de lucro, qualquer emissão de outras condições essenciais do contrato executado, bem como sobre as questões de aceitação do contrato como nulas ou ineficazes, as partes aceitarão o procedimento obrigatório de reivindicação para resolver discrepâncias surgindo (procedimento preliminar).<br/>
<span className="text-green-900">1.13.</span> A reivindicação deve ser feita dentro de 10 (dez) dias a partir do dia em que a pessoa teve ou deve ter conhecimento da violação de seu direito. À reivindicação deve ser anexada todos os argumentos e fatos que confirmam e baseiam as declarações feitas. Em caso de ausência de razões suficientes para basear as reivindicações estabelecidas, a reivindicação será objeto de retorno sem revisão.<br/>

<span className="text-green-900">1.14.</span> No caso de a equipe cometer erros ao aceitar apostas (tipos errados evidentes na lista de eventos oferecidos, quebra de coeficientes na linha e na aposta, etc.), em caso de desvio dessas Regras aceitando a aposta, bem como em caso de outros argumentos que confirmam a incorreção das apostas, a empresa de apostas terá o direito de declarar as apostas feitas sob tais condições inválidas. O pagamento dessas apostas será feito com o coeficiente "1".<br/>

<span className="text-green-900">1.15.</span> Em caso de suspeita de antidesportividade nos jogos, teremos o direito de bloquear apostas no evento esportivo até a conclusão da organização internacional e reconhecer as apostas como não válidas, caso o fato de antidesportividade seja confirmada. O pagamento dessas apostas será feito com o coeficiente "1". A administração não será obrigada a fornecer aos participantes as apostas de qualquer prova ou conclusão.<br/>

<span className="text-green-900">1.16.</span> Em caso de qualquer erro evidente em nossos coeficientes, esta aposta será calculada após o resultado final do coeficiente atual que cumpre o referido marcador.<br/>

<span className="text-green-900">1.17.</span> A empresa de apostas não aceitará nenhuma reivindicação relacionada a várias leituras em caso de transliteração (tradução de idiomas estrangeiros) dos nomes das equipes, nomes dos jogadores, sede das competições. Todas as informações indicadas no nome do torneio terão um personagem auxiliar. Quaisquer

erros possíveis nessas informações não serão um motivo para o retorno das apostas.<br/>

<span className="text-green-900">1.18.</span> Continuaremos a ter o direito de fechar a conta de apostas e cancelar todas as apostas feitas nessa conta, se estiver determinado que o participante da aposta no momento da execução dela teve as informações sobre o resultado do evento. Nesse caso, a administração da empresa de apostas não será obrigada a fornecer aos participantes as apostas para qualquer prova ou conclusão.<br/>

</p>

<p>
<span className="text-white font-bold">2. Regras principais da aceitação das apostas</span><br/>
<span className="text-green-900">2.1.</span> A empresa de apostas terá o direito de limitar a aposta máxima, os coeficientes em qualquer evento específico, bem como limitar ou aumentar aposta máxima, os coeficientes para qualquer cliente em particular, sem notificação e explicação dos motivos.<br/>

<span className="text-green-900">2.2.</span> A aceitação de qualquer aposta repetida no mesmo resultado ou combinação de resultados do mesmo jogador pode ser limitada pela decisão da empresa de apostas.<br/>

<span className="text-green-900">2.3.</span> A aposta será considerada aceita após o registro no servidor e a emissão de confirmação on-line. Nenhuma aposta registrada pode ser cancelada ou modificada.<br/>

<span className="text-green-900">2.4.</span> As apostas serão aceitas apenas por um valor que não exceda o saldo atual da conta do cliente. Após o registro da aposta, o valor do mesmo será cobrado na conta. Após o cálculo da aposta, o valor da vitória será pago na conta do cliente.<br/>

<span className="text-green-900">2.5.</span> As apostas serão aceitas antes do início do evento; a data, início da rodada. No caso de, por qualquer motivo de bug ou fraude essa aposta será anulada.<br/>

<span className="text-green-900">2.6.</span> As apostas não podem ser editadas ou eliminadas, exceto nos casos especiais previstos nesses regulamentos.<br/>
<span className="text-green-900">2.7.</span> No caso de uma aposta ser anulada, os fundos da aposta serão devolvidos. em caso de anulação de uma aposta relacionada a varias rodadas mais rodadas, o cálculo do ganho nesses rodadas não será realizado.<br/>
<span className="text-green-900">2.8.</span> No caso de o cálculo das apostas estar errado (por exemplo, devido a um resultado introduzido erroneamente), essas apostas serão calculadas novamente. Nesse caso, as apostas feitas entre o cálculo errôneo e o novo cálculo serão válidas. No caso, após o recálculo, o saldo do jogador é negativo, ele não poderá fazer nenhuma aposta até que a conta seja reabastecida.<br/>
<span className="text-green-900">2.9.</span> A aposta estará sujeita à anulação se o cliente trapaceou a equipe ou funcionários da empresa de apostas, tenha lhes fornecido informações falsas e demandas sobre determinado jogo, o pagamento do ganho, o resultado do evento e outras informações e demandas

dessa natureza. Os casos mencionados acima também se aplicarão a crianças menores de 18 anos, assim como a seus pais.<br/>

<span className="text-green-900">2.10.</span> Uma aposta estará sujeita a anulação se a aposta foi feita em um resultado falso ou editado (o evento ocorreu, mas o resultado não foi refletido no sistema).
</p>
<p>
<span className="text-white font-bold">3. Partes</span><br/>
<span className="text-green-900">3.1.</span> Todas as cláusulas do contrato, incluindo os pronomes "nós", "nossos" ou "a empresa de apostas", se referirão à empresa de apostas com a qual o cliente concorda conforme a primeira cláusula.<br/>
</p>
<p>
  <span className="text-white font-bold">4. Modificação das condições</span><br/>
  <span className="text-green-900">4.1.</span> Por alguns motivos, a saber: comercial, legal, bem como qualquer motivo relacionado ao atendimento ao cliente, temos o direito de fazer qualquer modificação do contrato. As cláusulas atuais do contrato e as datas de sua entrada em vigor são fornecidas no site. O cliente será o pessoal responsável por revisar o contrato atual. A empresa de apostas terá direito a

fazer modificações no funcionamento do site a qualquer momento e sem notificação prévia aos clientes. <br/>

<span className="text-green-900">4.1.</span> Se você não concordar com as cláusulas modificadas do contrato, o cliente deve parar de usar o site. Qualquer uso subsequente do site da Rockeetz após a entrada em vigor das cláusulas modificadas do contrato será considerado como aceitação total, embora o cliente recebeu a notificação correspondente ou tenha aprendido sobre as modificações pelo contrato renovado. <br/>
</p>
<p>
<span className="font-bold text-white">5. Direito de demanda</span><br/> 

<span className="text-green-900">5.1.</span> É proibido o uso do site da Rockeetz para qualquer pessoa com menos de 18 anos (a seguir, a idade permitida) obrigatória para a participação legal no jogo, conforme a legislação de qualquer jurisdição em particular é proibida. O uso do site da Rockeetz por pessoas que não atingiram a idade permitida constituirá uma violação direta do acordo. Por esse motivo, temos o direito de solicitar documentos que possam confirmar a idade do cliente. O Cliente pode ser recusado para receber a prestação de serviços, e a operação de sua conta pode ser suspensa se ao nosso pedido nenhuma prova for fornecida de que a idade do cliente corresponder à idade permitida.<br/> 

<span className="text-green-900">5.2.</span> O jogo online é proibido por lei em certas jurisdições. Ao aceitar o contrato, o cliente entenderá que não podemos oferecer garantias ou qualquer aconselhamento jurídico sobre a legalidade do uso do site da Rockeetz em qualquer jurisdição em que o cliente esteja. Não podemos afirmar que os serviços do site da Rockeetz não violam nenhuma lei da jurisdição do cliente. O cliente usa os serviços do site da Rockeetz por sua própria vontade e assumirá toda a responsabilidade, entendendo completamente todos os riscos possíveis.<br/> 

<span className="text-green-900">5.3.</span> Não pretendemos ou pretendemos fornecer aos serviços do cliente que violem a legislação de sua jurisdição. Ao aceitar o contrato, o cliente confirma e garante que qualquer uso do site da Rockeetz esteja conforme as leis e regulamentos válidos no território de sua jurisdição. Não assumimos nenhuma responsabilidade por qualquer uso ilegal de serviços de site na Rockeetz.<br/> 

<span className="text-green-900">5.4.</span> Por razões legais, não aceitamos clientes dos seguintes países:<br/> 

<span className="text-green-900">5.4.1.</span> Restrição absoluta. Clientes dos Estados Unidos, Canadá, Reino Unido, Espanha, Letônia, Lituânia, Holanda, França, Itália, Suíça, Albânia, Barbados, Paquistão, Síria, Sudão do Sul, Ilhas Caimán, Haiti, Jamaica, Norte Norte, Malta e Panama não vão ser aceites. As pessoas que residem ou estão no território desses países não estão autorizadas a abrir contas, ou fazer qualquer tipo de entrada de dinheiro no site. As modificações da lista de jurisdições serão possíveis e podem ser feitas por nós sem nenhuma notificação prévia aos clientes. Você aceita que não abrirá a conta ou transferirá fundos monetários para ela estar localizada no território de qualquer uma das jurisdições mencionadas acima.<br/> 

<span className="text-green-900">5.4.2</span> Territórios da lista negra. <br/> Além do ponto 5.4.1, você não pode jogar ou acessar todos os jogos nos seguintes territórios: Afeganistão, Albânia, Argélia, Angola, Austrália, Camboja, Equador, Guiana, Hong Kong, Indonésia, Irã, Iraque, Israel, Kuwait, Laos, Myanmar, Namibia, Nicaragua, North Korea, Pakistan, Panama, Papua New Guinea, Philippines, Singapore, South Korea, Sudan, Syria, Taiwan, Uganda, Yemen, Zimbabue, Belgium, Bulgaria, Denmark, Estonia , Latvia, Lithuania, República Tcheca, México, Portugal, Romênia.<br/> 
<span className="text-green-900">5.5.</span> A responsabilidade pela violação da cláusula Em caso de violação deste Contrato, a empresa de apostas terá o direito de se recusar a pagar qualquer ganho ou devolver qualquer valor pago, bem como cancelar qualquer aposta. A Rockeetz não será responsável

com relação ao momento específico em que ele aprendeu que o cliente se refere a qualquer uma das categorias de pessoas mencionadas acima. Isso significa que a empresa de apostas terá o direito de tomar as medidas mencionadas a qualquer momento após saber que o cliente é uma das pessoas mencionadas.

<span className="text-green-900">5.6.</span> Se o apostador cometer ações fraudulentas contra a empresa de apostas (multi-conta, utilização de software de de apostas automáticas, jogar em situações onde o website está com instabiliade para se benificiar, executar ações abusando de problema dentro de um jogo especifico, se a conta do jogo não for usada para fazer apostas, abuso de programas de fidelização, etc.), a empresa de apostas reserva-se o direito de parar tais ações fraudulentas, cancelando apostas e fechando a conta de jogo do apostador.
<span className="text-green-900">5.7.</span> Qualquer ação feita de maneira suspeita, e adquirido saldo na conta de forma ilegal ou através de fraude no sistema. o usuario será penalizado, seus ganhos serão desconsiderados.
</p>

<p>
  <span className="text-white font-bold">6. Abertura de uma conta</span><br/>
  

  <span className="text-green-900">6.1.</span> Para ter acesso a todos os principais serviços do site da Rockeetz, é necessário abrir uma conta. Para fazer isso, o cliente pode fazer um registro de "1 clique" ou inserir seu endereço de e-mail e fazer uma senha que será usada posteriormente para a entrada da conta de apostas, bem como dados pessoais, deve ser introduzida adicionalmente: nome, número de telefone e data de nascimento.<br/>

  <span className="text-green-900">6.2.</span> O cliente deve indicar um endereço de e-mail real durante o registro ou alterá-lo para um real se o registro de 1 clique tiver sido usado.<br/>

  <span className="text-green-900">6.3.</span> Para confirmar a autenticidade das informações, teremos o direito de solicitar os documentos que confirmam a identidade dos clientes. Se, por algum motivo, o cliente não puder fornecer os documentos solicitados para identificação, teremos o direito de suspender ou bloquear a conta do cliente até fornecer as informações necessárias<br/>

  <span className="text-green-900">6.4.</span> O cliente confirma que durante o registro no site da Rockeetz indicou as informações completas e confiáveis ​​sobre si mesmo e, caso sejam feitas modificações em relação a ele, o cliente será obrigado a apresentá-los imediatamente em seu perfil. A falha ou a não observância desta regra pode causar a aplicação de limitações, suspensão ou bloqueio da conta, bem como o cancelamento de pagamentos.<br/>

  <span className="text-green-900">6.5.</span> Se surgirem perguntas ou problemas no escopo do registro no site da Rockeetz, o cliente poderá entrar em contato com o serviço de suporte on-line ou em nosso suporte via whatsapp.<br/>

  <span className="text-green-900">6.6.</span> Cada cliente pode abrir apenas uma conta no site da Rockeetz. As outras contas abertas pelo cliente serão consideradas contas de reserva. Temos o direito de fechar essas contas e:<br/> 6.6.1. Reconhecer como não válido todas as operações realizadas com uma conta de suporte;

  <span className="text-green-900">6.6.2.</span> Não reembolse o cliente Todos os depósitos e apostas feitos da conta principal e da reserva; <br/>
  <span className="text-green-900">6.1.3.</span> Exigir o reembolso de todos os lucros, títulos e fundos monetários recebidos usando

a conta de reserva. O cliente será obrigado a devolvê -los à nossa primeira demanda.
</p>
<p>
  <span className="text-white font-bold">
  7. Sua identidade. Proteção de lavagem de dinheiro
  </span><br/>


  <span className="text-green-900">7.1.</span> Lembre-se de que o nome e a data de nascimento listados em sua conta devem coincidir com sua identidade e identidade verdadeiras e legais.<br/>

  <span className="text-green-900">7.2.</span> O nome que aparece no registro da sua conta deve coincidir com o nome do(s) cartão(s) de crédito(s) ou outras contas de pagamento usadas para inserir ou retirar dinheiro no/o saldo da sua conta.<br/>

  <span className="text-green-900">7.3.</span> Para verificar sua identidade, endereço e/ou Conta de pagamento ("Identidade"), podemos solicitar a qualquer momento:<br/> 7.3.1 uma prova de identidade (incluindo, mas sem se limitar a ele, cópias de um documento válido para passaporte/identidade e/ou qualquer cartão de pagamento usado).<br/>

  <span className="text-green-900">7.3.2.</span> uma prova de morada, que inclui, entre outras coisas, um atendimento recente de serviços públicos (não deve ter mais de três meses).<br/>

  <span className="text-green-900">7.3.3.</span> uma cópia de um extrato recente de uma conta de crédito/débito/banco relacionada a um método de pagamento usado (não deve ter mais de três meses).<br/>

  <span className="text-green-900">7.4.</span> Se esta documentação não nos fornecer e/ou se não pudermos verificar satisfatoriamente sua identidade dentro de um período razoável, determinado por nós, reservamos o direito discricionário de: <br/>7.4.1 reter o saldo da sua conta até que nosso processo de verificação esteja totalmente concluído, <br/>7.4.2 anular algumas transações que você fez e congelou sua conta, e/ou

  <span className="text-green-900">7.4.1</span>suspender ou fechar sua conta e encerrar esses acordos.<br/>

  <span className="text-green-900">7.5.</span> Podemos realizar uma revisão de segurança a qualquer momento para validar sua identidade, idade e outras informações de registro ou pagamento fornecidas por você, para verificar seu uso de serviços (por exemplo, para Práticas Proibidas), o cumprimento desses acordos e seus financeiros transações realizadas através dos serviços por uma possível violação desses acordos.<br/>

  <span className="text-green-900">7.6.</span> Você nos autoriza a fazer qualquer consulta sobre você e usar e revelar a terceiros qualquer informação sobre você para as validar.<br/>

  <span className="text-green-900">7.7.</span> Você concorda em fornecer qualquer informação ou documentação adicional que possamos solicitar para realizar a revisão de segurança.<br/>

  <span className="text-green-900">7.8.</span> Este procedimento é um requisito legal sendo realizado após os regulamentos aplicáveis ​​em jogo e os requisitos legais contra a lavagem de dinheiro, em particular, após a lei nacional sobre a notificação de transações incomuns e nossas políticas internas em matéria de AML/CTF.<br/>
</p>
<p>
  <span className="text-white font-bold">
  8. Nome de usuário, senha e segurança


  </span><br/>
  <span className="text-green-900">8.1.</span> Após abrir uma conta no site da Internet, o Cliente é obrigado a manter sua senha e nome de usuário em sigilo e não divulgar essas informações a terceiros. Em caso de perda dos dados necessários para acesso à conta, eles podem ser aprendidos ou restaurados pressionando o botão "Restaurar senha".<br/>

  <span className="text-green-900">8.2.</span> O Cliente será responsável pela segurança da senha, bem como por qualquer ação ou transação realizada com sua conta. Além disso, o Cliente será responsável por todas as perdas sofridas como resultado das ações de terceiros.<br/>

  <span className="text-green-900">8.3.</span> Em caso de violação de segurança e qualquer acesso não autorizado à conta, o Cliente deve notificar imediatamente a empresa de apostas. Se necessário, o Cliente é obrigado a fornecer-nos prova de que ocorreu acesso não autorizado. A empresa de apostas não será responsável por danos sofridos pelo Cliente devido ao uso incorreto ou descuidado do nome de usuário e senha por terceiros ou devido a qualquer acesso não autorizado à conta.<br/>
</p>
<p>
  <span className="text-white font-bold">
  9. Depósitos, colocação e retirada de fundos da conta
    </span><br/>


    <br/><span className="text-green-900">9.1.</span> Para fazer uma aposta no site da Rockeetz, o Cliente deve colocar uma certa quantia em dinheiro em sua conta.

<br/>    <span className="text-green-900">9.2.</span> O Cliente confirma e compromete-se a:

<br/>    <span className="text-green-900">9.2.1.</span> os fundos de dinheiro depositados na conta de apostas não foram obtidos de forma ilegal ou proibida;

<br/>    <span className="text-green-900">9.2.2.</span> o Cliente não rejeitará nenhuma transação realizada, não negará ou cancelará qualquer pagamento efetuado que possa causar a devolução de dinheiro a qualquer terceiro e permitir-lhe evitar a responsabilidade legal.

<br/><span className="text-green-900">9.3.</span> Não aceitamos fundos em dinheiro de terceiros: amigos, familiares ou associados. O

Cliente será obrigado a pagar fundos em dinheiro apenas de sua conta, cartão bancário ou sistema registrado em nome do Cliente. Em caso de deteção do incumprimento desta condição, todos os ganhos podem ser confiscados.

<br/><span className="text-green-900">9.4.</span>. No caso de solicitar a transferência bancária para devolver os fundos em dinheiro ao seu proprietário legal, todas as despesas e comissões serão pagas pelo destinatário.

<br/><span className="text-green-900">9.5.</span> Não aceitamos pagamentos em dinheiro. Podemos usar para pagamentos eletrônicos, incluindo pagamentos recebidos e pagamentos a Clientes, diferentes instituições envolvidas no processamento de pagamentos eletrônicos ou qualquer instituição financeira. Exceto nos casos em que as regras e condições de tais instituições contrariem as disposições do Contrato, o Cliente deverá aceitar integralmente tais regras.

<br/><span className="text-green-900">9.6.</span> O Cliente compromete-se a não recusar, não cancelar e não encerrar as transações realizadas com a participação de sua conta. Além disso, em cada um desses casos, o Cliente será obrigado a nos devolver ou reembolsar o valor dos fundos não alocados, incluindo as despesas que possamos incorrer na cobrança dos depósitos do Cliente.

<br/><span className="text-green-900">9.7.</span> Temos o direito de bloquear a conta do Cliente, bem como cancelar pagamentos e recolher ganhos se suspeitarmos que o reabastecimento da conta foi feito de forma fraudulenta. Teremos o direito de notificar as autoridades competentes sobre quaisquer ações fraudulentas com pagamentos e quaisquer atividades ilegais. Temos o direito de usar os serviços de agências de cobrança para devolver pagamentos. A Rockeetz não se responsabiliza pelo uso não autorizado de cartões de crédito, independentemente do fato de seu roubo ter sido declarado ou não.

<br/><span className="text-green-900">9.8.</span> Teremos o direito de usar qualquer saldo positivo na conta do Cliente para liquidar qualquer quantia de dinheiro que o Cliente seja obrigado a devolver à rockeetz. Em particular, no caso de apostas ou apostas repetidas, cláusula 5 ("Conluio, ações fraudulentas, fraude e atividade criminosa) ou cláusula 13 ("Erros e defeitos").

<br/><span className="text-green-900">9.9</span> O Cliente compreende e aceita totalmente o facto de a conta de apostas não ser uma conta bancária. Portanto, não será coberto pelos métodos de seguro, substituição, garantia e outros recursos pelo sistema de seguro de depósito e outros sistemas de seguro. Não

serão acumulados juros sobre fundos de dinheiro na conta de apostas.

<br/><span className="text-green-900">9.10</span> O cliente terá o direito de enviar uma inscrição para retirar o dinheiro da conta se:

<br/><span className="text-green-900">9.10.1</span> todos os pagamentos recebidos na conta foram inspecionados e nenhum deles foi cancelado ou terminado.

<br/><span className="text-green-900">9.10.2.</span> qualquer atividade de inspeção foi devidamente realizada.

<br/><span className="text-green-900">9.10.3.</span> Para fins de execução de uma solicitação para retirar os fundos da conta, os seguintes momentos devem ser considerados:

<br/><span className="text-green-900">9.11.1.</span> o perfil de apostas deve ser preenchido. Além disso, se o cliente tiver registrado usando a função "1 clique", o endereço de email deve modificar;

<br/><span className="text-green-900">9.11.2.</span> Se o valor solicitado para a retirada exceder o valor total das retiradas dos fundos de dinheiro no valor de 30.000 reais brasileiros ou seu equivalente em qualquer outra moeda, podemos realizar um procedimento de identificação. Para fazer isso, o cliente deve nos fornecer uma cópia digital ou fotografia do documento de identificação do cliente. No caso de substituição da conta do cartão de crédito, a fotografia de ambos os lados do referido cartão deve ser enviada. No número do cartão, os seis primeiros dígitos devem ser vistos e os quatro últimos, o código CVV2 pode estar oculto;

<br/><span className="text-green-900">9.11.3.</span> Teremos o direito de coletar uma comissão no número de nossas despesas para a retirada de fundos não comprometidos com o jogo.

<br/><span className="text-green-900">9.11.4.</span> O valor da retirada de até 25.000 reais ou seu equivalente em qualquer outra moeda será pago na conta do cliente dentro de 1 a 60 minutos a partir do momento do pedido, exceto em caso de mau funcionamento técnico pelo banco ou do sistema de pagamento.

<br/><span className="text-green-900">9.11.5.</span> O valor da retirada de 25.000 reais ou seu equivalente em qualquer outra moeda será pago na conta do cliente dentro de 5 dias a partir do momento do pedido.

<br/><span className="text-green-900">9.11.6.</span> Outros atrasos serão possíveis até 3 dias úteis, dependendo do banco ou de outro sistema de pagamento.

<br/><span className="text-green-900">9.11.7.</span> Outras maneiras de retirar os fundos de dinheiro devem ser expressamente discutidos com a administração do site da Internet.

<br/><span className="text-green-900">9.12.</span> Não fornecemos serviços de câmbio. Temos o direito de fechar essas contas e

<br/><span className="text-green-900">9.12.1.</span>manter uma comissão para o valor de nossos custos de aposentadoria não envolvidos no jogo;

<br/><span className="text-green-900">9.11.2.</span> invalidar todas as transações feitas com as contas principais e duplicadas;

<br/><span className="text-green-900">9.11.3.</span> não retornar aos clientes todos os depósitos feitos das contas principais e duplicadas.

<br/><span className="text-green-900">9.13.</span> Para ativar a retirada de fundos, o jogador deve fazer uma aposta com as probabilidades não inferiores a 1,3 no valor não inferior a 100% de cada valor depositado para jogos.

<br/><span className="text-green-900">9.14</span> Se a conta do jogador não tiver atividade de jogo suficiente especificada na cláusula 9.13, a casa de apostas terá o direito de reter as despesas de comissão do sistema de pagamento do cliente (até 15% para cada transação) ao retirar seus fundos

<br/><span className="text-green-900">9.15</span> Se a quantidade de lucros for superior a 1.000.000 (1 milhão de reais), a Companhia da Rockeetz se reserva o direito de estabelecer um limite diário para a retirada de fundos, que é calculada individualmente para cada cliente.
</p>
<p>
  <span className="text-white font-bold">

10. Confluência, ações enganosas, fraude e atividade criminosa  </span><br/>

<span className="text-green-900">10.1.</span> A Rockeetz não recomenda a realização de apostas por um grupo de clientes do mesmo endereço IP ou da mesma rede local, bem como, um acordo prévio, de maneira consertada. As atividades acima podem ser conluio, fraude, ações enganosas.<br/>

<span className="text-green-900">10.2.</span> A Rockeetz não é responsável pelo momento exato em que teve conhecimento ou posteriormente constatou que a aposta foi feita violando os padrões. Temos o direito de fechar essas contas

e reconheçer todas as transações como inválidas, não retorne aos clientes todos os depósitos e apostas feitas em conjunto com outros clientes;<br/>

<span className="text-green-900">10.2.1</span> exigir o retorno de todos os lucros, títulos e fundos recebidos como resultado de conluio. O cliente é obrigado a devolvê -los ao nosso primeiro requisito.<br/>

<span className="text-green-900">10.3.</span> Os seguintes tipos de atividade serão estritamente proibidos, a comissão de qualquer uma dessas ações será considerada uma violação direta do Contrato:<br/>

<span className="text-green-900">10.3.1</span> a transferência de informações para terceiros;<br/>

<span className="text-green-900">10.3.2</span> Ações ilegais: fraude, uso de software malicioso, bots e bugs no software do site;<br/>

<span className="text-green-900">10.3.3</span> Ações fraudulentas, como o uso de dados roubados, clonados ou obtidos ilegalmente de cartões de crédito, ou débito para recarregar a conta;<br/>

<span className="text-green-900">10.3.4</span> Participação em atividade criminosa, lavagem de dinheiro e outros tipos de atividade cuja participação pode causar responsabilidade pelo direito penal.<br/>

<span className="text-green-900">10.4.</span> Temos o direito de suspender, cancelar ou retirar pagamentos, ou lucros relacionados a fundos de bônus, caso tenhamos suspeitas de que o cliente tenha feito um uso indevido intencional deles.<br/>

<span className="text-green-900">10.5.</span> Tomaremos todas as medidas permitidas e autorizadas por lei a excluir e detectar qualquer conluio fraudulento e seus participantes diretos, também serão tomadas medidas correspondentes contra as pessoas. Não seremos responsáveis ​​pelas perdas e danos sofridos pelos clientes como resultado de ações fraudulentas.<br/>

<span className="text-green-900">10.6.</span> Não seremos responsáveis ​​pelas perdas e danos sofridos pelos clientes como resultado de ações fraudulentas. Eles podem entrar em contato conosco através do site do Serviço de Assistência usando o nosso suporte no Whastapp ou Instagram Oficial.<br/>

<span className="text-green-900">10.7.</span> Reservamo-nos o direito, sem aviso, de proibir os clientes de acessar o site da Internet e bloquear suas contas se houver suspeita de alguma atividade fraudulenta. Nesses casos, não seremos responsáveis ​​pelo reembolso e compensação de fundos monetários nas contas dos referidos clientes. Além disso, temos o direito de notificar as autoridades policiais de qualquer atividade ilegal. Os clientes serão obrigados a cooperar conosco no campo da investigação de tais casos.<br/>

<span className="text-green-900">10.8.</span> Os clientes estão proibidos de usar os serviços e o software do site para executar qualquer ação e transação fraudulenta e ilegal conforme a legislação de qualquer jurisdição específica. Se for definido ou detectado que o cliente cometeu tais ações, a empresa de apostas poderá suspender ou bloquear a conta do cliente e remover os fundos disponíveis nela. Nesses casos, os clientes não terão o direito de registrar nenhuma reclamação contra a empresa de apostas.<br/>

<span className="text-green-900">10.9.</span> A regulamentação deste site restringe a realização de qualquer atividade de jogo em nome de terceiros (titulares de contas nominais).<br/>

A conta do jogo será bloqueada imediatamente após a verificação feita pelo departamento de segurança, que demonstra que a pessoa que está em verificação não possui conhecimento suficiente nos esportes.

</p>
<p>
  <span className="text-white font-bold">
  11. Outras ações proibidas no site
  </span><br/>
  <span className="text-green-900">11.1.</span> É proibido usar qualquer idioma agressivo ou ofensivo no site da Rockeetz, palavras obscenas, ameaças e qualquer ação violenta em relação aos funcionários e outros clientes da empresa de apostas. Se o jogador não cumprir esta cláusula de regras, a empresa de apostas terá o direito de parar de corresponder a esse jogador ou deixar a solicitação de entrada sem consideração.<br/>

  <span className="text-green-900">11.2.</span> É proibido carregar no site da Rockeetz qualquer informação no volume que possa causar mau funcionamento do site de internet e executar qualquer outra ação que possa afetar a operação do recurso. Isso será entendido como vírus, software malicioso, correio em massa e spam - tudo isso é estritamente proibido. Além disso, os clientes são proibidos de excluir, modificar e editar as informações publicadas no site.<br/>

  <span className="text-green-900">11.3.</span> Os clientes podem usar o site da Rockeetz exclusivamente para fins divertidos. É proibido copiar o Site da Rockeetz todo ou qualquer parte dele.<br/>

  <span className="text-green-900">11.4.</span> Os clientes se comprometem a não executar nenhuma ação destinada à pirataria do sistema de segurança do site da Rockeetz, para obter acesso ilegal a quaisquer dados confidenciais ou ataques de DDoS.  Com relação aos clientes suspeitos de violar esse padrão, as medidas correspondentes serão aplicadas: proibição total de acesso ao site da Internet e a bloqueio da conta. Além disso, temos o direito de notificar as autoridades responsáveis ​​qualquer ação ilegal do cliente.<br/>

  <span className="text-green-900">11.5.</span> Não seremos responsáveis ​​pelas perdas e danos que nossos clientes ou terceiros podem sofrer devido a qualquer mau funcionamento técnico causado por qualquer ataque de vírus, ou qualquer outra ação maliciosa endereçada ao site da Internet.<br/>

  <span className="text-green-900">11.6.</span> A transferência e venda da conta de um cliente para outra são estritamente proibidas.<br/>

</p>
<p>
  <span className="text-white font-bold">12. Erros do sistema</span><br/>
 

  <span className="text-green-900">12.1.</span> Se durante o processo de apostas houve um mau funcionamento do Site, tentaremos corrigir a situação o mais rápido possível. Não somos responsáveis ​​pelas falhas operacionais dos dispositivos de computador que ocorrem devido à operação do equipamento usado pelos Clientes para acessar o site, bem como as interrupções no trabalho de fornecedores de Internet.<br/>

<span className="text-white font-bold">13. Erros e defeitos</span><br/>

<span className="text-green-900">13.1.</span> Reservamo-nos o direito de limitar ou cancelar qualquer aposta.<br/>

<span className="text-green-900">13.2.</span> Se o cliente usou os fundos monetários credenciados em sua conta como resultado do erro para fazer as apostas, podemos cancelar essas apostas e qualquer ganho recebido através delas. Se algum pagamento dos fundos monetários foi feito nos referidos jogos, os valores serão considerados transferidos para o cliente em gerenciamento fiduciário, o Cliente será obrigado a devolvê-los ao nosso primeiro requisito.<br/>

<span className="text-green-900">13.3.</span> A empresa de apostas e os provedores de serviços não serão responsáveis ​​por nenhuma perda, incluindo qualquer ganho perdido, causado por erros pelo Cliente ou por nossa parte.<br/>

<span className="text-green-900">13.4.</span> A empresa de apostas, seus distribuidores, filiais, licenciados, subsidiárias e funcionários e gerentes não serão responsáveis ​​pelas perdas e danos causados ​​pela interceptação ou uso inadequado de qualquer

informações transferidas pela Internet.  <br/>
</p>
<p>
  <span className="text-white font-bold"> 14. Limitação de nossa responsabilidade</span><br/>
 

  <span className="text-green-900">14.1.</span> O Cliente decidirá independentemente o uso dos serviços do site, e qualquer ação e suas consequências serão causadas pela escolha pessoal do cliente feito a seu critério, sob seu próprio risco.

  <span className="text-green-900">14.2.</span> A operação do site da Internet ocorrerá seguindo as cláusulas deste Contrato Não fornecemos nenhuma garantia ou segurança adicional em relação ao site da Internet e aos serviços oferecidos nele e, portanto, excluímos nossa responsabilidade (no campo da lei) em relação a qualquer garantia respectiva.

  <span className="text-green-900">14.3.</span> Não seremos responsáveis ​​por ofensas, negligência, perdas e danos que não podemos prever na época. 

  <span className="text-green-900">14.4.</span> A empresa de apostas não será responsável pelo conteúdo dos recursos cujo acesso pode ser obtido através do site.
</p>
<p>
  <span className="text-white font-bold">15. Violação das condições</span><br/>


  <span className="text-green-900">15.1.</span> O Cliente concede seu consentimento para reembolsar completamente as perdas, proteger e defender os interesses da empresa de apostas, seus parceiros, funcionários e diretores de qualquer reclamação, responsabilidade, despesas, perdas e custos causados ​​pelo seguinte:<br/>

  <span className="text-green-900">15.1.1.</span> não cumprir as condições do contrato pelo Cliente;<br/>

  <span className="text-green-900">15.1.2.</span> a violação pelo Cliente das leis e direitos de terceiros;<br/>

  <span className="text-green-900">15.1.3.</span> acesso aos serviços do site por qualquer outra pessoa que use dados de identificação do cliente, com sua permissão ou não, ou;<br/>

  <span className="text-green-900">15.1.4.</span> a apropriação dos lucros obtidos dessa maneira.<br/>

  <span className="text-green-900">15.2.</span> Se o Cliente violar as condições do contrato, teremos o direito de:<br/>

  <span className="text-green-900">15.2.1.</span> notificar o Cliente que, com suas ações, viola as condições do contrato e exige que qualquer ação proibida cessa imediatamente;<br/>

  <span className="text-green-900">15.2.2.</span> suspender temporariamente a operação da conta do cliente;<br/>

  <span className="text-green-900">15.2.3.</span> bloquear a conta do Cliente sem aviso;<br/>

  <span className="text-green-900">15.2.4.</span> remover a conta do Сliente o número de pagamentos, lucros ou títulos obtidos como resultado da violação.<br/>

  <span className="text-green-900">15.3.</span> Temos o direito de cancelar o nome de usuário e a senha do Сliente, caso eles não cumpram nenhuma cláusula de Сontrato.<br/>
</p>
<p>
<span className="text-white font-bold">16. Resolução de litígios</span><br/>


<span className="text-green-900">16.1.</span> O cliente pode contestar qualquer situação. Consideraremos minuciosamente e de boa fé todas as questões disputáveis ​​no caso de fornecer qualquer informação específica - fatos e argumentos. As informações discutíveis com um anexo de fatos e argumentos devem ser fornecidas em forma por escrito através do bate-papo on-line no site ou para o endereço de email do serviço de suporte. 
</p>

        <p id="politicaCookies" className="text-center text-3xl text-white py-6">
        Política de Cookies
        </p>

        <p>
        O site da Rockeetz (o "Site") usa cookies para oferecer um serviço melhor e mais personalizado aos utilizadores.    
        Esta Política de Cookies explica o que são cookies, como eles são usados no Site e como gerenciar o seu uso.
        </p>

        <p className="text-center text-3xl text-white py-6">
        O que é um Cookie?
        </p>
        
        <p>
        Cookies são pequenos arquivos de texto que contêm pequenas quantidades de informação. Estes arquivos são enviados para o seu computador, celular ou outro dispositivo quando você acessa um site, sendo depois armazenados no dispositivo em questão. Os cookies são então enviados de volta para o site de onde são originários em cada visita posterior ou ao visitar um site  que reconheça o cookie em questão. Cookies são úteis porque permitem que um site reconheça o dispositivo utilizado pelo usuário.
        Você poderá encontrar mais informações sobre cookies acessando a computer.howstuffworks.com.
        </p>

        <p className="text-center text-3xl text-white py-6">
        Utilização de Cookies no Site
        </p>

            <p>
            São utilizados cookies no site por um conjunto de razões. Estas razões incluem, mas não são limitadas a:
            <br></br>
            1- permitir que os usuários entrem nas suas contas Rockeetz;
            <br></br>
            2- permitir que os usuários selecionem as suas preferências de Log In;
            <br></br>
            3- monitorar e recolher informação sobre transações efetuadas no Site; e
            <br></br>
            4- para efeitos de prevenção de fraude e para proteção à privacidade no geral.
            <br></br>
            Os cookies são geralmente usados para melhorar e potencializar a experiência do usuário. Alguns dos cookies utilizados por nós são essenciais para o funcionamento do site.
           
            A utilização de cookies por parte da Rockeetz no Site recai sobre as seguintes categorias:
          
            Gerência de Sessão - Estes cookies são essenciais para permitir a utilização do Site. Estes cookies gerenciam a informação da sessão e permitem que os usuários naveguem pelo site.

            Funcionalidade - Estes cookies armazenam informação que nos permite recordar as preferências do usuário. Ex.: idioma preferido, tipos de organização e definições de mídia. Os referidos cookies são usados ainda para melhorar a experiência do usuário ao, por exemplo, impedir que a mesma mensagem seja mostrada duas vezes.

            Prevenção de Fraude - Estes cookies armazenam informação que nos ajuda a prevenir a utilização fraudulenta do site.

            Rastreio (Tracking) - Estes cookies nos permitem armazenar informação sobre como os usuários acessam o site. Necessitamos armazenar esta informação para que possamos atribuir os devidos pagamentos aos nossos Afiliados.

            A Rockeetz não utiliza cookies de rastreio de terceiros.

            Quando forem fornecidos links para sites de terceiros no site, é importante lembrar que tais sites terão as suas próprias políticas relacionadas aos cookies e privacidade, que serão aplicáveis a qualquer informação que possa inserir. Se você decidir utilizar qualquer site de terceiros através de links, queira por favor ler as políticas de cookies e de privacidade das mesmas antes de o fazer.
            </p>

            <p className="text-center text-3xl text-white py-6">
            Gerenciar Cookies
           </p>

            <p>Se desejar apagar alguns dos cookies já armazenados no seu computador ou parar os cookies que armazenam os seus padrões de navegação no Site você poderá fazê-lo apagando os seus cookies existentes e/ou alterando as definições de privacidade do seu navegador (o processo a seguir variará segundo o navegador utilizado). Se desejar mais informação sobre como desativar cookies ou alterar as suas definições de privacidade, queira por favor acessar computer.howstuffworks.com. A função de "ajuda" do seu navegador  deverá também conter informação sobre como proceder.

            Queira por favor ter em mente que apagar os nossos cookies ou desativar os nossos cookies futuros significa que você poderá não conseguir acessar a certas áreas ou funcionalidades da Página Web. Por exemplo, se o seu navegador estiver definido para desativar cookies de sua "sessão", você não conseguirá entrar na sua conta Rockeetz.</p>

        <p className="text-center text-3xl text-white py-6">
          TIPOS DE DADOS COLETADOS
        </p>

        <p>
          A utilização, pelo usuário, de determinadas funcionalidades do
          sistema dependerá de cadastro, sendo que, nestes casos, os seguintes
          dados do usuário serão coletados e armazenados: - Nome – Cadastro de
          pessoa física – data de nascimento - Endereço de e-mail - Número de
          telefone. Os dados eventualmente informados pelo usuário que
          utilizar o formulário de contato disponibilizado no sistema,
          incluindo o teor da mensagem enviada, serão coletados e armazenados.
          Para a execução de contrato ou depósito eventualmente firmado entre
          o sistema e o usuário, poderão ser coletados e armazenados outros
          dados relacionados ou necessários a sua execução, incluindo o teor
          de eventuais comunicações tidas com o usuário.
        </p>

        <p className="text-center text-3xl text-white py-6">
          DEVE DE NÃO FORNECER DADOS A TERCEIROS
        </p>

        <p>
          Durante a utilização do sistema, a fim de resguardar e de proteger
          os direitos de terceiros, o usuário do sistema deverá fornecer
          somente seus dados pessoais, e não os de terceiros.
        </p>

        <p className="text-center text-3xl text-white py-6">
          FINALIDADES DO TRATAMENTO DOS DADOS PESSOAIS
        </p>

        <p>
          Os dados pessoais do usuário coletados pelo sistema têm por
          finalidade facilitar, agilizar e cumprir os compromissos
          estabelecidos com o usuário e a fazer cumprir as solicitações
          realizadas por meio do preenchimento de formulários. Os dados de
          cadastro serão utilizados para permitir o acesso do usuário à
          determinado conteúdo do sistema, exclusivos para usuários
          cadastrados. A coleta de dados relacionados ou necessários à
          execução de um contrato eventualmente firmado com o usuário terá a
          finalidade de conferir às partes segurança jurídica, além de
          facilitar e viabilizar a conclusão do negócio de investimento.
        </p>

        <p className="text-center text-3xl text-white py-6">
          PRAZO DE CONSERVAÇÃO DOS DADOS PESSOAIS
        </p>

        <p>
          Os dados pessoais do usuário serão conservados por um período não
          superior ao exigido para cumprir os objetivos em razão dos quais
          eles são processados. O período de conservação dos dados é definido
          de acordo com os seguintes critérios: - Os dados ficarão armazenados
          enquanto o usuário permanecer com a conta ativa no sistema; - O
          usuário poderá solicitar a exclusão da conta e seus dados a qualquer
          momento. Os dados pessoais dos usuários apenas poderão ser
          conservados após o término de seu tratamento nas seguintes
          hipóteses: - Para o cumprimento de obrigação legal ou regulatória
          pelo controlador; - Para estudo por órgão de pesquisa, garantida,
          sempre que possível, a anonimização dos dados pessoais; - Para a
          transferência a terceiro, desde que respeitados os requisitos de
          tratamento de dados dispostos na legislação; - Para uso exclusivo do
          controlador, vedado seu acesso por terceiro, e desde que sejam
          anônimos os dados.
        </p>

        <p className="text-center text-3xl text-white py-6">
          SEGURANÇA NO TRATAMENTO DOS DADOS PESSOAIS DO USUÁRIO
        </p>

        <p>
          O sistema se compromete a aplicar as medidas técnicas e
          organizativas aptas a proteger os dados pessoais de acessos não
          autorizados e de situações de destruição, perda, alteração,
          comunicação ou difusão de tais dados. Para a garantia da segurança,
          serão adotadas soluções que levem em consideração: as técnicas
          adequadas; os custos de aplicação; a natureza, o âmbito, o contexto
          e as finalidades do tratamento; e os riscos para os direitos e
          liberdades do usuário. O sistema utiliza certificado SSL (Secure
          Socket Layer) que garante que os dados pessoais se transmitam de
          forma segura e confidencial, de maneira que a transmissão dos dados
          entre o servidor e o usuário, e em retroalimentação, ocorra de
          maneira totalmente cifrada ou encriptada. No entanto, o sistema se
          exime de responsabilidade por culpa exclusiva de terceiros, como em
          caso de ataque de hackers ou crackers, ou culpa exclusiva do
          usuário, como no caso em que ele mesmo transfere seus dados a
          terceiro. O sistema se compromete, ainda, a comunicar o usuário em
          prazo adequado caso ocorra algum tipo de violação da segurança de
          seus dados pessoais que possa lhe causar um alto risco para seus
          direitos e liberdades pessoais. A violação de dados pessoais é uma
          violação de segurança que provoque, de modo acidental ou ilícito, a
          destruição, a perda, a alteração, a divulgação ou o acesso não
          autorizado a dados pessoais transmitidos, conservados ou sujeitos a
          qualquer outro tipo de tratamento. Por fim, o sistema se compromete
          a tratar os dados pessoais do usuário com confidencialidade, dentro
          dos limites legais.
        </p>

        <p className="text-center text-3xl text-white py-6">ALTERAÇÕES</p>

        <p>
          A presente versão desta Política de Privacidade foi atualizada pela
          última vez em: 15/09/2021. O editor se reserva o direito de
          modificar, a qualquer momento o sistema e as presentes normas,
          especialmente para adaptá-las às evoluções do sistema, seja pela
          disponibilização de novas funcionalidades, seja pela supressão ou
          modificação daquelas já existentes. O usuário será explicitamente
          notificado em caso de alteração desta política no marcador indicado
          no próprio sistema de correio e comunicação disponibilizado por
          este. Ao utilizar o serviço após eventuais modificações, o usuário
          demonstra sua concordância com as novas normas. Caso discorde de
          alguma das modificações, deverá pedir, imediatamente, o cancelamento
          de sua conta e apresentar a sua ressalva ao serviço de atendimento,
          se assim o desejar.
        </p>

        <p className="text-center text-3xl text-white py-6">
          DIREITO APLICÁVEL E DE FORO
        </p>

        <p>
          Para a solução das controvérsias decorrentes do presente
          instrumento, será aplicado integralmente o Direito do país que o
          servidor estiver sediado, tendo em vista a sede da administradora.
        </p>

        <p className="text-center text-3xl text-white py-6">
          TERMOS E CONDIÇÕES
        </p>

        <p>
          A Rockeetz é uma plataforma operada por um sistema aleatório de
          investimento sem qualquer interferência humana que usa a cadeia
          sha256 em combinação com uma seed de cliente indeterminada para
          gerar rodadas de maneira justa nos multiplicadores exarados na tela
          inicial que proporciona que o usuário multiplique seus pontos
          obtidos por depósitos específicos caso este indique um determinado
          valor em conta antes da parada do marcador multiplicador das rodadas
          que se iniciarem. O usuário cadastrado se responsabiliza e aceita
          todas as regras e condições estabelecida pela administradora do
          sistema de investimentos. Caso o usuário não retire o investimento
          antes do marcador multiplicador atingir seu ponto máximo da rodada,
          os pontos indicados pelo usuário para multiplicar é automaticamente
          recolhido pelo sistema para eventual redistribuição para os usuários
          e demais despesas. A Rockeetz não se responsabiliza por eventuais
          erros comedidos pelos usuários ou por terceiros. O sistema
          encontra-se em período de testes, portanto, a administradora não se
          responsabiliza por eventuais delley e bugs de qualquer natureza.
        </p>

        <p className="text-center text-3xl text-white py-6">CYBER SPACE</p>

        <p>
          O jogo Cyber Space é um jogo interativo em que o objetivo é se
          retirar da partida antes que o gráfico (pare). Primeiramente, o
          usuário deve digita o valor que deseja apostar na próxima rodada em
          "quantia". Depois de selecionar um valor, pressione o botão
          "Começar". Quando a próxima rodada começar, você verá um
          multiplicador aumentando. O gráfico continuará subindo e, quanto
          maior o multiplicador, maior será o pagamento. Contudo, caso o
          usuário não retirar o valor investido antes do gráfico parar,
          perderá o investimento. Caso o usuário retire o investimento antes
          do gráfico parar com sucesso, receberá o valor do investimento vezes
          o multiplicador.
        </p>

        <p className="text-center text-3xl text-white py-6">DEPÓSITOS</p>

        <p>
          Os depósitos poderão ser feitos de R$ 40,00 à R$ 15.000,00 de acordo
          com as opções disponíveis no sistema. A retirada dos pontos poderá
          ser realizada em 5 dias úteis em caso de solicitação pelo sistema
          PIX ou por depósito em conta bancária em 10 dias úteis. O usuário
          que adquirir os pontos com a forma de pagamento com o cartão de
          crédito, deverá investir até 50% dos pontos adquiridos para saque
          para ter direito ao saque. Caso o usuário queira retirar os pontos,
          poderá a administradora confirmar a identidade dos usuários com
          documentação complementar. Caso o usuário tenha adquiridos pontos
          por cartão de crédito, ficará sujeito à aprovação do saque na
          plataforma por 30 dias. Não será permitido depósitos na sua conta se
          os fundos não vierem de um método de pagamento que pertença ao
          usuário. Qualquer método de pagamento usado para depositar ou sacar
          deve estar registrado em seu nome do usuário titular da conta, sendo
          assim, também significa que não será permitido o uso de cartões ou
          contas bancárias de empresas ou de terceiros. Mas as contas
          bancárias ou cartões compartilhados podem ser usados, desde que o
          usuário esteja registrado como um dos proprietários. Todos os
          investimentos feitos com fundos de terceiros serão consideradas
          nulas. Qualquer caso omisso, má-fé ou qualquer ato ilícito não
          tratado no presente termo poderá ser objeto de deliberação da
          administradora.
        </p>

        <p id="cupomDeBonus" className="text-center text-3xl text-white py-6">
          CUPOM DE BÔNUS 
        </p>

        <p>
        Um rollover define o multiplicador no qual a soma de seu depósito e bônus precisará ser entregue.
com nossos jogos você pode multiplicador seu valor de 1.1x, 2x, 5x , ou até 100x pra cima, isso depende do jogo que você irá jogar. definimos nosso rollover em uma vantagem de 4%. Isso significa que, se os usuários jogarem um jogo com vantagem de 1%, ele será concluído em uma taxa 4x mais lenta. Assim, o requisito de rollover seria acumulado em uma proporção de $ 0,25 para cada $ 1 apostado. Por outro lado, quanto maior a margem da casa do jogo que está sendo jogado, menos aposta é necessária para completar o rollover.
Enquanto tiver um rollover ativo, sua conta será bloqueada até que você cumpra o requisito definido ou, alternativamente, você perca todo o seu saldo (em sua moeda de bônus relativa) e não tenha apostas ativas restantes.
Durante o rollover ativo, os usuários não podem sacar o valor de bônus.
toda vez que você ganhar, você aumenta seu saldo bônus e diminui a % para completar o rollover, caso você tenha valor de saldo real, ele será descontado toda vez que você perder na rodada.
        </p>


        <p className="text-center text-3xl text-white py-6">
          BÔNUS INDICAÇÃO
        </p>

        <p>
          O bônus indicação é proveniente à indicação de usuários que venham a
          se cadastrar e a depositar o valor mínimo para o investimento no
          sistema aleatório da Rockeetz. O bônus indicação compreende a uma
          bonificação de R$10 por usuário depositante indicado. O bônus
          indicação é por prazo determinado a critério da administradora. A
          referida campanha está sujeita a todas as regras descritas na
          política de privacidade da Rockeetz.
        </p>

        
      </div>
    </div>
  </main>

)

}
  