import { useState } from "react";
import { Link } from "react-router-dom";
import Cadeado from "../../../assets/icons/lock.svg";
import User from "../../../assets/icons/user.svg";
import useRender from "../../../hooks/useRender";
import Swal from "sweetalert2";
import { enterKey } from '../../../assets/function/function.js'

function Modal() {
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [ emailRecuperarSenha, setEmailRecuperarSenha ] = useState("");
  const [error, setError] = useState("");
  const { setSigned, prefixoUrl, setLoading } = useRender();


  function block(){
    document.getElementById('blockUser').classList.toggle('hidden');
}

  function openLogin() {
    document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
    document.querySelector(".login__modal").classList.toggle("opacity-0");
    document.querySelector(".login__modal").classList.remove("hidden");
    document.querySelector(".login--opacidade").classList.toggle("hidden");

  }

  function RecuperarSenha() {
    document.querySelector(".login__modal").classList.toggle("hidden");
    document.querySelector(".login--opacidade").classList.toggle("hidden");
    document.querySelector(".recuperar__modal").classList.toggle("z-[999]");
    document.querySelector(".recuperar__modal").classList.toggle("opacity-0");
    document.querySelector(".recuperar__modal").classList.toggle("invisible");
    document.querySelector(".recuperar--opacidade").classList.toggle("hidden");
  }

  function openRegister(){
 
      var method = document.getElementById("modalRegistro")
      method.classList.toggle("hidden");    
      openLogin()  
  
 
  }

  function OpacityRecuperarSenha() {
    document.querySelector(".recuperar--opacidade").classList.toggle("hidden");
    document.querySelector(".recuperar__modal").classList.toggle("z-[999]");
    document.querySelector(".recuperar__modal").classList.toggle("opacity-0");
    document.querySelector(".recuperar__modal").classList.toggle("invisible");
    openLogin()
    document.querySelector(".login--opacidade").classList.toggle("hidden");
  }


  function handleRecuperarSenha() {
    if (!emailRecuperarSenha) {
      setError("E-mail não informado");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append('Access-Control-Allow-Origin','*');
    myHeaders.append('Access-Control-Allow-Methods','GET,POST,OPTIONS,DELETE,PUT');

    myHeaders.append('Access-Control-Allow-Headers', 'Content-Type,Authorization')
 
    myHeaders.append('Access-Control-Allow-Credentials', 'true')
    var raw = JSON.stringify({
      email: emailRecuperarSenha,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      mode: 'cors',
      cache: 'default'
    };

  
    setLoading(true);
    OpacityRecuperarSenha();
    fetch(`${prefixoUrl}/user/recuperar/senha`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let retorno = JSON.parse(result);
      
        if (retorno) {
          openLogin()
          setLoading(false)
        }
        

          Swal.fire(
            "Recuperação de conta",
            'Se o e-mail estiver vinculado a uma conta ativa será encaminhado o link de recuperação.',
            'success'
          ).then(()=>{
            window.location.href = '/cyberspace';
          })
                
      })
      .catch((error) => console.log("error", error));
  }


  function Login() {
    if (!usuario | !password) {
      setError("Preencha todos os campos");
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      login: usuario,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    setLoading(true)
    fetch(`${prefixoUrl}/login`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let retorno = JSON.parse(result);
        console.log(retorno)
        if(retorno.code === 1002){
          block();
          setLoading(false)
          openLogin() 
        }else{
          if (retorno.code === 1) {
            openLogin()
            localStorage.setItem("user_token", retorno.token);
            localStorage.setItem("user", JSON.stringify(retorno));
           // steakGetApi()
           // steakUpdateApi()
            setSigned(true);
            window.location.reload(true);
            setTimeout(()=>{
              setLoading(false)
            }, 2000)
          }else {  
            setError(retorno.msg);
            setLoading(false)
          }
        }
      })
      .catch((error) => console.log("error", error));
  }





  return (
    <>



      <div className="login__modal w-full fixed top-0 lg:left-[32%] md:left-[35%] left-[9%]  mt-[-600px] z-[99] opacity-0   duration-1000 ">
        <div className="absolute down rounded-xl top-20  transform translate-x-0  z-[5] overflow-y-auto  bg-[#191818] p-2  w-10/12 md:w-4/12   transition duration-500 ">
          <div className="relative h-[170px]">
            <img
              className="absolute w-[350px] left-[-20px] rounded-[50px] z-[1] h-[200px] top-[-70px]"
              src={require("../../../assets/img/fundoverdelogin.png")}
            />
            <h2 className="absolute top-[-10px] text-[5em] font-black ml-3 z-[6] montserrat text-white">
              Login
            </h2>
          </div>
          {/*  input email */}
          <div className="w-full flex justify-center relative input-group">
            <div className="w-12 flex justify-center items-center rounded-bl-lg rounded-tl-lg focus:border-0 bg-cinzaprimary border-2 border-cinzaprimary  ">
              <img className="w-8/12" src={User} alt="" />
            </div>
            <input
              className="w-9/12 border-2 border-cinzaprimary  focus:border-2  focus:border-verdeprimary focus:outline-none p-2 rounded-br-lg rounded-tr-lg"
              placeholder="E-mail/Apelido"
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
              name="login"
              id="login"
              type="text"
            />
          </div>
          {/*  input password */}
          <div className="w-full mt-2 flex justify-center relative input-group">
            <div className="w-12 flex justify-center items-center rounded-bl-lg rounded-tl-lg focus:border-0 bg-cinzaprimary border-2 border-cinzaprimary  ">
              <img className="w-8/12" src={Cadeado} alt="" />
            </div>
            <input
              className="w-9/12 border-2 border-cinzaprimary  focus:border-2  focus:border-verdeprimary focus:outline-none p-2 rounded-br-lg rounded-tr-lg"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => {enterKey(e, "entrar")}}
              name="password"
              id="password"
              type="password"
            />
          </div> 
          {/*  recovery password */}
          <div className="w-7/12 lg:text-lg text-base ml-1 font-normal text-verdeprimary flex justify-center">
            <div onClick={RecuperarSenha} className="font-verdeprimary cursor-pointer ">Esqueceu a senha ?</div>
          </div>

          <div className="flex justify-center mt-10 mb-5 12/12">
            <button
              id="entrar"
              onClick={Login}



              className="bg-verdeprimary border-green-500 hover:bg-green-800 text-white font-bold py-1 px-20 uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-1000"
            >
              Entrar
            </button>
          </div>
          <div className="flex justify-center items-center mt-[-20px]">
            {" "}
            <label className="text-red-500 ">{error}</label>
          </div>
          <div className="flex justify-center mt-2 whitespace-nowrap mb-2 12/12">
            <span className="text-white lg:text-base text-sm">Não possui cadastro?</span>
            <Link to="/register" className="cursor-pointer">
              <span className="ml-1 lg:text-base text-sm font-medium text-verdeprimary">
                CADASTRE-SE
              </span>
            </Link>
          </div>
        </div>


      </div>
      <div onClick={openLogin} className="login--opacidade hidden fixed top-0 bottom-0 right-[0px] left-0 backdrop-blur-sm bg-[#00000021] z-50  duration-[2000ms] "
      ></div>


      <div onClick={OpacityRecuperarSenha} className="recuperar--opacidade hidden fixed top-0 bottom-0 right-[0px] left-0 backdrop-blur-sm bg-[#00000021] z-50  duration-[2000ms] "
      ></div>

      <div className="recuperar__modal  w-full fixed top-0 lg:left-[32%] md:left-[35%] left-[9%]  opacity-0 invisible   duration-1000 ">
        <div className="absolute down rounded-xl top-20  transform translate-x-0  z-[5]  bg-[#191818] p-2  w-10/12 md:w-4/12   transition duration-500 ">
          <div className="relative h-[120px] space-y-6">
            <img
              className="mx-auto mt-8 z-[1] w-52 "
              src={require("../../../assets/logo/rkt_branco.png")}
            />
            <h2 className=" text-2xl font-semibold  text-center z-[6] montserrat text-white">
              Esqueceu a senha?
            </h2>
          </div>
          {/*  input email */}
          <div className="w-full flex justify-center relative input-group">

            <input
              className="w-9/12 border-2 border-cinzaprimary  focus:border-2  focus:border-verdeprimary focus:outline-none p-3 rounded-lg"
              placeholder="Endereço De E-mail"
              onChange={(e)=> setEmailRecuperarSenha(e.target.value)}
              name="login"
              id="recuperarSenha"
              type="text"
            />
          </div>



          <div className="flex justify-center mt-3 mb-10 ">
            <button
              onClick={handleRecuperarSenha}

              className="w-9/12 rounded-lg p-3 bg-verdeprimary  hover:bg-green-800 text-white font-bold  uppercase border-t-3 border-2 border-green-900 hover:border-green-500  duration-1000"
            >
              ENVIAR
            </button>
          </div>
          <div className="flex justify-center items-center mt-[-20px]">
            {" "}
            <label className="text-red-500 ">{error}</label>
          </div>

        </div>
      </div>



     

    </>
  );
}

export default Modal;
