import ConteudoPagamento from "./pagamento/conteudo";
import ContainerBlur from "./blur/container";



export default function Pagamento() {





  return (
    <>

    <ContainerBlur openmodal="payment">    {/* CONTAINER COM O BLUR ATIVADO*/}
      <ConteudoPagamento openmodal="payment"/>   {/* CONTEUDO DO MODAL */}   
    </ContainerBlur>
    </>

  )
};

