
import { useEffect, useState } from "react";
import SteakPainel from "../../components/SteakLogin/steakPainel";
import RewardsLogin from "../modals/SteakLogin/rewardsLogin";
import RewardsLoginNull from "../modals/SteakLogin/rewardsLoginNull";
import RewardsLoginFalse from "../modals/SteakLogin/rewardsLoginFalse";
import ButtonsTop from "./ButtonsTop";
import SteakLogin from "../modals/SteakLogin";
import icoRoleta from "./../../assets/buttons/roleta.svg"
import icoHistorico from "./../../assets/buttons/historico.svg"
import icoAtalho from "./../../assets/buttons/atalho.svg"
import Roullet from "./../../assets/buttons/roullet.svg"
import CyberSpace from "./../../assets/buttons/cyberspace.svg"
import icoCrash from "./../../assets/buttons/crash.svg"
import HistoricodoCrash from "./../../assets/buttons/historicoexplosoes.svg"
import HistoricodaRoleta from "./../../assets/buttons/historicoroleta.svg"
import Atalhos from "./../../assets/buttons/atalhos.svg"
import penLeft from "./../../assets/buttons/pen-left.svg"
import penRight from "./../../assets/buttons/pen-right.svg"
import useRender from "../../hooks/useRender";
import BonusFinalStreak from "../modals/BonusFinalStreak";



export default function Topper(){

const { prefixoUrl, signed, setDiasArray } = useRender()
const [location] = useState( window.location.href) // pega o endereço atual

  function handleeHistorico(){
    document.getElementById("historicoRodadasModal").classList.toggle("hidden");
    document.getElementById("historicoExplosions").click()

  }

  function handleeHistoricoRoleta(){    
    document.getElementById("historicoRodadasRoleta").classList.toggle("hidden");


  }

  function openAtalhos() {
    document.querySelector(".atalho__modal").classList.toggle("mr-[-300px]");
    document.querySelector(".atalho__modal").classList.toggle("opacity-0");
    document.querySelector(".atalho--opacidade").classList.toggle("hidden");
    
    
    
  }
{/* Abre modal diario de streak de login */}
    function handleeSteak() {
        
        var method = document.getElementById("steak")
        setTimeout(() => {
          document.getElementById("steak1").classList.toggle("hidden")
        }, 3000);
    
        setTimeout(() => {
          document.getElementById("steak2").classList.toggle("hidden")
        }, 3000);
    
        setTimeout(() => {
          document.getElementById("steak2").classList.toggle("hidden")
          document.getElementById("steakbarra").classList.toggle("hidden")
          document.getElementById("tudo2Svg").classList.toggle("hidden")
          document.getElementById("titleSteak").innerHTML = "Bônus Diário"
          document.getElementById("steak3").classList.toggle("hidden")
          document.getElementById("cardSteak").classList.add("md:w-4/12")
          document.getElementById("cardSteak").classList.add("h-[340px]")
        }, 6000);
    
    
    
        method.classList.toggle("hidden");
      }

{/* Abre modal para mostrar recompensa resgatada do streak de login */}
      function handleeRewardsSteak(dias , recompensa) {
        
        let method = document.getElementById("rewardsLogin")
        let opacity = document.getElementById("rewardsOpacity")
        method.classList.toggle("hidden"); 
        opacity.classList.add("opacity-0");

        setTimeout(() => {
          opacity.classList.toggle("opacity-0");
    
        }, 100);

        document.getElementById("diaLogin").innerText = dias !== 'sabado' ?? dias !== 'domingo'  ? dias + '-feira' :  dias;
        document.getElementById("recompensaStreakCheck").innerHTML = recompensa
      }

      function handleeNullRewardsSteak(dias, recompensa) {
        
        let method = document.getElementById("rewardsLoginNull")
        let opacity = document.getElementById("rewardsOpacityNull")
        method.classList.toggle("hidden"); 
        opacity.classList.add("opacity-0");

        setTimeout(() => {
          opacity.classList.toggle("opacity-0");
    
        }, 100);

        document.getElementById("diaLoginNull").innerText = dias !== 'sabado' ?? dias !== 'domingo'  ? dias + '-feira' :  dias;
        document.getElementById("recompensaStreakNull").innerHTML = recompensa 
      }


      {/* Abre modal para mostrar a recompensa que não foi resgatada do streak de login */}
      function handleeFalseRewardsSteak(dias , recompensa) {
        
        let method = document.getElementById("rewardsLoginFalse")
        let opacity = document.getElementById("rewardsOpacityFalse")
        method.classList.toggle("hidden"); 
        opacity.classList.add("opacity-0");

        setTimeout(() => {
          opacity.classList.toggle("opacity-0");
    
        }, 100);

        document.getElementById("diaLoginFalse").innerText = dias !== 'sabado' ?? dias !== 'domingo'  ? dias + '-feira' :  dias ;
        document.getElementById("recompensaStreakFalse").innerHTML = recompensa
      }

      {/* Api Streak de login, verificando os dias da semanas que foi resgatado */}
      function handleeStreakloginGet() {  

        var myHeaders = new Headers();
    
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
    
        fetch(
          `${prefixoUrl}/streak_login/get?token=${localStorage.getItem("user_token")}`,requestOptions)
          .then((response) => response.text())
          .then((result) => {
            let dados = JSON.parse(result);
            if(dados.code == 1){
              SteakLoginDate(dados)
            }
           
            
           
          })
          .catch((error) => console.log("error", error));
      }


    {/* Verifica o dia da semana */}
    function SteakLoginDate(result) {// recebe como parametro o retorno da api handleeStreakloginGet
      let semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
      let day = new Date();
      let diaHoje = semana[day.getDay()];
  
      switch (diaHoje) {

       
        case "Segunda-Feira": 
          if(result.result[0].checked === null){ //verifica se o dia atual não foi resgatado o bonus
            handleeSteak()                       //abre modal de streak de login 
          }
          result.result[0].checked = true      //marca o dia atual como true 
          setDiasArray(result.result)          //manda para o contexto o retorno atualizado da api
          break;
  
        case "Terça-Feira":

          if(result.result[1].checked === null){
            handleeSteak()
          }
          result.result[1].checked = true
          setDiasArray(result.result)
          break;
  
        case "Quarta-Feira":

          if(result.result[2].checked === null){
            handleeSteak()
          }
          result.result[2].checked = true
          setDiasArray(result.result)
          break;
  
        case "Quinta-Feira":
       
          if(result.result[3].checked === null){
            handleeSteak()
          }
          result.result[3].checked = true
          setDiasArray(result.result)

          break;
  
        case "Sexta-Feira":
          if(result.result[4].checked === null){
            handleeSteak()
          }
          result.result[4].checked = true
          setDiasArray(result.result)
  
          break;
  
        case "Sábado":
          if(result.result[5].checked === null){
            handleeSteak()
          }
          result.result[5].checked = true
          setDiasArray(result.result)
        
          break;

          case "Domingo":
            if(result.result[6].checked === null){
              handleeSteak()
            }
            result.result[6].checked = true
            setDiasArray(result.result)
            break;
  
        default:
          break;
      }
    }

    useEffect(() => {
      handleeStreakloginGet()
    }, []);

    return(
    <>
    <div className={`flex mt-4 justify-center w-full ${signed === false ? ` lg:flex hidden` : ` `}`}>
              <div className="w-11/12 relative flex justify-center md:w-9/12 rounded-3xl border-green-500 border-4 bg-cinzasecondary">
                <div className="absolute h-[100%] flex items-center w-full">
                  <div className="absolute w-2/12  lg:w-1/12">
                    <img className="w-8/12" src={penLeft} />
                  </div>
                  <div className="absolute w-2/12 flex justify-end right-0 lg:w-1/12">
                    <img className="w-8/12" src={penRight} />
                  </div>

             
                </div>
             
                <div className="lg:flex hidden gap-16  w-7/12 z-[1] p-4  ">


                  {/* verifica a rota atual e renderiza apenas os btn desejado em cada rota */}
                            {location.indexOf("cyberspace") > -1 ? (
                    <>
                        <ButtonsTop ico={icoRoleta} conteudo={Roullet} link="/roulletplanet"  />
                        <ButtonsTop ico={icoHistorico} conteudo={HistoricodoCrash} click={handleeHistorico}/>
                        <ButtonsTop ico={icoAtalho} conteudo={Atalhos} click={openAtalhos} />
                    </>
            ): location.indexOf("roulletplanet") > -1 ?  (
                      <>
                        <ButtonsTop ico={icoCrash} conteudo={CyberSpace} link="/cyberspace"  />
                        <ButtonsTop ico={icoHistorico} conteudo={HistoricodaRoleta} click={handleeHistoricoRoleta}/>
                        <ButtonsTop ico={icoAtalho} conteudo={Atalhos} click={openAtalhos} />
                    </>
                ):     (
                      <>
                        <ButtonsTop ico={icoCrash} conteudo={CyberSpace} link="/cyberspace"  />
                        <ButtonsTop ico={icoRoleta} conteudo={Roullet} link="/roulletplanet"  />
                        <ButtonsTop ico={icoAtalho} conteudo={Atalhos} click={openAtalhos} />
                      </>
                    )}
             

                </div>
     
                  {signed === true ? (
                    <> 
                    <div className="lg:w-3/12  w-full flex justify-center ">
                      <div className="w-10/12 lg:w-full flex justify-center ">  
                        <SteakPainel checkOnClick={handleeRewardsSteak}
                                     nullOnClick={handleeNullRewardsSteak} 
                                     falseOnClick={handleeFalseRewardsSteak}  /> 
                      </div>
                   </div>          
                    <RewardsLogin diaSemana=""/>
                    <RewardsLoginNull diaSemana=""/>
                    <RewardsLoginFalse diaSemana=""/>
                    </>
           

                   
                  ) : " "}
      
          
              </div>
            </div>

            <SteakLogin handlee="steak"/>

            <BonusFinalStreak />
    </>
    )
}