import { useState, useEffect } from "react";
import ButtonsPhone from "./buttons";
import { Link } from "react-router-dom";
import TermoseCondicoes from "../tipoderegras/Termos";
import RegrasPoliticas from "../tipoderegras/Politicas";
import JogoResponsavel from "../tipoderegras/JogoResponsavel";
import AmlKyc from "../tipoderegras/aml";
import RegrasReembolso from "../tipoderegras/reembolso";
import RegrasBonus from "../tipoderegras/bonus";
import Eua from "../../../assets/translate/eua.png"
import Brasil from "../../../assets/translate/brasil.png"
import TermoseCondicoesEn from "../typesrules/TermosEn";
import RegrasPoliticasEn from "../typesrules/PoliticasEn";
import JogoResponsavelEn from "../typesrules/JogoResponsavelEn";
import AmlKycEn from "../typesrules/amlEn";
import RegrasReembolsoEn from "../typesrules/reembolsoEn";
import RegrasBonusEn from "../typesrules/bonusEn";

export default function RegrasPhone() {

    const [option, setOption] = useState("1")

    const [locales, setLocales] = useState('en'); // Idioma inicial (português)


    const toggleEn = () => {

        setLocales("en");

    };
    const togglePt = () => {
        setLocales("pt");

    };

    useEffect(() => {
        console.log(locales)
    }, [locales])

    function buttonactive(item) {
        document.getElementById("regras--true").classList.remove("hidden")
        document.getElementById("regras--true").classList.add("block")
        document.getElementById("regras--menu").classList.remove("flex")
        document.getElementById("regras--menu").classList.add("hidden")
        setOption(item)
    }

    function back() {
        document.getElementById("regras--true").classList.remove("block")
        document.getElementById("regras--true").classList.add("hidden")
        document.getElementById("regras--menu").classList.remove("hidden")
        document.getElementById("regras--menu").classList.add("flex")

    }


    return (
        <>
            <div className="w-full">
                <div id="regras--menu" className="flex bg-gray-800 flex-col rounded-lg p-2">
                    <div className="w-full flex items-center justify-between my-2 ">
                        <div className="flex justify-center gap-3 w-4/12 bg-gray-900 px-2 py-1">
                            <img onClick={toggleEn} className="cursor-pointer w-[40px] h-[25px]" src={Eua} />
                            <img onClick={togglePt} className="cursor-pointer w-[40px] h-[25px]" src={Brasil} />

                        </div>
                        <Link to="/">
                            <div className="text-xl text-white mx-2">Voltar</div>
                        </Link>
                    </div>
                    <div className="h-[1px] bg-purple-900 mb-2 w-full"></div>
                    <div className="w-full flex-col">
                        <ButtonsPhone id="1" buttonactive={buttonactive} title={locales == "pt" ? "Termos e Condições" : "Terms and Conditions"} />
                        <ButtonsPhone id="2" buttonactive={buttonactive} title={locales == "pt" ? "Politica de Privacidade" : "Privacy Policy "} />
                        <ButtonsPhone id="3" buttonactive={buttonactive} title={locales == "pt" ? "Jogo Responsável" : "Responsible Gambling"} />
                        <ButtonsPhone id="4" buttonactive={buttonactive} title={locales == "pt" ? "Aml - Anti-Lavagem de dinheiro" : "AML - Anti-Money"} />
                        <ButtonsPhone id="5" buttonactive={buttonactive} title={locales == "pt" ? "Política de Reembolso" : "REFUND POLICY"} />
                        <ButtonsPhone id="6" buttonactive={buttonactive} title={locales == "pt" ? "Bônus" : "BONUS"} />
                    </div>
                </div>

                <div id="regras--true" className="hidden">
                    <div className="h-[60px] p-2 fixed top-0 w-full bg-gray-800 items-center flex justify-between border-b">

                    <div className="flex justify-center gap-3 w-4/12 bg-gray-900 px-2 py-1">
                            <img onClick={toggleEn} className="cursor-pointer w-[40px] h-[25px]" src={Eua} />
                            <img onClick={togglePt} className="cursor-pointer w-[40px] h-[25px]" src={Brasil} />

                        </div>

                        <div onClick={back} className="text-xl text-white mx-2">Voltar</div>


                    </div>
                    <div className="p-3 mt-14">
                    {locales === "pt" ? 
                        (() => {
                            switch (option) {
                                case '1':
                                    return <TermoseCondicoes />;
                                case '2':
                                    return <RegrasPoliticas />;
                                case '3':
                                    return <JogoResponsavel />;
                                case '4':
                                    return <AmlKyc />;
                                case '5':
                                    return <RegrasReembolso />;
                                    case '6':
                                    return <RegrasBonus />;
                                default:
                                    return null;
                            }
                        })()
                    :
                    (() => {
                        switch (option) {
                            case '1':
                                return <TermoseCondicoesEn />;
                            case '2':
                                return <RegrasPoliticasEn />;
                            case '3':
                                return <JogoResponsavelEn />;
                            case '4':
                                return <AmlKycEn />;
                            case '5':
                                return <RegrasReembolsoEn />;
                                case '6':
                                return <RegrasBonusEn />;
                            default:
                                return null;
                        }
                    })()
                    }
                    </div>

                </div>
            </div>
        </>
    )
}