import { Link } from "react-router-dom";
import useRender from "../../../hooks/useRender";



function Menu() {
  const { signed, setSigned, prefixoUrl } = useRender();

  function openMenu() {
    document.querySelector(".sidebar").classList.toggle("ml-[-250px]");
    document.querySelector(".sidebar").classList.toggle("opacity-0");
    document.querySelector(".sidebar--opacidade").classList.toggle("hidden");
  }

  function Sair() {
    setSigned(false);
   // localStorage.clear()
   localStorage.removeItem("user");
   localStorage.removeItem("user_token");
   localStorage.removeItem("indicacao");
   localStorage.removeItem("numeroTotalIndicados");
   localStorage.removeItem("indicadosQueDepositaram");
   localStorage.removeItem("saldoTotalParaResgate");
   localStorage.removeItem("historicoDeJogo");
   localStorage.removeItem("historicoDeSaque");
   localStorage.removeItem("historicoDeDeposito");
   localStorage.removeItem("Bonus");
   localStorage.removeItem("saldoSaque");
   localStorage.removeItem("streak_dados");
    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${prefixoUrl}/logout`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
      localStorage.removeItem("user");
      window.location.href = '/home';
      openMenu();
  }



  function methodPayment() {
    var method = document.getElementById("payment")
    method.classList.toggle("hidden");
    document.getElementById("modalPagamento").classList.remove("hidden");
    openMenu();
  }

  function goHome(){
    window.location.href = '/home';
  }

  function openSelectGame() {
    let selectgame = document.getElementById("selectGame")
    selectgame.classList.toggle("hidden");
    openMenu();
}


  function openCupom(){
    let method = document.getElementById("modalCupom")
    method.classList.toggle("hidden");
    let opacity = document.getElementById("cupomOpacity")
    opacity.classList.add("opacity-0");

    setTimeout(() => {
      opacity.classList.toggle("opacity-0");

    }, 100);


    openMenu();
  }
 
  return (
    <>
      <div onClick={openMenu} className="cursor-pointer  ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="white"
              className="bi bi-list cursor-pointer ml-3"
              viewBox="0 0 16 16" >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
        <div className=" sidebar--opacidade fixed top-0 bottom-0 left-[0px] right-0 bg-black z-50 opacity-75 duration-[2000ms]  hidden "></div>
        <div onClick={openMenu} className="sidebar  fixed top-0 bottom-0 left-0 overflow-y-auto ml-[-250px] p-2 w-[250px] text-center text-[#42aa2f] bg-[#141414] z-[99] opacity-0 duration-1000"  >
          <div className="flex justify-between">
            <div onClick={openMenu} className="items-center ml-4 flex">
              <svg
                id="Camada_1"
                data-name="Camada 1"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 62.58 62.58">
                <path
                  fill="white"
                  d="M23.43,81.31h0a8.9,8.9,0,0,1,0-12.56l47.1-47.1a5,5,0,0,1,7.07,0h0a8.9,8.9,0,0,1,0,12.56L25.5,81.31A5,5,0,0,1,23.43,81.31Z"
                  transform="translate(-19.23 -20.19)" />
                <path
                  fill="white"
                  d="M80.35,78.57h0a8.9,8.9,0,0,1-12.56,0l-47.1-47.1a5,5,0,0,1,0-7.07h0a8.9,8.9,0,0,1,12.56,0l47.1,47.1A5,5,0,0,1,80.35,78.57Z"
                  transform="translate(-19.23 -20.19)" />
              </svg>
            </div>
            <div className="text-white font-medium mr-4 text-2xl">MENU</div>
          </div>
          <hr className="w-full mt-3" />
          {/* HOME */}

    
      <div onClick={goHome} className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              className="bi bi-house-door"
              viewBox="0 0 16 16"
            >
              <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
            </svg>
            <span className="text-base ml-4 text-white">Home</span>
          </div>

                {/* Jogos */}

       
                    <div onClick={openSelectGame}   className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-controller" viewBox="0 0 16 16">
                      <path d="M11.5 6.027a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2.5-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm-1.5 1.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm-6.5-3h1v1h1v1h-1v1h-1v-1h-1v-1h1v-1z"/>
                      <path d="M3.051 3.26a.5.5 0 0 1 .354-.613l1.932-.518a.5.5 0 0 1 .62.39c.655-.079 1.35-.117 2.043-.117.72 0 1.443.041 2.12.126a.5.5 0 0 1 .622-.399l1.932.518a.5.5 0 0 1 .306.729c.14.09.266.19.373.297.408.408.78 1.05 1.095 1.772.32.733.599 1.591.805 2.466.206.875.34 1.78.364 2.606.024.816-.059 1.602-.328 2.21a1.42 1.42 0 0 1-1.445.83c-.636-.067-1.115-.394-1.513-.773-.245-.232-.496-.526-.739-.808-.126-.148-.25-.292-.368-.423-.728-.804-1.597-1.527-3.224-1.527-1.627 0-2.496.723-3.224 1.527-.119.131-.242.275-.368.423-.243.282-.494.575-.739.808-.398.38-.877.706-1.513.773a1.42 1.42 0 0 1-1.445-.83c-.27-.608-.352-1.395-.329-2.21.024-.826.16-1.73.365-2.606.206-.875.486-1.733.805-2.466.315-.722.687-1.364 1.094-1.772a2.34 2.34 0 0 1 .433-.335.504.504 0 0 1-.028-.079zm2.036.412c-.877.185-1.469.443-1.733.708-.276.276-.587.783-.885 1.465a13.748 13.748 0 0 0-.748 2.295 12.351 12.351 0 0 0-.339 2.406c-.022.755.062 1.368.243 1.776a.42.42 0 0 0 .426.24c.327-.034.61-.199.929-.502.212-.202.4-.423.615-.674.133-.156.276-.323.44-.504C4.861 9.969 5.978 9.027 8 9.027s3.139.942 3.965 1.855c.164.181.307.348.44.504.214.251.403.472.615.674.318.303.601.468.929.503a.42.42 0 0 0 .426-.241c.18-.408.265-1.02.243-1.776a12.354 12.354 0 0 0-.339-2.406 13.753 13.753 0 0 0-.748-2.295c-.298-.682-.61-1.19-.885-1.465-.264-.265-.856-.523-1.733-.708-.85-.179-1.877-.27-2.913-.27-1.036 0-2.063.091-2.913.27z"/>
                    </svg>
                      <span className="text-base ml-4 text-white ">
                      Jogos
                      </span>
                    </div>
                
            


          {/* CENTRAL DO JOGADOR */}

          {signed == true ? (
            <div >

                <Link to="/centraldojogador">
                    <div  className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="white"
                        className="bi bi-person-workspace"
                        viewBox="0 0 16 16" >
                        <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                      </svg>
                      <span className="text-base ml-4 text-white ">
                        Central do jogador
                      </span>
                    </div>
                
                </Link>




              

              {/* CUPOMS */}
           
              <div onClick={openCupom} className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="white"
                  className="bi bi-card-checklist"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                  <path d="M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
                </svg>
                <span className="text-base ml-4 text-white ">Cupom</span>
              </div>

              {/* BONUS */}
              <Link to="/bonus">
              <div  className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-card-heading" viewBox="0 0 16 16">
                <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-1z"/>
              </svg>
                <span className="text-base ml-4 text-white ">Bônus</span>
              </div>
              </Link>

              
     
 
              {/* DEPOSITAR */}
              <div onClick={methodPayment} className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="white"
                  className="bi bi-clipboard-plus"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z"
                  />
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                </svg>
                <span className="text-base ml-4 text-white ">Depositar</span>
              </div>

              {/* SACAR */}
              <Link to="/sacar">
              <div
              //  onClick={renderSacar}
                className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="white"
                  className="bi bi-clipboard-minus-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1ZM6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1 0-1Z" />
                </svg>
                <span className="text-base ml-4 text-white ">Sacar</span>
              </div>
              </Link>
             

              {/* INDICAR AMIGOS */}
                <Link to="/centraldojogador/indicacao">
                <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] " >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="white"
                  className="bi bi-person-plus"
                  viewBox="0 0 16 16"
                >
                  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                  <path
                    fillRule="evenodd"
                    d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                  />
                </svg>
                <span className="text-base ml-4 text-white ">INDIQUE e GANHE</span>
              </div>
                </Link>


            </div>
          ) : (
            " "
          )}

          {/* REGRAS DO JOGO */}


            <Link to="/regras">
            <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] " >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              className="bi bi-journal-text"
              viewBox="0 0 16 16"
            >
              <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
              <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
              <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
            </svg>
            <span className="text-base ml-4 text-white ">Regras do jogo</span>
          </div>
            </Link>


          {/* SUPORTE */}
            <Link to="/suporte">
            <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] " >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              className="bi bi-headset"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
            </svg>
            <span className="text-base ml-4 text-white ">Suporte</span>
          </div>
            </Link>


          {signed == true ? (
                 
          <div onClick={Sair}
          className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="white"
            className="bi bi-door-open"
            viewBox="0 0 16 16"
          >
            <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
            <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z" />
          </svg>
          <span className="text-base ml-4 text-white ">Sair</span>
        </div>

          ): (
                 <Link to="/register">
                      <div className="p-2 mt-3 flex items-center rounded-md px-4 duration-500 cursor-pointer  hover:bg-[#0e0d0d] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                          <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                          <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                        </svg>
                      <span className="text-base ml-4 text-white ">Cadastre-se</span>
                    </div>
                 </Link>
  

          )}


        </div>
      </div>

     
    </>
  );
}

export default Menu;
