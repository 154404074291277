import { Link } from "react-router-dom";
import BtnLogin from "../../../assets/header/login.svg"
import Polygon from "../../../assets/header/polygon.svg"
function Login() {

  function openRegister(){
 
    var method = document.getElementById("modalRegistro")
    method.classList.toggle("hidden");     



 }
 function openLogin() {
  document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
  document.querySelector(".login__modal").classList.toggle("opacity-0");
  document.querySelector(".login--opacidade").classList.toggle("hidden");
  document.getElementById("login").focus();
}

  return (
    <>
      <div className="flex items-center">
      
          <Link to="/register" className="cursor-pointer hidden lg:block mx-2 bg-cinzaprimary  hover:bg-green-800 text-white font-bold py-1 px-6 whitespace-nowrap uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-700">
            CADASTRE-SE
          </Link>
     

        
        <div onClick={openLogin} className="w-32 lg:w-40  ml-3 cursor-pointer group flex justify-center items-center relative">
          <div className="z-[1] absolute text-white  font-bold group-hover:scale-125 animate-pulse hover:text-green-500 duration-300">LOGIN</div>
         <div className="w-full relative  flex items-center ">
          <img className="absolute w-[16px] h-[16px] lg:w-[24px] lg:h-[24px] left-[-8px] lg:left-[-12px] animate-spin duration-300  group-hover:left-[-15px]" src={Polygon}></img>
          <img className="absolute w-[16px] h-[16px] lg:w-[24px] lg:h-[24px] right-[-8px] lg:right-[-12px] animate-spin duration-300 group-hover:right-[-15px]" src={Polygon}></img>
          <img  className="w-full cursor-pointer" src={BtnLogin}/>
          </div>
        
        </div>
     
      </div>
    </>
  );
}

export default Login;
