export default function AmlKycEn() {
    return (
        <>


            <div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">AML Policy of LAM Gaming Group B.V. </div>
                </div>
                <div className="text-white mt-5">Last updated: June 1, 2023</div>
                <br /><br /><br />
                <div className="text-white mt-2 text-lg font-semibold">For Cash Deposits and Cash Withdraws. </div>
                
                (AML - Anti-Money Laundering Policy of rockeetz.com)
                <br /><br />Introduction: rockeetz.com is operated by LAM Gaming Group B.V. with an office at Abraham de Veerstraat 9, Willemstad, Curaçao. Company registration number 163826.
                <br /> <br />Objective of AML Policy: We aim to provide the highest level of security to all our users and customers on www.rockeetz.com by conducting a three-step account verification process to ensure the identity of our customers. The purpose behind this is to prove that the registered person's data is correct and the deposit methods used are not stolen or used by someone else, which is to create an overall framework for combating money laundering. We also take into account that depending on nationality and origin, different security measures should be taken for payment and withdrawal.
                <br /><br />
                www.rockeetz.com also implements reasonable measures to control and limit the risk of ML (Money Laundering), including the provision of appropriate means.
                <br /><br />
                www.rockeetz.com is committed to high standards of anti-money laundering (AML) in accordance with EU guidelines and compliance and requires management and employees to apply these standards in preventing the use of their services for money laundering purposes.

                <br /> <br />
                The AML program of www.rockeetz.com is designed to be compliant with:
                <br /> <br />
                EU: "Directive 2015/849 of the European Parliament and of the Council of 20 May 2015 on the prevention of the use of the financial system for the purposes of money laundering".
                <br /> <br />
                EU: "Regulation 2015/847 on information accompanying transfers of funds".
                <br /> <br />
                EU: Various regulations imposing sanctions or restrictive measures against individuals and embargoes on certain goods and technologies, including all dual-use goods.
                <br /> <br />
                EU: "Law of 18 September 2017 on the prevention of money laundering, limitation of the use of cash.

                <div className="text-white mt-2 font-semibold">Definition of money laundering:</div>

                • Money laundering is understood as:
                <br />• The conversion or transfer of property, especially money, knowing that such property is derived from criminal activities or from participation in such activities, with the intention of concealing or disguising the illicit origin of the property or of assisting any person involved in the commission of such activity to evade the legal consequences of their actions.
                <br />• The concealment or disguise of the true nature, source, location, disposition, movement, rights with respect to, or ownership of, property, knowing that such property is derived from criminal activity or from an act of participation in such activity.
                <br />• The acquisition, possession, or use of property, knowing, at the time of receipt, that such property was derived from criminal activity or from assisting in such activity.
                <br />• Participation, association to commit, attempts to commit, and aiding, abetting, facilitating, and counseling the perpetration of any of the actions referred to in the previous points.
                <br /><br />
                Money laundering is considered as such even when the activities that gave rise to the property to be laundered have been carried out on the territory of another Member State or a third country.

                <div className="text-white mt-2 font-semibold">AML Organization for www.rockeetz.com:</div>
                According to AML legislation, www.rockeetz.com has appointed the "highest level" for the prevention of ML: The overall management of LAM Gaming Group B.V. is in charge.
                <br /><br />In addition, an AMLCO (Anti Money Laundering Compliance Officer) oversees the implementation of the AML policy and procedures within the System.
                <br /><br />
                <div className="text-white  text-md mt-2 font-semibold">The AMLCO reports directly to the Management Board:</div>
                <div className="text-white mt-2 font-semibold">Changes in AML policy and implementation requirements:</div>

                Each major change to www.rockeetz.com's AML policy is subject to approval from the general management of LAM Gaming Group B.V. and the Anti-Money Laundering Compliance Officer.

                <div className="text-white mt-2 font-semibold">Three-Step Verification:</div>
                The first-step verification must be completed by all users and clients for withdrawal. Regarding the choice of payment, the payment amount, the withdrawal amount, the choice of withdrawal, and the nationality of the user/client, the first step of verification must be performed. The first-step verification is a document that must be filled out by the user/client themselves. The following information must be provided: first name, last name, date of birth, country of habitual residence, gender, and complete address.
                <div className="text-white mt-2 font-semibold">Second-Step Verification:</div>
                The second-step verification must be completed by any user who deposits more than $2,000 or withdraws more than $2,000. Until the second-step verification is completed, the withdrawal or deposit will be on hold. The verification step will lead the user or client to a subpage where they will be required to submit their ID. The user/client must take a photograph of their identification document while a paperclip with a random six-digit number is next to their ID. Only an official ID can be used for ID verification, and depending on the country, the range of accepted IDs may vary. There will also be an electronic check to verify if the data entered in the first-step verification is correct. The electronic check will verify through two different databases to ensure that the provided information matches the filled document and the name on the ID. If the electronic check fails or is not possible, the user/client is required to submit proof of their current residency. A government-issued registration certificate or a similar document is required.
                <div className="text-white mt-2 font-semibold">Third-Step Verification:</div>
                The third-step verification must be completed by any user who deposits more than $5,000 or withdraws more than $5,000. Until the third-step verification is completed, the withdrawal or deposit will be on hold. For the third step, a user/client will be asked to provide a source of wealth.
                <div className="text-white mt-2 font-semibold">Customer Identification and Verification (KYC)</div>
                Formal identification of customers at the start of business relationships is a vital element for both anti-money laundering regulations and KYC policy.
                This identification is based on the following fundamental principles:
                A copy of your passport, identity card, or driver's license, each shown alongside a handwritten note mentioning six randomly generated numbers. Additionally, a second photo with the user/client's face is required. The user/client may blur all information, including date of birth, nationality, gender, name, last name, and photo, to protect their privacy.
                Please note that all four corners of the ID must be visible in the same image, and all details must be clearly legible in addition to the mentioned above. We may request further details if necessary.
                An employee may perform additional checks if needed, based on the situation.


                <div className="text-white mt-2 font-semibold">Proof of Address:</div>
                Proof of address will be done through different electronic checks, using two different databases. If an electronic check fails, the user/client has the option to provide manual proof.<br /><br />
                A recent utility bill sent to your registered address, issued within the last 3 months, or an official government document verifying your residency status will serve as proof of address.<br />
                To expedite the approval process, please ensure that the document is submitted with clear resolution, where all four corners of the document are visible and all text is legible.<br />
                For example: A utility bill, water bill, bank statement, or any government correspondence addressed to you.<br />
                An employee may conduct additional checks, if necessary, based on the situation.<br /><br />

                <div className="text-white mt-2 font-semibold">Source of Income</div>
                If a player deposits more than five thousand euros, there is a process of understanding the source of wealth (SOW).<br /><br />

                Examples of SOW include:<br />
                • Business ownership<br />
                • Employment<br />
                • Inheritance<br />
                • Investment<br />
                • Family<br /><br />

                It is crucial that the origin and legitimacy of this wealth are clearly understood. If this is not possible, the employee may request additional documentation or proof.<br /><br />

                The account will be frozen if the same user deposits this amount in one go or through multiple transactions reaching this amount. An email will be manually sent to them to go through the above process, and a notification will be displayed on the website.<br /><br />

                www.rockeetz.com also requests a bank transfer/credit card to further ensure the identity of the user/client. It also provides additional information regarding the financial situation of the user/client.<br /><br />


                <div className="text-white mt-2 font-semibold">Basic Document for the First Step:</div>
                The basic document will be accessible through the configuration page on www.rockeetz.com. Every user must fill in the following information:
                <br /><br />
                • First name<br />
                • Last name<br />
                • Nationality<br />
                • Gender<br />
                • Date of birth<br />
                The document will be saved and created by an AI; an employee may conduct additional checks, if necessary, based on the situation.<br /><br />

                <div className="text-white mt-2 font-semibold">Risk Management:</div>

                To deal with different risks and varying wealth statuses in different regions of the world, www.rockeetz.com will categorize each nation into three different risk regions.<br /><br />
                Region one: Low risk<br />
                For every nation in region one, the three-step verification will be conducted as described earlier.<br /><br />
                Region two: Medium risk<br />
                For every nation in region two, the three-step verification will be conducted for lower deposit, withdrawal, and tipping amounts. The first step will be conducted as usual. The second step will be conducted after depositing $1000 and withdrawing $1000. The third step will be conducted after depositing $2500 and withdrawing $2500 for clients in a medium-risk region.<br /><br />
                Region three: High risk<br />
                High-risk regions will be banned. The high-risk regions will be regularly updated to keep up with the changing environment of a rapidly changing world.


                <div className="text-white mt-2 font-semibold">Additional Measures</div>
                Furthermore, an AI supervised by the AML compliance officer will monitor any unusual behavior and report it immediately to an employee at www.rockeetz.com. Based on general experience and risk-based approach, human employees will re-verify all checks previously conducted by the AI or other employees and may redo or conduct additional checks based on the situation. Additionally, a data scientist supported by modern electronic analytic systems will look for unusual behaviors such as depositing and withdrawing without longer betting sessions, attempts to use a different bank account for deposit and withdrawal, changes in nationality, changes in currency, behavioral and activity changes, as well as checks if an account is being used by its original owner. Furthermore, a user must use the same method for withdrawal as used for deposit, for the initial deposit, to prevent any money laundering.<br /><br />

                <div className="text-white mt-2 font-semibold">Enterprise-Wide Risk Assessment</div>
                As part of its risk-based approach, www.rockeetz.com has conducted an "Enterprise-Wide Risk Assessment" (EWRA) to identify and understand the specific risks of www.rockeetz.com and its lines of business. The AML risk policy is determined after identifying and documenting the inherent risks in its business lines such as the services the website offers, the users to whom the services are offered, the transactions conducted by those users, the delivery channels used by the platform, the geographic locations of the platform's operations, customers and transactions, and other qualitative and emerging risks. The identification of AML risk categories is based on www.rockeetz.com's understanding of regulatory requirements, regulatory expectations, and industry guidance. Additional security measures are taken to address the additional risks that come with the worldwide web. The EWRA is reassessed annually.



                <div className="text-white mt-2 font-semibold">Ongoing Transaction Monitoring</div>
                AML Compliance ensures that "continuous transaction monitoring" is conducted to detect unusual or suspicious transactions compared to the customer's profile. This transaction monitoring is conducted at two levels:<br /><br />
                1) The First Line of Control:<br />
                www.rockeetz.com only works with reliable payment service providers, all of whom have effective AML policies to prevent most suspicious deposits on www.rockeetz.com from occurring without proper execution of KYC procedures for the potential customer.
                <br /><br />
                2) The Second Line of Control:<br />
                www.rockeetz.com notifies its network so that any contact with the customer or player or authorized representative must lead to the exercise of due diligence on the transactions in the account in question. These include:<br /><br />
                • Requests for the execution of financial transactions in the account.<br />
                • Inquiries regarding payment methods or services in the account.<br /><br />
                In addition, the three-step verification with adjusted risk management should provide all necessary information about all customers of www.rockeetz.com at all times.
                <br /><br />Furthermore, all transactions must be monitored by employees supervised by the AML compliance officer, who is overseen by the general management.
                <br /><br />Specific transactions submitted to the customer support manager, possibly through their Compliance Manager, must also undergo due diligence.
                <br /><br />Determining the atypical nature of one or more transactions essentially depends on a subjective assessment, relative to the customer's knowledge (KYC), their financial behavior, and the counterparty of the transaction.
                <br /><br />These checks will be performed by an automated system, while an employee verifies them for additional security.
                <br /><br />Movements observed in customer accounts for which it is difficult to obtain a proper understanding of lawful activities and the origin of funds should therefore be quickly deemed atypical (as they cannot be directly justified).
                <br /><br />Any employee of www.rockeetz.com must report to the AML division any atypical operations they observe and cannot attribute to the customer's lawful activity or known source of income.
                <br /><br />

                3) The Third Line of Control:<br />
                As the final line of defense against AML, www.rockeetz.com will perform manual checks on all suspicious and high-risk users to fully prevent money laundering.
                <br /><br />If fraud or money laundering is detected, the authorities will be notified.


                <div className="text-white mt-2 font-semibold">Reporting Suspicious Transactions at ROCKEETZ.COM</div>
                In its internal procedures, www.rockeetz.com accurately describes to its employees when it is necessary to report and how to proceed with such reporting.
                <br /><br />Reports of unusual transactions are analyzed by the AML team according to the precise and detailed methodology described in the internal procedures.
                <br /><br />Based on the outcome of this examination and the information collected, the AML team will:<br />
                <br />•	Decide whether or not to submit a report to the UIF, in accordance with the legal obligations set forth in the Law of September 18, 2017.
                <br />•	Decide whether or not to terminate the business relationship with the customer.



                <div className="text-white mt-2 font-semibold">Procedures</div>
                AML rules, including minimum KYC standards, will be translated into guidelines or operational procedures that are available on the Intranet website at www.rockeetz.com.

                <div className="text-white mt-2 font-semibold">Record Keeping</div>
                Records of data obtained for identification purposes must be kept for at least ten years after the termination of the business relationship.
                <br /><br />Records of all transaction data must be kept for at least ten years after the completion of the transactions or the termination of the business relationship.
                <br /><br />These data will be securely stored and encrypted both offline and online.




                <div className="text-white mt-2 font-semibold">Training:</div>
                Employees at www.rockeetz.com will undergo manual controls in a risk-based approval for which they receive specialized training.
                <br /><br />The training and awareness program manifests in:
                <br />- A mandatory AML training program in accordance with the latest regulatory developments, for those who have contact with finances.
                <br />- Academic AML learning sessions for all new employees.
                <br /><br />The content of this training program should be defined according to the type of business the trainees are in and the positions they hold. These sessions are conducted by an AML specialist working in the AML team of LAM Gaming Group B.V.

                <div className="text-white mt-2 font-semibold">Audit:</div>
                Internal auditing regularly establishes missions and reports on AML activities.





                <div className="text-white mt-2 font-semibold">Data Security</div>
                All data provided by any user/client will be kept secure, not sold, or shared with third parties. Only if compelled by law, or to prevent money laundering, the data may be shared with the relevant AML authority.
                <br /><br />rockeetz.com will adhere to all guidelines and rules of the data protection directive (officially Directive 95/46/EC).

                <div className="text-white mt-2 font-semibold">Contact Us:</div>
                If you have any questions regarding our AML and KYC policy, please contact us:<br />
                •	By email: datasecurity@rockeetz.com
                <br /><br />
                If you have any complaints about our AML and KYC policy or about the checks conducted on your Account and Person, please contact us:
                <br /><br />
                •	By email: datasecurity@rockeetz.com
           



        </div >





        </>
    )
}