import { useEffect, useState } from "react"
import Whatsapp from "../../../assets/regras/whatsapp.png"
import ButtonsRegras from "./buttons"
import TermoseCondicoes from "../tipoderegras/Termos"
import RegrasPoliticas from "../tipoderegras/Politicas"
import JogoResponsavel from "../tipoderegras/JogoResponsavel"
import AmlKyc from "../tipoderegras/aml"
import RegrasReembolso from "../tipoderegras/reembolso"
import RegrasBonus from "../tipoderegras/bonus"
import Eua from "../../../assets/translate/eua.png"
import Brasil from "../../../assets/translate/brasil.png"
import TermoseCondicoesEn from "../typesrules/TermosEn"
import RegrasPoliticasEn from "../typesrules/PoliticasEn"
import JogoResponsavelEn from "../typesrules/JogoResponsavelEn"
import AmlKycEn from "../typesrules/amlEn"
import RegrasReembolsoEn from "../typesrules/reembolsoEn"
import RegrasBonusEn from "../typesrules/bonusEn"
import RegrasRktCoin from "../tipoderegras/RktCoin"
import RegrasRktCoinEn from "../typesrules/RktCoinEn"

export default function RegrasDesktop() {
    const [option, setOption] = useState("1")


    function buttonactive(item) {
        setOption(item)
    }

    const [locales, setLocales] = useState('en'); // Idioma inicial (português)


    const toggleEn = () => {

        setLocales("en");

    };
    const togglePt = () => {
        setLocales("pt");

    };

    useEffect(() => {
        console.log(locales)
    }, [locales])



    return (
        <>
            <div className="mt-10 w-full flex justify-center">


                <div className="w-11/12 flex gap-1">
                    {/* LADO ESQUERDO | CARD + MENU */}
                    <div className="w-3/12">
                        <div className="overflow-hidden rounded-lg">
                            <img src={Whatsapp} />
                        </div>
                        <div className="mt-3 bg-purple-800 flex-col rounded-lg p-2">
                            <div className="flex justify-between items-center pr-2">
                                <div className=" font-bold text-white pl-2 text-rounded-md mb-2">Regras</div>
                                <div className="flex justify-center gap-2 w-4/12 bg-purple-900 px-2 py-1">
                                    <img onClick={toggleEn} className="cursor-pointer w-[30px] h-[15px]" src={Eua} />
                                    <img onClick={togglePt} className="cursor-pointer w-[30px] h-[15px]" src={Brasil} />

                                </div>
                            </div>
                            <div className="h-[1px] bg-purple-900 mb-2 w-full"></div>
                            <div className="w-full flex-col">
                                <ButtonsRegras id="1" buttonactive={buttonactive} title={locales == "pt" ? "Termos e Condições" : "Terms and Conditions"} />
                                <ButtonsRegras id="2" buttonactive={buttonactive} title={locales == "pt" ? "Politica de Privacidade" : "Privacy Policy "} />
                                <ButtonsRegras id="3" buttonactive={buttonactive} title={locales == "pt" ? "Jogo Responsável" : "Responsible Gambling"} />
                                <ButtonsRegras id="4" buttonactive={buttonactive} title={locales == "pt" ? "Aml - Anti-Lavagem de dinheiro" : "AML - Anti-Money"} />
                                <ButtonsRegras id="5" buttonactive={buttonactive} title={locales == "pt" ? "Políticas de Reembolso" : "REFUND POLICY"} />
                                <ButtonsRegras id="6" buttonactive={buttonactive} title={locales == "pt" ? "Bônus" : "BONUS"} />
                                <ButtonsRegras id="7" buttonactive={buttonactive} title={locales == "pt" ? "Políticas de Rkt Coin" : "RKT COIN POLICY"} />
                            </div>
                        </div>
                    </div>
                    {/* LADO DIREITO | TEXTOS */}
                    <div className="w-9/12 border text-white border-gray-800 rounded-md p-2">
                        {locales === "pt" ?
                            (() => {
                                switch (option) {
                                    case '1':
                                        return <TermoseCondicoes />;
                                    case '2':
                                        return <RegrasPoliticas />;
                                    case '3':
                                        return <JogoResponsavel />;
                                    case '4':
                                        return <AmlKyc />;
                                    case '5':
                                        return <RegrasReembolso />;
                                    case '6':
                                        return <RegrasBonus />;
                                    case '7':
                                        return <RegrasRktCoin />;
                                    default:
                                        return null;
                                }
                            })()
                            :
                            (() => {
                                switch (option) {
                                    case '1':
                                        return <TermoseCondicoesEn />;
                                    case '2':
                                        return <RegrasPoliticasEn />;
                                    case '3':
                                        return <JogoResponsavelEn />;
                                    case '4':
                                        return <AmlKycEn />;
                                    case '5':
                                        return <RegrasReembolsoEn />;
                                    case '6':
                                        return <RegrasBonusEn />;
                                        case '7':
                                            return <RegrasRktCoinEn />;
                                    default:
                                        return null;
                                }
                            })()
                        }


                    </div>
                </div>

            </div>
        </>
    )
}