function InputCadastrar2023({ value, onChange, type, placeHolder, id, onBlur, BorderColor, erro}){
    return (
        <>
        <input id={id} onBlur={onBlur} type={type} value={value}
               onChange={onChange} placeholder={placeHolder} 
               className={`bg-cinzasecondary h-[50px] lg:h-[35px]  text-[0.8em] border  text-verdeprimary duration-700 ${BorderColor} rounded-md  py-2 w-full outline-none px-4`} />

             {BorderColor == "border-red-700" ? (  <label className="uppercase text-[10px] text-red-600 font-semibold ">{erro}</label>) : ""}
        </>
       
    )
}



export default InputCadastrar2023;