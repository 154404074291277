
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";
import RegrasConteudo from "./conteudo";
function Regras() {

  return (
 <TemplateCentralDoJogador title="Regras">
     <RegrasConteudo />
 </TemplateCentralDoJogador>
  );
}

export default Regras;
