import Menos from "../../../assets/buttons/menos.png";
import Mais from "../../../assets/buttons/mais.png";
import buttonSong from "../../../assets/audio/button2.mp3";
import React, { useState, useRef, useEffect } from "react";
import Swal from 'sweetalert2';
import useRender from "../../../hooks/useRender";
import $ from 'jquery'

export default function ControlesCrash() {
  const [quantia, setQuantia] = useState(1);
  const [autoretirarValue, setAutoretirarValue] = useState(2);
  const [comeca, setComeca] = useState("começar");
  const audioRef = useRef();
  const {saldo, _setSaldo} = useRender()

  function openLogin() {
    document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
    document.querySelector(".login__modal").classList.toggle("opacity-0");
    document.querySelector(".login--opacidade").classList.toggle("hidden");
    document.getElementById("login").focus();
  }

  const { signed } = useRender();

  function MoneyExit(){
    document.getElementById("moneyExit").classList.remove("hidden")
    setTimeout(() => {
      document.getElementById("moneyExit").classList.add("hidden")
    }, 2000);
  }


  useEffect(() => {
      document.getElementById("btnIniciar").setAttribute("disabled", "true") // deixa o botão começar desabilitado ao recarregar a página
    if (window.cliente.fila == true) {
      setComeca("começar")
      document.getElementById("Quantia").disabled = false;
      window.cliente.fila = false;
      window.cliente.aposta = 0;
      return;
    }

  }, []);

  useEffect(() => {


    window.cliente.token = localStorage.getItem("user_token");

    function inputAutoretirar() {
      let auto = autoretirarValue;
      setAutoretirarValue(auto.toFixed(2));
    
    }
    inputAutoretirar();
  }, []);



 

  window.cliente.socket.on("PararJogadaAtualizarsaldo", (value) => {
   

    let result = parseFloat(saldo) + parseFloat(value)
    
    _setSaldo(result)

  
  
  
    document.getElementById("modalWin").style.display = "flex";
    $("#btnIniciar").html(`Começar`);
    document.getElementById("enterMoney").style.display = "block";
    document.getElementById("enterMoney").innerHTML = `R$ ${value}`;
    document.getElementById("moneyWinModal").innerHTML = `R$ ${value}`;
  
      window.cliente.botao.status = "parar";
      
      window.cliente.aposta = 0;
     setTimeout(function() { window.cliente.botao.resetBotoes();
      document.getElementById("moneyGanho").style.display = "none";
      document.getElementById("arrowBalao").style.display = "none";
      document.getElementById("modalWin").style.display = "none";
      document.getElementById("enterMoney").style.display = "none";
     }, 5000);
   
   
   });


  function handleeChangeBtn() {

    if(quantia < 1){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Investimento mínimo de 1 R$!',
      })
      return
    }

    
    //se o usuário clicar no botão e a aposta não estiver confirmada ("cancelar")
    //vai cancelar a aposta e voltar o botão para default
    if (window.cliente.fila == true) {
      document.getElementById('btnIniciar').innerHTML = "Começar";
      document.getElementById("btnIniciar").style.opacity = 1;
      window.cliente.fila = false;
      window.cliente.aposta = 0;

          
      return;
  }

 

    let valor = quantia;

    
    if (signed != true && window.cliente.botao.status == "começar"){
      openLogin()
    }

    if (signed != true && window.cliente.botao.status == "naoIniciado"){
      openLogin()
    }

    
    if ((valor <= 0 || valor == "") && window.cliente.botao.status == "naoIniciado") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Digite um valor para iniciar a partida.',
      })

      return;
    }

  



    if (saldo < valor && window.cliente.botao.status == "naoIniciado") {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Saldo insuficiente.',
      })

      if (saldo === 0 && window.cliente.botao.status == "naoIniciado" ){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Saldo insuficiente.',
        })
      }

      // openDeposit()         CRIAR MODAL DE DEPÓSITO
      return;
    }






    //iniciar jogada, entrando na fila

    if (window.cliente.botao.status == "naoIniciado") {
      try {



        //valor default para o auto retirar
        let targetAutoRetirar = 0;
        if(document.getElementById('checkbox-1').checked == true){//se o auto retirar for marcado ele joga o valor pra variavel autoretirarValue
          targetAutoRetirar = autoretirarValue;
          if(autoretirarValue<1.10){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Digite um valor acima de 1.10 no auto retirar.',
            })
      
            return;
          }
        }

        let aposta = { 'status': window.cliente.botao.status, 'valor': valor, "autoretirar": targetAutoRetirar, 'token': window.cliente.token }
      
        
        window.cliente.socket.emit('EntrarNaFila', aposta);
        window.cliente.aposta = valor;
        window.cliente.fila = true;
      
        //document.getElementById("apostaForm").disabled = true;
        //document.getElementById("autoRetirarForm").disabled = true;
        if (signed == true){
          MoneyExit()
          document.getElementById("btnIniciar").innerHTML = "Cancelar";
          document.getElementById("btnIniciar").style.opacity = 0.4;
        }
        

      } catch (e) {
        console.log(e);
      }
    }



    //parar jogada retirar saldo
    if (window.cliente.botao.status == "iniciado") {

      if(window.cliente.multiplicadorObj.valor < 1.10) return;
      
      window.cliente.socket.emit('PararJogada', window.cliente.token);
      
      document.getElementById("btnIniciar").style.opacity = 0.4
    }





  }

  function handleInputBlur() {
    let atualiza = autoretirarValue;
    setAutoretirarValue(parseFloat(atualiza).toFixed(2));
    
  }

  function dobrarQuantia() {
    setTimeout(async () => {
      let audio = audioRef.current;
      audio.currentTime = 0;
      audio.play();
    }, 1);

    function decimalInt() {
      var resto = dobrar - parseInt(dobrar);
      if (resto == 0) {
        setQuantia(dobrar.toFixed(0));
      } else {
        setQuantia(dobrar.toFixed(2));
      }
    }
    if (quantia <= 2) {
      if (quantia >= 1.5) {
        var dobrar = 0.5 + parseFloat(quantia);

        decimalInt();
      } else {
        var dobrar = 0.25 + parseFloat(quantia);

        decimalInt();
      }
    } else {
      var dobrar = 2 * quantia;

      decimalInt();
    }
  }

  function dividirQuantia() {
    setTimeout(async () => {
      let audio = audioRef.current;
      audio.currentTime = 0;
      audio.play();
    }, 1);

    var dividir;
    function decimalInt() {
      var resto = dividir - parseInt(dividir);
      if (resto == 0) {
        setQuantia(dividir.toFixed(0));
      } else {
        setQuantia(dividir.toFixed(2));
      }
    }
    if (quantia <= 2.5) {
      if (quantia == 1.5) {
        dividir = parseFloat(quantia) - 0.25;
        decimalInt();
      } else if (quantia <= 1) {
        setQuantia(1);
      } else {
        if (quantia <= 1.5) {
          setQuantia(1);
        } else {
          dividir = parseFloat(quantia) - 0.5;

          decimalInt();
       
        }
      }
    } else {
      dividir = quantia / 2;
      decimalInt();
    }
  }

  return (
    <>
      <audio ref={audioRef} src={buttonSong}></audio>
      <div className="w-12/12 flex flex-wrap justify-center ">
        

        <div className="w-11/12 mt-2 flex rounded-xl flex-wrap bg-cinzaprimary">
          {/* AUTO RETIRAR */}
          <div className="w-full flex justify-between bg-gradient-to-r from-[#281347] to-[#161220] rounded-tl-lg rounded-tr-lg h-[40px]">
            <div className="w-6/12 mt-2 uppercase Oswald ml-4">
              <div className="custom-checkbox">
                <input id="checkbox-1" type="checkbox" />
                <label htmlFor="checkbox-1">AUTO RETIRAR</label>
              </div>
            </div>
            <div className="w-6/12 mt-2 ">
              <div className="w-12/12 flex justify-end">
                <div className="w-8/12 mr-5 flex relative  Oswald text-right pr-2 rounded-xl bg-cinzaprimary border border-roxoprimary">
                  <input
                    onBlur={handleInputBlur}
                    id="autoRetirar"
                    value={autoretirarValue}
                    onChange={(e) => setAutoretirarValue(e.target.value)}
                    className="w-11/12 pr-1 Oswald text-right rounded-xl bg-cinzaprimary appearance-none outline-none"
                  />
                  <div className="text-roxoprimary">X</div>
                </div>
              </div>
            </div>
          </div>
          {/* BUTTONS */}
          <div className="w-5/12 mt-3 pb-3 flex relative justify-center">
            <button onClick={handleeChangeBtn}  id="btnIniciar" action="naoIniciado" className="disabled:opacity-[0.4] bg-verdesixtyn active:top-[-2px] font-xs font-bold top-[-6px] h-[70px] rounded-lg uppercase absolute text-black flex items-center cursor-pointer justify-center w-11/12">
              {comeca}
            </button>
            <div className="bg-cinzafourth text-black rounded-lg  h-[70px] flex justify-center w-11/12"></div>
                   {/* valor apostado na rodada   */}
            <div id="moneyExit" className="absolute text-[1em] rounded-[10px] px-[10px] font-bold bg-roxoprimary duration-1000 hidden  leave_money_roullet">
               <p>R$ -{quantia}</p>
            </div>

                          {/* Valor a ser ganho */}
            <div id="arrowBalao" className="absolute top-[65px] z-10  " style={{display: "none"}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#06ff7b" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
            </div>
             <div className="absolute top-[75px] bg-verdesixtyn text-black text-[1em] rounded-[10px] px-[10px] font-bold ">
                <p id="moneyGanho" className="whitespace-nowrap"></p>
             </div>

          </div>

          <div className="w-7/12  flex  relative items-center  justify-center">
            <div className="w-11/12 bg-cinzaprimary z-[2] rounded-lg top-[-6px] relative py-[10px] border-2 border-roxoprimary h-[70px]">
              <div
                onClick={dobrarQuantia}
                className="absolute  right-0 px-1 cursor-pointer z-[2] text-black rounded-tr-md rounded-br-md active:top-[2px]  active:h-[96.8%] top-0 h-full  text-xl flex justify-center items-center font-bold bg-gradient-to-r from-cinzathird to-cinzafourth"
              >
                <div className="flex items-center relative">
                  <img className="w-4 py-3" src={Mais} alt="" />
                </div>
              </div>

              <div className="absolute  right-0 h-full w-[24px] rounded-sm top-0 bg-roxofourth rounded-tr-md rounded-br-md"></div>

              <div
                onClick={dividirQuantia}
                className="absolute left-0  px-1 cursor-pointer z-[2] h-full text-black rounded-tl-md rounded-bl-md active:top-[2px] active:bg-verdeprimary active:h-[96.8%] active:top-[18px] drop-shadow-xl top-0 text-xl flex justify-center items-center font-bold bg-gradient-to-r from-cinzafourth to-cinzathird"
              >
                <div className="flex items-center relative ">
                  <img className="w-4  py-3" src={Menos} alt="" />
                </div>
              </div>

              <div className="absolute  left-0 h-full w-[24px] rounded-tl-md rounded-bl-md top-0 bg-roxofourth"></div>

              <div className="text-black flex h-full justify-center text-3xl items-center">
                <div className="text-xs text-white absolute top-0">
                  investimento
                </div>
                <div className="text-verdesixtyn Oswald absolute text-[0.3em] left-[28px] top-[16px]">
                  BRL
                </div>
                <input
                  maxLength="4"
                  id="Quantia"
                  type="number"
                  disabled={false}
                  value={quantia}
                  onChange={(e) => setQuantia(e.target.value)}
                  className="outline-none ml-3 disabled:opacity-20  appearance-none text-left appearance-textfield text-[0.8em] overflow-hidden scroll-hidden text-verdesixtyn placeholder:text-verdesixtyn bg-cinzaprimary text-center w-8/12 Oswald"
                />
              </div>
            </div>
            <div className="w-11/12 rounded-lg top-[12px] absolute py-[10px] border-2 border-roxothird bg-roxothird h-[70px]"></div>
          </div>
        </div>
      </div>
    </>
  );
}
