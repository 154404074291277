import ContainerBlur from "../modals/blur/container";
import ConteudoDepositoSwetAlert from "./Conteudo";




export default function DepositoSwetAlert(){
    return(

        <>
       

       <ContainerBlur openmodal="DepositoConfirmado">    {/* CONTAINER COM O BLUR ATIVADO*/}
       <ConteudoDepositoSwetAlert openmodal="DepositoConfirmado"/>   {/* CONTEUDO DO MODAL */}   
       </ContainerBlur>
        
        
        
        </>
    )
}