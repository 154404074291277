import ContainerBlur from "./blur/container";
import ConteudoHistoricoRodadas from "./historicoRodadas/conteudo";


function HistoricoRodadas () {


    return ( 
        <>
        
        <ContainerBlur openmodal="historicoRodadasModal">    {/* CONTAINER COM O BLUR ATIVADO*/}
        <div className="w-full">
        <ConteudoHistoricoRodadas openmodal="historicoRodadasModal"/>   {/* CONTEUDO DO MODAL */}  
        </div>
 
    </ContainerBlur>
        </>
     );
}

export default HistoricoRodadas ;