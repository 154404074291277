import logopreta from "../../assets/logo/rkt_preto.svg"

export default function ImagensUpload(){
    return(
        <>
        <div className="flex justify-center w-full">
            <div className="w-11/12 lg:w-8/12">
            <img src={logopreta} />
            </div>
        </div>
     
        </>
    )
}