import { Link } from 'react-router-dom'
import useRender from '../../hooks/useRender';
import ButtonSubMenu from './ButtonSubMenu';

export default function ButtonMenuHeader(props) {

  function methodPayment() {
    var method = document.getElementById("payment")
    method.classList.toggle("hidden");
    document.getElementById("modalPagamento").classList.remove("hidden");

  }

  function openLogin() {
    document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
    document.querySelector(".login__modal").classList.toggle("opacity-0");
    document.querySelector(".login--opacidade").classList.toggle("hidden");
    document.getElementById("login").focus();
  }

  const { signed } = useRender()

  return (
    <>
      <div className="relative group  top-[15px] cursor-pointer">

        <div className="absolute w-[0px] h-[100%] duration-500 z-[3] text-white group-hover:w-[100%] group-hover:bg-verdeprimary  rounded-md  text-sm   font-bold  skew-x-[-8deg]  p-2"></div>
        <div className="absolute   text-white rounded-md  text-sm   font-bold z-[4] skew-x-[-8deg]  p-2 whitespace-nowrap">{props.name}</div>
        <div className="relative  duration  bg-cinzathird text-cinzathird rounded-md  text-sm   font-bold z-[2] skew-x-[-8deg]  p-2 whitespace-nowrap">{props.name}</div>
        <div className="absolute bg-verdeprimary rounded-md z-[1] text-sm left-[-2px] bottom-[-2px] skew-x-[-8deg]  p-2 whitespace-nowrap">{props.name}</div>

        <div className={props.submenu == 1 ? `hidden  group-hover:block relative` : `hidden`}>
          <div className="bg-cinzathird absolute w-[170px] top-[2px] z-[4] text-base px-3 py-2 rounded-md">
        
              <ul className="font-bold text-white flex flex-col gap-1  ">
                <ButtonSubMenu new={true} render={true} tittle="Runner Jelly" link="/runnerjelly" />
                <ButtonSubMenu  render={true} tittle="mines" link="/mines" />
                <ButtonSubMenu render={true} tittle="cyber space" link="/cyberspace" />
                <ButtonSubMenu render={true} tittle="roullet planet" link="/roulletplanet" />
                <ButtonSubMenu render={true} tittle="crash free rkt" link="/gamefree" />
             
              </ul>       

          </div>
        </div>




        <div className={props.submenu == 2 ? `hidden  group-hover:block relative` : `hidden`}>
          <div className="bg-cinzathird absolute w-[140px] top-[2px] z-[4] text-base px-3 py-2 rounded-md">
            {signed == true ? (
              <ul className="font-bold text-white flex flex-col gap-1  ">
                <ButtonSubMenu tittle="meus bônus" link="/bonus" />
                <ButtonSubMenu tittle="sacar" link="/sacar" />
                <li onClick={methodPayment} className="hover:bg-verdeprimary bg-cinzasecondary rounded-md py-0.5 px-1 hover:text-cinzathird  text-[0.6em] Orbitron">DEPOSITAR</li>
              </ul>
            ) : (
              <ul className="font-bold text-white flex flex-col gap-1  ">

                <li onClick={openLogin} className="hover:bg-verdeprimary bg-cinzasecondary rounded-md py-0.5 px-1  hover:text-cinzathird text-[0.6em] Orbitron">MEUS BÔNUS</li>

                <li onClick={openLogin} className="hover:bg-verdeprimary bg-cinzasecondary rounded-md py-0.5 px-1 hover:text-cinzathird  text-[0.6em] Orbitron">SACAR</li>

                <li onClick={openLogin} className="hover:bg-verdeprimary bg-cinzasecondary rounded-md py-0.5 px-1 hover:text-cinzathird  text-[0.6em] Orbitron">DEPOSITAR</li>
              </ul>
            )}

          </div>
        </div>

        <div className={props.submenu == 3 ? `hidden  group-hover:block relative` : `hidden`}>
          <div className="bg-cinzathird absolute w-[140px] top-[2px] z-[4] text-base px-3 py-2 rounded-md">
       
              <ul className="font-bold text-white flex flex-col gap-1  ">
                <ButtonSubMenu tittle="regras" link="/regras" />
                <ButtonSubMenu tittle="suporte" link="/suporte" />              
              </ul>
           
      

          </div>
        </div>


      </div>

    </>
  )
}