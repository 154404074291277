import Ball from "./../../../assets/personagem/luzes.gif"
import Astronauta from '../../../assets/personagem/astronauta.png'

export default function WinCrashFree() {


    return (
        <>

            <div id="modalWin--Gamefree" className="bg-[#1c0c31e0] text-white flex justify-center items-center fixed margin-0 top-0 bottom-0 left-0 right-0 w-full h-screen z-[20]" style={{display:'none'}}>
                <div className="w-full absolute flex justify-center z-[3]">
                    <div className="flex justify-center items-center flex-col w-11/12 lg:w-8/12">
                        
                        <div id="youwincrash--Gamefree" className="bg-verdesecondary relative h-[70px] w-10/12 flex justify-center p-4 font-black text-3xl uppercase md:text-[6em] lg:text-[3em]">
                            <div className="mv1 absolute z-[3]">Você ganhou!!</div>
                            <div className="mv2 text-roxoprimary absolute z-[2]">Você ganhou!!</div>
                            <div className="mv3 text-verdeprimary absolute z-[1]">Você ganhou!!</div>
                        </div>

                        <p  className="relative bg-roxoprimary rounded-md border-verdesecondary border-[14px] py-4 px-10 lg:py-6  lg:px-20 top-[30px] lg:text-[5em] md:text-[7em] text-4xl font-bold">
                            <div className="absolute w-[15px] h-[15px] top-[-14px] left-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] top-[-14px] right-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] bottom-[-14px] left-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] bottom-[-14px] right-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] top-[50%] translate-y-[-50%] right-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] top-[50%] translate-y-[-50%] left-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] left-[20%] lg:left-[14%] translate-x-[-50%] top-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] left-[50%] translate-x-[-50%] top-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] left-[80%] lg:left-[84%] translate-x-[-50%] top-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="hidden lg:block absolute w-[15px] h-[15px] lg:left-[32%] left-[30%] translate-x-[-50%] top-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="hidden lg:block absolute w-[15px] h-[15px] left-[66%] translate-x-[-50%] top-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] left-[20%] lg:left-[14%] translate-x-[-50%] bottom-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] left-[50%] translate-x-[-50%] bottom-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="hidden lg:block absolute w-[15px] h-[15px] lg:left-[32%] left-[30%] translate-x-[-50%] bottom-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="hidden lg:block absolute w-[15px] h-[15px] left-[66%] translate-x-[-50%] bottom-[-14px]">
                                <img src={Ball} />
                            </div>
                            <div className="absolute w-[15px] h-[15px] left-[80%] lg:left-[84%] translate-x-[-50%] bottom-[-14px]">
                                <img src={Ball} />
                            </div>

                                
                       
                            <div id="moneyWinModal-free"></div>
                        </p>
                    </div>
                </div>
                <div className="flex w-full absolute h-[100%]">
                    <div className="w-6/12  flex justify-start">
                        <div id="bg-left" className="w-full"></div>
                    </div>
                    <div className="w-6/12 flex justify-end">
                        <div id="bg-right" className="w-full"></div>
                    </div>
                </div>
                <div className="w-full h-[100%] z-[2] top-[20px] absolute flex justify-center items-start lg:items-center lg:justify-end">
                    <img id="astronauta--Gamefree" className="w-10/12 md:w-6/12" src={Astronauta} />
                </div>
            

                <div className='pow3 w-full z-[1]'>
  <div className='squares3'>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
    <div className='square3'></div>
  </div>
</div>
<div className='grey'></div>
               
            </div>

        </>
    )
}