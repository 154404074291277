import React from 'react';

import useRender from "../hooks/useRender";
import Principal from './Principal/home';

const Private = ({ children }) => {
    const { signed, loading } = useRender();
  
    if (loading) {
      return <div>Carregando...</div>;
    }
  
    return signed ? children : <Principal />;
}

export default Private;


