export default function RktCoin() {
    return (
        <>


            <div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Política RKT COIN</div>
                </div>
                <div className="text-white mt-5"> Última atualização: 1º de Julho de 2023</div>
                <br /><br />

                <div className="text-white mt-2 font-semibold">Regras de Uso para RKT COIN no Jogo "rockeetz"
                </div>
                <br />
                <div className="text-white mt-2 font-semibold">1 - Aquisição de RKT COIN:</div>


                1.1 Os jogadores podem adquirir RKT COIN através do streak login, completando desafios, conquistando metas e outras atividades dentro do jogo. As RKT COINs não têm valor monetário real e são fornecidas gratuitamente.
                <br />
                <div className="text-white mt-2 font-semibold">2- Utilização de RKT COIN:</div>
                2.1 As RKT COINs podem ser usadas para jogar o Game Free dentro da Rockeetz


                <div className="text-white mt-2 font-semibold">3 - Restrições de Uso:</div>

                3.1 Podem ser aplicadas restrições ao uso de RKT COINs, incluindo limites diários de gastos ou requisitos de nível de jogador. Consulte o suporte para obter detalhes específicos.
                <div className="text-white mt-2 font-semibold">4 - Transferência entre Jogadores::</div>
                4.1 A transferência de RKT COINs entre jogadores não é permitida.
                <div className="text-white mt-2 font-semibold">5 - Ressarcimento e Reset:</div>
                5.1  Reservamos o direito de modificar, suspender ou cancelar a RKT COIN a qualquer momento, sem aviso prévio. Como a RKT COIN é uma moeda virtual gratuita dentro do jogo, os jogadores não têm direito a compensação ou reembolso em caso de modificações, suspensões ou cancelamentos.
                <div className="text-white mt-2 font-semibold">6 - Conformidade com as Leis:</div>
                6.1  O uso de RKT COIN deve estar em conformidade com todas as leis e regulamentações aplicáveis.
                <div className="text-white mt-2 font-semibold">7 - Política de Privacidade e Segurança:</div>
                7.1 Todas as transações com RKT COIN são protegidas e mantidas em conformidade com nossa Política de Privacidade. Consulte nossa Política de Privacidade para obter mais detalhes.


                <div className="text-white mt-2 font-semibold">8 - Conversões:</div>
                8.1 A conversão de RKT COIN para Real Brasileiro (BRL) é permitida sob as seguintes condições:<br />
                8.1.1 O jogador deve ter um saldo mínimo de 1000 RKT COINs para ser elegível para conversão.
                <br />8.1.2 O valor da conversão é fixado em 1000 RKT COINs para 1 BRL.
                <br />8.1.3 O jogador deve ter depositado um valor mínimo acumulado de 80 BRL dentro do rockeetz para ser elegível para a conversão.
                <br />8.1.4 Todas as conversões estão sujeitas a aprovação e revisão por nossa equipe, e reservamos o direito de recusar a conversão por qualquer motivo a nosso critério.
                <br />8.1.5 A conversão pode estar sujeita a taxas adicionais e/ou demoras no processamento, conforme descrito dentro do rockeetz.

                <div className="text-white mt-2 font-semibold">9 - Alterações nas Regras:</div>
                Reservamos o direito de alterar essas regras a qualquer momento, com ou sem aviso prévio. É responsabilidade dos jogadores revisar essas regras regularmente para estar ciente de quaisquer alterações.

            </div>





        </>
    )
}