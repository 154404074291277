import Check from "../../../../assets/icons/checkLogin.svg";
import Tudo2 from "../../../../assets/icons/tudo2.svg";
import Polygon from "../../../../assets/icons/polygon.svg";
import PolygonOff from "../../../../assets/icons/polygonoff.svg";

export default function ChecksAnimation() {
    return (
        <>
            <div id="$Checks" className="flex flex-col items-center w-full">
            <div className="flex w-10/12 animate-bounce lg:w-6/12 justify-center items-center gap-4">
                <img id="tudo2Svg" className="h-7 w-5  " src={Tudo2} alt="" />
                <p id="titleSteakBonusFinal" className="text-verdesixtyn  font-['Orbitron']  text-4xl" >
                   SEMANA COMPLETA!
                </p>
            </div>



            <div id="steak2BonusFinal" className="w-10/12 scale-0 duration-500 lg:w-6/12 flex justify-around items-center lg:space-x-[-120px] mt-3 pb-8 " >

                <div className="cursor-pointer relative flex justify-center items-center w-full ">
                    <img className="h-[35px] w-[35px]   absolute "
                        src={Polygon}
                        alt="" />

                    <img className="h-[35px] w-[35px]   absolute streak  "
                        src={Check}
                        alt="" />
                    <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                    <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">SEG</p>
                </div>

                <div className="cursor-pointer relative flex justify-center items-center w-full ">
                    <img className="h-[35px] w-[35px]   absolute "
                        src={Polygon}
                        alt="" />

                    <img id="checkTER" className="h-[35px] w-[35px]   absolute hidden   "
                        src={Check}
                        alt="" />
                    <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                    <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">TER</p>
                </div>

                <div className="cursor-pointer relative flex justify-center items-center w-full ">
                    <img className="h-[35px] w-[35px]   absolute "
                        src={Polygon}
                        alt="" />

                    <img id="checkQUA" className="h-[35px] w-[35px]   absolute hidden  "
                        src={Check}
                        alt="" />
                    <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                    <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">QUA</p>
                </div>

                <div className="cursor-pointer relative flex justify-center items-center w-full ">
                    <img className="h-[35px] w-[35px]   absolute "
                        src={Polygon}
                        alt="" />

                    <img id="checkQUI" className="h-[35px] w-[35px]   absolute  hidden  "
                        src={Check}
                        alt="" />
                    <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                    <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">QUI</p>
                </div>

                <div className="cursor-pointer relative flex justify-center items-center w-full ">
                    <img className="h-[35px] w-[35px]   absolute "
                        src={Polygon}
                        alt="" />

                    <img id="checkSEX" className="h-[35px] w-[35px]   absolute hidden  "
                        src={Check}
                        alt="" />
                    <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                    <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">SEX</p>
                </div>

                <div className="cursor-pointer relative flex justify-center items-center w-full ">
                    <img className="h-[35px] w-[35px]   absolute "
                        src={Polygon}
                        alt="" />

                    <img id="checkSAB" className="h-[35px] w-[35px]   absolute  hidden "
                        src={Check}
                        alt="" />
                    <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                    <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">SAB</p>
                </div>

                <div className="cursor-pointer relative flex justify-center items-center w-full ">
                    <img className="h-[35px] w-[35px]   absolute "
                        src={Polygon}
                        alt="" />

                    <img id="checkDOM" className="h-[35px] w-[35px]   absolute  hidden "
                        src={Check}
                        alt="" />
                    <img className="h-[35px] w-[35px] absolute " src={PolygonOff} alt="" />
                    <p className="lg:mt-16 mt-[73px]  uppercase text-white lg:text-[14px] text-sm">DOM</p>
                </div>

            </div>
            </div>
        </>
    )
}