import Template from "../../layouts/template/template";
import SuporteConteudo from "../CentralDoJogador/Suporte/conteudo";


export default function SuporteHome(){
    return(
    <Template>
        <div className="w-full flex justify-center">
        <div className="w-11/12">
        <SuporteConteudo/>
        </div>
        </div>
    </Template>
    )
}
