import Menu from "./Menu";
import Login from "./Login";
import Modal from "./Login/Modal.js";
import SetaDown from "./../../assets/icons/js/setadown";
import useRender from "./../../hooks/useRender";
import Saldo from "./Saldo/saldo";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from "../../assets/logo/rkt_branco.svg"
import Icon from "../../assets/logo/icon.svg"
import IcoGamefree from "../../assets/header/icogamefree.gif"
import RKTZ from '../../assets/icons/RKT_COIN.svg'
import ButtonMenuHeader from "./ButtonMenuHeader";



function Header() {
  const { signed, setSigned, prefixoUrl, saldo, saldorkzc } = useRender();
  const [location] = useState(window.location.href)

  function methodPayment() {
    var method = document.getElementById("payment")
    method.classList.toggle("hidden");   
   

}



  function openLogin() {
    document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
    document.querySelector(".login__modal").classList.toggle("opacity-0");
    document.querySelector(".login--opacidade").classList.toggle("hidden");
    document.getElementById("login").focus();
  }

  
  function openSaldo() {
    var cardSaldo = document.getElementById("card-saldo");

    var fundoSaldo = document.getElementById("opacidade--saldo")
    cardSaldo.classList.toggle("hidden");

    fundoSaldo.classList.toggle("hidden");
    document.getElementById("modalPagamento").classList.remove("hidden");

  }

  function Sair() {
    setSigned(false);
    // localStorage.clear()
    localStorage.removeItem("user");
    localStorage.removeItem("user_token");
    localStorage.removeItem("indicacao");
    localStorage.removeItem("numeroTotalIndicados");
    localStorage.removeItem("indicadosQueDepositaram");
    localStorage.removeItem("saldoTotalParaResgate");
    localStorage.removeItem("historicoDeJogo");
    localStorage.removeItem("historicoDeSaque");
    localStorage.removeItem("historicoDeDeposito");
    localStorage.removeItem("Bonus");
    localStorage.removeItem("saldoSaque");
    localStorage.removeItem("streak_dados");
    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${prefixoUrl}/logout`, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    localStorage.removeItem("user");
    window.location.href = '/home';

  }

  function openCupom() {
    let method = document.getElementById("modalCupom")
    method.classList.toggle("hidden");
    let opacity = document.getElementById("cupomOpacity")
    opacity.classList.add("opacity-0");

    setTimeout(() => {
      opacity.classList.toggle("opacity-0");

    }, 100);



  }



  return (
    <header className={location.indexOf("roulletplanet") > -1 ? `bg-[#0f2245] w-100 top-0 left-0 right-0 h-14 flex items-center   border-b-[2px] border-b-verdeprimary  border-verdeprimary` : location.indexOf("cyberspace") > -1 ? `bg-[#2f0c3e] w-100 top-0 left-0 right-0 h-14 flex items-center  border-b-[2px] border-b-verdeprimary border-verdeprimary` : location.indexOf("gamefree") > -1 ? `bg-[#401468] w-100 top-0 left-0 right-0 h-14 flex items-center  border-b-[2px] border-b-verdeprimary  border-verdeprimary` : ` w-100 top-0 left-0 right-0 h-14 flex items-center  border-b-[2px] border-b-verdeprimary  border-verdeprimary`} >



      <div className="lg:hidden block w-1/1">
        <Menu />
      </div>
      <Link className="w-2/12  flex justify-center items-center lg:w-3/12" to="/">
        <img className="hidden lg:block w-48 lg:w-48" src={Logo} />
        <img className="lg:hidden block w-12 lg:w-12" src={Icon} />
      
      </Link>
      <div className="w-3/5 lg:flex hidden text-white gap-x-3">
        {signed == true ? (
          <>

            <ButtonMenuHeader name="Jogos" submenu="1" />

            <Link to="/centraldojogador">
              <ButtonMenuHeader name="Central do Jogador" />
            </Link>

            <ButtonMenuHeader name="Carteira" submenu="2" />

            <div onClick={openCupom}>
              <ButtonMenuHeader name="Cupom" />
            </div>


            <Link to="/centraldojogador/indicacao">
              <ButtonMenuHeader name="INDIQUE & GANHE" />
            </Link>

            <ButtonMenuHeader name="Ajuda" submenu="3" />

          </>
        ) : (
          <>
            <div onClick={openLogin}>
              <ButtonMenuHeader name="Central do Jogador" />
            </div>

            <ButtonMenuHeader name="Jogos" submenu={true} />

            <div onClick={openLogin}>
              <ButtonMenuHeader name="Cupom" />
            </div>


            <div onClick={openLogin}>
              <ButtonMenuHeader name="INDIQUE & GANHE" />
            </div>

            <Link to="/regras">
              <ButtonMenuHeader name="Regras do Jogo" />
            </Link>

            <Link to="/suporte">
              <ButtonMenuHeader name="Suporte" />
            </Link>

          </>
        )}



      </div>


      {signed == false ? (
        <>
          <Modal />
          <div className="mr-5 flex justify-end  z-[1] w-2/3">

            <Login />
          </div>
        </>
      ) : (
        <div className="w-full  md:w-3/5 flex justify-end">

{/* 
          <a href="/gamefree" className={location.indexOf("gamefree") > -1 ? `cursor-pointer hidden w-2/5` : `cursor-pointer hidden lg:flex w-2/5 hover:scale-110 duration-300`} >
            <img className="w-8/12 pl-5" src={IcoGamefree} />
          </a>

          */}

          <div className="flex justify-end items-center w-11/12 md:w-2/5 mr-2  ">

            <div className="w-10/12 flex items-end flex-col z-[6]">
              <div className={location.indexOf("cyberspace") > -1 ? `w-10/12 bg-[#1f0a33] h-[32px]  shadow--purple min-w-[120px] relative rounded-md border border-[#3e3e3e] ` : location.indexOf("roulletplanet") > -1 ? `w-10/12 bg-[#0a1a33] h-[32px]  shadow--purple min-w-[120px] relative rounded-md border border-[#3e3e3e] ` : `w-10/12 bg-[#1f0a33] h-[32px]  shadow--purple min-w-[120px] relative rounded-md border border-[#3e3e3e] `}>
                <div className="flex">
                  <div className={location.indexOf("cyberspace") > -1 ? `w-10/12 rounded-md border relative h-[30px]  bg-cinzasecondary border-[#830ebf]` : location.indexOf("roulletplanet") > -1 ? `w-10/12 rounded-md border relative h-[30px]  bg-cinzasecondary border-[#0e90bf]` : `w-10/12 rounded-md border relative h-[30px]  bg-cinzasecondary border-[#830ebf]`}>
                    <div className={location.indexOf("cyberspace") > -1 ? `text-[0.5em] absolute text-roxoprimary top-[-2px] right-1` : location.indexOf("roulletplanet") > -1 ? `text-[0.5em] absolute text-azulprimary top-[-2px] right-1` : `text-[0.5em] absolute text-roxoprimary top-[-2px] right-1`}>saldo total</div>
                    <div id="varSaldo" className="Orbitron text-[0.9em] text-white w-full justify-end flex absolute bottom-0 px-1 right-1">{location.indexOf("gamefree") > -1 ? (<div className="px-2 flex w-full justify-between  gap-1"><img src={RKTZ} className="w-5 hover:scale-125 duration-500" alt="" />{saldorkzc} </div>) : saldo}</div>
                    <p id="enterMoney" className=" enter__money" style={{ display: "none" }}>..</p>
                  </div>

                  <div onClick={openSaldo} className={location.indexOf("roulletplanet") > -1 ? `w-2/12 z-[4] flex items-center hover:bg-[#0b0b0b76] hover:rounded-br-md hover:rounded-tr-md justify-center cursor-pointer fill-white hover:fill-azulprimary` : location.indexOf("cyberspace") > -1 ? `w-2/12 z-[4] flex items-center hover:bg-[#0b0b0b76] hover:rounded-br-md hover:rounded-tr-md justify-center cursor-pointer fill-white hover:fill-roxoprimary` : `w-2/12 z-[4] flex items-center hover:bg-[#0b0b0b76] hover:rounded-br-md hover:rounded-tr-md justify-center cursor-pointer fill-white hover:fill-roxoprimary`}>
                    <SetaDown />
                  </div>

                </div>



              </div>
              <Saldo />
            </div>


          </div>

          <div onClick={methodPayment} className="cursor-pointer hover:bg-white duration-150 px-2 transition bg-verdeprimary  mr-3 flex justify-center items-center text-[0.8em] font-bold rounded-md">Depositar</div>
             
          {signed == true ? (
            <div onClick={Sair} className="cursor-pointer group text-white lg:flex items-center hidden  w-1/5">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" className="fill-white group-hover:fill-roxoprimary bi bi-door-open" viewBox="0 0 16 16" >
                <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
                <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z" />
              </svg>

              <div className="font-bold group-hover:text-roxoprimary">Sair</div>
            </div>

          ) : ""}

        </div>
        
      )}








      {/* FUNDO BLUR */}
      <div id="opacidade--saldo" onClick={openSaldo} className="hidden backdrop-blur-sm fixed top-0 bottom-0 left-[0px] right-0 bg-[#00000021] z-[5]  duration-[2000ms] "></div>

    </header >
  );
}

export default Header;
