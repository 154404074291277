

import {useRef, useState } from 'react';

function ConteudoTermosDeUso(props) {


  const [disabled, setDisabled] = useState(true)
  const [scrollPos, setScrollPos] = useState(0);
  const scrollContainerRef = useRef(null);

  const handleScrollTermos = () => {
    const currentScrollPos = scrollContainerRef.current.scrollTop;
    const scrollHeight = scrollContainerRef.current.scrollHeight;
    const containerHeight = scrollContainerRef.current.clientHeight;

    if (currentScrollPos + containerHeight >= scrollHeight) {
      setDisabled(false);
    }
    setScrollPos(currentScrollPos);
  };





  function closeCupom() {
    document.getElementById(props.openmodal).classList.toggle("hidden");

  }



  return (
    <>

      <div id="TermosOpacity" className="bg-[#2B2B2B] top-0 lg:w-6/12 lg:h-5/6 h-[550px] w-11/12 rounded-2xl z-[6]  p-2 opacity-0 duration-700 relative pb-5 " >


        <div className=" border-b border-[#38E034] w-10/12 mx-auto  lg:p-6 py-5">
          <h1 className="font-['Orbitron'] text-[#38E034] lg:text-3xl text-lg whitespace-nowrap">Termos & Condições de Uso</h1>
        </div>

        <div ref={scrollContainerRef} onScroll={handleScrollTermos} id="TermosScroll" className="w-full mx-auto bg-[#1B1717] mt-10 h-[330px] overflow-auto ">
          <div className="text-white px-5 py-6 lg:text-[11px] text-[10px] space-y-4 ">


            <p>Bem-vindo à <strong>Rockeetz Games</strong>, uma casa de apostas online que oferece uma variedade de jogos de azar. Ao utilizar os nossos serviços, você concorda com os termos e condições de uso descritos abaixo. Por favor, leia-os com atenção antes de começar a jogar.</p>



            <ul className='space-y-1'>
              <strong>Uso dos serviços</strong>
              <li>
                1.1. Para utilizar os serviços da Rockeetz Games, você deve ter pelo menos 18 anos de idade e ter capacidade legal para realizar apostas em seu país de residência.
              </li>

              <li>
                1.2. Ao utilizar os nossos serviços, você concorda em cumprir todas as leis e regulamentações aplicáveis às apostas em seu país.
              </li>

              <li>
                1.3. Você é responsável por garantir que todas as informações fornecidas ao se cadastrar em nosso site sejam verdadeiras e precisas. A Rockeetz Games não se responsabiliza por quaisquer problemas decorrentes de informações incorretas ou desatualizadas fornecidas por você.
              </li>


              <li>
                1.4. A Rockeetz Games se reserva o direito de recusar ou limitar o acesso aos seus serviços a qualquer momento, por qualquer motivo, sem aviso prévio.
              </li>

            </ul>



            <ul className='space-y-1'>
              <strong>Responsabilidade do usuário</strong>
              <li>
                2.1. Ao jogar na Rockeetz Games, você concorda em jogar com responsabilidade e reconhece que o jogo pode ser viciante. Recomendamos que você estabeleça limites de gastos e jogue apenas com dinheiro que você pode se dar ao luxo de perder.
              </li>

              <li>
                2.2. A Rockeetz Games não se responsabiliza por quaisquer perdas financeiras decorrentes do uso dos nossos serviços.
              </li>

              <li>
                2.3. Você é responsável por proteger sua conta de apostas e garantir que suas informações de login permaneçam confidenciais. A Rockeetz Games não se responsabiliza por quaisquer perdas decorrentes do uso não autorizado de sua conta.
              </li>

              <li>
                2.4. O saque dos seus ganhos só poderá ser realizado na mesma conta bancária com o mesmo CPF utilizado para o cadastro, com o objetivo de proteger a integridade dos nossos serviços e evitar lavagem de dinheiro.
              </li>

            </ul>






            <ul className='space-y-1'>
              <strong>Propriedade intelectual</strong>
              <li>
                3.1. Todo o conteúdo do site da Rockeetz Games, incluindo software, design, gráficos e texto, é propriedade da empresa e está protegido por direitos autorais e outras leis de propriedade intelectual.


              </li>

              <li>
                3.2. Você não está autorizado a copiar, modificar, distribuir ou criar trabalhos derivados de qualquer conteúdo do site da Rockeetz Games sem a autorização prévia por escrito da empresa.

              </li>

            </ul>




            <ul className='space-y-1'>
              <strong> Alterações nos termos e condições</strong>
              <li>
                4.1. A Rockeetz Games se reserva o direito de alterar estes termos e condições a qualquer momento, sem aviso prévio. As alterações entrarão em vigor imediatamente após a publicação em nosso site.

              </li>

              <li>

                4.2. Ao continuar a utilizar os nossos serviços após a publicação de quaisquer alterações nos termos e condições, você concorda em cumprir os novos termos e condições.
              </li>

            </ul>



            <ul className='space-y-1'>
              <strong> Depósito e Rollover</strong>
              <li>
                5.1. Para garantir a segurança e prevenir a lavagem de dinheiro, todo valor depositado na Rockeetz Games inicia com um rollover de 1x, o que significa que o usuário deve apostar o valor depositado pelo menos uma vez antes de solicitar o saque. A Rockeetz Games se reserva o direito de aplicar regras adicionais de rollover, dependendo da forma de depósito escolhida pelo usuário. O não cumprimento das regras de rollover pode resultar em cancelamento do saque e/ou desativação da conta do usuário. É importante que o usuário leia atentamente as regras de rollover antes de fazer qualquer depósito na Rockeetz Games.
              </li>

              <li>
                5.2. A Rockeetz Games se reserva o direito de alterar as regras de depósito e rollover a qualquer momento, sem aviso prévio.
              </li>
            </ul>

            <ul className='space-y-1'>
              <strong>  Disposições gerais</strong>
              <li>
                6.1. Estes termos e condições de uso constituem o acordo completo entre você e a Rockeetz Games em relação ao uso dos nossos serviços.

              </li>

              <li>
                6.2. Caso alguma cláusula deste contrato seja considerada inválida ou inexequível, as demais cláusulas permanecerão em pleno vigor e efeito.


              </li>

              <li>
                6.3. O não cumprimento de quaisquer termos e condições deste acordo pela Rockeetz Games não constitui uma renúncia a esses termos e condições.


              </li>

              <li>
                6.4. Este acordo será regido e interpretado de acordo com as leis do país em que a empresa estiver estabelecida.


              </li>
            </ul>

            <p>Ao utilizar os serviços da Rockeetz Games, você concorda em cumprir estes termos e condições de uso. Se você tiver alguma dúvida ou preocupação, por favor entre em contato conosco pelo nosso serviço de atendimento ao cliente.</p>
          </div>
        </div>








        <div className="flex justify-center mt-3 absolute bottom-0 w-full ">
          <button  onClick={closeCupom} class="disabled:opacity-50 w-4/12 mb-4  mx-auto whitespace-nowrap bg-[#41DD3E] hover:text-white hover:bg-green-800 text-[#090808] font-bold py-2 text-sm lg:text-base uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-700">
            entendi
          </button>


        </div>








      </div>


    </>
  );
}

export default ConteudoTermosDeUso;