export default function Blocked(){

    function block(){
        document.getElementById('blockUser').classList.toggle('hidden');
    }

    function ajuda(){
     window.location.href = "/regras"
     setTimeout(()=>{
     block();
     },100)
    
    }
    function entendi(){
    block();
    }
    return(
        <>
        <div id="blockUser" className="hidden z-[9999] backdrop-grayscale bg-[#00000066] backdrop-blur-lg overflow-hidden fixed w-full h-screen margin-0 right-0 left-0 bottom-0 top-0 flex justify-center items-center">
        <div className="rounded-md border-black border-4 bg-cinzaprimary w-11/12 lg:w-6/12">
          <div className="w-full p-2 font-bold rounded-t-md text-white bg-cinzafourth ">Aviso de violação</div>
          <div className="flex text-white  lg:mt-5 justify-center  w-full">
            <div className="w-11/12 rounded-lg bg-cinzasecondary mb-5 text-center p-2">
            Detectamos ações impróprias nesta conta que violam nossas regras e termos de serviço.
            <span className="text-red-800"> Esta conta foi suspensa permanentemente</span></div>
            </div>


        <div className="flex mb-4 justify-around">
          <div onClick={ajuda} className="cursor-pointer font-bold hover:bg-roxothird hover:text-white text-black bg-gray-300 rounded-md p-2">AJUDA</div>
          <div onClick={entendi} className="cursor-pointer font-bold hover:bg-roxothird hover:text-white text-black bg-verdeprimary rounded-md p-2">ENTENDI</div>
        </div>

        </div>
     
      </div>
        </>
    )
}