import Win from "../../assets/mines/win.png"

export default function WinMines(props) {
    return (<>
        <div id="winMines" className="hidden scale-75 duration-[400ms] fixed z-[6] justify-center items-center w-full h-[100vh] margin-0 top-0 left-0 right-0 bottom-0">
            <div className="absolute z-[2] w-full flex justify-center items-center">

                <div className="w-full lg:w-6/12 flex flex-col justify-center items-center relative">
                <div className="w-6/12 absolute top-[60%] left-[35%] lg:left-[35%] translate-y-[-50%]  translate-x-[-50%] ">
                <div className="flex items-center font-bold montserrat bg-green-300 opacity-90 justify-center rounded-lg px-2"><span className="text-[0.8em] mr-2 ">BRL</span> <span className="font-black text-4xl lg:text-6xl">{props.ganho}</span></div> 
            </div>
            <img  className="w-full" src={Win} />


                </div>

            </div>


            <div className="w-full h-full backdrop-blur-sm absolute"></div>
        </div>

    </>)
}