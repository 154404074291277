import {useEffect} from 'react';

function Tabela() {

  useEffect(() => {

 
    
   },[])


  return (
    <article className="bg-[#0a0b0a] rounded-[10px] mt-8">
      <div className="px-6 py-4">
        <div className="flex justify-center items-center lg:justify-start gap-3">
          <p className="lg:text-2xl text-xl font-semibold whitespace-nowrap ">VALOR TOTAL:</p>
          <p
            id="nvalor"
            className="text-verdeprimary font-semibold text-xl lg:text-2xl whitespace-nowrap"
          >
            -
          </p>
        </div>
        <div>
          <p
            id="njogadores"
            className="leading-4 whitespace-nowrap text-[0.6em] lg:text-base text-center lg:text-left "
          >
            - JOGADORES fizeram seus investimentos
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[-6px] px-6 py-1  bg-[#212529] lg:hidden">
        <p className="text-sm">Usuário/Investimento</p>
        <p className="text-sm">Multiplicador</p>
        <p className="text-sm">Lucro</p>
      </div>

      <div className="hidden lg:block">
        <div className="grid grid-cols-4 mt-[-6px] px-6 py-1  bg-[#212529]">
          <p className="text-sm">Usuário</p>
          <p className=" text-center text-sm ">Investimento</p>
          <p className="text-center text-sm">Multiplicador</p>
          <p className=" text-center text-sm">Lucro</p>
        </div>
      </div>

      <div id="painelApostas" className="px-2 py-1">
        <div className="grid lg:grid-cols-4 grid-cols-3 mt-2 mb-2    px-6 py-2 rounded-lg border-b border-verdeprimary bg-[#020202]">
          <div className="hidden lg:block text-verdeprimary uppercase whitespace-nowrap font-bold">
            -
          </div>

          <div className="lg:hidden">
            <p className="text-verdeprimary uppercase font-bold whitespace-nowrap "></p>
            <p className="text-sm leading-3 whitespace-nowrap ">R$ -</p>
          </div>

          <div className="text-center hidden lg:block text-sm leading-3 whitespace-nowrap">
            R$ -
          </div>

          <div className="lg:text-center text-right  ">-</div>

          <div className="lg:text-center text-right ">-</div>
        </div>
      </div>

      <div  className=" px-2 py-1">
        <div id="footerTabelaJogadores" className="flex justify-end items-center gap-2 px-6 py-1 rounded-lg  bg-[#212529] whitespace-nowrap ">
          <p id="totaljogadores" className="lg:text-sm text-[0.6em]">+ 35 outros JOGADORES fizeram um</p>
          <p id="totalvalores" className="lg:text-sm text-[12px]"> total de R$ 231.91</p>
        </div>
      </div>
    </article>
  );
}

export default Tabela;
