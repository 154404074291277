
import Roleta from '../../assets/roleta/roleta.png'

export default function WinRoleta() {


    return (
        <>

            <div id="modalWinRoullet" className="bg-[#1c0c31e0] text-white flex justify-center items-center fixed margin-0 top-0 bottom-0 left-0 right-0 w-full h-screen z-[20]" style={{display:'none'}}>
                <div className="w-full absolute flex justify-center z-[3]">
                    <div className="flex justify-center items-center flex-col w-11/12 lg:w-8/12">
                        <div className="font-bold text-xl lg:text-[2em] top-[-10px] relative md:text-[4em]">PARABÉNS!! </div>
                        <div id="youwincrash" className="bg-azulsecondary relative h-[70px] w-10/12 flex justify-center p-4 font-black text-3xl uppercase md:text-[6em] lg:text-[3em]">
                            <div className="mv1 absolute z-[3]">Você ganhou!!</div>
                            <div className="mv2 text-roxoprimary absolute z-[2]">Você ganhou!!</div>
                            <div className="mv3 text-azulthird absolute z-[1]">Você ganhou!!</div>
                        </div>

                        <p id="moneyWinModalRoullet" className="relative top-[30px] lg:text-[5em] md:text-[7em] text-3xl font-bold"></p>
                    </div>
                </div>
                <div className="flex w-full absolute h-[100%]">
                    <div className="w-6/12  flex justify-start">
                        <div id="bg-left" className="w-full"></div>
                    </div>
                    <div className="w-6/12 flex justify-end">
                        <div id="bg-right" className="w-full"></div>
                    </div>
                </div>
                <div className="w-full h-[100%] z-[2] top-[20px] absolute flex justify-center items-start lg:items-center lg:justify-end">
                    <div id="roleta" className="w-10/12 flex justify-center md:w-6/12">
                    <img  className="w-10/12 rotating" src={Roleta} />
                    </div>
                    
                </div>
                <div className='pow2 w-full z-[1]'>
  <div className='squares2'>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
    <div className='square2'></div>
  </div>
</div>
<div className='grey2'></div>
               
            </div>

        </>
    )
}