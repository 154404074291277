import Template from "../../layouts/template/template";
import RegrasConteudo from "../CentralDoJogador/Regras/conteudo";
import RegrasDesktop from "./Desktop/desktop";
import RegrasPhone from "./Phone/phone";

export default function RegrasHome(){
    return(
        <>
        <div className="hidden lg:block">
        <Template>
        <div className="w-full flex justify-center">
        <div className="w-11/12 lg:w-9/12">
        <RegrasDesktop/>
        </div>
        </div>
       </Template>
        </div>
        <div className="lg:hidden block">
         <RegrasPhone/>
        </div>
        </>
 
 
    )
}