export default function RktCoinEn() {
    return (
        <>


<div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">RKT COIN Policy</div>
                </div>
                <div className="text-white mt-5">Last update: July 1st, 2023</div>
                <br /><br />

                <div className="text-white mt-2 font-semibold">Rules of Use for RKT COIN in "rockeetz" Game</div>
                <br />
                <div className="text-white mt-2 font-semibold">1 - Acquisition of RKT COIN:</div>
                1.1 Players can acquire RKT COIN through streak login, completing challenges, achieving goals, and other activities within the game. RKT COINs have no real monetary value and are provided for free.
                <br />
                <div className="text-white mt-2 font-semibold">2 - Utilization of RKT COIN:</div>
                2.1 RKT COINs can be used to play the Free Game within Rockeetz
                <div className="text-white mt-2 font-semibold">3 - Usage Restrictions:</div>
                3.1 Restrictions may apply to the use of RKT COINs, including daily spending limits or player level requirements. Refer to support for specific details.
                <div className="text-white mt-2 font-semibold">4 - Transfer Between Players:</div>
                4.1 The transfer of RKT COINs between players is not allowed.
                <div className="text-white mt-2 font-semibold">5 - Reimbursement and Reset:</div>
                5.1 We reserve the right to modify, suspend, or cancel RKT COIN at any time, without prior notice. As RKT COIN is a free virtual currency within the game, players have no right to compensation or reimbursement in case of modifications, suspensions, or cancellations.
                <div className="text-white mt-2 font-semibold">6 - Compliance with Laws:</div>
                6.1 The use of RKT COIN must comply with all applicable laws and regulations.
                <div className="text-white mt-2 font-semibold">7 - Privacy and Security Policy:</div>
                7.1 All transactions with RKT COIN are protected and maintained in compliance with our Privacy Policy. Refer to our Privacy Policy for more details.
                <div className="text-white mt-2 font-semibold">8 - Conversions:</div>
                8.1 The conversion of RKT COIN to Brazilian Real (BRL) is allowed under the following conditions:<br />
                8.1.1 The player must have a minimum balance of 1000 RKT COINs to be eligible for conversion.
                <br />8.1.2 The conversion rate is fixed at 1000 RKT COINs to 1 BRL.
                <br />8.1.3 The player must have deposited a minimum accumulated amount of 80 BRL within rockeetz to be eligible for conversion.
                <br />8.1.4 All conversions are subject to approval and review by our team, and we reserve the right to refuse conversion for any reason at our discretion.
                <br />8.1.5 Conversion may be subject to additional fees and/or delays in processing, as described within rockeetz.
                <div className="text-white mt-2 font-semibold">9 - Changes to the Rules:</div>
                We reserve the right to change these rules at any time, with or without prior notice. It is the players' responsibility to review these rules regularly to be aware of any changes.
            </div>

        </>
    )
}