export default function RegrasBonus(){
    return(
        <>

<div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Política de Privacidade</div>
                </div>
                <div className="text-white font-bold">CUPOM DE BÔNUS</div>
              
                Um rollover define o multiplicador no qual a soma de seu depósito e bônus precisará ser entregue.
                com nossos jogos você pode multiplicador seu valor de 1.1x, 2x, 5x , ou até 100x pra cima, isso depende do jogo que você irá jogar. definimos nosso rollover em uma vantagem de 4%. Isso significa que, se os usuários jogarem um jogo com vantagem de 1%, ele será concluído em uma taxa 4x mais lenta. Assim, o requisito de rollover seria acumulado em uma proporção de $ 0,25 para cada $ 1 apostado. Por outro lado, quanto maior a margem da casa do jogo que está sendo jogado, menos aposta é necessária para completar o rollover. Enquanto tiver um rollover ativo, sua conta será bloqueada até que você cumpra o requisito definido ou, alternativamente, você perca todo o seu saldo (em sua moeda de bônus relativa) e não tenha apostas ativas restantes. Durante o rollover ativo, os usuários não podem sacar o valor de bônus. toda vez que você ganhar, você aumenta seu saldo bônus e diminui a % para completar o rollover, caso você tenha valor de saldo real, ele será descontado toda vez que você perder na rodada.
<br/>

<br/><br/>
<div className="text-white font-bold">BÔNUS INDICAÇÃO</div>
O bônus indicação é proveniente à indicação de usuários que venham a se cadastrar e a depositar o valor mínimo para o investimento no sistema aleatório da Rockeetz. O bônus indicação compreende a uma bonificação de R$10 por usuário depositante indicado. O bônus indicação é por prazo determinado a critério da administradora. A referida campanha está sujeita a todas as regras descritas na política de privacidade da Rockeetz.


            </div>

            
        </>
    )
}