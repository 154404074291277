
import useRender from "../../../hooks/useRender";
import {useState, useEffect} from 'react'
import Swal from "sweetalert2"
import Loading from "../../../components/Loading"





function Pix() {


  const {prefixoUrl, SairApi, saldo, _setSaldo} = useRender();
  const [valueSaque, setValueSaque] = useState();
  const [selectPix, setSelectPix] = useState("CPF");
  const [pix, setPix] = useState()
  const [saldoSacar, setSaldoSacar] = useState();
  const [error, setError] = useState("");
  const [errorQuantia, setErrorQuantia] = useState();
  const [colorBorder, setColorBorder ] = useState ("border-verdeprimary");
  const [validando, setValidando] = useState(false);
  const [user, setUser] = useState()

  function User() {
    let resultado = localStorage.getItem("user");
    resultado = JSON.parse(resultado);
    setUser(resultado.user);
  }

  const list = [
    {id: 2, pix: 'CPF'},
    {id: 3, pix: 'E-MAIL'},
    {id: 4, pix: 'CELULAR'},
    {id: 5, pix: 'CHAVE'},

  ]


  function saldoDisponivel() {
    var myHeaders = new Headers();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
  
  fetch(`${prefixoUrl}/saque/saldodisponivel?token=${localStorage.getItem('user_token')}`, requestOptions)
    .then(response => response.text())
    .then(result => {
      let dados = JSON.parse(result)
      if(dados.code === 1099){ // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }
      localStorage.setItem("saldoSaque", JSON.stringify(dados));
      setSaldoSacar(dados)
    })
    .catch(error => console.log('error', error));
     
  }

  useEffect(() => {
    saldoDisponivel()
    User()
  }, []);




  function handleeSaque(){
    if(!valueSaque){
      setErrorQuantia("Informe a quantia que deseja sacar!");
      document.getElementById("inputQuantia").classList.add("border-red-700");
  
    }
    else{
      setErrorQuantia("");
      document.getElementById("inputQuantia").classList.remove("border-red-700");
    }

    if(!pix){
      setError("Informe a chave pix desejada!");
      setColorBorder("border-red-700 ");
    }

    else{
      setError("");
      setColorBorder("border-verdeprimary ");

    }



    if(valueSaque && selectPix && pix){
        if(valueSaque < 100){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Valor do saque mínimo é de R$ 100,00"
         
          })
        } else if (selectPix){
           switch (selectPix) {
            case "CPF":
                if(pix.length < 11){
                  setError("Insira todos os 11 dígitos do cpf.");
                  setColorBorder("border-red-700");
                }else if (pix != user.cpf){
                  setError("Para efetuar o saque o cpf deve ser o mesmo da conta cadastrada.");
                  setColorBorder("border-red-700");
                }else{ apiSacar() }
              break;

              case "CELULAR":
                if(pix.length < 10){
                  setError("Insira todos os  dígitos do celular.");
                  setColorBorder("border-red-700");
                }else{ apiSacar() }
              break;

              case "CHAVE":
                if(pix.length < 37){
                  setError("Insira todos os  dígitos da chave aleatória");
                  setColorBorder("border-red-700");
                }else{ apiSacar() }
              break;

              case "E-MAIL":
                if(pix.indexOf("@") === -1){
                  setError("Insira e-mail válido.");
                  setColorBorder("border-red-700");
                }else{ apiSacar() }
              break;
          
          
            default:
              break;
          }
    

        }

        
       else{ apiSacar()  }}}


function apiSacar(){
  setValidando(true);
  let token = localStorage.getItem('user_token')
  var myHeaders = new Headers();

var raw = JSON.stringify({
"token": token,
"valor": valueSaque,
"tipoChavePix": selectPix,
"pix": pix
});
console.log(raw)
var requestOptions = {
method: 'POST',
headers: myHeaders,
body: raw,
};

fetch(`${prefixoUrl}/saque/sacar`, requestOptions)
.then(response => response.text())
.then(result => {
  let dados = JSON.parse(result)
  console.log(dados.msg)
  if (dados.code === 1) {  
            
  _setSaldo(saldo - valueSaque)
 setValidando(false);    
    Swal.fire(
      'Sucesso!',
      dados.msg,
      'success'
    )   

}else if(dados.code === 1099){ // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
  SairApi()
}else{
  setValidando(false);
  Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: dados.msg
 
  })
 
}})
.catch(error => console.log('error', error));
}


  return (


<main>
      <div className="flex justify-between items-center border border-[#121111] rounded-lg p-2 text-[12px] mt-5">
        <div
          className="bg-[#2bbd9f] w-full text-center rounded-full cursor-pointer" >
          <p>PIX</p>
        </div>
      </div>

      <div className="py-5">
        <p>INFORME O VALOR E A CHAVE PIX PARA SOLICITAR O SAQUE</p>
      </div>

      <div className="pb-5">
        <input
           id="inputQuantia"
          type="number" 
          value={valueSaque} onChange={e=> setValueSaque(e.target.value) }
          placeholder="Qual é o valor do saque?"
          className="outline-none rounded-lg border border-verdeprimary duration-700 bg-[#121111] px-3 py-2 w-full"
        />
        <label className="text-[#3f445e] text-sm leading-5 flex ">
          <p className="text-red-500 ml-1   duration-700"> {errorQuantia}</p>
        </label>
        {saldoSacar?.rollover > 0 ? (
            <label className="text-[#989ebb] ml-1   text-sm leading-5 flex">
            Rollover necessário (<b>R$ {parseFloat(saldoSacar.rollover).toFixed(2)} </b>)
          
            
          </label>
        ) : (
                  <label className="text-[#989ebb] text-sm leading-5 flex">
                  Saldo disponível em conta
                  {!saldoSacar ? (
                    <Loading/>
                   ) : (
                    <p className="text-white ml-1"> R${saldoSacar.saldo}</p>
                   )}
                  
                </label>
        )}

      </div>

      <div>
        
        <select className="bg-[#363f47]  w-full px-4 outline-none py-1" value={selectPix} onChange={e=> setSelectPix(e.target.value)}>
            {list.map((item, index) => (
            <option key={item.id} value={item.pix}>{item.pix}</option>
                   ))}
        </select>
      </div>
      {selectPix == "CPF" ? (
              <div className="mt-5">
              <input
                type="number"
                id="cpfSaque"
                value={pix} onChange={e=> setPix(e.target.value)}
                placeholder="Digite seu cpf."
                className={`outline-none rounded-lg border ${colorBorder} duration-700 bg-[#121111] px-3 py-2 w-full`}
              />
           
            </div>
      ) : selectPix == "E-MAIL" ?(
        <div className="mt-5">
        <input
          type="text"
          maxLength="40"
          value={pix} onChange={e=> setPix(e.target.value)}
          placeholder="Digite seu e-mail."
          className={`outline-none rounded-lg border ${colorBorder} duration-700 bg-[#121111] px-3 py-2 w-full`}
        />
     
      </div>
      ) : selectPix == "CELULAR" ? (
        <div className="mt-5">
        <input
        id="celSaque"
          type="number"
          value={pix} onChange={e=> setPix(e.target.value)}
          placeholder="Digite seu celular."
          className={`outline-none rounded-lg border ${colorBorder} duration-700 bg-[#121111] px-3 py-2 w-full`}
        />
     
      </div>
      ) : selectPix == "CHAVE" ? (
        <div className="mt-5">
        <input
          type="text"
          
          value={pix} onChange={e=> setPix(e.target.value)}
          placeholder="Digite sua chave aleatória ."
          className={`outline-none rounded-lg border ${colorBorder} duration-700 bg-[#121111] px-3 py-2 w-full`}
        />
     
      </div>
      ) : "" }

        <label className={`text-[#3f445e] text-sm leading-5 flex ${error ? `mb-[-20px]` : ``}`}>
          <p id="errorOpacity" className={`text-red-500 ml-1 duration-700"`}> {error}</p>
        </label>

      <div className="flex justify-end items-center mt-5">
      <div className="flex justify-center">
          <button disabled={validando} onClick={handleeSaque} class="disabled:opacity-50 whitespace-nowrap bg-verdeprimary border-green-500 hover:bg-green-800 text-white font-bold py-1 lg:px-20 px-6 text-sm lg:text-base uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-700">
            {validando && <svg role="status" class="inline mr-2 w-6 h-6 text-green-200 whitespace-nowrap animate-spin dark:text-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>}
            {validando && 'Solicitando...' }
            {!validando && 'Solicitar Saque' }
          </button>
          </div>


      </div>

      <div>
        <p className="text-sm mt-5">
          (Atenção a transferência por PIX é feita em até 3 dias úteis após o
          pedido de saque, você receberá uma notificação por e-mail confirmando
          a transação.)
        </p>
      </div>
    </main>



   
  );
}

export default Pix;
