
import ContainerOpacity from "./opacity/container"
import SelectGameConteudo from "./selectGame/conteudo"


export default function SelectGame(){
    return(
        <>
         <ContainerOpacity openmodal="selectGame">    {/* CONTAINER COM O BLUR ATIVADO*/}
         <SelectGameConteudo openmodal="selectGame" />
         </ContainerOpacity>
        </>
    )
}