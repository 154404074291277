import Voltar from "../../assets/icons/voltar.gif";
import Money1 from "../../assets/icons/money_1.svg";
import Money2 from "../../assets/icons/money_2.svg";
import Money3 from "../../assets/icons/money_3.svg";
import { useEffect, useState } from "react";
import useRender from "../../hooks/useRender";
import Loading from "../../components/Loading";
import Title from "../../components/centraldojogador/titulo";
import Template from "../../layouts/template/template";
import Swal from "sweetalert2";
import Card from './Card/Card';
import ModalExcluir from './Card/Modal/ModalExcluirBonus';

function Bonus() {
  const { prefixoUrl, updateSaldos } = useRender();
  const [bonus, setBonus] = useState();




  async function apiBonus() {
  
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    

    await fetch(
      `${prefixoUrl}/cupom/bonus?token=${localStorage.getItem("user_token")}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        setBonus(dados)
        updateSaldos({saldoReal:dados.saldoreal,saldototal:dados.saldototal,saldoBonus:dados.saldobonus})
        localStorage.setItem("Bonus", JSON.stringify(dados));
      })
      .catch((error) => console.log("error", error));
  }

  function dropInfo(id) {
    document.getElementById(`box--card--${id}`).classList.toggle("h-[270px]");
    document.getElementById(`verInfo${id}`).classList.toggle("hidden");
    document.getElementById(`card--${id}`).classList.toggle("hidden");
    document.getElementById(`card2--${id}`).classList.toggle("hidden");
  }


  function handlerOpenModalSetIdCupomModalExcluirBonus(idcupom){
    document.getElementById("modalExcluirBonus").classList.toggle("hidden");
    document.getElementById('btnConfirmarIdCupom').setAttribute('idcupom',idcupom);
  }

  function excluirBonusApi(campo) {
    let id = campo.target.getAttribute('idcupom');
   
    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

fetch(`${prefixoUrl}/bonus/cancelar/${id}?token=${localStorage.getItem("user_token")}`, requestOptions)
  .then(response => response.json())
  .then(result =>{

    if(result.code == 1){
      Swal.fire({
        icon: 'success',
        title: 'Feito!',
        text: result.msg,
      });
   
      apiBonus();
    }

    if(result.code > 1000){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: result.msg,
      })
      handlerOpenModalSetIdCupomModalExcluirBonus()
    }

  } )
  .catch(error => console.log('error', error))

  .finally(() => {
    document.getElementById("modalExcluirBonus").classList.toggle("hidden");
  })
    
  }
  

  function voltarHome() {
    window.location.href = "/home"
  }

  useEffect(() => {
    apiBonus()

  }, []);
  return (
    <>
      <Template>

        <div className="flex justify-between items-center py-4 px-2 w-full mb-4 border-b-2 border-verdeprimary">

          <div onClick={voltarHome} className="lg:hidden">
            <img className="w-[110px] cursor-pointer" src={Voltar} alt="" />
          </div>


          <Title title="Meus bônus" />
        </div>

        {/* Saldo */}
        <div className="w-full lg:w-10/12 lg:flex lg:items-center">
          <div className="mt-1 lg:w-full">
            <div className="flex justify-between items-center  py-1 bg-[#2C3440] rounded-r-full lg:rounded-r-none  lg:rounded-l-full lg:mr-2 mr-3 ml-[-6px] lg:ml-0 ">
              <div className="flex items-center max-h-10 ml-4">
                <img src={Money3} className="w-[45px]" alt="" />
                <div className="text-sm leading-none font-semibold text-[#13BA5B]">
                  SALDO <br></br> REAL
                </div>
              </div>

              <div className="bg-[#1a1c20] mr-2 flex items-center w-[200px] rounded-full  ">
                <p className="text-white  ml-3 my-auto text-[23px] font-semibold ">
                  BRL {bonus?.saldoreal.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-1 lg:w-full">
            <div className="flex justify-between items-center  py-1 bg-[#2C3440] rounded-r-full lg:rounded-none lg:mr-2 mr-3 ml-[-6px] ">
              <div className="flex items-center max-h-10 ml-2">
                <img src={Money2} className="w-[50px]" alt="" />
                <div className="text-sm leading-none font-semibold text-[#13BA5B]">
                  SALDO <br></br> EM BÔNUS
                </div>
              </div>

              <div className="bg-[#1a1c20] mr-2 flex items-center w-[200px] rounded-full  ">
                <p className="text-white  ml-3 my-auto text-[23px] font-semibold ">
                  BRL {bonus?.saldobonus.toFixed(2)}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-1 lg:w-full">
            <div className="flex justify-between items-center  py-1 bg-[#2C3440] rounded-r-full  mr-3  ml-[-6px] ">
              <div className="flex items-center max-h-10 ml-2">
                <img src={Money1} className="w-[50px]" alt="" />
                <div className="text-sm leading-none font-semibold text-[#13BA5B]">
                  SALDO <br></br> TOTAL
                </div>
              </div>

              <div className="bg-[#1a1c20] mr-2 flex items-center w-[200px] rounded-full  ">
                <p className="text-white  ml-3 my-auto text-[23px] font-semibold ">
                  BRL {bonus?.saldototal.toFixed(2)}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border border-verdeprimary mt-4 w-full"></div>

        <div className="p-3 ">
          <div className="font-bold text-[31px] text-[#23F811]">BÔNUS ROLLOVER</div>
        </div>



        <div className="lg:grid  min-h-[600px] lg:grid-cols-3">
          {/* Bônus */}
          {bonus != undefined ? (            
           bonus.bonus.map((item) => {
            return (
              <>
                <Card item={item} setCupomidModal={handlerOpenModalSetIdCupomModalExcluirBonus}></Card>
              </>


                );
            })

          ) : (
            <Loading />
          )}
          <ModalExcluir excluirBonusApi={excluirBonusApi}></ModalExcluir>
        </div>

 

      </Template>
    </>
  );
}

export default Bonus;
