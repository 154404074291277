import { useEffect } from "react";

export default function Tabela(){
 const indice = [1,2,3,4,5,6,7,8,9,10];

 useEffect(()=>{
    // =========== ADICIONA TABELA DOS PLAYERS =======================================


window.c_Roullet.socket.on('addJogadorTabelaPlayer', (player) => {

    try {
        switch (player.cor) {
            case 'azul':
                window.c_Roullet.listaJogadores.azul.push(player);
                window.c_Roullet.listaJogadores.azul.sort(function (a, b) {
                    return b.aposta - a.aposta
                });
                break;

            case 'preto':
                window.c_Roullet.listaJogadores.preto.push(player);
                window.c_Roullet.listaJogadores.preto.sort(function (a, b) {
                    return b.aposta - a.aposta
                });
                break;

            case 'branco':
                window.c_Roullet.listaJogadores.branco.push(player);
                window.c_Roullet.listaJogadores.branco.sort(function (a, b) {
                    return b.aposta - a.aposta
                });
                break;


        }


        /*Listar as 3 maiores apostas*/
        for (let i = 0; i <= 9; i++) {
            let indice_tabela = i + 1;

            /*azul*/
            if (window.c_Roullet.listaJogadores.azul[i] != undefined) {
                document.getElementById('planet_blue_nome_' + indice_tabela).innerHTML = window.c_Roullet
                    .listaJogadores.azul[i].apelido;
                document.getElementById('planet_blue_valor_' + indice_tabela).innerHTML = 'R$ ' +
                    parseFloat(window.c_Roullet
                        .listaJogadores.azul[i].aposta).toFixed(2);
            }

            /*preto*/
            if (window.c_Roullet.listaJogadores.preto[i] != undefined) {
                document.getElementById('planet_gray_nome_' + indice_tabela).innerHTML = window.c_Roullet
                    .listaJogadores.preto[i].apelido;
                document.getElementById('planet_gray_valor_' + indice_tabela).innerHTML = 'R$ ' +
                    parseFloat(window.c_Roullet
                        .listaJogadores.preto[i].aposta).toFixed(2);
            }

            /*branco*/
            if (window.c_Roullet.listaJogadores.branco[i] != undefined) {
                document.getElementById('planet_astro_nome_' + indice_tabela).innerHTML = window.c_Roullet
                    .listaJogadores.branco[i].apelido;
                document.getElementById('planet_astro_valor_' + indice_tabela).innerHTML = 'R$ ' +
                    parseFloat(window.c_Roullet
                        .listaJogadores.branco[i].aposta).toFixed(2);
            }


        }

        /*Listar numero total de apostas /////////////////////////////////*/
        document.getElementById('planet_blue_desc_totalapostas').innerHTML = window.c_Roullet.listaJogadores.azul
            .length + ' TOTAL DE INVESTIMENTOS';
        document.getElementById('planet_gray_desc_totalapostas').innerHTML = window.c_Roullet.listaJogadores.preto
            .length + ' TOTAL DE INVESTIMENTOS';
        document.getElementById('planet_astro_desc_totalapostas').innerHTML = window.c_Roullet.listaJogadores.branco
            .length + ' TOTAL DE INVESTIMENTOS';


        /*soma total de azuls /////////////////////////////////    */

        let total_azul = 0;
        for (let i = 0; i < window.c_Roullet.listaJogadores.azul.length; i++) {
            total_azul = total_azul + parseFloat(window.c_Roullet.listaJogadores.azul[i].aposta);
        }
        total_azul = parseFloat(total_azul).toFixed(2);


        /*soma total de brancos*/
        let total_branco = 0;
        for (let i = 0; i < window.c_Roullet.listaJogadores.branco.length; i++) {
            total_branco = total_branco + parseFloat(window.c_Roullet.listaJogadores.branco[i].aposta);
        }
        total_branco = parseFloat(total_branco).toFixed(2);

        /*soma total de pretos*/
        let total_preto = 0;
        for (let i = 0; i < window.c_Roullet.listaJogadores.preto.length; i++) {
            total_preto = total_preto + parseFloat(window.c_Roullet.listaJogadores.preto[i].aposta);
        }
        total_preto = parseFloat(total_preto).toFixed(2);

        // SOMA DO TOTAL DAS APOSTAS DE CADA COR
        document.getElementById('planet_blue_valor_totalapostas').innerHTML = 'R$ ' + total_azul;
        document.getElementById('planet_gray_valor_totalapostas').innerHTML = 'R$ ' + total_preto;
        document.getElementById('planet_astro_valor_totalapostas').innerHTML = 'R$ ' + total_branco;


        return;



    } catch(err) {
        console.log(err);
    }

});
 },[])

    return(
        <>

<div className="w-11/12 md:w-9/12 grid lg:grid-cols-3 grid-cols-1 ">
{/* TABELA SAFIRA */}
<div className="rounded-lg border-2  p-2 m-0 lg:m-2 border-[#1385B5] mt-6">
    <div className="bg-gradient-to-r from-[#1385b5] rounded-lg text-white text-right pr-4 flex justify-between items-center">
        <div>
            <img className="max-w-[100%] w-20 h-auto  " src="./img/roleta/planet.png" alt="" />
        </div>
        <div>
            <p className="text-2xl font-bold text-[#1385B5] ">PLANETA SAFIRA</p>
            <p id="planet_blue_desc_totalapostas" className="leading-4 font-semibold text-[13px]"> - </p>
            <p id="planet_blue_valor_totalapostas" className="leading-4 font-bold text-xl">R$ -</p>
        </div>
    </div>

    <div className="space-y-1 py-4">
    { indice.map( (number, index) => 
    <div key={index} className="flex justify-between items-center bg-[#1a242b] px-4 text-white ">
    <p id={`planet_blue_nome_${number}`}>-</p>
    <p id={`planet_blue_valor_${number}`}>R$-</p>
    </div>
    ) }
        

       
    </div>
</div>

{/* TABELA ASTRO */}
<div className="rounded-lg border-2  p-2 m-0 lg:m-2 border-[#1385B5] mt-6">
    <div className="bg-gradient-to-r from-[#27282b] via-[#5d5e61] to-[#2e2f33] rounded-lg text-white text-right pr-4 flex justify-between items-center">
        <div>
            <img className="max-w-[100%] w-20 h-auto  " src="./img/roleta/astro.png" alt="" />
        </div>
        <div>
            <p className="text-2xl font-bold text-white ">ASTRO <b className="text-[#1385B5]">14x</b></p>
            <p className="leading-4 font-semibold text-[13px]" id="planet_astro_desc_totalapostas"> - </p>
            <p className="leading-4 font-bold text-xl" id="planet_astro_valor_totalapostas" >R$ -</p>
        </div>
    </div>

    <div className="space-y-1 py-4">
    { indice.map( (number, index) => 
    <div key={index} className="flex justify-between items-center bg-[#1a242b] px-4 text-white ">
    <p id={`planet_astro_nome_${number}`}>-</p>
    <p id={`planet_astro_valor_${number}`}>R$-</p>
    </div>
    ) }

    
    </div>
</div>

{/* TABELA DARK */}
<div className="rounded-lg border-2  p-2 m-0 lg:m-2 border-[#1385B5] mt-6">
    <div className="bg-gradient-to-r from-[#5d5e61] via-[#27282b] to-[#5d5e61] rounded-lg text-white text-right pr-4 flex justify-between items-center">
        <div>
            <img className="max-w-[100%] w-20 h-auto  " src="./img/roleta/planet-cinza.png" alt="" />
        </div>
        <div>
            <p className="text-2xl font-bold text-white ">PLANETA DARK</p>
            <p className="leading-4 font-semibold text-[13px]" id="planet_gray_desc_totalapostas"> - </p>
            <p className="leading-4 font-bold text-xl text-[#27282b]" id="planet_gray_valor_totalapostas">R$ -</p>
        </div>
    </div>

    <div className="space-y-1 py-4">
    { indice.map( (number, index) => 
    <div key={index} className="flex justify-between items-center bg-[#1a242b] px-4 text-white ">
    <p id={`planet_gray_nome_${number}`}>-</p>
    <p id={`planet_gray_valor_${number}`}>R$-</p>
    </div>
    ) }

       
    </div>
</div>
</div>
        </>
    )
}