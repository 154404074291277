import Topper from "../../../components/Topper/Topper";
import Template from "../../../layouts/template/template";
import CarrosselPg from "../../Principal/jogos/pg/carrossel";

export default function Pgsoft() {
    return (
        <>
            <Template>
           <div className="w-full min-h-[100vh]">
            <CarrosselPg/>
           </div>

            </Template>

        </>
    )
}