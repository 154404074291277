import cyber from "../../../assets/icons/crash_hist.png";
import { useEffect, useState } from "react";
import useRender from "../../../hooks/useRender";
import Loading from "../../../components/Loading";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";


function HistoricoDeJogo() {
  const { prefixoUrl, SairApi } = useRender();
  const [result, setResult] = useState();

  async function PaginacaoNext() {
    let dados = localStorage.getItem("historicoDeJogo");
    dados = JSON.parse(dados);
    if (dados) {
      if (dados.paginaAtual < dados.paginas) {
        let dados = localStorage.getItem("historicoDeJogo");
        dados = JSON.parse(dados);
        dados.paginaAtual = dados.paginaAtual + 1;
        localStorage.setItem("historicoDeJogo", JSON.stringify(dados));

        let inicio = (dados.paginaAtual - 1) * 10;
        let final = inicio + 10;
        dados.result = dados.result.slice(inicio, final);
        setResult(dados);
      }
    }
  }

  async function PaginacaoPrevius() {
    let dados = localStorage.getItem("historicoDeJogo");
    dados = JSON.parse(dados);
    if (dados) {
      if (dados.paginaAtual == 1) return;
      dados.paginaAtual = dados.paginaAtual - 1;
      localStorage.setItem("historicoDeJogo", JSON.stringify(dados));

      let inicio = (dados.paginaAtual - 1) * 10;
      let final = inicio + 10;
      dados.result = dados.result.slice(inicio, final);
      setResult(dados);
    }
  }

  async function PaginacaoLast() {
    var myHeaders = new Headers();

    var raw = "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${prefixoUrl}/centraldojogador/historicodejogos/crash/10?token=${localStorage.getItem(
        "user_token"
      )}&page=${result.paginas}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        setResult(dados);
        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  async function HistoricoDeJogo() {

    let dados = localStorage.getItem("historicoDeJogo");
    dados = JSON.parse(dados);

    if(dados === null){
      await HistoricoDeJogoApi(); }

    else if(  dados  ) { 
      if(dados.code === 1099){ // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }
      let inicio = (dados.paginaAtual - 1) * 10;
      let final = inicio + 10;
      dados.result = dados.result.slice(inicio, final);
      setResult(dados);
    }
    
  }

  async function HistoricoDeJogoApi() {
    var myHeaders = new Headers();

    var raw = "";
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${prefixoUrl}/centraldojogador/historicodejogos/crash/1000?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        dados.paginas = parseInt(dados.totalRegistro / 10);

        if (dados.totalRegistro % 10 > 0) {
          dados.paginas = dados.paginas + 1;
        }

        localStorage.setItem("historicoDeJogo", JSON.stringify(dados));
        setResult(dados);

        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    HistoricoDeJogo();
  }, []);

  return (
  <TemplateCentralDoJogador title="histórico de jogo">
      <main className="w-full">
     
          
   

      {result != undefined ? (
        result.result.map((item,index) => {
          return (
            <article key={index} className="w-full ">
              <div className="flex  w-full lg:h-[67px] lg:items-center bg-[#121111] border-b border-[#2b2828] ">
                <div className="w-3/12 lg:w-1/12">
                  <img src={cyber} alt="logo" className="w-8/12 lg:w-5/12" />
                </div>

                <div className="text-white pt-2 lg:pt-0 font-bold flex flex-col lg:flex-row w-5/12 lg:w-6/12 ">
                  <div className="lg:w-6/12 w-full">
                    <p
                      className={
                        item.statusPartida == "Vitória"
                          ? `text-verdeprimary uppercase`
                          : `text-red-700 uppercase`
                      }
                    >
                      {item.statusPartida}
                    </p>
                    <p className="lg:text-sm text-[0.6em]  font-normal">MULTIPLICADOR</p>
                    <p className="relative top-[-5px]">{item.multiplicador}x</p>
                  </div>

                  <div className="lg:w-6/12 w-full">
                    <p className="mb-1 text-[0.6em] lg:text-base">QUANTIA</p>
                    <p className="lg:text-3xl text-xl leading-3 flex items-center">
                      {item.quantia}
                      <span className="text-[10px] font-normal ml-2 ">BRL</span>
                    </p>
                  </div>

                </div>

                <div className="lg:w-5/12 w-4/12 mr-3 pt-3 lg:pt-0 text-white  lg:flex lg:flex-row-reverse ">
                 
                    <div className="lg:w-6/12 w-full flex justify-end">
                      <p className="">
                        {item.data}
                      </p>
                    </div>

                    {/*  */}
                    <div className="lg:w-6/12 mt-3 lg:mt-0  w-full flex flex-col items-end">
                      <span className="">LUCRO</span>
                      <p
                        className={
                          item.statusPartida == "Vitória"
                            ? `text-verdeprimary font-bold text-2xl leading-3 flex items-center justify-end  `
                            : `text-red-700 text-2xl font-bold leading-3 flex items-center justify-end  `
                        }
                      >
                        <span className="text-[10px] font-normal  text-white">
                          BRL
                        </span>
                        {item.statusPartida == "Vitória" &&(
                         <> {item.lucro} </>
                        )}
                        {item.statusPartida == "Derrota" &&(
                         <div className="ml-2"> -{item.lucro} </div>
                        )}
                     
                      </p>
                    </div>
                  
                </div>
              </div>
            </article>
          );
        })
      ) : (
        <Loading />
      )}

{result != undefined && result.paginas > 1 ?(
      <div className="flex justify-center items-center py-5 mb-[50px] ">
        <button
          onClick={PaginacaoPrevius}
          className="uppercase bg-white text-[#1F2327]   hover:bg-[#1f9114] hover:text-white  font-bold px-8 rounded-full h-8 flex items-center gap-2  scale-90 hover:scale-100 duration-500"
        >
          Previus
        </button>

        <div className="flex items-center px-2">
          <p className="text-md  text-white">
            {result != undefined ? result.paginaAtual - 1 : ""}
          </p>
          <p className="text-3xl px-4 font-bold text-white">
            {result != undefined ? result.paginaAtual : ""}
          </p>
          <p className="text-md  text-white">
            {result != undefined && result.paginas > result.paginaAtual
              ? result.paginaAtual + 1
              : ""}
          </p>
          <p
            onClick={PaginacaoLast}
            className="text-md pl-4  text-white cursor-pointer "
          >
            {result != undefined ? `...${result.paginas}` : " "}
          </p>
        </div>

        <button
          onClick={PaginacaoNext}
          className="uppercase bg-[#1BFF07] text-[#1F2327]   hover:bg-[#1f9114] hover:text-white  font-bold px-8 rounded-full h-8 flex items-center gap-2  scale-90 hover:scale-100 duration-500"
        >
          NEXT
        </button>
      </div>
         ):""}
     
    
    </main>
  </TemplateCentralDoJogador>
  );
}

export default HistoricoDeJogo;
