
import { Link } from 'react-router-dom'
import {useState} from 'react'


export default function MenuIndicacao(){
    const [location] = useState(window.location.href)
    return(
        <Link to="/centraldojogador/indicacao">
        <div className={location.indexOf("indicacao") > -1 ? `border-r-4 border-r-verdeprimary hover:text-verdeprimary hover:bg-cinzasecondary bg-cinzathird p-4 cursor-pointer` : `p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary` }  >
          <p className="text-white text-xl">Indicações</p>
        </div>
        </Link>

    )
}