import RoutesApp from "./routes";
import Loading from "./components/Loading";
import useRender from "./hooks/useRender";
import FooterMenu from "./components/Footer/footerMenu";
import Atalhos from "./components/crash/atalhos";
import { useEffect, useState, useRef } from 'react'
import ScrollDetector from './components/ScrollDetector/ScrollDetector.js';
import Manutencao from "./components/Manutencao";



function App() {
  const { loading, signed } = useRender();


  function openLogin() {
    try{
      document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
      document.querySelector(".login__modal").classList.toggle("opacity-0");
      document.querySelector(".login--opacidade").classList.toggle("hidden");
      document.getElementById("login").focus();

    }catch(e){
      console.log(e);
    }
   
  }
  useEffect(() => {


    let cookie = document.cookie;
    if (!cookie) {
      document.getElementById("cookiesTerms").classList.toggle("hidden");

    }

    if (!localStorage.user_token) {
      openLogin()
    }

  }, [])





  function AcceptCookies() {
    document.cookie = "username=; expires=Thu, 01 Jan 2023 00:00:00 UTC; path=/;";
    document.getElementById("cookiesTerms").classList.toggle("hidden");
  }


  return (
    <>
      {loading === true ? <Loading /> : ""}


      <ScrollDetector>
        <RoutesApp>
          <Atalhos />
        </RoutesApp>
      </ScrollDetector>
    



   
      <div id="cookiesTerms" className="hidden z-[12355] ">
        <div className=" text-sm fixed bottom-0 left-0 right-0 bg-[#eeeded] p-3 lg:flex  justify-between items-center z-[12346]">
          <div>
            <p className="text-cinzafourth font-bold whitespace-nowrap">A Rockeetz utiliza cookies</p>
            <p className="text-[12px] mt-2 lg:mt-0">Nós utilizamos cookies para oferecer um serviço melhor e mais personalizado. Para mais informações, consulte a nossa <b className="cursor-pointer"> <a href="/regras#politicaCookies">  Política de Cookies. </a></b> </p>
          </div>
          <div className=" lg:mr-24 lg:w-1/12 w-full mt-1 lg:mt-0   ">
            <button onClick={AcceptCookies} className="bg-[#279c54] hover:bg-[#32d06f] duration-700  text-white text-[15px] lg:px-3 px-8 w-full  py-2 font-semibold " >Aceitar</button>

          </div>
        </div>

      </div>


    </>
  );
}

export default App;
