
import { Link } from 'react-router-dom'
import {useState} from 'react'


export default function SubMenuPrivacidade(){
    const [location] = useState(window.location.href)

    function DropSubMenuPrivacidadeDesktop() {
        document
          .getElementById("subMenuPrivacidadeDesktop")
          .classList.toggle("hidden");
        document.getElementById("subMenuAjudaDesktop").classList.add("hidden");
        document.getElementById("subMenuHistoricoDesktop").classList.add("hidden");
      }

    return(
      <>
       {location.indexOf("excluirconta") > -1 ? ( <div className="border-t-2 border-[#221f1f] p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary" >
            <p className="text-white text-xl">Privacidade e Segurança</p>
          </div>):  <div onClick={DropSubMenuPrivacidadeDesktop} className="border-t-2 border-[#221f1f] p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary" >
            <p className="text-white text-xl">Privacidade e Segurança</p>
          </div>}

          <div id="subMenuPrivacidadeDesktop" className="bg-[#181717] text-white p-4 hidden "  >
            <Link to="/centraldojogador/excluirconta">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"  >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Excluir Conta
                </p>
              </div>
            </Link>
          </div>
          {location.indexOf("excluirconta") > -1 && (
            <div id="subMenuPrivacidadeDesktop" className="bg-[#181717] text-white p-4  "  >
            <Link to="/centraldojogador/excluirconta">
              <div className="hover:bg-[#131212] border-r-2 bg-cinzathird border-verdeprimary  duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"  >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Excluir Conta
                </p>
              </div>
            </Link>
          </div>
          )}
      </>
    )
}