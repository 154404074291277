export default function RegrasPoliticas() {
    return (
        <>
            <div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Política de Privacidade</div>
                </div>
                <div className="text-white font-bold">1. PRIVACIDADE</div>

                rockeetz.com está empenhada em proteger suas informações pessoais. Esta Política de Privacidade permite que você saiba quais informações coletamos quando você usa nossos serviços, por que coletamos essas informações e como usamos as informações coletadas.
                Observe que esta Política de Privacidade será acordada entre você e rockeetz.com ('Nós', 'Nos' ou 'Nosso', conforme o caso). Esta Política de Privacidade é parte integrante dos Termos e Condições do rockeetz.com.
                O site rockeetz.com ("Casino", "Website", "Empresa", "Nós", "Nós", "Nosso") é de propriedade e operado pela LAM Gaming Group B.V., uma empresa registrada de acordo com Curaçao Law, registro No. 163826, Endereço: Abraham de Veerstraat 9, Willemstad, Curaçao. rockeetz.com é licenciado e regulamentado pela Curaçao Gaming Authority e opera sob a Licença No. 365/JAZ emitida para Gaming Services Provider, autorizado e regulamentado pelo Governo de Curaçao.
                Podemos fazer alterações periódicas nesta Política de Privacidade e notificaremos você sobre essas alterações publicando os termos modificados em nossas plataformas. Recomendamos que você revise esta Política de Privacidade regularmente.
                <br />

                <div className="text-white font-bold mt-3">2. INFORMAÇÕES COLETADAS</div>


                Consideramos informações que podem ser usadas para identificar um indivíduo, incluindo, mas não limitado a nome e sobrenome, data de nascimento, residência ou outro endereço físico, endereço de e-mail, número de telefone ou outras informações relevantes como Informações Pessoais ('Pessoal Informação'). Você pode ser solicitado a fornecer informações pessoais ao usar nosso site, registrar uma conta ou usar nossos serviços. As Informações Pessoais que coletamos podem incluir informações como: informações de contato (incluindo número de telefone), informações de remessa, informações de cobrança, histórico de transações, preferências de uso do site e feedback sobre os Serviços. Essas informações são mantidas por nós em servidores baseados na Alemanha e em outros lugares de tempos em tempos. Quando você interage com os serviços, nossos servidores mantêm um registro de atividades exclusivo para você que coleta certas informações administrativas e de tráfego, incluindo: endereço IP de origem, hora de acesso, data de acesso, página(s) da web visitada, uso do idioma, relatórios de falhas de software e tipo de navegador usado. Esta informação é essencial para a prestação e qualidade dos nossos serviços. Não coletamos informações pessoais sobre você sem o seu conhecimento.


                <div className="text-white font-bold mt-3">3. MEIOS DE COLETA E TRATAMENTO DE DADOS</div>
                Podemos coletar automaticamente certos dados conforme discutido acima e receber informações pessoais sobre você quando você fornecer essas informações por meio dos serviços ou outras comunicações e interações no site www.rockeetz.com. Também podemos receber informações pessoais de fornecedores e provedores de serviços on-line e de listas de clientes legalmente adquiridas de fornecedores terceirizados. Além disso, podemos contratar os serviços de provedores de serviços terceirizados para fornecer suporte técnico, processar suas transações online e manter sua conta. Teremos acesso a qualquer informação que você fornecer a tais fornecedores, prestadores de serviços e serviços de comércio eletrônico de terceiros, e usaremos as Informações Pessoais conforme estabelecido nesta Política de Privacidade abaixo. Esta informação só será divulgada a terceiros fora da empresa de acordo com esta Política de Privacidade. Tomamos medidas para garantir que nossos acordos com provedores de serviços terceirizados e fornecedores on-line protejam sua privacidade.

                <div className="text-white font-bold mt-3">4. USO DA INFORMAÇÃO</div>
                Usamos as informações pessoais que coletamos de você para fornecer nossos serviços, fornecer suporte ao cliente, realizar verificações de segurança e identificação necessárias, processar qualquer uma de suas transações on-line, auxiliar sua participação em promoções de terceiros, atender a certos requisitos comerciais e para qualquer outra finalidade relacionada à operação dos Serviços. Como tal, podemos compartilhar suas informações pessoais com nossos parceiros cuidadosamente selecionados (incluindo quaisquer outras partes que tenham acordos de compartilhamento de dados com o último).
                Suas informações pessoais também podem ser usadas por nós para fornecer a você: (1) ofertas promocionais e informações sobre nossos produtos e serviços; e (2) ofertas promocionais e informações sobre produtos e serviços de nossos parceiros, para ampliar a gama de produtos fornecidos e melhorar nosso atendimento ao cliente. Ocasionalmente, podemos solicitar informações suas por meio de pesquisas ou concursos. A participação nessas pesquisas ou concursos é totalmente voluntária e você tem a opção de divulgar essas informações. As informações solicitadas podem incluir informações de contato (como nome, endereço para correspondência e número de telefone) e informações demográficas (como CEP ou código postal ou idade). Ao aceitar qualquer prêmio de concurso ou ganhos de nós, você concorda em usar seu nome para fins publicitários e promocionais sem compensação adicional, exceto se for proibido por lei. A menos que você tenha optado por não receber informações promocionais, também podemos usar suas informações pessoais (incluindo seu endereço de e-mail e número de telefone) para fornecer informações sobre nossos produtos, serviços e promoções, incluindo outros produtos de jogos (incluindo pôquer online, cassino, apostas, gamão) e produtos e serviços de terceiros cuidadosamente selecionados por nós.

                <div className="text-white font-bold mt-3">5. CERTAS DIVULGAÇÕES EXCLUÍDAS</div>
                Podemos divulgar suas Informações Pessoais se exigido por lei, ou se acreditarmos de boa fé que tal ação é necessária para: (1) cumprir qualquer processo legal apresentado a nós, qualquer um de nossos sites ou serviços ou em circunstâncias quando estivermos sob uma obrigação legal substancialmente semelhante; (2) proteger e defender nossos direitos ou propriedade; ou (3) agir para proteger a segurança pessoal dos usuários dos serviços ou do público. Se, de acordo com nossa determinação exclusiva, for descoberto que você enganou ou tentou fraudar a nós, à empresa ou a qualquer outro usuário dos serviços de qualquer forma, incluindo, entre outros, manipulação de jogo ou fraude de pagamento, ou se suspeitarmos de fraude pagamento, incluindo o uso de cartões de crédito roubados ou qualquer outra atividade fraudulenta (incluindo qualquer estorno ou outro estorno de pagamento) ou transação proibida (incluindo lavagem de dinheiro), reservamo-nos o direito de compartilhar essas informações (juntamente com sua identidade) com outros sites de jogos online, bancos, empresas de cartão de crédito, agências apropriadas e autoridades relevantes. (4) Para efeitos de investigação sobre a prevenção da toxicodependência, os dados podem ser tornados anónimos e transmitidos às respetivas instituições.

                <div className="text-white font-bold mt-3">6. ACESSO</div>

                Você pode 'desativar' o recebimento de qualquer comunicação promocional, optando por não participar nas configurações de sua conta disponíveis em nossos sites ou serviços ou em um e-mail que receber de nós, ou a qualquer momento, enviando um e-mail ou escrevendo para nós no Atendimento ao Cliente.
                Além disso, você pode entrar em contato conosco se:
                <br /> 1) quiser confirmar a exatidão das informações pessoais que coletamos sobre você;
                <br /> 2) gostaria de atualizar suas informações pessoais; e/ou
                <br /> 3) tiver qualquer reclamação sobre o uso que fazemos de suas informações pessoais. Se solicitado, iremos (1) atualizar qualquer informação que você nos forneceu caso você prove a necessidade de tais alterações ou (2) marcar qualquer informação para proibir o uso futuro para fins de marketing. Para evitar dúvidas, nada nesta Política de Privacidade nos impedirá de reter suas Informações Pessoais quando formos obrigados a fazê-lo por lei.



                <div className="text-white font-bold mt-3">7. COOKIES</div>
                <div className="text-white font-bold mt-3">Informações colocadas no seu dispositivo.</div>
                Ao acessar nossos serviços, podemos armazenar informações em seu dispositivo. Essas informações são chamadas de cookies, que são pequenos arquivos de texto armazenados em seu dispositivo quando você visita páginas online que registram suas preferências. Também usamos Objetos Compartilhados Locais ou 'flash cookies'. 'Flash cookies' são como cookies de navegador. Eles nos permitem lembrar coisas sobre suas visitas em nossos sites. Nem os cookies nem os flashs cookies podem ser usados para acessar ou usar outras informações em seu computador. Usamos esses métodos apenas para rastrear seu uso de nossos serviços. Os cookies ajudam-nos a monitorizar o tráfego do site, a melhorar os nossos serviços e a torná-lo mais fácil e/ou mais relevante para a sua utilização. Usamos flash cookies e cookies de terceiros para nos ajudar a mostrar anúncios mais relevantes e desejáveis.


                <div className="text-white font-bold mt-3">Cookies estritamente necessários</div>
                Os cookies estritamente necessários são essenciais para permitir que um usuário navegue em um site e use seus recursos, como acessar áreas seguras do site ou fazer transações financeiras. Sem esses cookies, não poderíamos fazer nossos sites funcionarem com eficiência.

                <div className="text-white font-bold mt-3">Durante o processo de registro</div>
                Esses cookies manterão as informações coletadas durante o seu registro e nos permitirão reconhecê-lo como cliente e fornecer os serviços de que você precisa. Também podemos usar esses dados para entender melhor seus interesses enquanto estiver online e para aprimorar suas visitas às nossas plataformas.
                <div className="text-white font-bold mt-3">Em nosso site</div>
                Para os visitantes do nosso site, usamos cookies para coletar informações. Nossos servidores usam três tipos diferentes de cookies: <br /><br />
                Um cookie 'baseado em sessão': Este tipo de cookie é alocado apenas para o seu computador durante a sua visita ao nosso site. Um cookie baseado em sessão ajuda você a navegar em nosso site mais rapidamente e, se você for um cliente registrado, nos permite fornecer informações mais relevantes para você. Este cookie expira automaticamente quando você fecha o navegador. <br /><br />
                Um cookie 'persistente': Este tipo de cookie permanecerá no seu computador por um período definido para cada cookie. Flash cookies também são persistentes.
                ' analíticos': Este tipo de cookie permite-nos reconhecer e contar o número de visitantes do nosso site e ver como os visitantes utilizam os nossos serviços. Isso nos ajuda a melhorar a maneira como nossos sites funcionam, por exemplo, garantindo que você encontre facilmente o que procura.<br /><br />
                Você pode aceitar ou recusar cookies. A maioria dos navegadores da web aceita cookies automaticamente, mas, se preferir, geralmente você pode modificar a configuração do seu navegador para recusar cookies. O menu Ajuda na barra de menus da maioria dos navegadores informa como impedir que seu navegador aceite novos cookies, como fazer com que o navegador o notifique quando você receber um novo cookie e como desabilitar os cookies completamente.

                <div className="text-white font-bold mt-3">Flash cookies</div>
                Você pode modificar as configurações do Flash Player para impedir o uso de flash cookies. O Gerenciador de configurações do seu Flash Player permite que você gerencie suas preferências. Para proibir flash cookies de todo o conteúdo de terceiros, vá para o painel 'Configurações de armazenamento global' do Gerenciador de configurações e desmarque a caixa de seleção 'Permitir que conteúdo flash de terceiros armazene informações em seu computador' e feche o Gerenciador de configurações. Como alternativa, você também pode ajustar suas configurações para sites específicos que visita por meio do painel 'Configurações de armazenamento do site', que também pode ser encontrado no Gerenciador de configurações.
                <br /><br />

                Se você estiver usando uma versão antiga do Flash Player ou um navegador da Web mais antigo, o Gerenciador de configurações pode não estar disponível para você. Recomendamos que você atualize seu Flash Player e navegador para as versões mais recentes disponíveis.

                <br /><br />

                Se você optar por recusar os cookies, talvez não consiga experimentar todos os recursos interativos em nossos sites.


                <div className="text-white font-bold mt-3">8. CONSENTIMENTO DE USO DE PRESTADORES DE SERVIÇOS ELETRÔNICOS</div>

                Para jogar jogos com dinheiro real em nossos serviços, você deverá enviar e receber dinheiro de nós. Podemos usar sistemas de pagamento eletrônico de terceiros para processar essas transações financeiras. Ao aceitar esta Política de Privacidade, você concorda expressamente com as Informações Pessoais necessárias para o processamento de transações, incluindo, quando necessário, a transferência de informações para fora do seu país. Tomamos medidas para garantir que nossos acordos com sistemas de pagamentos protejam sua privacidade.

                <div className="text-white font-bold mt-3">9. CONSENTIMENTO PARA ANÁLISE DE SEGURANÇA</div>
                Reservamo-nos o direito de realizar uma revisão de segurança a qualquer momento para validar os dados de registro fornecidos por você e verificar seu uso dos serviços e suas transações financeiras quanto a possível violação de nossos Termos e Condições e da lei aplicável. Ao usar nossos serviços e, assim, concordar com nossos Termos e Condições, você nos autoriza a usar suas informações pessoais e a divulgar suas informações pessoais a terceiros para fins de validação das informações que você fornece durante o uso de nossos serviços, incluindo, quando necessário, a transferência de informações para fora do seu país. As análises de segurança podem incluir, mas não estão limitadas a solicitar um relatório de crédito e/ou verificar as informações fornecidas em bancos de dados de terceiros. Além disso, para facilitar essas análises de segurança, você concorda em fornecer as informações ou documentação que solicitarmos.
                <div className="text-white font-bold mt-3">10. SEGURANÇA</div>
                Entendemos a importância da segurança e as técnicas necessárias para proteger as informações. Armazenamos todas as informações pessoais que recebemos diretamente de você em um banco de dados criptografado e protegido por senha que reside em nossa rede segura por trás de um software de firewall de última geração. (Nossos serviços suportam SSL versão 3 com criptografia de 128 bits). Também tomamos medidas para garantir que nossas subsidiárias, agentes, afiliados e fornecedores empreguem medidas de segurança adequadas.
                <div className="text-white font-bold mt-3">11. PROTEÇÃO DE MENORES</div>
                Nossos Serviços não se destinam ou são direcionados a pessoas menores de dezoito (18) anos (ou a idade legal em sua respectiva jurisdição). Qualquer pessoa que nos fornecer suas informações por meio de qualquer parte dos serviços significa para nós que tem dezoito (18) anos de idade (ou a idade legal em sua respectiva jurisdição) ou mais. É nossa política descobrir tentativas de menores de acessar nossos serviços, o que pode envolver a necessidade de iniciar uma análise de segurança. Se tomarmos conhecimento de que um menor tentou ou enviou informações pessoais por meio de nossos serviços, não aceitaremos suas informações e tomaremos medidas para eliminar as informações de nossos registros.
                <div className="text-white font-bold mt-3">12. TRANSFERÊNCIAS INTERNACIONAIS</div>
                As Informações Pessoais coletadas nos serviços podem ser armazenadas e processadas em qualquer país no qual nós ou nossas afiliadas, fornecedores ou agentes mantemos instalações. Ao usar nossos serviços, você concorda expressamente com qualquer transferência de informações para fora do seu país (incluindo para países que podem não ter leis de privacidade adequadas). No entanto, tomamos medidas para garantir que nossos agentes, afiliados e fornecedores cumpram nossos padrões de privacidade, independentemente de sua localização.
                <div className="text-white font-bold mt-3">13. PRÁTICAS DE TERCEIROS</div>
                Não podemos garantir a proteção de qualquer informação que você forneça a um site on-line de terceiros com links para ou a partir dos serviços ou qualquer informação coletada por terceiros que administrem nosso programa de afiliados (se aplicável) ou qualquer outro programa, uma vez que esses terceiros sites online de terceiros são de nossa propriedade e operados independentemente. Qualquer informação coletada por esses terceiros é regida pela política de privacidade, se houver, de tal terceiro.
                <div className="text-white font-bold mt-3">14. AVISO LEGAL</div>
                Os Serviços operam 'COMO ESTÃO' e 'COMO DISPONÍVEIS' sem responsabilidade de qualquer tipo. Não somos responsáveis por eventos além do nosso controle direto. Devido à natureza complexa e em constante mudança de nossa tecnologia e negócios, não podemos garantir, nem afirmamos que haverá desempenho livre de erros em relação à privacidade de suas informações pessoais, e não seremos responsáveis por quaisquer danos indiretos, incidentais, consequenciais ou punitivos relacionados ao uso ou divulgação de tais Informações Pessoais.
                <div className="text-white font-bold mt-3">15. CONSENTIMENTO COM A POLÍTICA DE PRIVACIDADE</div>
                Os Serviços operam 'COMO ESTÃO' e 'COMO DISPONÍVEIS' sem responsabilidade de qualquer tipo. Não somos responsáveis por eventos além do nosso controle direto. Devido à natureza complexa e em constante mudança de nossa tecnologia e negócios, não podemos garantir, nem afirmamos que haverá desempenho livre de erros em relação à privacidade de suas informações pessoais, e não seremos responsáveis por quaisquer danos indiretos, incidentais, consequenciais ou punitivos relacionados ao uso ou divulgação de tais Informações Pessoais.
                <div className="text-white font-bold mt-3">16. OUTROS SITES DA WEB</div>
                Nosso site pode conter links para outros sites, que estão fora de nosso controle e não são cobertos por esta Política de Privacidade. Se você acessar outros sites usando os links fornecidos, os operadores desses sites poderão coletar informações sobre você, que serão usadas por eles de acordo com sua política de privacidade, que pode ser diferente da nossa. Nós não somos responsáveis. Somente os operadores desses sites serão responsáveis por sua funcionalidade ou possíveis erros nos sites vinculados.
                <br />
                17. Quando você joga slots de cassino desenvolvidos por PG Soft.


            </div>


        </>
    )
}