import { Link } from "react-router-dom"



export default function ButtonSubMenu(props) {
    return (
        <>
            {props.render == true && props.new ? (<>
            
                <div className="flex items-center  justify-between Orbitron uppercase  hover:bg-verdeprimary bg-cinzasecondary rounded-md  px-1 py-0.5  ">
                <a href={props.link} className="  text-[0.6em] hover:text-cinzathird  ">{props.tittle}</a>
                <div className="text-[8px] animate-pulse bg-red-800 rounded-lg px-1 tracking-widest  ">new!</div>
                </div>

            </>
            ) : (
                <a className="" href={props.link} >
                    <li className="uppercase hover:bg-verdeprimary bg-cinzasecondary rounded-md py-0.5 px-1  hover:text-cinzathird text-[0.6em] Orbitron">{props.tittle}</li>
                </a>
            )}




        </>
    )
}


/*
<a href={props.link} className=" relative flex items-center justify-between uppercase hover:bg-verdeprimary bg-cinzasecondary rounded-md py-0.5 px-1  hover:text-cinzathird text-[0.6em] Orbitron">{props.tittle}
<div className="  ">
    <p className="text-[8px] font-['Orbitron'] h-[5px] text-white font-semibold  whitespace-nowrap uppercase animate-pulse bg-red-600 rounded-full px-2 "  >new!</p>
</div>
</a>

*/
