import Tudo2 from "../../assets/icons/tudo2.svg";
import useRender from "../../hooks/useRender";
import NoCheckedComponent from "./Pol/components/NoCheckedComponent";
import CheckedComponent from "./Pol/components/CheckedComponent";
import NullCheckComponent from "./Pol/components/nullCheckedComponent";


function SteakPainel(props) {
  const {  diasArray } = useRender();


  return (
     
      <>
        <div className="w-full bg-[#4242423a]  p-2  ">
          <div className="flex mt-[-5px] justify-center items-center gap-4">
            <img id="tudo2Svg" className="h-4 w-3  " src={Tudo2} alt="" />
            <p
              id="titleSteak"
              className="text-white  font-['Orbitron']  text-md"
            >
              Streak De Login
            </p>
          </div>

          <div className=" flex justify-around items-center space-x-[-20px] mt-[-20px]">
              
              {
                diasArray.map((dia,index) => {
                  if (dia.checked === false){
                  
                    return <NoCheckedComponent onClick={() => {props.falseOnClick(dia.name, dia.recompensa)}}  key={index}  text={dia.name.substring(0, 3)}></NoCheckedComponent>
                  }else if(dia.checked === true) {
                    return <CheckedComponent onClick={() => {props.checkOnClick(dia.name, dia.recompensa)}}  key={index} text={dia.name.substring(0, 3)}></CheckedComponent>
                  } else{
                    return <NullCheckComponent onClick={() => {props.nullOnClick(dia.name, dia.recompensa)}}   key={index} text={dia.name.substring(0, 3)}></NullCheckComponent>
                  }
                })
              }

          </div>
        </div>
      </>
    
  );
}

export default SteakPainel;
