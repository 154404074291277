export default function CarouselItemPg(props){

  

    return(
        <>
        <a href={props.link} className="relative hover:translate-y-[-5px] hover:scale-105 transition duration-500 cursor-pointer w-1/12 lg:1.5/12 mx-1 my-3 group bg-cinzafourth  rounded-xl  ">
            <div className="w-full">
                <img className="rounded-md" src={props.image} />
            </div>
        
        </a>
        </>
    )
}