import Play from '../../../../assets/slots/buttonPlay.png'
import React from 'react';
import useRender from '../../../../hooks/useRender';

const gameImages = {
    "Runner Jelly": require("../../../../assets/jogosOriginais/runnerjelly.png"),
    "Mines": require("../../../../assets/jogosOriginais/mines.png"),
    "Cyber Space": require("../../../../assets/jogosOriginais/cyberspace.png"),
    "Roullet Planet": require("../../../../assets/jogosOriginais/roulletplanet.png"),
    "Game Free": require("../../../../assets/jogosOriginais/gamefree.png"),
};

export default function CarouselItemPg(props) {

    const { openLogin } = useRender()

    

    const gameImage = gameImages[props.nome] || gameImages["default"];


    return (
        <a onClick={openLogin}  className="relative group">
            <img className="relative rounded-md w-[9em] h-[12em] lg:w-[17em] lg:h-[24em] group-hover:scale-100 scale-90 duration-500" src={gameImage} alt={props.nome} />

            {/* Div de informações para o hover */}
            <div className="absolute z-[1] top-0 w-full h-full bg-black bg-opacity-60 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-500">

                {/* Adicione qualquer informação adicional sobre o jogo aqui */}
                <div className="flex flex-col items-center w-full z-[1]  justify-between h-full py-5 lg:py-10">
                    <div className="text-white flex justify-center w-full text-sm font-semibold ">
                        <div className="w-full lg:block whitespace-normal hidden text-center bg-[#0000003d]">
                            {props.nome}
                        </div>
                    </div>
                    <div>
                        <img src={Play} alt="play" className="block w-10 lg:w-20 hover:scale-125 duration-500" />
                    </div>
                    <div className="hidden w-full justify-center relative lg:flex ">
                    <a className="text-white text-[0.6em]  font-semibold   p-1  relative lg:mt-8  duration-700"></a>
                    </div>




                </div>
            </div>
        </a>
    );
}