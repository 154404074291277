function ModalExcluirBonus(props) {

    
  function closeModalExcluirBonus() {
    document.getElementById("modalExcluirBonus").classList.toggle("hidden");
  }

    return (
        <>
            {/* MODAl EXCLUIR */}

            <div id="modalExcluirBonus" className="flex justify-center absolute top-0 bottom-0 left-0 right-0 items-center duration-1000 hidden">
                <div onClick={closeModalExcluirBonus} className="bg-black fixed top-0 bottom-0 left-0 right-0 opacity-75 "></div>

                <div className="z-[6]">
                    <div className=" bg-white w-[350px]  rounded-lg p-5 space-y-3">
                        <div className="text-black font-extrabold text-xl">
                            Você tem certeza que deseja desativar seu cartão bônus ?
                        </div>

                        <div className="text-[12px] font-bold text-black ">
                            se você desativar você perderá seu saldo de bônus atual
                        </div>

                        <div className="flex justify-between ">
                            <div onClick={closeModalExcluirBonus} className="bg-[#797474] hover:bg-[#BB0C0C] uppercase duration-700   cursor-pointer rounded-full text-white px-4 py-1 font-bold">
                                Cancelar
                            </div>

                            <div  onClick={(e) => props.excluirBonusApi(e)} idcupom="0" id="btnConfirmarIdCupom" className="bg-[#1AE270] uppercase hover:bg-[#11a04f] hover:text-white duration-700   cursor-pointer rounded-full text-[#373F38] px-4 py-1 font-bold">
                                Confirmar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalExcluirBonus