function ModalPadrao({conteudo, onClick, title, conteudoBtn, onClickCancelar}) {
    return (<>
        {/* Modal Container */}
        <div id="modalPadrao" className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center hidden">

            {/* Modal Background Overlay */}
            <div className="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-75"></div>

            {/* Modal Content */}
            <div className="absolute">
                <div className="grid content-center bg-white w-[350px] h-[190px] rounded-lg">

                    {/* Title */}
                    <h1 id="titulo" className="text-black font-extrabold text-2xl text-center mx-6">{title}</h1>

                    {/* Message */}
                    <div id="msg" className="text-[12px] font-bold text-black mx-6 mt-3">{conteudo}</div>

                    {/* Play Button */}
                    <div id="btnJogarCupom" className="flex justify-center mx-6 mt-4 hidden">
                       
                            <div className="bg-[#1AE270] hover:bg-[#11a04f] duration-700 cursor-pointer rounded-full text-[#373F38] px-4 py-1 font-bold">Jogar!</div>
                       
                    </div>

                    {/* Ok Button */}
                    <div id="btnOkCupom" className="flex justify-center mx-6 mt-4 hidden">
                        <div className="bg-[#1AE270] hover:bg-[#11a04f] duration-700 cursor-pointer rounded-full text-[#373F38] px-4 py-1 font-bold">Ok!</div>
                    </div>

                    {/* Deposit and Cancel Buttons */}
                    <div id="btnDepositarCupom" className="flex justify-between mx-6 mt-4 ">
                        <div onClick={onClickCancelar} className="bg-[#797474] hover:bg-red-600 duration-700 cursor-pointer rounded-full text-white px-4 py-1 font-bold">Cancelar</div>
                        <div onClick={onClick} className="bg-[#1AE270] hover:bg-[#11a04f] hover:text-white duration-700 cursor-pointer rounded-full text-[#373F38] px-4 py-1 font-bold">{conteudoBtn}</div>
                    </div>

                </div>
            </div>
        </div>

    </>);
}

export default ModalPadrao;