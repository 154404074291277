
import Close from "../../../../assets/icons/close.svg";
import PolygonOff from "../../../../assets/icons/polygonoff.svg";

function NoCheckComponent({ text, onClick  }) {
  return (
    <>
      <div onClick={onClick} className="cursor-pointer  relative flex justify-center items-center w-full ">
        <img className="h-[21px] w-[21px] absolute " src={PolygonOff} alt="" />
        <img className="h-[11px] w-[11px] absolute   " src={Close} alt="" />
        <p className="mt-12 uppercase text-white text-[11px]">{text}</p>
      </div>
    </>
  );
}

export default NoCheckComponent;
