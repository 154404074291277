import useRender from "../../hooks/useRender";
import Loading from "../Loading";
import { useState,useEffect } from "react";


export default function RoundRoullet(){

    const {prefixoUrl} = useRender()
    const [rodadas, setRodadas] = useState();

    function apiHistoricoDeRodadasRoleta() {
      
      var myHeaders = new Headers();
  
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
  
  fetch(`${prefixoUrl}/roleta/historicopartidas/50`, requestOptions)
    .then(response => response.text())
    .then(result => {
     
      setRodadas(JSON.parse(result))
      
    })
    .catch(error => console.log('error', error));
    }
  
    useEffect(()=>{
      apiHistoricoDeRodadasRoleta()
    },[])


    return(
        <>
        
        <div id="historicoRoleta" className="overflow-x-hidden flex items-center space-x-1 mr-2 flex-row-reverse  py-1">



       
               
        {rodadas != undefined ? rodadas.result.map((item, index) => {
          return (
            <div key={index} className="ml-1 mr-1">
            {item.cor == "branco" && (
                <div className="max-w-[100%] bg-white rounded-md w-8 h-auto">
                   
                 <img className="w-full " src="./img/roleta/astro.png" />
                </div>
            )}
              {item.cor == "azul" && (
                <div className="max-w-[100%] w-8 h-auto relative flex justify-center items-center">
                     <div className="absolute border-white border-2 text-[0.6em] font-bold bg-azulthird rounded-xl flex justify-center items-center w-[20px] h-[20px]">{item.cor_numero}</div>
                 <img className="w-full " src="./img/roleta/planet.png" />
                </div>
            )}
              {item.cor == "preto" && (
              <div className="max-w-[100%] w-8 h-auto relative flex justify-center items-center">
              <div className="absolute border-white border-2 text-[0.6em] font-bold bg-cinzafourth rounded-xl flex justify-center items-center w-[20px] h-[20px]">{item.cor_numero}</div>
          <img className="w-full " src="./img/roleta/planet-cinza.png" />
         </div>
            )}
          
            
            </div>
          )
         }) : <Loading/>}

                   
                    
 
                    

                </div>
        
        </>
    )
}