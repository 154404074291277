import { useEffect, useState } from "react";
import useRender from "../../../hooks/useRender";
import Swal from 'sweetalert2'
import VMasker from "vanilla-masker";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";
import { enterKey } from '../../../assets/function/function.js'
import Verify from '../../../assets/perfil/verify.png'
import { useLocation } from 'react-router-dom';
import MinhaConta from "./minhaConta";
import ModalValidarCel from "../../../components/modals/ModalValidarCel/ModalValidarCel";
import axios from "axios";


function Perfil() {
  const location = useLocation();
  const [user, setUser] = useState({});
  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { prefixoUrl, setTempo } = useRender();
  const [validando, setValidando] = useState(false);


  const dataString = user.dataNascimento;

  const data = new Date(dataString);
  const dia = data.getDate().toString().padStart(2, "0");
  const mes = (data.getMonth() + 1).toString().padStart(2, "0");
  const ano = data.getFullYear();

  const dataFormatada = `${dia}/${mes}/${ano}`;

  const [level, setLevel] = useState();

  function getLevel(params) {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${prefixoUrl}/user/level?token=${localStorage.user_token}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result.code === 1) {
          setLevel(result);
        }
      })
      .catch(error => console.log('error', error));
  }




  useEffect(() => {

    //sem o setTimeout não aplica o mask
    setTimeout(() => {
      var docMask = ['999.999.999-99'];
      var doc = document.getElementById('inputCadastro');

      VMasker(doc).maskPattern(docMask[0]);
    }, 500)

    let resultado = localStorage.getItem("user");
    resultado = JSON.parse(resultado);

    setUser(resultado.user);
    getLevel()

  }, []);

  function changePassword() {

    if (!password || !passwordConfirm) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Campo senha deve ser preenchido',
      })

      return
    }

    if (!passwordCurrent) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Campo senha deve ser preenchido',
      })
      return
    }

    if (password == passwordConfirm) {

      let formData = new FormData();
      let token = localStorage.getItem('user_token');
      formData.append('passwordCurrent', passwordCurrent);
      formData.append('passwordNew', password);
      formData.append('token', token);
      fetch(`${prefixoUrl}/user/changepassword`, {
        method: "POST",
        body: formData,
      })
        .then((resposta) => resposta.json())
        .then((json) => {

          if (json.code === 1) {

            Swal.fire(
              'Sucesso',
              json.msg,
              'success'
            )

            setValidando(false);

          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: json.msg,

            })

            setValidando(false);
          }
        });
    }
  }

  function handleePerfil() {
    document.getElementById("handleePerfil").classList.toggle("hidden")
    document.getElementById("handleeMinhaConta").classList.toggle("hidden")


  }

  function openModal() {  
    document.getElementById("btnVerificar").classList.remove("z-[99]");
    //document.getElementById("svgSetaVerify").classList.add("hidden");

    document.getElementById("modalValidarCel").classList.toggle("hidden");
    setTempo(60)
    SolicitarCodigoSMS()

  }


  useEffect(() => {
    // Se o estado existir e for verdadeiro, execute a função
    if (location.state?.fromVerifyButton) {
      handleePerfil();
    

    }
  }, [location]);



  function SolicitarCodigoSMS() {

    axios.get(`${prefixoUrl}/sms/enviar?token=${localStorage.user_token}`)
      .then((result) => {
        console.log("result.data:", result.data)

      })
      .catch((error) => { console.log(error) })

  }




  return (
    <TemplateCentralDoJogador title="informação da conta">
 
     

      <MinhaConta level={level} user={user} />



      <main id="handleePerfil" className="min-h-[100vh] hidden ">

        <div onClick={handleePerfil} className=" cursor-pointer p-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36 " fill="white" className="bi bi-arrow-bar-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M12.5 15a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5ZM10 8a.5.5 0 0 1-.5.5H3.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L3.707 7.5H9.5a.5.5 0 0 1 .5.5Z" />
          </svg>
        </div>


        <div className="mt-8 flex justify-center items-center">


          <p className="text-2xl lg:text-4xl uppercase text-verdeprimary font-semibold">{user.name}</p>

        </div>



        <div className="flex flex-col lg:flex-row justify-center items-center mt-10 gap-3 px">
          <div className="flex flex-col justify-center gap-3 p-4">
            <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">NOME</div>
            <input
              type="text"
              value={user.name}
              className="rounded-lg lg:w-[340px] w-[370px]   p-3 bg-roxofourth text-white uppercase font-bold text-kg outline-none border border-roxofourth"
            />
            <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">APELIDO</div>
            <input
              type="text"
              value={user.apelido}
              className="rounded-lg lg:w-[340px] w-[370px]   p-3 bg-roxofourth text-white uppercase font-bold text-kg outline-none border border-roxofourth"
            />
            <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">E-MAIL</div>
            <input
              type="text"
              value={user.email}
              className="rounded-lg lg:w-[340px] w-[370px]   p-3 bg-roxofourth text-white uppercase font-bold text-kg outline-none border border-roxofourth"
            />

            <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">CPF</div>
            <input
              type="text"
              id="inputCadastro"
              value={user.cpf}
              className="rounded-lg lg:w-[340px] w-[370px]   p-3 bg-roxofourth text-white uppercase font-bold text-kg outline-none border border-roxofourth "
            />

            <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">CELULAR</div>
            <div className="rounded-lg lg:w-[340px] w-[370px]   p-3 bg-roxofourth text-white uppercase font-bold text-kg outline-none border border-roxofourth flex items-center justify-center  ">
              <input
                type="text"
                value={user.cel}
                className=" bg-roxofourth "
              />


              {user.cel_valid == 0 ?
                (<div className="relative">
                  <button onClick={openModal} id="btnVerificar" className=" bg-verdeprimary animate-pulse z-[99]  border-green-500 hover:bg-green-800 text-black text-sm px-4 font-bold  uppercase border-t-3 border-2  hover:border-green-500 hover:text-white rounded-xl duration-700">
                    VERIFICAR
                  </button>

                  {location.state?.fromVerifyButton && (
                    <div id="svgSetaVerify" className=" animate-bounce duration-500 absolute -top-10 right-10">
                      <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="#3edc1d" class="bi bi-capslock rotate-180" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM14.346 8.5 8 1.731 1.654 8.5H4.5a1 1 0 0 1 1 1v1h5v-1a1 1 0 0 1 1-1h2.846zm-9.846 5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1zm6 0h-5v1h5v-1z" />
                      </svg>
                    </div>
                  )}


                </div>


                )


                : (<img src={Verify} alt="verificado" className="w-10 mx-auto" />

                )}




            </div>


          </div>




          <div className="justify-center flex flex-col gap-3  ">
            <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">DATA DE NASCIMENTO</div>
            <input
              type="text"
              value={dataFormatada}
              className="rounded-lg w-[370px]  p-3  bg-roxofourth text-white uppercase font-bold text-kg outline-none border border-roxofourth"
            />

            <div className="justify-center flex flex-col gap-3 border border-purple-600 rounded-xl p-4 bg-[#141414]">

              <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">SENHA ATUAL</div>
              <input
                type="password"
                placeholder="Senha Atual..."
                value={passwordCurrent}
                onChange={(value) => setPasswordCurrent(value.target.value)}
                className="rounded-lg w-[340px]  p-3 bg-[#131212] text-white text-kg outline-none border border-verdeprimary"
              />
              <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">SENHA NOVA</div>
              <input
                type="password"
                value={password}
                onChange={(value) => setPassword(value.target.value)}
                placeholder="Nova Senha..."
                className="rounded-lg w-[340px]  p-3 bg-[#131212] text-white text-kg outline-none border border-verdeprimary"
              />
              <div className="text-[0.5em] lg:text-[0.6em] h-[5px] text-white">CONFIRMAÇÃO DE SENHA</div>
              <input
                type="password"
                value={passwordConfirm}
                onChange={(value) => setPasswordConfirm(value.target.value)}
                placeholder="Repita nova senha..."
                onKeyPress={(e) => { enterKey(e, "alterarBtn") }}
                className="rounded-lg w-[340px]   p-3 bg-[#131212] text-white text-kg outline-none border border-verdeprimary"
              />

              <div className="flex justify-center ">
                <button id="alterarBtn" onClick={changePassword} className="disabled:opacity-50 bg-verdeprimary border-green-500 hover:bg-green-800 text-white font-bold py-1 px-24 uppercase border-t-3 border-2  hover:border-green-500 rounded-xl duration-700">
                  {validando && <svg role="status" className="inline mr-2 w-6 h-6 text-green-200 animate-spin dark:text-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                  </svg>}
                  {validando && 'validando...'}
                  {!validando && 'alterar senha'}
                </button>
              </div>

            </div>
          </div>


        </div>

      </main>

    </TemplateCentralDoJogador>

  );
}

export default Perfil;
