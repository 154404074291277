import Load from "../../assets/loading/LOADING.GIF";

function Loading() {
  return (
    <main className="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center bg-black z-[999]">
      <img src={Load} alt="" className="w-52" />
    </main>
  );
}

export default Loading;
