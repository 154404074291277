export default function JogoResponsavel(){
    return(
        <>

<div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Jogar com Responsabilidade</div>
                </div>
                <div className="text-white mt-5"> Última atualização: 1º de Junho de 2023</div>
              
               
                <br/><br/><br/>
Por favor, leia estas informações cuidadosamente para seu próprio benefício.
www.rockeetz.com é operado pela LAM Gaming Group B.V. com escritório em Abraham de Veerstraat 9, Willemstad, Curaçao. Número de registro da empresa 163826.

<br/>

<div className="text-white mt-5 font-bold">Interpretação e Definições</div>

<div className="text-white mt-5 font-semibold">Interpretação</div>
As palavras cuja letra inicial são maiúsculas têm significados definidos nas seguintes condições.
<br/>
As seguintes definições terão o mesmo significado independentemente de aparecerem no singular ou no plural.


<div className="text-white mt-5">Definições</div>
<div className="text-white mt-5">Para efeitos destes Termos e Condições:</div>

•	Conta significa uma conta exclusiva criada para você acessar nosso Serviço ou partes de nosso Serviço.<br/>
•	Empresa (referida como "a Empresa", "Nós", "Nos" ou "Nosso" neste Contrato) refere-se a Curaçao Co<br/>
•	Serviço refere-se ao site.<br/>
•	O site refere-se a www.rockeetz.com. <br/>
•	Você significa o indivíduo acessando ou usando o Serviço, ou a empresa ou outra pessoa jurídica em nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.
<br/>

<div className="text-white mt-5 font-bold">Jogo Responsável e Auto-Exclusão</div>
<div className="text-white mt-5 font-semibold">Jogo Responsável</div>
O jogo significa, para a maioria de nossos usuários, entretenimento, diversão e entusiasmo. Mas também sabemos que, para alguns de nossos usuários, o jogo tem efeitos colaterais negativos. Na ciência médica, o jogo patológico é reconhecido há muitos anos como uma doença grave.<br/><br/>
Desde o primeiro dia pensamos nessa problemática e tentamos o melhor para ajudar. Em “Jogo responsável” entendemos várias etapas de medidas, com as quais um provedor de jogos de azar pode ajudar a diminuir a possibilidade de aparecimento de efeitos colaterais negativos. -Caso eles já apareçam, também tentamos tomar medidas ativas contra eles.<br/><br/>
O instrumento mais importante contra os efeitos colaterais negativos do jogo é o conhecimento e a educação sobre os riscos do jogo, além de apoiar o autocontrole de nossos usuários para garantir que eles não sofram efeitos colaterais negativos.
<br/>

Nosso Suporte sempre irá ajudá-lo via e-mail sem nenhum custo adicional para você:
<br/> •	e-mail: datasecurity@rockeetz.com <br/><br/>
É claro que nosso suporte não fornecerá nenhuma informação sobre você sem o seu consentimento para mais ninguém.<br/><br/>
Além disso, você também pode fazer um autoteste, se já for viciado em jogos de azar em: <a className="text-green-300 " href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/">https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/</a><br/>
E você também pode encontrar informações adicionais sobre vícios de jogos de azar em: <a className="text-green-300 cursor-pointer" href="https://www.begambleaware.org/safer-gambling/">https://www.begambleaware.org/safer-gambling/</a> <br/>

<div className="text-white mt-5 font-semibold">Dicas úteis para jogo responsável na ROCKEETZ.COM</div>

<br/>
Recomendamos que você pense nas seguintes dicas antes de jogar para garantir que o jogo continue divertido para você e sem efeitos colaterais negativos:
<br/><br/>
•	Defina um limite de depósito para si mesmo.
Antes de começar a jogar, pense em quanto você pode gastar de acordo com sua situação financeira. Jogue com quantias que são para diversão e para o Seu entretenimento.
<br/><br/>
•	Não tente recuperar uma perda a todo custo.
Tente não correr grandes riscos para reconquistar o que você perdeu antes a qualquer custo. Jogue para entretenimento e não para ganhar dinheiro.
<br/><br/>
•	Defina um limite de tempo para si mesmo.
Defina um limite de tempo para si mesmo e não o quebre. Lembre-se de que o jogo deve estar em equilíbrio com seus outros hobbies e não ser seu único hobby.
<br/><br/>
•	Jogue de forma inteligente:
É mais inteligente não jogar quando você está extremamente estressado, deprimido ou sob muita pressão. Também não jogue quando estiver sob a influência de medicamentos, drogas ou álcool.
<br/><br/>
•	Faça pausas:
Você deve fazer pausas quando perceber que está cansado ou não consegue mais se concentrar.
<br/><br/>
•	Apenas uma conta:
Para facilitar a visualização de quanto tempo e dinheiro você gasta em jogos de azar, é altamente recomendável não criar mais de uma conta por pessoa.
<br/><br/>



Para usar nosso Serviço, você deve ter 18 anos ou mais. Para evitar abusos, mantenha seus dados de login salvos de qualquer menor próximo a você.
<br/><br/>
Recomendamos principalmente um programa de filtro para evitar que menores, principalmente crianças, acessem qualquer contexto da internet que não seja saudável para eles.
<br/><br/>
Para os pais podemos recomendar uma lista de filtros de internet, para apoiá-los, evitando que seus filhos fiquem longe de qualquer contexto, que não foi feito para eles:
<a  className="text-green-300" href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html">https://famisafe.wondershare.com/internet-filter/best-internet-filters.html</a>


            </div>


        </>
    )
}