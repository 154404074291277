import Madeira from '../../../assets/minhaConta/shield_iniciante.png'
import Bronze from '../../../assets/minhaConta/shield_bronze.png'
import Prata from '../../../assets/minhaConta/shield_silver.png'
import Ouro from '../../../assets/minhaConta/shield_gold.png'
import Platina from '../../../assets/minhaConta/shield_platina.png'
import Diamante from '../../../assets/minhaConta/shield_diamente.png'
import Mestre from '../../../assets/minhaConta/shield_mestre.png'
import Mitico from '../../../assets/minhaConta/shield_gloriamitica.png'
import ProgressBar from "../../../components/progressBar/progressBar";
import { useEffect, useState, useRef } from "react";




function CardRank({level, cardRef}) {




    return (

        <div ref={cardRef} id="card1" className="bg-[#302f2f] rounded-lg lg:min-w-[550px] min-w-[291px] mx-1 snap-start">

            <div className="border-b border-cinzafourth uppercase grid grid-cols-4 ">
                <h1 className=" text-[18px] p-1  col-span-1  text-center ml-4 ">
                    {level?.level == 0 ? "Madeira" : level?.level == 1 ? "Bronze" : level?.level == 2 ? "Prata" : level?.level == 3 ? "Ouro" : level?.level == 4 ? "Platina" : level?.level == 5 ? "Diamante" : level?.level == 6 ? "Mestre" : level?.level == 7 ? "Mitico" : "Madeira"}
                </h1>
            </div>

            <div className="grid grid-cols-4">
                <div className="flex flex-col items-center leading-3 lg:col-span-1">
                    <img src={level?.level == 0 ? Madeira : level?.level == 1 ? Bronze : level?.level == 2 ? Prata : level?.level == 3 ? Ouro : level?.level == 4 ? Platina : level?.level == 5 ? Diamante : level?.level == 6 ? Mestre : level?.level == 7 ? Mitico : Madeira} alt="Rank" className="lg:min-w-[130px] min-w-[90px] my-auto  ml-5" />
                </div>

                <div className="col-span-3 space-y-4 px-4 my-auto py-4 ">
                    <ProgressBar texto="depósito" porcent={level?.porcento_deposito} status={level?.depositos + `/` + level?.next_level_depositos} />

                    <ProgressBar texto="apostas em jogos originais" porcent={level?.porcento_originais} status={level?.aposta_originais + `/` + level?.next_level_apostasOriginais} />

                    <ProgressBar texto="apostas em slots" porcent={level?.porcento_slot} status={level?.aposta_slot + `/` + level?.next_level_apostasSlots} />

                </div>
            </div>

            <div className="flex justify-center items-center border-t border-cinzafourth w-10/12 mx-auto ">

                <div className=" grid lg:grid-cols-4 grid-cols-6 gap-4 p-5">

                    <div className="flex items-center justify-center col-span-6 lg:col-span-1">
                        <p className="uppercase text-[#AF53F6] text-lg">benêficios</p>
                    </div>

                    <div className="lg:col-span-2 col-span-4 mx-auto space-y-3 whitespace-nowrap  text-[10px] lg:text-md  ">
                        <p>Limite de Saque por dia</p>
                        <p className="hidden">Rodadas grátis no RunnerJelly</p>
                        <p>Streak de Login</p>
                    </div>

                    <div className="mx-auto  col-span-1 space-y-3 whitespace-nowrap  text-[10px] lg:text-md  ">
                        <p className="text-[#9FFF72]">BRL {level?.retirada_dia}</p>
                        <p className="hidden">2</p>
                        <p className={`uppercase ${level?.streak_login == false ? "text-red-500" : "text-verdeprimary"}`}>{level?.streak_login == false ? "Indisponível" : "Disponível"}</p>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default CardRank;