export default function JogoResponsavelEn() {
    return (
        <>

            <div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Responsible Gambling</div>
                </div>
                <div className="text-white mt-5">Last updated: June 1, 2023</div>

                <br /><br /><br />
                Please read this information carefully for your own benefit.
                www.rockeetz.com is operated by LAM Gaming Group B.V. with an office at Abraham de Veerstraat 9, Willemstad, Curaçao. Company registration number 163826.

                <br />


                <div className="text-white mt-5 font-bold">Interpretation and Definitions</div>
                <div className="text-white mt-5 font-semibold">Interpretation</div>
                Words with initial capital letters have defined meanings under the following conditions.
                <br />
                The following definitions will have the same meaning regardless of whether they appear in the singular or plural.
                <div className="text-white mt-5">Definitions</div>
                <div className="text-white mt-5">For the purposes of these Terms and Conditions:</div>
                • Account means a unique account created for you to access our Service or parts of our Service.<br />
                • Company (referred to as "the Company", "We", "Us" or "Our" in this Agreement) refers to Curaçao Co.<br />
                • Service refers to the website.<br />
                • The website refers to www.rockeetz.com.<br />
                • You mean the individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                <br />

                <div className="text-white mt-5 font-bold">Responsible Gaming and Self-Exclusion</div>
                <div className="text-white mt-5 font-semibold">Responsible Gaming</div>
                Gambling means, for most of our users, entertainment, fun, and excitement. But we also know that for some of our users, gambling has negative side effects. In medical science, pathological gambling has been recognized for many years as a serious disease.<br /><br />
                From day one, we have been thinking about this issue and trying our best to help. Under "Responsible Gaming," we understand various steps and measures with which a gambling provider can help reduce the possibility of negative side effects. If they do appear, we also try to take active measures against them.<br /><br />
                The most important tool against the negative side effects of gambling is knowledge and education about the risks of gambling, as well as supporting self-control of our users to ensure that they do not experience negative side effects.
                <br />
                Our Support will always assist you via email at no additional cost to you:
                <br /> • email: datasecurity@rockeetz.com <br /><br />
                Of course, our support will not provide any information about you to anyone else without your consent.<br /><br />
                Additionally, you can also take a self-test if you are already addicted to gambling at: <a className="text-green-300" href="https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/">https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/</a><br />
                And you can find additional information about gambling addiction at: <a className="text-green-300 cursor-pointer" href="https://www.begambleaware.org/safer-gambling/">https://www.begambleaware.org/safer-gambling/</a> <br />

                <div className="text-white mt-5 font-semibold">Helpful Tips for Responsible Gaming at ROCKEETZ.COM</div>

                <br />
                We recommend that you consider the following tips before gambling to ensure that the game remains enjoyable for you and without negative side effects:
                <br /><br />
                • Set a deposit limit for yourself.
                Before you start playing, think about how much you can afford to spend based on your financial situation. Play with amounts that are for fun and entertainment purposes.
                <br /><br />
                • Don't try to chase losses.
                Try not to take big risks to recover what you have lost before at any cost. Play for entertainment and not to make money.
                <br /><br />
                • Set a time limit for yourself.
                Set a time limit for yourself and stick to it. Remember that gambling should be in balance with your other hobbies and not be your only hobby.
                <br /><br />
                • Play smartly:
                It is smarter not to gamble when you are extremely stressed, depressed, or under a lot of pressure. Also, do not gamble when you are under the influence of medication, drugs, or alcohol.
                <br /><br />
                • Take breaks:
                You should take breaks when you notice that you are tired or can no longer concentrate.
                <br /><br />
                • Only one account:
                To easily track how much time and money you spend on gambling, it is highly recommended not to create more than one account per person.
                <br /><br />



                To use our Service, you must be 18 years or older. To prevent misuse, keep your login credentials saved away from any minors in your vicinity.
                <br /><br />
                We especially recommend implementing a filtering program to prevent minors, especially children, from accessing any internet content that is not suitable for them.
                <br /><br />
                For parents, we can recommend a list of internet filters to support them in keeping their children away from any content that is not intended for them: <a className="text-green-300" href="https://famisafe.wondershare.com/internet-filter/best-internet-filters.html">https://famisafe.wondershare.com/internet-filter/best-internet-filters.html</a>


            </div>


        </>
    )
}