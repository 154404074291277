
import Instagram from "../../../../assets/popup/instagram.png";
import Telegram from "../../../../assets/popup/telegram.png";
import indicacaoImg from "../../../../assets/popup/indicacao.webp";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { HashLink as Link } from "react-router-hash-link";
import useRender from "../../../../hooks/useRender";
import X from "../../../../assets/pagamento/x.png"


function ConteudoPopUp(props) {
  const [cupom] = useState("JELLY200");
  const [isNewsActive, setNewsActive] = useState(true);
  const [isRedesActive, setRedesActive] = useState(false);
  const [isBonusActive, setBonusActive] = useState(false);
  const [numberRandons, setNumbersRandons] = useState([]);
  const [isConteudo, setConteudo] = useState("news");
  const [contador, setContador] = useState({
    dias: 0,
    horas: 0,
    minutos: 0,
    segundos: 0,
  });
  const { prefixoUrl, updateSaldos, openLogin, closeLogin } = useRender();





  function closeCupom() {
    document.getElementById(props.openmodal).classList.toggle("hidden");
    openIndicacaoPP();
  }

  function openIndicacaoPP() {
    let method = document.getElementById("modalPopUp3")
    method.classList.toggle("hidden");
    let opacity = document.getElementById("popup--3")
    opacity.classList.add("opacity-0");

    setTimeout(() => {
        opacity.classList.toggle("opacity-0");

    }, 100);
  }

  function cpPromo() {
    let textoCopiado = document.getElementById("txtPromo");
    textoCopiado.select();
    navigator.clipboard.writeText(textoCopiado.value);
    Swal.fire("Sucesso", "Cupom copiado!", "success");
  }

 

 



  return (
    <>
      <div id="popup--2"  className="bg-[#170d30] bgpopup lg:w-4/12 w-11/12 border-2 border-[#2d2934] rounded-lg z-[6]  p-2 opacity-0 duration-700 relative pb-5 "   >
       
        <div className="w-full font-bold flex justify-center">
          <div className="flex justify-between w-11/12 border border-white p-2 rounded-md">
            <div           className={`bg-white lg:text-base text-[0.8em] flex justify-center items-center cursor-pointer mx-2 px-2 rounded-md`} >
              Redes Sociais
            </div>
           <div onClick={closeCupom} className="cursor-pointer text-white w-6">
             <img src={X}  />
            </div>
            
          </div>
        </div>

        <>
            <div className="min-h-[450px] lg:min-h-[550px]">
              <div className="p-2 text-white text-center pt-10 text-4xl w-full flex justify-center">
                Siga em nossas redes sociais
              </div>

              <a href="https://t.me/rockeetzoficial">
                <div className="duration-300 cursor-pointer scale-100 hover:scale-105">
                  <img src={Telegram} alt="" className="w-full" />
                </div>
              </a>

              <a href="https://instagram.com/rockeetz">
                <div className="duration-300 cursor-pointer scale-100 hover:scale-105">
                  <img src={Instagram} alt="" className="w-full" />
                </div>
              </a>
            </div>
          </>


      </div>
    </>
  );
}

export default ConteudoPopUp;
