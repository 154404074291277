import React, { useRef } from 'react';


function InputValidarConta({ value, onChange, type, placeHolder, maxLength, id, onClick, autoFocus }){




    return (
    <input  maxLength={maxLength} id={id} type={type} onClick={onClick} autoFocus={autoFocus}
            value={value} onChange={onChange} placeholder={placeHolder} 
            className={`bg-white drop-shadow-2xl rounded-lg py-2 outline-none pl-4 text-black font-semibold uppercase text-lg`} />
    )
}



export default InputValidarConta;