import FundoRegister from '../../../assets/cadastro/fundoregister.png'
import InputValidarConta from "../../../components/Input/InputCadastro/InputValidarConta.js";
import { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import useRender from '../../../hooks/useRender';



function ValidarConta(props) {

    const [usuario, setUsuario] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState("");


    const { prefixoUrl, tempo, setTempo, setLoading, setSigned } = useRender();
    const navigate = useNavigate();
    const [code1, setCode1] = useState("")
    const [code2, setCode2] = useState("")
    const [code3, setCode3] = useState("")
    const [code4, setCode4] = useState("")
    const [code5, setCode5] = useState("")
    const [validando, setValidando] = useState(false)
    const [validandoReenviar, setValidandoReenviar] = useState(false)
    const [validaConta, setValidaConta] = useState((V) => {
        let storage = localStorage.validar_conta
        if (storage) {
            V = storage
            return V
        }

        V = false
        return V
    })

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (tempo > 0) {
                setTempo(tempo => tempo - 1);

            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [tempo]);



    function ReenviarCodigo() {
        setValidandoReenviar(true)
        if (EnviarCodigoEmail()) {
            Swal.fire(
                'Sucesso',
                'Código enviado com sucesso, verifique seu e-mail',
                'success'
            ).then(() => {
                const input1 = document.getElementById(`inputRef1`);
                input1.focus();

            })
            setTempo(60);
            setCode1("")
            setCode2("")
            setCode3("")
            setCode4("")
            setCode5("")

        }

    }

    async function EnviarCodigoEmail() {
        let retorno_envio_email = null;
        let formData = new FormData();

        let apelido = localStorage.getItem("register_apelido");
        let email = localStorage.getItem("register_email");


        formData.append('name', apelido);
        formData.append('email', email);



        await fetch(`${prefixoUrl}/user/emailconfirmation`, {
            method: "POST",
            body: formData
        })
            .then((resposta) => resposta.json())
            .then((json) => {
                retorno_envio_email = json;
                console.log(json);
            }).catch(e => {
                console.log(e);
            })
            .finally(() => {
                setValidandoReenviar(false)
            })


        if (retorno_envio_email?.code != 1) {
            Swal.fire({
                icon: 'error',
                title: 'Opa...',
                text: retorno_envio_email.msg
            });
            setValidando(false);
            return false
        }

        return true
    }

    function formatarTempo() {
        let horas = Math.floor(tempo / 3600);
        let minutos = Math.floor((tempo - horas * 3600) / 60);
        let segundos = tempo % 60;
        return `${minutos.toString().padStart(2, '0')}:${segundos.toString().padStart(2, '0')}`;
    }


    const codigo = code1 + code2 + code3 + code4 + code5
    const index = codigo.length

    useEffect(() => {
        if (index > 0) {
            const input2 = document.getElementById(`inputRef2`);
            input2.focus();
        }
    }, [code1])

    useEffect(() => {
        if (index > 0) {
            const input3 = document.getElementById(`inputRef3`);
            input3.focus();
        }
    }, [code2])

    useEffect(() => {
        if (index > 0) {
            const input4 = document.getElementById(`inputRef4`);
            input4.focus();
        }
    }, [code3])

    useEffect(() => {
        if (index > 0) {
            const input5 = document.getElementById(`inputRef5`);
            input5.focus();
        }
    }, [code4])

    useEffect(() => {
        if (index == 5) {
            ValidarConta()
        }
    }, [code5])









    let telefone_tmp = localStorage.getItem("register_telefone");
    let nome_tmp = localStorage.getItem("register_nome");
    let sobrenome_tmp = localStorage.getItem("register_sobrenome");
    let apelido_tmp = localStorage.getItem("register_apelido");
    let cpf_tmp = localStorage.getItem("register_cpf");
    let idade_tmp = localStorage.getItem("register_dataNascimento");
    let email_tmp = localStorage.getItem("register_email");
    let password_tmp = localStorage.getItem("register_password");



    async function confirmStep() {
        setValidando(true);

        if (codigo.length < 5) {
            Swal.fire({
                icon: 'error',
                title: 'Opa...',
                text: 'Código não informado'

            })
            setValidando(false);
            return
        }


        if (!nome_tmp || !email_tmp || !apelido_tmp || !cpf_tmp || !password_tmp || !idade_tmp) {
            Swal.fire({
                icon: 'error',
                title: 'Opa...',
                text: "Erro, realizar cadastro novamente"
            });
            setValidando(false);
            return
        }

        let formData = new FormData();
        let linkIndicacao = localStorage.getItem('link_indicacao')

        formData.append('name', nome_tmp + ' ' + sobrenome_tmp);
        formData.append('email', email_tmp);
        formData.append('apelido', apelido_tmp);
        formData.append('cpf', cpf_tmp);
        formData.append('dataNascimento', idade_tmp);
        formData.append('password', password_tmp);
        formData.append('celular', telefone_tmp);



        if (linkIndicacao != undefined) {
            formData.append('link_indicacao', localStorage.getItem('link_indicacao'));
        } else {
            formData.append('link_indicacao', '');
        }

        fetch(`${prefixoUrl}/user/register`, {
            method: "POST",
            body: formData,
        })
            .then((resposta) => resposta.json())
            .then((json) => {
                if (json.code == 1) {
                    let userLogin = {};
                    userLogin.email = email_tmp;
                    userLogin.token = json.token;



                    localStorage.removeItem("register_nome");
                    localStorage.removeItem("register_sobrenome");
                    localStorage.removeItem("register_apelido");
                    localStorage.removeItem("register_cpf");
                    localStorage.removeItem("register_dia");
                    localStorage.removeItem("register_mes");
                    localStorage.removeItem("register_ano");
                    localStorage.removeItem("validar_conta");
                    localStorage.removeItem("register_dataNascimento");




                    localStorage.setItem("user_token", JSON.stringify(userLogin));
                    setValidando(false);
                    // window.location.href = '/home'; // recarrega a pagina

                    // document.getElementById("modalCriarConta").classList.toggle("hidden")
                    // document.getElementById("modalRegistro").classList.toggle("hidden")
                    //  methodPayment()


                    Login()
                    props.setSteps("step4")
                    props.setSubtitulo("deposite com ou sem bônus e divirta-se!")
                    props.setTitulo("BÔNUS BOAS-VINDAS!")


                    Swal.fire(
                        'Sucesso',
                        'Conta criada com sucesso!',
                        'success'
                    );

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Erro ao tentar criar o Usuário, tente novamente mais tarde'
                    })
                    setValidando(false);
                }
            });


        return true;

    }

    //   function methodPayment() {
    //        var method = document.getElementById("payment")
    //      method.classList.toggle("hidden");   


    //   }

    async function ValidarConta() {
        setValidando(true)
        if (codigo.length < 5) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Insira todos os caracteres do código enviado para seu e-mail!",

            })
            setValidando(false)
            return
        }


        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${prefixoUrl}/user/emailconfirmationcode/${codigo}`, requestOptions)
            .then(response => response.json())
            .then(result => {


                if (result.code == 1) {
                    localStorage.setItem("validar_conta", result.code);

                    if (email_tmp) {
                        setUsuario(email_tmp)
                        console.log("vamos", usuario)
                    }
                    if (password_tmp) {
                        setPassword(password_tmp)
                    }



                    confirmStep()
                    Swal.fire(
                        'Sucesso!',
                        result.msg,
                        'success'
                    )
                    return

                }
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: result.msg,

                })
                setCode1("")
                setCode2("")
                setCode3("")
                setCode4("")
                setCode5("")
                const input1 = document.getElementById(`inputRef1`);
                input1.focus();

            })
            .catch(error => console.log('error', error))
            .finally(() => {
                setValidando(false)
            })

    }


    function Login() {


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            login: localStorage.register_email,
            password: localStorage.register_password
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };


        fetch(`${prefixoUrl}/login`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                let retorno = result;

                if (result.code === 1) {
                    localStorage.setItem("user_token", retorno.token);
                    localStorage.setItem("user", JSON.stringify(retorno));
                    setSigned(true);

                    setTimeout(() => {
                        setLoading(false)
                    }, 1000)
                } else {
                    setError(retorno.msg);
                    setLoading(false)
                }
            })
            .catch((error) => console.log("error", error))
            .finally(() => { setLoading(false) })
    }



    return (<>




        <div className="w-10/12 ">
            <p className="text-verdeseven text-[12px] font-semibold ">
                Agradecemos por escolher nossa plataforma. Para garantir a segurança e a proteção dos nossos usuários, enviamos um código de ativação para o endereço de e-mail fornecido durante o processo de cadastro. Esse código é essencial para a ativação da sua conta na nossa plataforma.
            </p>
        </div>


        <div className="w-10/12 mx-auto space-y-2">
            <p className="uppercase mt-10  font-semibold text-white whitespace-nowrap text-[12px]">digite o código abaixo e ative sua conta</p>

            <div className="grid grid-cols-5 gap-4">

                <InputValidarConta type="text" maxLength={1} id={"inputRef1"} value={code1} onChange={(e) => { setCode1(e.target.value) }} autoFocus={true} />
                <InputValidarConta type="text" maxLength={1} id={"inputRef2"} value={code2} onChange={(e) => { setCode2(e.target.value) }} />
                <InputValidarConta type="text" maxLength={1} id={"inputRef3"} value={code3} onChange={(e) => { setCode3(e.target.value) }} />
                <InputValidarConta type="text" maxLength={1} id={"inputRef4"} value={code4} onChange={(e) => { setCode4(e.target.value) }} />
                <InputValidarConta type="text" maxLength={1} id={"inputRef5"} value={code5} onChange={(e) => { setCode5(e.target.value) }} />


            </div>
        </div>






        <div className="  mt-5 w-10/12 grid grid-cols-1 gap-2 text-white">

            <div className="flex justify-between gap-2 items-center py-5 font-semibold ">
                <button disabled={tempo == 0 ? false : true} onClick={ReenviarCodigo} className={`bg-[#7406CA] whitespace-nowrap disabled:opacity-50   text-white drop-shadow-2xl py-2 px-6 rounded-lg text-[12px] cursor-pointer hover:bg-white hover:text-[#7406CA] hover:font-bold duration-500  ${tempo == 0 ? `animate-pulse` : ` `}`}>
                    {validandoReenviar && <svg role="status" class="inline mr-2 w-5 h-5 text-green-200 whitespace-nowrap animate-spin dark:text-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}
                    {validandoReenviar && 'Reenviando...'}
                    {!validandoReenviar && 'Reenviar Código'}
                </button>

                <p className="whitespace-nowrap text-[12px]">tempo restante : {formatarTempo()}</p>

            </div>

            <div className="border-t border-[#2DE956] w-11/12  my-3"></div>

            <div className="flex items-center gap-3">
                <button onClick={() => {
                    localStorage.removeItem("validar_conta")
                    window.location.reload()
                }}
                    className="disabled:opacity-50 w-10/12 whitespace-nowrap flex items-center justify-center gap-1 bg-[#41DD3E] hover:text-white hover:bg-green-800 text-[#090808] font-bold py-1 text-[12px]  uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-700">

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                    </svg>

                    Cancelar

                </button>

                <button disabled={validando} onClick={ValidarConta} class="disabled:opacity-50 w-10/12 flex items-center justify-center gap-1 whitespace-nowrap bg-[#41DD3E] hover:text-white hover:bg-green-800 text-[#090808] font-bold py-1 text-[12px]  uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-700">
                    {validando && <svg role="status" class="inline mr-2 w-6 h-6 text-green-200 whitespace-nowrap animate-spin dark:text-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                    </svg>}
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                    </svg>
                    {validando && 'Validando...'}
                    {!validando && 'Validar Conta'}
                </button>




            </div>
        </div>




    </>);
}

export default ValidarConta;