import { useNavigate } from "react-router-dom";
import GameFree from "../../components/crashFree/game/game";
import Topper from "../../components/Topper/Topper";
import Template from "../../layouts/template/template";



function CrashFree() {


  // DIRECIONA PARA HOME 
  const navigate = useNavigate();
 
  const useGamefree = true; // Substitua com sua condição

  if (useGamefree) {
    navigate('/home');
    return null;
  }

  // DIRECIONA PARA HOME 



  return (
    <>
        <Template>
          <Topper/>
          <GameFree/>
        </Template>
    </>
  );
}

export default CrashFree;



