import ChecksAnimation from "./Components/checks";
import Premium from "./Components/premium";
import WildScreenStreak from "./Components/wildscreen";



function ConteudoBonusFinalStreak() {



  return (
    <>
      <div className="flex justify-center items-center w-full">
      
         <WildScreenStreak>
         <ChecksAnimation/>
         <Premium/>
        </WildScreenStreak>



      
      </div>


    </>
  );
}

export default ConteudoBonusFinalStreak;
