
import { Link } from 'react-router-dom'
import {useState} from 'react'


export default function MenuPerfil(){
    const [location] = useState(window.location.href)
    return(
        <Link to="/centraldojogador/perfil">
            <div className={location.indexOf("perfil") > -1 ? `border-r-4 border-r-verdeprimary hover:text-verdeprimary hover:bg-cinzasecondary bg-cinzathird p-4 cursor-pointer` : `p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary` }   >
              <p className="text-white text-xl">Minha Conta</p>
            </div>
          </Link>
    )
}