import { useState, memo, useEffect } from "react";
import Pci_dss from "../../../assets/pagamento/pci_dss.png";
import Pix from "../../../assets/pagamento/pix.png"
import PixHeader from '../../../assets/pagamento/pix100.png'
import X from "../../../assets/pagamento/x.png"
import Swal from 'sweetalert2';
import BtnCancelar from '../../BtnCancelar';
import BtnConfirmar from "../../BtnConfirmar";
import useRender from '../../../hooks/useRender'
import Verify from '../../../assets/perfil/verify.png'
import axios from "axios"
import ModalValidarCel from "../ModalValidarCel/ModalValidarCel";

function PagamentoRegister(props) {

    const { prefixoUrl, setTempo } = useRender()



    const [user, setUser] = useState({});
    const [quantia, setQuantia] = useState(30);
    const [Cpf, setCpf] = useState();
    const [nome, setNome] = useState();
    const [Cep, setCep] = useState();
    const [ddd, setDdd] = useState(localStorage.register_telefone?.substring(0, 2))
    const [tel, setTel] = useState(localStorage.register_telefone?.substring(2));
    const [endereco, setEndereco] = useState();
    const [num, setNum] = useState();
    const [bairro, setBairro] = useState();
    const [cidade, setCidade] = useState();
    const [estado, setEstado] = useState();
    const [qrCode, setQrCode] = useState()
    const [iscupom, setIscupom] = useState();
    const [cupompadrao, setCupompadrao] = useState("set250")
    const [isVerificado, setVerificado] = useState(false);
    const [statusVerifyCel, setStatusVerifyCel] = useState(false);

    const [apply, setApply] = useState("aplicar")


    function getVerifyCel() {
        var myHeaders = new Headers();


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${prefixoUrl}/getcodevalidatecellwp?token=${localStorage.user_token}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.cel_valid == 1) {
                    let userLS = JSON.parse(window.localStorage.user);
                    userLS.user.cel_valid = 1;
                    window.localStorage.user = JSON.stringify(userLS);
                    setUser(userLS);

                    setVerificado(true);

                } else {
                    setTimeout(() => {
                        getVerifyCel()
                    }, 5000);
                }
            })
            .catch(error => console.log('error', error));



    }

    function getUser() {
        let resultado = localStorage.getItem("user");
        resultado = JSON.parse(resultado);

        if (resultado) {
            setUser(resultado.user);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            getUser()
            AddCodeLinknoCupom()
        }, 2000);

    }, []);



    useEffect(() => {
        getVerifyCel();
    }, [statusVerifyCel]);


    function RegisterAtivarCupom() {

        let cupom = iscupom
        var requestOptions = {
            method: 'GET',
        };

        fetch(`${prefixoUrl}/cupom/utilizar/${cupom}?token=${localStorage.getItem("user_token")}`, requestOptions)
            .then(response => response.json())
            .then(result => {

                if (result.code == 1) {
                    Swal.fire(
                        "Parabéns",
                        'Bônus ativo com sucesso.',
                        'success'
                    ).then(() => {

                    });

                } else {
                    Swal.fire(
                        "Ops",
                        result.msg,
                        'error'
                    )

                }

            })
            .catch(error => {
                console.log('error', error);


            })

            .finally(() => {

            });
    }


    function AdicionarCupomNaConta() {

        let cupom = iscupom

        var requestOptions = {
            method: 'GET',
        };

        fetch(`${prefixoUrl}/cupom/utilizar/${cupom}?token=${localStorage.getItem("user_token")}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setApply("Aplicado")
            })
            .catch(error => {
                console.log('error', error);


            })

            .finally(() => {
                setApply("Aplicado")

            });
    }

    function RegisterapiVerificarCupom() {

        let cupom = iscupom

        if (cupom) {

            var myHeaders = new Headers();


            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${prefixoUrl}/cupom/buscar/${cupom}?token=${localStorage.getItem("user_token")}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code == 1) {

                        RegisterAtivarCupom()
                    } else if (result.code == 2) {
                        //codigo 2 => cupom ok porem precisa depositar
                        //    closeModalDepositoPendente()
                        //      openCupom()
                        AdicionarCupomNaConta();


                    } else {

                        Swal.fire(
                            "Ops",
                            result.msg,
                            'error'
                        )

                    }
                })
                .catch(error => console.log('error', error));
        }
        else {
            Swal.fire(
                "Ops",
                "Insira um cupom válido.",
                'error'
            )

        }
    }


    function AddCodeLinknoCupom() {
        var myHeaders = new Headers();


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        let linkIndicacao = localStorage.getItem("link_indicacao")
        if (!linkIndicacao) {
            linkIndicacao = (iscupom)
        }



        fetch(`${prefixoUrl}/cupom/buscar/${linkIndicacao}?token=${localStorage.getItem("user_token")}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.code == 1) {

                    setIscupom(linkIndicacao)
                } else if (result.code == 2) {
                    //codigo 2 => cupom ok porem precisa depositar
                    //    closeModalDepositoPendente()
                    //      openCupom()
                    setIscupom(linkIndicacao)


                } else {

                    setIscupom(cupompadrao)

                }
            })
            .catch(error => console.log('error', error));
    }

    function cancelRegister() {

        window.location.href = "/home"



    }


    function apiQrCode() {
        let usuario = localStorage.getItem("user");
        usuario = JSON.parse(usuario);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "valor": quantia,
            "cpf": usuario.user.cpf,
            "name": usuario.user.name,
            "email": usuario.user.email,
            "uf": estado,
            "cidade": cidade,
            "cep": Cep,
            "numero": num,
            "rua": endereco,
            "bairro": bairro,
            "telefone": ddd + tel
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${prefixoUrl}/qrcode?token=${localStorage.user_token}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.setItem("paymentInfo", raw)
                let dados = JSON.parse(result)
                setQrCode(dados.qr_code)
            })
            .catch(error => console.log('error', error));
    }


    function closemethodPayment() {
        document.getElementById(props.openmodal).classList.toggle("hidden");

    }

    function cancelPayment() {
        document.getElementById("Register--QrCode-Modal").classList.add("hidden")
        document.getElementById("Register--QrCode--Pagamento").classList.add("hidden")
        closemethodPayment()
        setQrCode("")
    }

    function limpa_formulário_cep() {
        //Limpa valores do formulário de cep.
        document.getElementById('rua').value = ("");
        document.getElementById('bairro').value = ("");
        document.getElementById('cidade').value = ("");
        document.getElementById('uf').value = ("");

    }

    function handleeQrCode() {
        if (!quantia || !ddd || !tel || !endereco || !num || !bairro || !cidade || !estado || !Cep) {
            Swal.fire(
                'Ops..',
                'Preencha todos os campos!',
                'error'
            );

        } else if (quantia < 20) {
            Swal.fire(
                'Ops..',
                'Valor mínimo para depósito é de R$ 20,00.',
                'error'
            );
        }
        else {
            apiQrCode()
            props.setQrcodeactive(true)
            document.getElementById("Register--QrCode-Modal").classList.remove("hidden")
            document.getElementById("register_pagamentos").classList.add("hidden")
        }

    }

    // função que copia o qrCode
    function cpQrCode() {
        let qrCodeReplace = qrCode.replace(' ""')
        navigator.clipboard.writeText(qrCodeReplace);
        Swal.fire(
            'Sucesso',
            'QR Code copiado!',
            'success'
        );
    }


    function pesquisacep() {

        //Nova variável "cep" somente com dígitos.
        var cep = Cep.replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != "") {

            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if (validacep.test(cep)) {

                //Preenche os campos com "..." enquanto consulta webservice.
                document.getElementById('rua').value = "...";
                document.getElementById('bairro').value = "...";
                document.getElementById('cidade').value = "...";
                document.getElementById('uf').value = "...";


                //Cria um elemento javascript.


                //Sincroniza com o callback.
                var Teste = fetch('https://viacep.com.br/ws/' + cep + '/json')
                    .then((response) => response.json())
                    .then((json) => {
                        meu_callback(json);
                    })






            } //end if.
            else {
                //cep é inválido.
                limpa_formulário_cep();

                Swal.fire(
                    'Ops..',
                    'O Cep Digitado é invalido.',
                    'error'
                );
            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            limpa_formulário_cep();
        }
    };
    function meu_callback(conteudo) {
        if (!("erro" in conteudo)) {
            //Atualiza os campos com os valores.
            setEndereco(conteudo.logradouro)
            setBairro(conteudo.bairro)
            setCidade(conteudo.localidade)
            setEstado(conteudo.uf)
            //document.getElementById('rua').value = (conteudo.logradouro);
            //document.getElementById('bairro').value = (conteudo.bairro);
            //document.getElementById('cidade').value = (conteudo.localidade);
            // document.getElementById('uf').value = (conteudo.uf);

        } //end if.
        else {
            //CEP não Encontrado.
            limpa_formulário_cep();

            Swal.fire(
                'Ops..',
                'CEP não encontrado.',
                'error'
            );
        }
    };



    function valida_cpf(cpf) {
        var cpf = Cpf.replace(/\D/g, '');
        if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        var result = true;
        [9, 10].forEach(function (j) {
            var soma = 0, r;
            cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
                soma += parseInt(e) * ((j + 2) - (i + 1));
            });
            r = soma % 11;
            r = (r < 2) ? 0 : 11 - r;
            if (r != cpf.substring(j, j + 1)) result = false;

            if (!result) {
                Swal.fire(
                    'Ops..',
                    'SEU CPF É INVALIDO.',
                    'error'
                );
            }
        });



        return result;
    }



    function openModal() {
        document.getElementById("modalValidarCel").classList.toggle("hidden");
        setTempo(60)
        SolicitarCodigoSMS()
        
    }
    
    
    
    
    
    function SolicitarCodigoSMS() {
    
      axios.get(`${prefixoUrl}/sms/enviar?token=${localStorage.user_token}`)
          .then((result) => {
              console.log("result.data:", result.data)
    
          })
          .catch((error) => { console.log(error) })
    
    }
    

    return (
        <>



            <div id="register_pagamentos">

                <div className="w-full rounded-tr-xl rounded-tl-xl md:rounded-bl-xl bg-[#000000d1]">
                    <div className="w-full flex-wrap flex text-center justify-center">
                        <div className="w-full pl-3 justify-start md:justify-center">
                            <div className="w-8/12  text-white text-left text-[0.6em] font-bold pt-5">

                            </div>
                        </div>
                        {/* INPUT QUANTIA*/}
                        <div className="w-full bg-cinzaprimary py-2 flex justify-center">
                            <div className="flex w-10/12 justify-between">
                                <div className="text-white py-1 w-3/12 flex justify-start">
                                    Quantia:
                                </div>
                                <div className="w-9/12">
                                    <input id="valorDepositoPagsile" type="number" placeholder="Digite o valor do deposito" value={quantia} onChange={(event) => setQuantia(event.target.value)} className="text-sm text-white text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>
                                </div>

                            </div>
                        </div>
                        <div className="w-full bg-cinzaprimary  flex justify-center">
                            <div className="flex lg:w-5/12 text-[0.6em] justify-center text-purple-400">
                                Depósito minimo BRL 20

                            </div>
                        </div>
                        {/* INPUT CPF*/}





                        {/* INPUT CEP E TELEFONE*/}
                        <div className="w-full bg-cinzaprimary  flex justify-center">
                            <div className="flex w-10/12 justify-between">
                                <div className="w-5/12">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">CEP</div>
                                    <input onBlur={pesquisacep} value={Cep} onChange={e => (setCep(e.target.value))} name="cep" type="text" id="cep" size={9} maxLength={9} placeholder="Digite o CEP" className="text-[0.6em] text-white text-center h-[25px] font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>

                                </div>
                                <div className="w-3/12">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Numero</div>

                                    <input name="numero" id="numero" type="text" placeholder="Nº" value={num} onChange={(e) => setNum(e.target.value)} maxLength={5} className="text-white text-[0.6em] text-center font-bold px-2 h-[25px] bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>



                                </div>


                            </div>
                        </div>

                        {/* endereço e numero*/}
                        <div className="w-full bg-cinzaprimary pt-1 flex justify-center">
                            <div className="flex w-10/12 justify-between">
                                <div className="w-full">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Endereço</div>
                                    <input name="rua" type="text" id="rua" size={60} value={endereco} onChange={(e) => setEndereco(e.target.value)} placeholder="Digite o endereço" className="text-white text-[0.6em] text-center h-[25px] font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                                </div>


                            </div>
                        </div>

                        {/* bairro cidade e uf*/}
                        <div className="w-full bg-cinzaprimary pt-1 flex justify-center">
                            <div className="flex w-10/12 justify-between">
                                <div className="w-4/12">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Bairro</div>
                                    <input name="bairro" id="bairro" size={60} type="text" placeholder="Digite o bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} className="text-white text-[0.6em] text-center h-[25px] font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                                </div>
                                <div className="w-4/12">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Cidade</div>
                                    <input name="cidade" type="text" id="cidade" size={40} placeholder="Digite a cidade" value={cidade} onChange={(e) => setCidade(e.target.value)} className="text-white text-[0.6em] text-center h-[25px] font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                                </div>
                                <div className="w-3/12">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Estado</div>

                                    <input name="uf" type="text" id="uf" maxLength={2} value={estado} onChange={(e) => setEstado(e.target.value)} className="text-[0.6em] text-white text-center font-bold px-2 bg-cinzasecondary h-[25px] border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>



                                </div>

                            </div>
                        </div>

                        {/* INPUT CEP E TELEFONE*/}
                        <div className="w-full bg-cinzaprimary pt-2 mt-2 border-t-2 border-gray-900 flex justify-center">
                            <div className="flex w-10/12 justify-between">

                                <div className="w-6/12">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Telefone</div>
                                    <div className="flex justify-between w-full">
                                        <input id="codigoTelefonePagamento" type="text" placeholder="DDD" value={ddd} onChange={(e) => setDdd(e.target.value)} maxLength={2} className="h-[25px]  text-white text-center text-[0.6em] font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-4/12 mr-2"></input>
                                        <input id="telefonePagamento" placeholder="Telefone" value={tel} onChange={(e) => setTel(e.target.value)} maxLength={9} className="text-[0.6em] text-white h-[25px] text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-8/12"></input>
                                    </div>

                                </div>
                                <div className="w-6/12 flex items-end justify-center">


                                    {user.cel_valid == 0 ?
                                        (<button onClick={openModal} className=" bg-verdeprimary border-green-500 hover:bg-green-800 text-black text-sm px-4 font-bold  uppercase border-t-3 border-2  hover:border-green-500 hover:text-white rounded-xl duration-700">
                                            VERIFICAR
                                        </button>

                                        )


                                        : (<img src={Verify} alt="verificado" className="w-10 mx-auto" />

                                        )}
                                </div>

                            </div>
                        </div>

                        {/* cupom */}
                        <div className="w-full bg-cinzaprimary  py-2 flex flex-col items-center justify-center">
                            <div className="flex w-10/12 justify-between">
                                <div className="w-full">
                                    <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Cupom</div>
                                    <div className={` ${apply == "aplicar" ? `bg-cinzasecondary` : `bg-purple-700`} text-white  flex text-sm text-center font-bold px-2  border-2 focus:outline-none focus:border-verdeprimary border-verdeseven rounded-md py-1 placeholder:text-center w-full`}>
                                        <input name="cupom" id="cupomregister" size={60} type="text" placeholder="Digite o Cupom" value={iscupom} onChange={(e) => setIscupom(e.target.value)} className={`${apply == "aplicar" ? `text-white bg-cinzasecondary` : `text-yellow-400 font-black bg-purple-700`} uppercase text-sm text-left font-bold px-2  focus:outline-none rounded-md py-1 placeholder:text-left w-8/12`}></input>
                                        <div className="w-4/12 flex items-center">
                                            <button onClick={(e) => RegisterapiVerificarCupom(e)} className={`${apply == "aplicar" ? `bg-green-600` : `bg-purple-600`} w-10/12 rounded-md  cursor-pointer text-[0.5em] uppercase`}>{apply}</button>

                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div className="text-white mt-2 text-[0.6em] w-10/12">
                                {apply == "aplicar" ? <> Para usar o <span className="font-bold text-purple-400">CUPOM</span> clique em <span className="text-green-500 uppercase">{apply}</span>. Após usar você não poderá remover o Cupom.</>
                                    : <> O cupom <span className="text-yellow-400 font-bold">{iscupom}  </span> foi {apply} com sucesso! clique em continuar para finalizar o pagamento.</>}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="w-full ">
                    {/* metodo pagamento*/}
                    <div className="w-full  bg-cinzaprimary py-5 py-2 flex justify-center">
                        <div className="flex w-10/12 justify-between">
                            <div className="w-full flex gap-x-2">
                                <div onClick={cancelRegister} className="cursor-pointer text-center hover:text-black flex justify-center text-[0.6em]  font-bold px-1 text-white bg-cinzasecondary hover:bg-cinzapriamry border-2 focus:outline-none focus:border-verdeprimary border-b-[5px] border-b-cinzafourth border-cinzafourth rounded-xl py-1 placeholder:text-center w-full">
                                    <div className="">Não quero pagar</div>


                                </div>

                                <div onClick={handleeQrCode} className="cursor-pointer text-center hover:text-white flex items-center justify-center text-[0.6em]  font-black px-2 text-black bg-verdesecondary hover:bg-verdeprimary border-2 focus:outline-none focus:border-verdeprimary border-b-[5px] border-b-verdethird border-verdethird rounded-xl py-1 placeholder:text-center w-full">
                                    <div className="">FAZER PAGAMENTO</div>


                                </div>
                            </div>


                        </div>
                    </div>






                </div>
            </div>


            {/* qrCode */}
            <div id="Register--QrCode-Modal" className="bg-[#000000d1] hidden rounded-xl  w-full flex flex-col items-center">

                <div className="md:w-8/12 w-10/12 mb-2 border-b-2 border-verdesixtyn ">
                    <img className="w-32" src={PixHeader} alt="logo pix" />
                </div>

                <div className="bg-white md:w-8/12 w-9/12  flex justify-center items-center">
                    {!qrCode ? (
                        <svg role="status" className="inline mr-2 md:w-4/12 md:h-[25vh] h-6 text-green-200 animate-spin dark:text-[#717171]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#303030" />
                        </svg>
                    ) : (
                        <img className="" src={`https://chart.googleapis.com/chart?chs=280x280&cht=qr&chl=${qrCode}`} alt="" />
                    )}

                </div>

                <div className="text-white text-[0.8em] mt-2 md:pl-20 px-6">


                    <p>
                        1 - Leia o  QR-CODE PIX acima, no seu banco digital.
                    </p>

                    <p>
                        2 - Conclua o PAGAMENTO com seu banco
                    </p>
                    <p>
                        3 - Clique em fechar.
                    </p>

                    <p>
                        4 - Aguarde seu saldo em reais serem creditados.
                    </p>
                </div>

                <div className="flex justify-center items-center text-white mt-3 gap-16">


                    <BtnCancelar
                        onClick={cancelRegister}
                        texto="Fechar"
                        altura="py-1"
                        largura="px-2"
                        radius="rounded-lg"
                        sizeText="text-md" />

                    <BtnConfirmar
                        onClick={cpQrCode}
                        texto="Copiar Código"
                        altura="py-1"
                        largura="px-2"
                        radius="rounded-lg"
                        sizeText="text-md" />

                </div>
            </div>


        </>

    )
};

export default memo(PagamentoRegister);

