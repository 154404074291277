import Voltar from "../../../assets/icons/voltar.gif";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useRender from "../../../hooks/useRender";
import Loading from "../../../components/Loading";
import Title from "../../../components/centraldojogador/titulo";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";

function HistoricoDeDeposito() {
  const { prefixoUrl, SairApi } = useRender();
  const [result, setResult] = useState();

  async function PaginacaoNext() {
    let dados = localStorage.getItem("historicoDeDeposito");
    if (dados) {
      dados = JSON.parse(dados);
      if (dados.paginaAtual < dados.paginas) {
        let dados = localStorage.getItem("historicoDeDeposito");
        dados = JSON.parse(dados);
        dados.paginaAtual = dados.paginaAtual + 1;
        localStorage.setItem("historicoDeDeposito", JSON.stringify(dados));

        let inicio = (dados.paginaAtual - 1) * 10;
        let final = inicio + 10;
        dados.result = dados.result.slice(inicio, final);
        setResult(dados);
      }
    }
  }

  async function PaginacaoPrevius() {
    let dados = localStorage.getItem("historicoDeDeposito");
    dados = JSON.parse(dados);
    if (dados) {
      if (dados.paginaAtual == 1) return;
      dados.paginaAtual = dados.paginaAtual - 1;
      localStorage.setItem("historicoDeDeposito", JSON.stringify(dados));

      let inicio = (dados.paginaAtual - 1) * 10;
      let final = inicio + 10;
      dados.result = dados.result.slice(inicio, final);
      setResult(dados);
    }
  }

  async function PaginacaoLast() {
    var myHeaders = new Headers();

    var raw = "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${prefixoUrl}/centraldojogador/historicodesaques/10?token=${localStorage.getItem(
        "user_token"
      )}&page=${result.paginas}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        setResult(dados);
        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  async function HistoricoDeDeposito() {

    let dados = localStorage.getItem("historicoDeDeposito");
    dados = JSON.parse(dados);
    if (dados === null) {
      await HistoricoDeDepositoApi();
    }
    else if (dados) {
      if (dados.code === 1099) { // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }
      let inicio = (dados.paginaAtual - 1) * 10;
      let final = inicio + 10;
      dados.result = dados.result.slice(inicio, final);
      setResult(dados);
    }
  }

  useEffect(() => {
    HistoricoDeDeposito();
  }, []);

  async function HistoricoDeDepositoApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${prefixoUrl}/centraldojogador/historicodedeposito/1000?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        dados.paginas = parseInt(dados.totalRegistro / 10);

        if (dados.totalRegistro % 10 > 0) {
          dados.paginas = dados.paginas + 1;
        }

        localStorage.setItem("historicoDeDeposito", JSON.stringify(dados));
        setResult(dados);
        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  return (
    <TemplateCentralDoJogador title="Histórico de Depósito">
      <main className="w-full">

        {result != undefined ? (
          result.result.map((item, index) => {
            return (
              <div key={index} className="space-y-[2px] w-full">
                {item.metodo == 10 ? <></> :   <div className="w-full text-white flex font-bold lg:h-[67px] bg-[#121111] border-b border-[#2b2828]">
                  <div className="w-6/12 ">
                    <div className="flex w-full lg:flex-row flex-col p-3 lg:mr-10">
                      <div className="lg:w-6/12">
                      <p className={item.statusDeposito == "Aprovado"
                        ? `lg:text-lg text-sm uppercase text-verdeprimary`
                        : item.statusDeposito == "Pendente" ||
                          item.statusDeposito == "Processando"
                          ? `lg:text-lg text-sm uppercase text-yellow-300`
                          : `text-red-700 lg:text-lg text-sm uppercase`} >

                        {item.statusDeposito}
                      </p>
                      </div>
                      <div>
                        <p className="text-sm">TIPO DE DEPÓSITO</p>
                        <p className="uppercase">{item.metodo}</p>
                      </div>

                    </div>

                  </div>
                  <div className="w-6/12 p-2 ">
                   <div className="w-full flex lg:flex-row flex-col-reverse">
                    <div className="lg:w-6/12">
                      <p className="text-sm lg:text-base mb-2 text-right">QUANTIA</p>
                      <p
                        className={
                          item.statusDeposito == "Aprovado"
                            ? ` text-verdeprimary  text-2xl leading-3 flex items-center justify-end `
                            : item.statusDeposito == "Pendente" ||
                              item.statusDeposito == "Processando"
                              ? `text-yellow-300 text-xl lg:text-2xl leading-3 flex items-center justify-end `
                              : `text-red-700  text-xl lg:text-2xl leading-3 flex items-center justify-end `
                        }>
                        <span className="text-[10px] font-normal mr-2 text-white">
                          BRL
                        </span>
                        {item.quantia}
                      </p>
                     
                    </div>
                    <div className="lg:w-6/12 flex justify-end font-normal lg:text-sm text-[0.6em]">{item.data}</div>
                    </div>
                  </div>

                  

                </div>}
              
              </div>
            );
          })
        ) : (
          <Loading />
        )}

        <div className="flex justify-center items-center py-5">
          <button
            onClick={PaginacaoPrevius}
            className="uppercase bg-white text-[#1F2327]   hover:bg-[#1f9114] hover:text-white  font-bold px-8 rounded-full h-8 flex items-center gap-2  scale-90 hover:scale-100 duration-500"
          >
            Previus
          </button>

          <div className="flex items-center px-2">
            <p className="text-md  text-white">
              {result != undefined ? result.paginaAtual - 1 : ""}
            </p>
            <p className="text-3xl px-4 font-bold text-white">
              {result != undefined ? result.paginaAtual : ""}
            </p>
            <p className="text-md  text-white">
              {result != undefined && result.paginas > result.paginaAtual
                ? result.paginaAtual + 1
                : ""}
            </p>
            <p
              onClick={PaginacaoLast}
              className="text-md pl-4  text-white cursor-pointer"
            >
              {result != undefined ? `...${result.paginas}` : " "}
            </p>
          </div>

          <button
            onClick={PaginacaoNext}
            className="uppercase bg-[#1BFF07] text-[#1F2327]   hover:bg-[#1f9114] hover:text-white  font-bold px-8 rounded-full h-8 flex items-center gap-2  scale-90 hover:scale-100 duration-500"
          >
            NEXT
          </button>
        </div>
      </main>
    </TemplateCentralDoJogador>
  );
}

export default HistoricoDeDeposito;
