import Template from "../../layouts/template/template";
import Video from "../../assets/video/runnerjelly/runnerjelly.mp4";
import VideoPrint from "../../assets/video/runnerjelly/print.png";
import BottomStyle from "../../assets/video/bottomstyle.svg";
import { useEffect, useState, useRef } from "react";
import PlaytoWin from "../../assets/video/playtowin.png"
import useRender from "../../hooks/useRender";
import Barra from "../../assets/home/barra.svg"
import Print from "../../assets/home/print.png"
import Recompensas from "../../assets/home/recompensas.png"
import Cicle from "../../assets/home/cicle.png"
import Ondulacao from "../../assets/home/ondulacao.png"
import Gamefree from "../../assets/home/gamefree.png"
import Crash from "../../assets/home/crash.png"
import Roleta from "../../assets/home/roleta.png"
import { useParams } from 'react-router-dom';
import CarrosselOriginal from "./jogos/originais/carrossel";
import CarrosselSlot from "./jogos/slot/carrossel";
import CarrosselPg from "./jogos/pg/carrossel";
import { Link } from "react-router-dom";


export default function Principal() {

    const { link } = useParams()
    const { prefixoUrl ,signed, setSigned,} = useRender();
    const [allPlayers, setAllPlayers] = useState();
    const [deslogado, setDeslogado] = useState(false);

    async function AllPlayers() {
        var myHeaders = new Headers();


        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${prefixoUrl}/users/total`, requestOptions)
            .then(response => response.text())
            .then(result => {
                let dados = JSON.parse(result);

                setAllPlayers(dados.totalusers.toLocaleString('pt-BR'))

            }
            )
            .catch(error => console.log('error', error));
    }

    function openSelectGame() {
        let selectgame = document.getElementById("selectGame")
        selectgame.classList.toggle("hidden");
    }


    async function sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    };

    function showPopUp() {
        let method = document.getElementById("modalPopUp")
        method.classList.toggle("hidden");
        let opacity = document.getElementById("popup--1")
        opacity.classList.add("opacity-0");

        setTimeout(() => {
            opacity.classList.toggle("opacity-0");

        }, 100);
    }

    function openLogin() {
        document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
        document.querySelector(".login__modal").classList.toggle("opacity-0");
        document.querySelector(".login--opacidade").classList.toggle("hidden");
        document.getElementById("login").focus();
      }

  

    useEffect(() => {
      
         
      

        if (link) {
            localStorage.setItem("link_indicacao", link)
            window.location.href = "/register"
        }

        if(localStorage.user_token){
            showPopUp()
      // se quiser popup abrindo só depois do login descomente  showPopUp()  
             return
        }
        showPopUp()
        openLogin()
    }, [])


  

   






    return (
        <>
            <Template>
            {signed == true ? <>
                <Link to="/runnerjelly" className="cursor-pointer border-b-0 relative flex justify-center w-full ">
                <VideoHomeExecute/>

                </Link>
            </> : 
            <>
                 <div onClick={()=>{ openLogin()}} className="cursor-pointer border-b-0 relative flex justify-center w-full ">
                 <VideoHomeExecute/>

                </div>
            </> }
            
                

                <div className=" z-1  w-full flex justify-end">
                    <div className="w-full ">
                        <CarrosselOriginal />
                    </div>                  
                </div>

                <div className=" z-1  w-full ">
                    <div className="w-full ">
                        <CarrosselSlot/>
                    </div>                  
                </div>
                
                <div className=" items-center flex justify-center w-full relative ">

                    <div className="w-8/12 absolute">
                        <div className="w-full text-black mt-5 font-black flex text-2xl lg:text-7xl justify-center items-center">
                            FREE-TO-PLAY
                        </div>
                    </div>





                    <img className="w-full" src={Barra} />
                </div>
                <div className="flex w-full flex-col items-center">

                    <div className="flex flex-col lg:w-11/12 w-full lg:flex-row">
                        <div className="lg:w-6/12 w-full flex justify-center items-center text-white">

                            <div className="bg-cinzasecondary font-normal rounded-xl lg:text-2xl p-4 w-10/12">
                                O modo <span className="font-bold">FREE TO PLAY </span> da <span className="font-bold text-verdeprimary">ROCKEETZ</span><br /> você pode acumular pontos e converter para <br /> REAL BRASILEIRO quando atingir o valor necessário.
                                <br /> Neste modo além de garantir sua diversão, nós damos a você a chance de ganhar sem a necessidade de depósito padrão.
                                <br />
                                Para você jogar de graça é fácil! <br /> Basta coletar as moedas diárias que você ganha quando conecta em sua conta na Rockeetz.
                            </div>
                        </div>
                        <div className="lg:w-6/12 w-full flex justify-center mt-10 lg:mt-0 ">
                            <div className="w-10/12 h-[100%] justify-center flex items-center ">
                                <img src={Print}></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RECOMPENSAS */}
                <div>
                    <img src={Recompensas} />
                </div>
                <div id="streakLoginHome" className="bg-[#3a0273] lg:pb-[200px] pb-[50px] p-4 flex justify-center w-full flex-col ">
                    <div className="w-full flex justify-center mb-5 text-white text-2xl lg:text-5xl font-bold">STREAK LOGIN</div>
                    <div className="w-full flex justify-center ">
                        <div className="w-full lg:w-10/12 flex  flex-col lg:flex-row items-center justify-center">
                            <div className="w-full flex justify-center lg:w-6/12">
                                <div className="w-10/12 flex justify-center text-[0.6em] lg:text-xl rounded-xl border-4  border-roxofourth bg-purple-800 p-4 text-white">
                                    <div>O <b>STREAK LOGIN</b> é uma recompensa diária que o usuário
                                        ganha por estar ativo na <b>ROCKEETZ GAMES</b>, toda semana o <b>STREAK LOGIN</b> é zerado e uma nova rodada de recompensa se inicia.
                                        Quando o usuário completa todos os dias ativos o mesmo é recompensado com um <b>BÔNUS FINAL</b>, caso o usuário perca algum dia o <b>BÔNUS FINAL</b> será perdido.<br />
                                        Suas recompensas podem ser convertidas por saldo real sempre que você acumular um valor de 1000 RKT, basta clicar no dropmenu ao lado do seu saldo e depois clicar em CONVERTER ao lado de RKT SALDO</div>
                                </div>
                            </div>
                            <div className="w-full lg:w-6/12">
                                <div>
                                    <img src={Cicle} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* 
                <div className="w-full relative lg:top-[-150px] top-[-50px]">
                    <img src={Ondulacao} />
                    <div className="w-full  animate-ping absolute font-black text-verdeprimary  text-3xl lg:text-6xl  flex justify-center items-center">JOGUE AGORA</div>
                    <div className="w-full  animate-pulse absolute font-black text-verdeprimary  text-3xl lg:text-6xl  flex justify-center items-center">JOGUE AGORA</div>
                    <div className="w-full pt-20 bg-gradient-to-b from-[#000000] ...">
                        <div className="w-full flex p-4 flex-col lg:flex-row">
                            <a href="/gamefree" className="cursor-pointer  duration-150 hover:scale-110 w-full lg:w-4/12">
                                <img src={Gamefree} />
                            </a>
                            <a href="/cyberspace" className="cursor-pointer duration-150 hover:scale-110 w-full lg:w-4/12">
                                <img src={Crash} />
                            </a>


                            <a href="/roulletplanet" className="cursor-pointer duration-150 hover:scale-110 w-full lg:w-4/12">
                                <img src={Roleta} />
                            </a>
                        </div>
                    </div>
                </div>
*/}
            </Template>
        </>
    )


    
}

 function VideoHomeExecute(){

    function clickBtnAuto() {
        let unmuteButton = document.getElementById("unmuteButtonHome")
        unmuteButton.addEventListener('click', function () {
            let video = videoHome.current
            video.muted = false;
        });
    }
 

    async function audioPlay() {
        videoHome.current.play();

    };
    
    useEffect(()=>{
        audioPlay()   
        clickBtnAuto()
    },[])
    const videoHome = useRef();
    return(
        <>
         <div className="absolute  flex  w-full h-[100%]">
                        <img className="w-full absolute bottom-[-5px]" src={BottomStyle} />
                    </div>
                    <video className="w-full" ref={videoHome} src={Video} playsInline={true} poster={VideoPrint} muted={true} loop={true} />
                    <button id="unmuteButtonHome"></button>
        </>
    )
}
