export default function LossSemAposta(){
    return(
        <>
        <div id="modal-loss-sem-aposta" className="bg-cinzasecondary h-[70%] lg:h-[50%] flex justify-center w-11/12 lg:w-6/12 rounded-xl z-[1]" style={{display:"none"}}>
                  <div className="w-full flex items-center flex-col">
                    <div className="w-full flex py-3 px-4 lg:px-5 lg:py-6">
                    <div className="w-full text-base flex justify-center items-center lg:text-xl">
                    <p><b className="text-red-700 font-black text-3xl">BOOOOOOOM!</b></p>
                    </div>
                   
                    </div>
                    <div className="w-11/12 lg:mt-4 flex  p-2 justify-center items-center uppercase font-bold bg-red-700 rounded-md">
                    <p>Explodiu em <span id="multiplicadorLossModal2">..</span></p>
                    
                    </div>
                  </div>
                
                 
                </div>
        </>
    )
}