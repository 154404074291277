import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Search from "./../../../../assets/icons/search.png";
import { useState, useRef, useEffect } from "react";
import CarouselItemPg from "./carouselitem";
import useRender from "../../../../hooks/useRender";
import CarouselItemDESLOGADO from "./carouselitemDESLOGADO";

function CarouselRow({ provedor }) {
  const carouselRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
 
  const [oppened, setOppened] = useState(false);

  const scroll = (direction) => {
    const node = carouselRef.current;
    const scrollAmount = direction === 'left' ? -1400 : 1400;
    node.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });

    // Após a rolagem suave, verifique se estamos perto do final e redefina a posição
    setTimeout(() => {
      if (node.scrollLeft >= node.scrollWidth / 2) {
        //node.scrollLeft = 0;
      }
      // Se a rolagem for maior que 0, exiba o botão esquerdo
      setShowLeftButton(node.scrollLeft > 0);
    }, 200); // Supondo 500ms para a rolagem suave, ajuste se necessário
  };

  function verMais() {
    if (oppened) {
      carouselRef.current.classList.add("whitespace-nowrap")
      setOppened(false)
    } else {
      carouselRef.current.classList.remove("whitespace-nowrap")
      setOppened(true)
    }

  }

  return (
    <div className="relative w-full space-y-2">
      {provedor.games.length > 0 && (
        <>
          <div className="absolute top-[-40px]  text-[0.8em] lg:text-normal right-[10px] lg:right-[40px] flex justify-end">
            <div onClick={verMais} className="cursor-pointer w-[100px] text-white bg-cinzafourth flex justify-center items-center py-1 px-2 rounded-md">{oppened ? <>Ver menos</> : <>Ver mais</>} </div>
          </div>

          <button id="btnLeft" onClick={() => scroll('left')} className="absolute left-0 lg:left-[25px] bottom-[15px] top-0 h-full  w-[60px]  z-[2] text-3xl text-white bg-gradient-to-r from-[#110e14] ...  ">
            {showLeftButton && (
              <div className="bg-black rounded-xl p-2 relative flex justify-center right-[20px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-caret-left-fill scale-90 hover:scale-125 duration-500" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z" />
                </svg>

              </div>
            )}
          </button>

          <div ref={carouselRef} className="overflow-hidden whitespace-nowrap overflow-x-scroll hide-scrollbar w-[95%] bg-cinzasecondary mx-auto">
            {provedor.games.map((item) => (
              <div className="inline-block">
                {localStorage.user_token ?
                <CarouselItemPg {...item} />
                
                : <CarouselItemDESLOGADO {...item} />
                } 
              </div>
            ))}
          </div>
          <button onClick={() => scroll('right')} className="absolute right-0 lg:right-[25px] bottom-[15px] top-0 h-full  w-[60px]  z-[2] text-3xl text-white bg-gradient-to-l from-[#110e14] ...  ">
            <div className="bg-black rounded-xl p-2 relative flex just left-[20px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-caret-right-fill  scale-90 hover:scale-125 duration-500" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />
              </svg>
            </div>
          </button>
        </>
      )}
    </div>
  );
}




export default function CarrosselPg() {
  const { prefixoUrl } = useRender();
  const [itemsSelecteds, setItemsSelecteds] = useState([]);
  const [items, setItems] = useState([]);
  const { loading, setLoading } = useRender();
  const [loadingGames, setLoadingGames] = useState(false);
  
  function getGames() {
    setLoadingGames(true);
    var requestOptions = {
      method: "GET",
    };

    fetch(
      `${prefixoUrl}/slot/games?token=${localStorage.getItem("user_token")}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {
          
          setItemsSelecteds(result.data.provedores);
          setItems(result.data.provedores);
        } else {
          setItemsSelecteds([]);
          setItems([]);
        }
      })
      .catch((error) => {
        console.log("error", error);
      }).finally(() => {
     
        setLoadingGames(false);
      })
  }

  function filterGame(value) {

    if (value === "") {
      setItemsSelecteds(items);
      return;
    }

    // Primeiro, filtramos pelos provedores
    let resultadoProvedor = items.filter(provedor =>
      provedor.name.toLowerCase().includes(value.toLowerCase())
    );

    // Se encontramos algum provedor que corresponda, usamos essa lista filtrada
    if (resultadoProvedor.length > 0) {
      setItemsSelecteds(resultadoProvedor);
      return;
    }

    // Se não encontramos provedores, filtramos os jogos dentro de cada provedor
    let resultadoJogos = items.map(provedor => {
      return {
        ...provedor,
        games: provedor.games.filter(game =>
          game.nome.toLowerCase().includes(value.toLowerCase())
        )
      };
    }).filter(provedor => provedor.games.length > 0);  // Removemos os provedores que não têm jogos correspondentes

    setItemsSelecteds(resultadoJogos);
  }




  useEffect(() => {
    getGames();
  }, []);




  const [slotDeslogado, setSlotDeslogado] = useState([
    {
      "name": "Pragmatic",
      "games": [
        {
          "id": 1,
          "nome": "Spaceman",
          "provedor_id": 1,
          "game_reference": "prag-slot-spaceman",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-01T19:37:01.000000Z",
          "updated_at": "2023-08-01T19:37:01.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-spaceman"
        },
        {
          "id": 1,
          "nome": "Fruit Party",
          "provedor_id": 1,
          "game_reference": "prag-slot-fruitParty",
          "prioridade": "90",
          "status": 1,
          "created_at": "2023-08-01T19:39:56.000000Z",
          "updated_at": "2023-08-01T19:39:56.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-fruitParty"
        },
        {
          "id": 1,
          "nome": "Mega Baccarat",
          "provedor_id": 1,
          "game_reference": "prag-tb-mega-baccarat",
          "prioridade": "80",
          "status": 1,
          "created_at": "2023-08-01T20:35:14.000000Z",
          "updated_at": "2023-08-01T20:35:14.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-tb-mega-baccarat"
        },
        {
          "id": 1,
          "nome": "3 Buzzing Wilds",
          "provedor_id": 1,
          "game_reference": "prag-slot-3BuzzingWilds",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-3BuzzingWilds"
        },
        {
          "id": 1,
          "nome": "3 Dancing Monkeys",
          "provedor_id": 1,
          "game_reference": "prag-slot-3-dancing-monkeys",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-3-dancing-monkeys"
        },
        {
          "id": 1,
          "nome": "African Elephant",
          "provedor_id": 1,
          "game_reference": "prag-slot-african-elephant",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-african-elephant"
        },
        {
          "id": 1,
          "nome": "Big Bass - Hold & Spinner",
          "provedor_id": 1,
          "game_reference": "prag-slot-big-bass-hold-spinner",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-big-bass-hold-spinner"
        },
        {
          "id": 1,
          "nome": "Big Bass - Keeping it Reel",
          "provedor_id": 1,
          "game_reference": "prag-slot-BigBassKeepingitReel",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-BigBassKeepingitReel"
        },
        {
          "id": 1,
          "nome": "Big Bass Amazon Xtrem",
          "provedor_id": 1,
          "game_reference": "prag-slot-big-bass-amazon-xtrem",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-26T18:01:45.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-big-bass-amazon-xtrem"
        },
        {
          "id": 1,
          "nome": "Big Bass Bonanza",
          "provedor_id": 1,
          "game_reference": "prag-slot-BigBassBonanza",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-BigBassBonanza"
        },
        {
          "id": 1,
          "nome": "Big Bass Bonanza Megaways",
          "provedor_id": 1,
          "game_reference": "prag-slot-BigBassBonanzaMegaways",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-BigBassBonanzaMegaways"
        },
        {
          "id": 1,
          "nome": "Book of the Fallen",
          "provedor_id": 1,
          "game_reference": "prag-slot-BookoftheFallen",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-BookoftheFallen"
        },
        {
          "id": 1,
          "nome": "Cash Box",
          "provedor_id": 1,
          "game_reference": "prag-slot-cashbox",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-cashbox"
        },
        {
          "id": 1,
          "nome": "Club Tropicana",
          "provedor_id": 1,
          "game_reference": "prag-slot-club-tropicana",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-club-tropicana"
        },
        {
          "id": 1,
          "nome": "Country Farming",
          "provedor_id": 1,
          "game_reference": "prag-slot-country-farming",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-country-farming"
        },
        {
          "id": 1,
          "nome": "Cowboy Coins",
          "provedor_id": 1,
          "game_reference": "prag-slot-cowboy-coins",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-cowboy-coins"
        },
        {
          "id": 1,
          "nome": "Diamond Cascade",
          "provedor_id": 1,
          "game_reference": "prag-slot-DiamondCascade",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-DiamondCascade"
        },
        {
          "id": 1,
          "nome": "Dragon Hero",
          "provedor_id": 1,
          "game_reference": "prag-slot-dragon-hero",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-dragon-hero"
        },
        {
          "id": 1,
          "nome": "Excalibur Unleashed",
          "provedor_id": 1,
          "game_reference": "prag-slot-excalibur-unleashed",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-excalibur-unleashed"
        },
        {
          "id": 1,
          "nome": "Fat Panda",
          "provedor_id": 1,
          "game_reference": "prag-slot-fat-panda",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-fat-panda"
        },
        {
          "id": 1,
          "nome": "Fire Archer",
          "provedor_id": 1,
          "game_reference": "prag-slot-fire-archer",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-fire-archer"
        },
        {
          "id": 1,
          "nome": "Fish Eye",
          "provedor_id": 1,
          "game_reference": "prag-slot-fish-eye",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-fish-eye"
        },
        {
          "id": 1,
          "nome": "Floating Dragon - Dragon Boat Festival",
          "provedor_id": 1,
          "game_reference": "prag-slot-floating-dragon-dragon-boat-festival",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-floating-dragon-dragon-boat-festival"
        },
        {
          "id": 1,
          "nome": "Gates of Olympus",
          "provedor_id": 1,
          "game_reference": "prag-slot-GatesofOlympus",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-GatesofOlympus"
        },
        {
          "id": 1,
          "nome": "Gods of Giza",
          "provedor_id": 1,
          "game_reference": "prag-slot-gods-of-giza",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-gods-of-giza"
        },
        {
          "id": 1,
          "nome": "Heist for the Golden Nugget",
          "provedor_id": 1,
          "game_reference": "prag-slot-HeistfortheGoldenNugget",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-HeistfortheGoldenNugget"
        },
        {
          "id": 1,
          "nome": "Hellvis Wild",
          "provedor_id": 1,
          "game_reference": "prag-slot-Hellvis-Wild",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-Hellvis-Wild"
        },
        {
          "id": 1,
          "nome": "Jane Hunter and the Mask of Montezuma",
          "provedor_id": 1,
          "game_reference": "prag-slot-jane-hunter-and-the-mask-of-montezuma",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-jane-hunter-and-the-mask-of-montezuma"
        },
        {
          "id": 1,
          "nome": "Jasmine Dreams",
          "provedor_id": 1,
          "game_reference": "prag-slot-jasmine-dreams",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-jasmine-dreams"
        },
        {
          "id": 1,
          "nome": "Jewel Rush",
          "provedor_id": 1,
          "game_reference": "prag-slot-jewel-rush",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-jewel-rush"
        },
        {
          "id": 1,
          "nome": "John Hunter and the Book of Tut",
          "provedor_id": 1,
          "game_reference": "prag-slot-JohnHunterandtheBookofTut",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-JohnHunterandtheBookofTut"
        },
        {
          "id": 1,
          "nome": "Jokers Jewels Dice",
          "provedor_id": 1,
          "game_reference": "prag-slot-jokers-jewels-dice",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-jokers-jewels-dice"
        },
        {
          "id": 1,
          "nome": "Kingdom of The Dead",
          "provedor_id": 1,
          "game_reference": "prag-slot-kingdom-of-the-dead",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-kingdom-of-the-dead"
        },
        {
          "id": 1,
          "nome": "Knight Hot Spotz",
          "provedor_id": 1,
          "game_reference": "prag-slot-knight-hot-spotz",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-knight-hot-spotz"
        },
        {
          "id": 1,
          "nome": "Lobster Bob’s Crazy Crab Shack",
          "provedor_id": 1,
          "game_reference": "prag-slot-LobsterBobsCrazyCrabShack",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-LobsterBobsCrazyCrabShack"
        },
        {
          "id": 1,
          "nome": "Mammoth Gold Megaways",
          "provedor_id": 1,
          "game_reference": "prag-slot-mammoth-gold-megaways",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:30.000000Z",
          "updated_at": "2023-08-25T22:33:30.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-mammoth-gold-megaways"
        },
        {
          "id": 1,
          "nome": "Mochimon",
          "provedor_id": 1,
          "game_reference": "prag-slot-mochimon",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-mochimon"
        },
        {
          "id": 1,
          "nome": "Monster Superlanche",
          "provedor_id": 1,
          "game_reference": "prag-slot-monster-superlanche",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-monster-superlanche"
        },
        {
          "id": 1,
          "nome": "Mystery of the Orient",
          "provedor_id": 1,
          "game_reference": "prag-slot-mystery-of-the-orient",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-mystery-of-the-orient"
        },
        {
          "id": 1,
          "nome": "Peak Power",
          "provedor_id": 1,
          "game_reference": "prag-slot-peak-power",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-peak-power"
        },
        {
          "id": 1,
          "nome": "Piggy Bankers",
          "provedor_id": 1,
          "game_reference": "prag-slot-PiggyBankers",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-PiggyBankers"
        },
        {
          "id": 1,
          "nome": "Pirates Pub",
          "provedor_id": 1,
          "game_reference": "prag-slot-pirates-pub",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-pirates-pub"
        },
        {
          "id": 1,
          "nome": "Power of Merlin Megaways",
          "provedor_id": 1,
          "game_reference": "prag-slot-Power-of-Merlin-Megaways",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-Power-of-Merlin-Megaways"
        },
        {
          "id": 1,
          "nome": "Power of Thor Megaways",
          "provedor_id": 1,
          "game_reference": "prag-slot-PowerofThorMegaways",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-PowerofThorMegaways"
        },
        {
          "id": 1,
          "nome": "Pub Kings",
          "provedor_id": 1,
          "game_reference": "prag-slot-PubKings",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-PubKings"
        },
        {
          "id": 1,
          "nome": "Rocket Blast Megaways",
          "provedor_id": 1,
          "game_reference": "prag-slot-RocketBlastMegaways",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-RocketBlastMegaways"
        },
        {
          "id": 1,
          "nome": "Secret City Gold",
          "provedor_id": 1,
          "game_reference": "prag-slot-secret-city-gold",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-secret-city-gold"
        },
        {
          "id": 1,
          "nome": "Sky Bount",
          "provedor_id": 1,
          "game_reference": "prag-slot-SkyBount",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-SkyBount"
        },
        {
          "id": 1,
          "nome": "Spellbinding Mystery",
          "provedor_id": 1,
          "game_reference": "prag-slot-Spellbinding-Mystery",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-Spellbinding-Mystery"
        },
        {
          "id": 1,
          "nome": "Sticky Bees",
          "provedor_id": 1,
          "game_reference": "prag-slot-sticky-bees",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-sticky-bees"
        },
        {
          "id": 1,
          "nome": "Sweet Bonanza Dice",
          "provedor_id": 1,
          "game_reference": "prag-slot-sweet-bonanza-dice",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-sweet-bonanza-dice"
        },
        {
          "id": 1,
          "nome": "The Dog House Dice Show",
          "provedor_id": 1,
          "game_reference": "prag-slot-the-dog-house-dice-show",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-the-dog-house-dice-show"
        },
        {
          "id": 1,
          "nome": "The Dog House Multihold",
          "provedor_id": 1,
          "game_reference": "prag-slot-the-dog-house-multihold",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-the-dog-house-multihold"
        },
        {
          "id": 1,
          "nome": "The Hand of Midas",
          "provedor_id": 1,
          "game_reference": "prag-slot-TheHandofMidas",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-TheHandofMidas"
        },
        {
          "id": 1,
          "nome": "The Knight King",
          "provedor_id": 1,
          "game_reference": "prag-slot-the-knight-king",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-the-knight-king"
        },
        {
          "id": 1,
          "nome": "Wild Bison Charge",
          "provedor_id": 1,
          "game_reference": "prag-slot-wild-bison-charge",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-wild-bison-charge"
        },
        {
          "id": 1,
          "nome": "Wild West Duels",
          "provedor_id": 1,
          "game_reference": "prag-slot-wild-west-duels",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-wild-west-duels"
        },
        {
          "id": 1,
          "nome": "Wild Wild Riches Megaways",
          "provedor_id": 1,
          "game_reference": "prag-slot-wild-wild-riches-megaways",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-wild-wild-riches-megaways"
        },
        {
          "id": 1,
          "nome": "Wisdom of Athena",
          "provedor_id": 1,
          "game_reference": "prag-slot-WisdomofAthena",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-WisdomofAthena"
        },
        {
          "id": 1,
          "nome": "Zeus vs Hades - Gods of War",
          "provedor_id": 1,
          "game_reference": "prag-slot-zeus-vs-hades-gods-of-war",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T22:33:31.000000Z",
          "updated_at": "2023-08-25T22:33:31.000000Z",
          "nome_provedor": "Pragmatic",
          "link": "/slot?game=prag-slot-zeus-vs-hades-gods-of-war"
        }
      ]
    },
    {
      "name": "PGSoft",
      "games": [
        {
          "id": 3,
          "nome": "Fortune Tiger",
          "provedor_id": 3,
          "game_reference": "pgs-slot-FortuneTiger",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-02T20:42:47.000000Z",
          "updated_at": "2023-08-01T20:42:47.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-FortuneTiger"
      },
      {
          "id": 3,
          "nome": "Leprechaun Riches",
          "provedor_id": 3,
          "game_reference": "pgs-slot-leprechaunRiches",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-02T20:42:47.000000Z",
          "updated_at": "2023-08-02T20:42:47.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-leprechaunRiches"
      },
      {
          "id": 3,
          "nome": "Candy Burst",
          "provedor_id": 3,
          "game_reference": "pgs-slot-candyburst",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-02T20:42:47.000000Z",
          "updated_at": "2023-08-02T20:42:47.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-candyburst"
      },
      {
          "id": 3,
          "nome": "Honey Trap of Diao Chan",
          "provedor_id": 3,
          "game_reference": "pgs-slot-honeyTrapOfDiaoChan",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-honeyTrapOfDiaoChan"
      },
      {
          "id": 3,
          "nome": "Fortune Gods",
          "provedor_id": 3,
          "game_reference": "pgs-slot-FortuneGods",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-FortuneGods"
      },
      {
          "id": 3,
          "nome": "Win Win Won",
          "provedor_id": 3,
          "game_reference": "pgs-slot-WinWinWon",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-WinWinWon"
      },
      {
          "id": 3,
          "nome": "Medusa II",
          "provedor_id": 3,
          "game_reference": "pgs-slot-MedusaII",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-MedusaII"
      },
      {
          "id": 3,
          "nome": "Tree Of Fortune",
          "provedor_id": 3,
          "game_reference": "pgs-slot-TreeOfFortune",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-TreeOfFortune"
      },
      {
          "id": 3,
          "nome": "Joker Wild ",
          "provedor_id": 3,
          "game_reference": "pgs-tb-JokerWild",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-tb-JokerWild"
      },
      {
          "id": 3,
          "nome": "Medusa",
          "provedor_id": 3,
          "game_reference": "pgs-slot-Medusa",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-Medusa"
      },
      {
          "id": 3,
          "nome": "Plushie Frenzy",
          "provedor_id": 3,
          "game_reference": "pgs-slot-PlushieFrenzy",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-PlushieFrenzy"
      },
      {
          "id": 3,
          "nome": "Gem Saviour",
          "provedor_id": 3,
          "game_reference": "pgs-slot-GemSaviour",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-GemSaviour"
      },
      {
          "id": 3,
          "nome": "Summon Conquer",
          "provedor_id": 3,
          "game_reference": "pgs-slot-SummonConquer",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-SummonConquer"
      },
      {
          "id": 3,
          "nome": "Hood vs Wolf",
          "provedor_id": 3,
          "game_reference": "pgs-slot-HoodvsWolf",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-HoodvsWolf"
      },
      {
          "id": 3,
          "nome": "Hot Pot",
          "provedor_id": 3,
          "game_reference": "pgs-slot-Hotpot",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-Hotpot"
      },
      {
          "id": 3,
          "nome": "Dragon Legend",
          "provedor_id": 3,
          "game_reference": "pgs-slot-DragonLegend",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-DragonLegend"
      },
      {
          "id": 3,
          "nome": "Mr Hallow Win",
          "provedor_id": 3,
          "game_reference": "pgs-slot-MrHallowWin",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-MrHallowWin"
      },
      {
          "id": 3,
          "nome": "Legend Of Hou Yi",
          "provedor_id": 3,
          "game_reference": "pgs-slot-LegendOfHouYi",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-LegendOfHouYi"
      },
      {
          "id": 3,
          "nome": "Prosperity Lion",
          "provedor_id": 3,
          "game_reference": "pgs-slot-ProsperityLion",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-ProsperityLion"
      },
      {
          "id": 3,
          "nome": "Hip Hop Panda",
          "provedor_id": 3,
          "game_reference": "pgs-slot-HipHopPanda",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-HipHopPanda"
      },
      {
          "id": 3,
          "nome": "Santas Gift Rush",
          "provedor_id": 3,
          "game_reference": "pgs-slot-SantasGiftRush",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-SantasGiftRush"
      },
      {
          "id": 3,
          "nome": "Baccarat Deluxe ",
          "provedor_id": 3,
          "game_reference": "pgs-tb-BaccaratDeluxe",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-tb-BaccaratDeluxe"
      },
      {
          "id": 3,
          "nome": "Gem Saviour Sword",
          "provedor_id": 3,
          "game_reference": "pgs-slot-GemSaviourSword",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-GemSaviourSword"
      },
      {
          "id": 3,
          "nome": "Piggy Gold",
          "provedor_id": 3,
          "game_reference": "pgs-slot-PiggyGold",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-PiggyGold"
      },
      {
          "id": 3,
          "nome": "Symbols Of Egypt",
          "provedor_id": 3,
          "game_reference": "pgs-slot-SymbolsOfEgypt",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-SymbolsOfEgypt"
      },
      {
          "id": 3,
          "nome": "Emperors Favour",
          "provedor_id": 3,
          "game_reference": "pgs-slot-EmperorsFavour",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-EmperorsFavour"
      },
      {
          "id": 3,
          "nome": "Jungle Delight",
          "provedor_id": 3,
          "game_reference": "pgs-slot-JungleDelight",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-JungleDelight"
      },
      {
          "id": 3,
          "nome": "Double Fortune",
          "provedor_id": 3,
          "game_reference": "pgs-slot-DoubleFortune",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-DoubleFortune"
      },
      {
          "id": 3,
          "nome": "The Great Ice Scape",
          "provedor_id": 3,
          "game_reference": "pgs-slot-TheGreatIcescape",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-TheGreatIcescape"
      },
      {
          "id": 3,
          "nome": "Journey To The Wealth",
          "provedor_id": 3,
          "game_reference": "pgs-slot-JourneyToTheWealth",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-JourneyToTheWealth"
      },
      {
          "id": 3,
          "nome": "Ganesha Gold",
          "provedor_id": 3,
          "game_reference": "pgs-slot-ganeshaGold",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-ganeshaGold"
      },
      {
          "id": 3,
          "nome": "Captains Bounty",
          "provedor_id": 3,
          "game_reference": "pgs-slot-captainsBounty",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-captainsBounty"
      },
      {
          "id": 3,
          "nome": "Flirting Scholar",
          "provedor_id": 3,
          "game_reference": "pgs-slot-flirtingScholar",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-flirtingScholar"
      },
      {
          "id": 3,
          "nome": "Ninja Vs Samurai",
          "provedor_id": 3,
          "game_reference": "pgs-slot-ninjaVsSamurai",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-ninjaVsSamurai"
      },
      {
          "id": 3,
          "nome": "Dragon Tiger Luck",
          "provedor_id": 3,
          "game_reference": "pgs-slot-dragonTigerLuck",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-dragonTigerLuck"
      },
      {
          "id": 3,
          "nome": "Mahjong Ways",
          "provedor_id": 3,
          "game_reference": "pgs-slot-mahjongWays",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-mahjongWays"
      },
      {
          "id": 3,
          "nome": "Dragon Hatch",
          "provedor_id": 3,
          "game_reference": "pgs-slot-dragonHatch",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-dragonHatch"
      },
      {
          "id": 3,
          "nome": "Fortune Mouse",
          "provedor_id": 3,
          "game_reference": "pgs-slot-fortuneMouse",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-fortuneMouse"
      },
      {
          "id": 3,
          "nome": "Reel Love",
          "provedor_id": 3,
          "game_reference": "pgs-slot-reelLove",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-reelLove"
      },
      {
          "id": 3,
          "nome": "Gem Saviour Conquest",
          "provedor_id": 3,
          "game_reference": "pgs-slot-gemsaviourconquest",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-gemsaviourconquest"
      },
      {
          "id": 3,
          "nome": "Shaolin Soccer",
          "provedor_id": 3,
          "game_reference": "pgs-slot-shaolinsoccer",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-shaolinsoccer"
      },
      {
          "id": 3,
          "nome": "Cai Shen Wins",
          "provedor_id": 3,
          "game_reference": "pgs-slot-caishenwins",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-caishenwins"
      },
      {
          "id": 3,
          "nome": "Bikini Paradise",
          "provedor_id": 3,
          "game_reference": "pgs-slot-bikiniparadise",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-bikiniparadise"
      },
      {
          "id": 3,
          "nome": "Ganesha Fortune",
          "provedor_id": 3,
          "game_reference": "pgs-slot-ganeshafortune",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-ganeshafortune"
      },
      {
          "id": 3,
          "nome": "Egypts Book Mystery",
          "provedor_id": 3,
          "game_reference": "pgs-slot-egyptsbookmystery",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-egyptsbookmystery"
      },
      {
          "id": 3,
          "nome": "Mahjong Ways 2",
          "provedor_id": 3,
          "game_reference": "pgs-slot-mahjongways2",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-mahjongways2"
      },
      {
          "id": 3,
          "nome": "Phoenix Rises",
          "provedor_id": 3,
          "game_reference": "pgs-slot-phoenixrises",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-phoenixrises"
      },
      {
          "id": 3,
          "nome": "Dreams of Macau",
          "provedor_id": 3,
          "game_reference": "pgs-slot-dreamsmacau",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-dreamsmacau"
      },
      {
          "id": 3,
          "nome": "Wild Fireworks",
          "provedor_id": 3,
          "game_reference": "pgs-slot-wildfFireworks",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-wildfFireworks"
      },
      {
          "id": 3,
          "nome": "Genies 3 Wishes",
          "provedor_id": 3,
          "game_reference": "pgs-slot-genies3Wishes",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-genies3Wishes"
      },
      {
          "id": 3,
          "nome": "Treasures Aztec",
          "provedor_id": 3,
          "game_reference": "pgs-slot-treasuresAztec",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-treasuresAztec"
      },
      {
          "id": 3,
          "nome": "Vampires Charm",
          "provedor_id": 3,
          "game_reference": "pgs-slot-vampiresCharm",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-vampiresCharm"
      },
      {
          "id": 3,
          "nome": "Circus Delight",
          "provedor_id": 3,
          "game_reference": "pgs-slot-circusDelight",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-circusDelight"
      },
      {
          "id": 3,
          "nome": "Thai River Wonders",
          "provedor_id": 3,
          "game_reference": "pgs-slot-thaiRiverWonders",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-thaiRiverWonders"
      },
      {
          "id": 3,
          "nome": "Secrets Of Cleopatra",
          "provedor_id": 3,
          "game_reference": "pgs-slot-secretsOfCleopatra",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-secretsOfCleopatra"
      },
      {
          "id": 3,
          "nome": "Muay Thai Champion",
          "provedor_id": 3,
          "game_reference": "pgs-slot-muayThaiChampion",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-muayThaiChampion"
      },
      {
          "id": 3,
          "nome": "Queen Bounty",
          "provedor_id": 3,
          "game_reference": "pgs-slot-queenBounty",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-queenBounty"
      },
      {
          "id": 3,
          "nome": "Jewels Prosper",
          "provedor_id": 3,
          "game_reference": "pgs-slot-jewelsProsper",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-jewelsProsper"
      },
      {
          "id": 3,
          "nome": "Lucky Neko",
          "provedor_id": 3,
          "game_reference": "pgs-slot-luckyNeko",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-luckyNeko"
      },
      {
          "id": 3,
          "nome": "Jack Frosts",
          "provedor_id": 3,
          "game_reference": "pgs-slot-jackFrosts",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-jackFrosts"
      },
      {
          "id": 3,
          "nome": "Guardian of Ice and Fire",
          "provedor_id": 3,
          "game_reference": "pgs-slot-gdnIceFire",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-gdnIceFire"
      },
      {
          "id": 3,
          "nome": "Opera Dynasty",
          "provedor_id": 3,
          "game_reference": "pgs-slot-operaDynasty",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-operaDynasty"
      },
      {
          "id": 3,
          "nome": "Fortune Ox",
          "provedor_id": 3,
          "game_reference": "pgs-slot-fortuneOx",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-fortuneOx"
      },
      {
          "id": 3,
          "nome": "Bali Vacation",
          "provedor_id": 3,
          "game_reference": "pgs-slot-baliVacation",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-baliVacation"
      },
      {
          "id": 3,
          "nome": "Candy Bonanza",
          "provedor_id": 3,
          "game_reference": "pgs-slot-candyBonanza",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-candyBonanza"
      },
      {
          "id": 3,
          "nome": "Majestic Treasures",
          "provedor_id": 3,
          "game_reference": "pgs-slot-majesticTreasures",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-majesticTreasures"
      },
      {
          "id": 3,
          "nome": "Wild Bandito",
          "provedor_id": 3,
          "game_reference": "pgs-slot-wildBandito",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-wildBandito"
      },
      {
          "id": 3,
          "nome": "Heist Stakes",
          "provedor_id": 3,
          "game_reference": "pgs-slot-heistStakes",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-heistStakes"
      },
      {
          "id": 3,
          "nome": "Crypto Gold",
          "provedor_id": 3,
          "game_reference": "pgs-slot-cryptoGold",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-cryptoGold"
      },
      {
          "id": 3,
          "nome": "Ways Of Qilin",
          "provedor_id": 3,
          "game_reference": "pgs-slot-waysQilin",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-waysQilin"
      },
      {
          "id": 3,
          "nome": "Rise Of Apollo",
          "provedor_id": 3,
          "game_reference": "pgs-slot-riseofApollo",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-riseofApollo"
      },
      {
          "id": 3,
          "nome": "Jurassic Kingdom",
          "provedor_id": 3,
          "game_reference": "pgs-slot-jurassicKingdom",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-jurassicKingdom"
      },
      {
          "id": 3,
          "nome": "Mermaid Riches",
          "provedor_id": 3,
          "game_reference": "pgs-slot-mermaidRiches",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-mermaidRiches"
      },
      {
          "id": 3,
          "nome": "Raider Jane's Crypt of Fortune",
          "provedor_id": 3,
          "game_reference": "pgs-slot-cryptFortune",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-cryptFortune"
      },
      {
          "id": 3,
          "nome": "Supermarket Spree",
          "provedor_id": 3,
          "game_reference": "pgs-slot-supermarketSpree",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-supermarketSpree"
      },
      {
          "id": 3,
          "nome": "Buffalo Win",
          "provedor_id": 3,
          "game_reference": "pgs-slot-buffaloWin",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-buffaloWin"
      },
      {
          "id": 3,
          "nome": "Legendary Monkey King",
          "provedor_id": 3,
          "game_reference": "pgs-slot-lMonkeyKing",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-lMonkeyKing"
      },
      {
          "id": 3,
          "nome": "Spirited Wonders",
          "provedor_id": 3,
          "game_reference": "pgs-slot-spiritedWonders",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-spiritedWonders"
      },
      {
          "id": 3,
          "nome": "Emoji Riches",
          "provedor_id": 3,
          "game_reference": "pgs-slot-emojiriches",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-emojiriches"
      },
      {
          "id": 3,
          "nome": "Cocktail Nights",
          "provedor_id": 3,
          "game_reference": "pgs-slot-cocktailnite",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-cocktailnite"
      },
      {
          "id": 3,
          "nome": "Mask Carnival",
          "provedor_id": 3,
          "game_reference": "pgs-slot-maskCarnival",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-maskCarnival"
      },
      {
          "id": 3,
          "nome": "Oriental Prosperity",
          "provedor_id": 3,
          "game_reference": "pgs-slot-orientalprosperity",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-orientalprosperity"
      },
      {
          "id": 3,
          "nome": "Garuda Gems",
          "provedor_id": 3,
          "game_reference": "pgs-slot-garudaGems",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-garudaGems"
      },
      {
          "id": 3,
          "nome": "Destiny of Sun and Moon",
          "provedor_id": 3,
          "game_reference": "pgs-slot-destinySunMoon",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-destinySunMoon"
      },
      {
          "id": 3,
          "nome": "The Queen's Banquet",
          "provedor_id": 3,
          "game_reference": "pgs-slot-queensBanquet",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-queensBanquet"
      },
      {
          "id": 3,
          "nome": "Butterfly Blossom",
          "provedor_id": 3,
          "game_reference": "pgs-slot-butterflyBlossom",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-butterflyBlossom"
      },
      {
          "id": 3,
          "nome": "Rooster Rumble",
          "provedor_id": 3,
          "game_reference": "pgs-slot-roosterRumble",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-roosterRumble"
      },
      {
          "id": 3,
          "nome": "Battleground Royale",
          "provedor_id": 3,
          "game_reference": "pgs-slot-battlegroundRoyale",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-battlegroundRoyale"
      },
      {
          "id": 3,
          "nome": "Win Win Fish Prawn Crab",
          "provedor_id": 3,
          "game_reference": "pgs-slot-winwinFishPC",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-winwinFishPC"
      },
      {
          "id": 3,
          "nome": "Prosperity Fortune Tree",
          "provedor_id": 3,
          "game_reference": "pgs-slot-prosperityfortuneT",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-prosperityfortuneT"
      },
      {
          "id": 3,
          "nome": "Wild Bounty Showdown",
          "provedor_id": 3,
          "game_reference": "pgs-slot-wildbountyS",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-wildbountyS"
      },
      {
          "id": 3,
          "nome": "Totem Wonders",
          "provedor_id": 3,
          "game_reference": "pgs-slot-TotemWonders",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-TotemWonders"
      },
      {
          "id": 3,
          "nome": "Alchemy Gold",
          "provedor_id": 3,
          "game_reference": "pgs-slot-AlchemyGold",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-AlchemyGold"
      },
      {
          "id": 3,
          "nome": "Legend of Perseus",
          "provedor_id": 3,
          "game_reference": "pgs-slot-LegendOfPerseus",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-LegendOfPerseus"
      },
      {
          "id": 3,
          "nome": "Wild Coaster",
          "provedor_id": 3,
          "game_reference": "pgs-slot-WildCoaster",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-WildCoaster"
      },
      {
          "id": 3,
          "nome": "Diner Delights",
          "provedor_id": 3,
          "game_reference": "pgs-slot-DinerDelights",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-DinerDelights"
      },
      {
          "id": 3,
          "nome": "Asgardian Rising",
          "provedor_id": 3,
          "game_reference": "pgs-slot-AsgardianRising",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-AsgardianRising"
      },
      {
          "id": 3,
          "nome": "Midas Fortune",
          "provedor_id": 3,
          "game_reference": "pgs-slot-MidasFortune",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-MidasFortune"
      },
      {
          "id": 3,
          "nome": "Fortune Rabbit",
          "provedor_id": 3,
          "game_reference": "pgs-slot-FortuneRabbit",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-FortuneRabbit"
      },
      {
          "id": 3,
          "nome": "Rave Party Fever",
          "provedor_id": 3,
          "game_reference": "pgs-slot-RavePartyFever",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-RavePartyFever"
      },
      {
          "id": 3,
          "nome": "Hawaiian Tiki",
          "provedor_id": 3,
          "game_reference": "pgs-slot-HawaiianTiki",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-HawaiianTiki"
      },
      {
          "id": 3,
          "nome": "Bakery Bonanza",
          "provedor_id": 3,
          "game_reference": "pgs-slot-BakeryBonanza",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-BakeryBonanza"
      },
      {
          "id": 3,
          "nome": "Songkran Splash",
          "provedor_id": 3,
          "game_reference": "pgs-slot-SongkranSplash",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-SongkranSplash"
      },
      {
          "id": 3,
          "nome": "Lucky Piggy",
          "provedor_id": 3,
          "game_reference": "pgs-slot-luckypiggyv2",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-luckypiggyv2"
      },
      {
          "id": 3,
          "nome": "Mystical Spirits",
          "provedor_id": 3,
          "game_reference": "pgs-slot-mysticalspirits",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-mysticalspirits"
      },
      {
          "id": 3,
          "nome": "Super Golf Drive",
          "provedor_id": 3,
          "game_reference": "pgs-slot-supergolfdrive",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-25T18:42:18.000000Z",
          "updated_at": "2023-08-25T18:42:18.000000Z",
          "nome_provedor": "PGSoft",
          "link": "/slot?game=pgs-slot-supergolfdrive"
      }
      ]
    },
    {
      "name": "Salsa",
      "games": [
        {
          "id": 2,
          "nome": "Super Hot Bingo",
          "provedor_id": 2,
          "imagem_base64": "teste",
          "game_reference": "shb",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-02T20:42:47.000000Z",
          "updated_at": "2023-08-02T20:42:47.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=shb"
        },
        {
          "id": 2,
          "nome": "Super Pachinko",
          "provedor_id": 2,
          "game_reference": "sp",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:33:48.000000Z",
          "updated_at": "2023-08-04T12:33:48.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=sp"
        },
        {
          "id": 2,
          "nome": "Zodiaco Bingo",
          "provedor_id": 2,
          "game_reference": "zb",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:41:05.000000Z",
          "updated_at": "2023-08-04T12:41:05.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=zb"
        },
        {
          "id": 2,
          "nome": "Show Ball",
          "provedor_id": 2,
          "game_reference": "sbp",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:42:31.000000Z",
          "updated_at": "2023-08-04T12:42:31.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=sbp"
        },
        {
          "id": 2,
          "nome": "Jogo dos Bichos Praia",
          "provedor_id": 2,
          "game_reference": "jbp",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-02T20:42:47.000000Z",
          "updated_at": "2023-08-02T20:42:47.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=jbp"
        },
        {
          "id": 2,
          "nome": "Super Flex Bingo",
          "provedor_id": 2,
          "game_reference": "sf",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:44:57.000000Z",
          "updated_at": "2023-08-04T12:44:57.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=sf"
        },
        {
          "id": 2,
          "nome": "Halloween Groove Bingo",
          "provedor_id": 2,
          "game_reference": "hgb",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-02T20:42:47.000000Z",
          "updated_at": "2023-08-02T20:42:47.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=hgb"
        },
        {
          "id": 2,
          "nome": "Candy Bingo",
          "provedor_id": 2,
          "game_reference": "cb",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:46:52.000000Z",
          "updated_at": "2023-08-04T12:46:52.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=cb"
        },
        {
          "id": 2,
          "nome": "Goal Bingo",
          "provedor_id": 2,
          "game_reference": "gb",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:47:56.000000Z",
          "updated_at": "2023-08-04T12:47:56.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=gb"
        },
        {
          "id": 2,
          "nome": "Pirate Bingo",
          "provedor_id": 2,
          "game_reference": "pb",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:49:07.000000Z",
          "updated_at": "2023-08-04T12:49:07.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=pb"
        },
        {
          "id": 2,
          "nome": "Candy 3D",
          "provedor_id": 2,
          "game_reference": "c3d",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:46:52.000000Z",
          "updated_at": "2023-08-04T12:46:52.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=c3d"
        },
        {
          "id": 2,
          "nome": "Pachinko 3D",
          "provedor_id": 2,
          "game_reference": "p3d",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:46:52.000000Z",
          "updated_at": "2023-08-04T12:46:52.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=p3d"
        },
        {
          "id": 2,
          "nome": "Pachinko 5",
          "provedor_id": 2,
          "game_reference": "p75",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:53:31.000000Z",
          "updated_at": "2023-08-04T12:53:31.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=p75"
        },
        {
          "id": 2,
          "nome": "Farm Bingo",
          "provedor_id": 2,
          "game_reference": "farm",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:55:13.000000Z",
          "updated_at": "2023-08-04T12:55:13.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=farm"
        },
        {
          "id": 2,
          "nome": "Super Pachinko Plus",
          "provedor_id": 2,
          "game_reference": "spp",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-04T12:57:43.000000Z",
          "updated_at": "2023-08-04T12:57:43.000000Z",
          "nome_provedor": "Salsa",
          "link": "/slot?game=spp"
        },

      ]
    }
  ])




  return (
    <>
      <div className="w-full flex justify-center my-10 ">
        <div className="rounded-xl border border-gray-600 items-center px-2 flex bg-cinzasecondary w-[95%]">
          <img src={Search} className="w-6" />
          <input
            placeholder="Procure seu jogo"
            className=" w-full bg-cinzasecondary p-2 text-white"
            onChange={(e) => {
              filterGame(e.target.value);
            }}
          ></input>
        </div>
      </div>



      {(localStorage.user_token && !loadingGames) ? itemsSelecteds.map((provedor) => (
        <>
          <div className="text-left w-full bg-green mt-4  flex justify-center">
            <div className="w-11/12 flex justify-between">
              <div className="w-6/12 text-white text-2xl font-bold hover:font-extrabold">
                {provedor.name}
              </div>

            </div>
          </div>

          <CarouselRow provedor={provedor} />
        </>
      )) :
      slotDeslogado.map((provedor) => (
          <>
            <div className="text-left w-full bg-green mt-4  flex justify-center">
              <div className="w-11/12 flex justify-between">
                <div className="w-6/12 text-white text-2xl font-bold hover:font-extrabold">
                  {provedor.name}
                </div>

              </div>
            </div>

            <CarouselRow provedor={provedor} />
          </>
        ))

      }



    </>
  );
}


