import ErrorImg from "../../assets/error/error.png"
export default function pageError(){
    return(
        <>
        <div className="flex w-full h-screen justify-center items-center">
            <img src={ErrorImg} />

        </div>
        </>
    )
}