import Play from '../../../../assets/slots/buttonPlay.png'
import React from 'react';


export default function CarouselItemPg(props) {

    const getGameImage = (gameReference) => {
        try {
          return require(`../../../../assets/slots/${gameReference}.jpg`);
        } catch (err) {
          console.log('File does not exist, loading fallback image');
          return require("../../../../assets/slots/buttonPlay.png");
        }
      };
      

        const gameImage = getGameImage(props.game_reference); 


    return (
        <a href={props.link} className="relative group">
            <img
                className="relative rounded-md w-[6em] h-[8em] lg:w-[14em] lg:h-[20em] lg:group-hover:scale-100 scale-90 duration-500"
                src={gameImage}
                alt={props.nome}
            />

            {/* Div de informações para o hover */}
            <div className="absolute z-[1] top-0 w-full h-full bg-black bg-opacity-60 flex items-center justify-center opacity-0 lg:group-hover:opacity-100 transition-opacity duration-500">

                {/* Adicione qualquer informação adicional sobre o jogo aqui */}
                <div className="flex flex-col items-center w-full z-[1]  justify-between h-full py-5 lg:py-10">
                    <div className="text-white flex justify-center w-full text-sm font-semibold ">
                        <div className="w-full lg:block whitespace-normal hidden text-center bg-[#0000003d]">
                            {props.nome}
                        </div>
                    </div>
                    <div>
                        <img src={Play} alt="play" className="block w-10 lg:w-20 lg:hover:scale-125 duration-500" />
                    </div>
                    <div className="hidden w-full justify-center relative lg:flex ">
                        <a href={props.link + '&free=true'} className="text-white text-[0.6em]  font-semibold border border-white p-1  relative rounded-md lg:mt-8 hover:bg-white hover:text-black duration-700">DEMO</a>
                    </div>




                </div>
            </div>
        </a>
    );
}