function BtnConfirmar({ texto, largura, altura, radius, sizeText, idBtn, onClick, disabled }) {
  return (

          <>

          <button
          id={idBtn}
          disabled={disabled}
          onClick={onClick}//executa funação cpIndicado do pai
          className={`bg-verdeprimary whitespace-nowrap uppercase  ${largura} ${altura} ${radius} ${sizeText} font-bold scale-95 hover:scale-100 hover:bg-verdesecondary hover:text-white  duration-700`} >
          {texto}
          </button>
          
          </>
  );
}

export default BtnConfirmar;
