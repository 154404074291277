import backtop from "../../assets/baixarapp/fundo-top.png"
import backtop2 from "../../assets/baixarapp/fundo-top2.png"
import Phone from "../../assets/baixarapp/phone.png"
import Baixeaqui from "../../assets/baixarapp/baixeaqui.png"
import Video from "../../assets/baixarapp/tutorial.mp4"
import Video2 from "../../assets/baixarapp/tutorial2.mp4"
import { useEffect, useState } from "react";
import Header from "../Header/header";
import { Link } from 'react-router-dom';

export default function BaixarApp() {

    const [iOSActive, setiOSActive] = useState(true);
    const [AndroidActive, setAndroidActive] = useState(false);
    const [changeMobile, setChangeMobile] = useState("iphone");

    function handleiOsClick() {
        setiOSActive(true);
        setAndroidActive(false);
        setChangeMobile("iphone")
    };

    function handleAndroidClick() {
        setiOSActive(false);
        setAndroidActive(true);
        setChangeMobile("android")
    }



    // VIDEO IPHONE
    function clickBtnAuto() {
        let unmuteButton = document.getElementById("unmuteButton")

        unmuteButton.addEventListener('click', function () {
            let video = document.getElementById("vidfundo")
            video.muted = false;
        });
    }

    async function audioPlay() {
        document.getElementById("vidfundo").play();

    };

    // VIDEO ANDROID

    function clickBtnAuto2() {
        let unmuteButton2 = document.getElementById("unmuteButton2")

        unmuteButton2.addEventListener('click', function () {
            let video2 = document.getElementById("vidfundo2")
            video2.muted = false;
        });
    }

    async function audioPlay2() {
        document.getElementById("vidfundo2").play();
    };



    async function sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    };


    useEffect(() => {

        if(changeMobile == "iphone"){
            audioPlay()
            clickBtnAuto()
           
        }
        if(changeMobile == "android"){
            audioPlay2()
            clickBtnAuto2()
          
        }
      


    }, [changeMobile])



    function closeTuto() {
        document.getElementById("tutorialapp").classList.add('hidden');
    }

    return (
        <>
            <Header />


            <div id="tutorialapp" className="z-[99999] w-full h-screen overflow-y-scroll bg-black">
                <div className="relative">
                    <div className="w-full h-full flex justify-center z-[1] pt-10 absolute">
                        <div className="montserrat font-black items-center relative flex flex-col">
                            <div className="text-white">JOGUE EM SEU</div>
                            <div className="text-green-400 text-4xl top-[-10px] relative">SMARTPHONE</div>

                        </div>


                    </div>

                    <img className="lg:hidden" src={backtop} />
                    <img className="hidden lg:block absolute" src={backtop2} />
                    <div className="w-full h-60 hidden lg:block"></div>
                </div>
                <div className="bg-gradient-to-b from-[#83a5f5] to-[#9391e6]  w-full flex items-center flex-col">
                    <div className="relative top-[-120px] w-full flex items-center flex-col">
                        <div className="lg:w-6/12">
                            <img src={Phone} />
                        </div>
                        <div className="montserrat font-black text-2xl text-purple-900">
                            BAIXE AGORA!
                        </div>
                        <div className="w-full flex justify-center">
                            <div className="w-8/12 rounded-xl p-1 justify-between flex border border-white">
                                <div onClick={handleiOsClick} className={`${iOSActive ? 'actbutton' : 'dstvbutton'} duration-300 cursor-pointer w-6/12  p-2 text-sm flex items-center justify-center  rounded-md montserrat font-black`}>IPHONE</div>
                                <div className=" bg-white w-[1px] h-full mx-2"></div>
                                <div onClick={handleAndroidClick} className={`${AndroidActive ? 'actbutton' : 'dstvbutton'} duration-300 cursor-pointer p-2 w-6/12 text-sm flex items-center justify-center  rounded-md montserrat font-black`}>ANDROID</div>
                            </div>

                        </div>

                        <div className="mt-10 w-full">
                            {iOSActive ?
                                <>
                                    <div className="w-full">
                                        <div className="w-full lg:w-8/12 lg:mx-auto pl-5 flex justify-start font-bold">
                                            <div className="text-xl text-purple-900">Instrução para iOS</div>
                                        </div>
                                        <div className="bg-[#481089]  flex flex-col">
                                            <div className="mt-2 h-[1px] w-full bg-white"></div>

                                            <div className="flex flex-col w-full lg:w-8/12 lg:mx-auto">

                                                <div className="flex items-center p-3">
                                                    <div className="bg-green-600 rounded-3xl flex items-center justify-center font-bold text-white   w-[30px] h-[30px]">1</div>
                                                    <div className="flex flex-wrap lg:w-6/12 w-10/12 ml-3 text-white">
                                                        <div className="font-bold">Abra no Safari</div>
                                                        <div className="text-[0.8em]">Se você estiver em outro navegador, abra esta página no Safari</div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center p-3">
                                                    <div className="bg-green-600 rounded-3xl flex items-center justify-center font-bold text-white   w-[30px] h-[30px]">2</div>
                                                    <div className="flex flex-wrap lg:w-6/12 w-10/12 ml-3 text-white">
                                                        <div className="font-bold">Clique em "Compartilhar</div>
                                                        <div className="text-[0.8em]">Toque no ícone Compartilhar no menu na parte inferior da tela. Uma janela será aberta.</div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center p-3">
                                                    <div className="bg-green-600 rounded-3xl flex items-center justify-center font-bold text-white   w-[30px] h-[30px]">3</div>
                                                    <div className="flex flex-wrap lg:w-6/12 w-10/12 ml-3 text-white">
                                                        <div className="font-bold">Toque em "Ir à tela de início"</div>
                                                        <div className="text-[0.8em]">Toque em "Ir à tela de início" e toque em "Pronto"</div>
                                                    </div>
                                                </div>


                                                <div className="w-full overflow-hidden flex justify-center">
                                                    <video className="w-10/12 lg:w-6/12 rounded-xl " id="vidfundo" src={Video} playsInline={true} muted={true} loop={true} />
                                                    <button id="unmuteButton"></button>
                                                </div>

                                                <div className="flex flex-col w-full mt-3 items-center">
                                                    <div className="text-xl font-bold text-white">Já Baixou ?</div>
                                                    <Link className="w-full flex justify-center" to="/runnerjelly">
                                                        <button className="animate-pulse bg-green-400 font-bold w-6/12 p-2 rounded-xl text-xl mt-2 mb-10 text-center ">JOGUE AGORA!</button>
                                                    </Link>

                                                </div>
                                            </div>



                                        </div>


                                    </div>
                                </>
                                :
                                <>
                                    <div className="w-full">
                                        <div className="w-full lg:w-8/12 lg:mx-auto pl-5 flex justify-start font-bold">
                                            <div className="text-xl text-purple-900">Instrução para Android</div>
                                        </div>
                                        <div className="bg-[#481089] w-full flex flex-col">
                                            <div className="mt-2 h-[1px] w-full bg-white"></div>

                                            <div className="flex flex-col lg:w-8/12 lg:mx-auto">

                                                <div className="flex items-center p-3">
                                                    <div className="bg-green-600 rounded-3xl flex items-center justify-center font-bold text-white   w-[30px] h-[30px]">1</div>
                                                    <div className="flex flex-wrap lg:w-6/12 w-10/12 ml-3 text-white">
                                                        <div className="font-bold">Abra no Google Chrome</div>
                                                        <div className="text-[0.8em]">Se você estiver em outro navegador, abra esta página no Google Chrome</div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center p-3">
                                                    <div className="bg-green-600 rounded-3xl flex items-center justify-center font-bold text-white   w-[30px] h-[30px]">2</div>
                                                    <div className="flex flex-wrap lg:w-6/12 w-10/12 ml-3 text-white">
                                                        <div className="font-bold">Clique em 3 pontos "Opções"</div>
                                                        <div className="text-[0.8em]">Toque no ícone de 3 Pontos no menu na parte superior da tela. Um menu será aberto.</div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center p-3">
                                                    <div className="bg-green-600 rounded-3xl flex items-center justify-center font-bold text-white   w-[30px] h-[30px]">3</div>
                                                    <div className="flex flex-wrap lg:w-6/12 w-10/12 ml-3 text-white">
                                                        <div className="font-bold">"Baixando o Aplicativo"</div>
                                                        <div className="text-[0.8em]">Toque em "Adicionar à tela de início" e toque em "Pronto"</div>
                                                    </div>
                                                </div>


                                                <div className="w-full overflow-hidden flex justify-center">
                                                    <video className="w-10/12 lg:w-6/12 rounded-xl " id="vidfundo2" src={Video2} playsInline={true} muted={true} loop={true} />
                                                    <button id="unmuteButton2"></button>
                                                </div>



                                                <div className="flex flex-col w-full mt-3 items-center">
                                                    <div className="text-xl font-bold text-white">Já Baixou ?</div>
                                                    <Link className="w-full flex justify-center" to="/runnerjelly">
                                                        <button className="animate-pulse bg-green-400 font-bold w-6/12 p-2 rounded-xl text-xl mt-2 mb-10 text-center ">JOGUE AGORA!</button>
                                                    </Link>

                                                </div>
                                            </div>



                                        </div>

                                    </div>
                                </>
                            }


                        </div>


                    </div>


                </div>
            </div>

        </>
    )
}