import ContainerBlur from "./blur/container";
import ConteudoHistoricoRodadasRoleta from "./historicoRoleta/conteudo";


function HistoricoRodadasRoleta () {


    return ( 
        <>
        
        <ContainerBlur openmodal="historicoRodadasRoleta">    {/* CONTAINER COM O BLUR ATIVADO*/}
       <ConteudoHistoricoRodadasRoleta openmodal="historicoRodadasRoleta"/>  {/* CONTEUDO DO MODAL */}  
 
 
    </ContainerBlur>
        </>
     );
}

export default HistoricoRodadasRoleta ;