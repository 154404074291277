import useRender from "../../hooks/useRender";
import { useState, useEffect } from 'react';
import Voltar from '../../assets/icons/voltar.gif'
import { Link } from 'react-router-dom'
import Title from "../../components/centraldojogador/titulo";
import VMasker from "vanilla-masker";
import Footer from "../../components/Footer";
import MenuPerfil from "./menu/perfil";
import SubMenuHistoricos from "./submenu/historicos";
import MenuIndicacao from "./menu/indicacao";
import SubMenuAjuda from "./submenu/ajuda";
import SubMenuPrivacidade from "./menu/privacidadeesegurança";
import MenuPromocao from "./menu/promocao";
import ModalValidarCel from "../../components/modals/ModalValidarCel/ModalValidarCel";


function TemplateCentralDoJogador(props) {
  const { prefixoUrl, setLoading } = useRender();
  const [render, setRender] = useState(1);
  const [location] = useState(window.location.href)

  /*  API HISTORICO DE JOGO     */
  async function HistoricoDeJogoApi() {
    var myHeaders = new Headers();

    var raw = "";

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/historicodejogos/crash/1000?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        dados.paginas = parseInt(dados.totalRegistro / 10);

        if (dados.totalRegistro % 10 > 0) {
          dados.paginas = dados.paginas + 1;
        }

        localStorage.setItem("historicoDeJogo", JSON.stringify(dados));

        return dados;
      })
      .catch((error) => console.log("error", error));
  }
  /* API HISTORICO DE SAQUE  */
  async function HistoricoDeSaqueApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/historicodesaques/1000?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        dados.paginas = parseInt(dados.totalRegistro / 10);

        if (dados.totalRegistro % 10 > 0) {
          dados.paginas = dados.paginas + 1;
        }

        localStorage.setItem("historicoDeSaque", JSON.stringify(dados));

        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  /* API HISTORICO DE DEPOSITO  */
  async function HistoricoDeDepositoApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/historicodedeposito/1000?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        dados.paginas = parseInt(dados.totalRegistro / 10);

        if (dados.totalRegistro % 10 > 0) {
          dados.paginas = dados.paginas + 1;
        }

        localStorage.setItem("historicoDeDeposito", JSON.stringify(dados));

        return dados;
      })
      .catch((error) => console.log("error", error));
  }

  /* API INDICAÇÃO */
  async function IndicacaoApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);

        localStorage.setItem("indicacao", JSON.stringify(dados));
      })
      .catch((error) => console.log("error", error));
  }

  /* API INDICAÇÃO SALDO TOTAL PARA RESGATE */
  async function SaldoTotalParaResgateApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao/saldototalpararesgate/20?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);

        localStorage.setItem("saldoTotalParaResgate", JSON.stringify(dados));
      })
      .catch((error) => console.log("error", error));
  }

  /* API NÚMERO TOTAL DE INDICADOS */
  async function NumeroTotalIndicadosApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao/numerototaldeindicados/20?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);

        localStorage.setItem("numeroTotalIndicados", JSON.stringify(dados));
      })
      .catch((error) => console.log("error", error));
  }

  /* API INDICADOS QUE DEPOSITARAM */

  async function IndicadosQueDepositaramApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao/indicadosquedepositaram/20?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);

        localStorage.setItem("indicadosQueDepositaram", JSON.stringify(dados));
      })
      .catch((error) => console.log("error", error));
  }

 

  



  function VoltarHome() {
    window.location.href = "/home";
  }

  useEffect(() => {
    //sem o setTimeout não aplica o mask
    setTimeout(() => {
      var docMask = ['999.999.999-99'];
      var doc = document.getElementById('inputCadastro');

      VMasker(doc).maskPattern(docMask[0]);
    }, 1)

    HistoricoDeJogoApi();
    HistoricoDeSaqueApi()
    HistoricoDeDepositoApi()
    IndicacaoApi()
    SaldoTotalParaResgateApi()
    NumeroTotalIndicadosApi()
    IndicadosQueDepositaramApi()
  }, []);


  return (
    <>
      <div className="w-full min-h-[850px] flex">
        <div className="w-3/12  bg-[#221f1f] border-r border-[#2e2c2c] hidden md:block">
          <div className="flex justify-between items-center py-4 px-2">
            <Link to="/home">
              <div className="p-1 ">
                <img className="w-[110px] cursor-pointer" src={Voltar} alt="" />
              </div>
            </Link>
          </div>
         
          <MenuPerfil />
          <MenuPromocao />
          <SubMenuHistoricos />
          <MenuIndicacao />
          <SubMenuAjuda />
          <SubMenuPrivacidade />
    
       
        </div>

        {/* Conteudo Central Do Jogador */}
        <div className=" lg:w-9/12  w-full flex justify-center">
          <div className=" w-full flex flex-col">
            <div className="flex justify-between py-4 px-4 border-b-2 border-verdeprimary">
              <Link to="/centraldojogador">
                <div className="md:hidden">
                  <img
                    className="w-[110px] cursor-pointer"
                    src={Voltar}
                    alt=""
                  />
                </div>
              </Link>

              <Title title={props.title} />
            </div>
            {props.children}
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </>
  );
}

export default TemplateCentralDoJogador;