export default function RegrasReembolso(){
    return(
    <>
      <div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">POLITICA DE REEMBOLSO</div>
                </div>
            
            Nenhum reembolso pode ser concluído uma vez que as finanças depositadas (incluindo o bônus) tenham sido usadas no processo de jogo. Um pedido de reembolso só será considerado se for solicitado dentro das primeiras vinte e quatro (24) horas da suposta transação. Reservamo-nos o direito de reter qualquer reembolso ou transação reversa até que a identidade do Usuário da conta do jogador seja adequadamente estabelecida para a nossa satisfação. Você concorda em fornecer, no caso da nossa solicitação, uma identificação autenticada ou qualquer outra identificação certificada de acordo com as leis aplicáveis ​​da jurisdição do jogador. Se tal identificação autenticada ou certificada não for fornecida dentro de cinco (5) dias a partir da nossa solicitação, tal reembolso ou transação reversa não será feita, a sua Conta de jogador será encerrada e você perderá todos os fundos na sua Conta de jogador, tal decisão será ser final, vinculante e inapelável. Um jogador deve jogar usando um método justo em todos os jogos e não deve de forma alguma afetar o resultado desse jogo. Isso inclui o uso de auxílios de computador, equações matemáticas, sistemas de apostas, etc.
              



            </div>
    </>
    )
}