
import Buttons from "./Controles";
import useRender from "../../../hooks/useRender";
import Tabela from "./tabelajogadores";
import { useEffect, useMemo, useState } from "react";
import Loading from "../../Loading";
import HistoricoRodadas from "../../modals/historicoRodadas";
import IcoHistorico from '../../../assets/buttons/historico.svg'
import { memo } from "react"

import LossComAposta from "./loss-com-aposta";
import LossSemAposta from "./loss-sem-aposta";



function Game() {

  const { prefixoUrl, updateSaldos } = useRender()
  const [rodadas, setRodadas] = useState()




  function apiHistoricoDeRodadas() {

    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${prefixoUrl}/crash/historicopartidas/24`, requestOptions)
      .then(response => response.text())
      .then(result => {
        setRodadas(JSON.parse(result))

      })
      .catch(error => console.log('error', error));
  }


  /* 
    function buttonsSound() {
      var ButtonsSound = document.getElementById("buttonsSound");
      ButtonsSound.innerHTML = [
        `  <div class="w-1/12 h-2 "></div>
       <div class="w-6/12 flex justify-between ">
      
       <div onClick="activeSound();" class="w-5/12 relative  ml-2 font-bold ">
       <div class="w-full absolute rounded-xl py-1  z-[1] active:top-[3px] cursor-pointer hover:bg-pink-500 transition duration-[50ms] hover:text-white font-bold bg-white flex justify-center items-center text-black">SIM</div>
       <div class="w-full absolute rounded-xl py-1 top-[5px] font-bold bg-verdeprimary flex justify-center items-center text-black">SIM</div>
       </div>
       
       <div onClick="desabledSound();" class="w-5/12 relative mr-5 font-bold ">
       <div class="w-full absolute rounded-xl py-1  z-[1] active:top-[3px] cursor-pointer hover:bg-pink-500 transition duration-[50ms] hover:text-white font-bold bg-white flex justify-center items-center text-black">NÃO</div>
       <div class="w-full absolute rounded-xl py-1 top-[5px] font-bold bg-verdeprimary flex justify-center items-center text-black">NÃO</div>
       </div>           
       </div>  `
  
      ];
  
  
    };
    */

  useEffect(() => {
    window.cliente.socket.on('atualizaSaldo', (saldoAtualizado) => {

      updateSaldos(saldoAtualizado);
    });




    apiHistoricoDeRodadas()

    // setTimeout(() => {
    //   buttonsSound();
    // }, 1300)



  }, []);






  function handleeHistorico() {
    document.getElementById("historicoRodadasModal").classList.toggle("hidden");
    document.getElementById("historicoExplosions").click()

  }


  return (
    <>
      <HistoricoRodadas />

      <div className="bg-[#191818] w-11/12 md:w-9/12 text-white   rounded-tl-3xl rounded-tr-3xl  mx-4 border border-verdeprimary mt-5 ">
        <div className="w-full flex justify-between bg-cinzaprimary  overflow-x-hidden rounded-tr-3xl rounded-tl-3xl">
          <div className="w-10/12 md:w-11/12 ">
            <div className="pl-4 bg-gradient-to-r from-[#129f0e]">
              <h2 className="hidden md:block text-2xl text-white">
                Rodadas Anteriores
              </h2>
            </div>
            {/* Rounds */}
            <div id="card-multiply-top-all" className="flex items-center p-1 mb-1 border-t-verdethird border-t border-r rounded-tr-xl rounded-br-xl border-r-verdeprimary border-b border-b-verdethird bg-cinzaprimary  justify-start w-full overflow-hidden flex-row-reverse">


              {rodadas != undefined ? rodadas.result.map((item, index) => {
                return (
                  <>
                    <div key={index} className={`${item.multiplicador > 2 ? "card-multiply-on text-multiply-win" : " card-multiply-off text-multiply-loss "}`}>{item.multiplicador.toFixed(2)}</div>

                  </>

                )
              }) : <Loading />}
            </div>

          </div>
          <div onClick={handleeHistorico} className="lg:hidden md:mt-8 flex justify-center items-center ">
            <div className="bg-[#344646] w-[35px] h-[35px] group-hover:bg-green-800 m-1 rounded-lg mr-3">
              <img className="w-8  " src={IcoHistorico} alt="" />
            </div>
          </div>

        </div>
        <div className="flex flex-wrap">


          {/* DIV DAD OF CANVAS */}
          <div className="md:w-8/12 relative w-full z-[1] p-2 ">

            <div className="w-full relative">
              <div id="btnSoundTop" className="absolute z-[3] left-[20px] top-[20px] w-1/12">
              </div>
              <div id="buttonsSound" className="absolute z-[3] bottom-[40%] w-full  flex">


              </div>

              <div id="black-and-white" className="absolute  w-full h-[100%] z-[1]  border-[8px] rounded-xl border-cinzathird"></div>
              <div className="absolute w-full h-[100%] z-[2]  border-[1px] rounded-xl border-roxosecondary"></div>

              {/* Modal de WIN CRASH */}
              <div className="absolute w-full h-[100%] flex items-center justify-center">

                {/* Modal de LOSS CRASH */}
                <LossComAposta />
                <LossSemAposta />
              </div>


              <div className="width-[640px] rounded-md" id="canvasscreen">

              </div>


              <div className="hidden lg:flex  justify-center items-center">
                <iframe
                  className='rounded-lg '
                  src={`${process.env.PUBLIC_URL}/SecretCyberSpace1090/index.html?v=2`}
                  width={`640`}
                  height={`360`}
                  frameBorder="0"
                ></iframe>
              </div>

              <div className="lg:hidden flex justify-center items-center">
                <iframe
                  className='rounded-lg '
                  src={`${process.env.PUBLIC_URL}/SecretCyberSpace1090/index.html?v=2`}

                  width={`640`}
                  height={`200`}
                  frameBorder="0"
                ></iframe>
              </div>

            </div>
          </div>


          <div className="w-full md:w-4/12">

            <Buttons />
          </div>
        </div>
        <Tabela />
      </div>
    </>
  );
}

export default memo(Game);