import FooterCard from "./FooterCard";
import InfoCard from "./InfoCard";
import ConteudoCard from "./ConteudoCard";

function CardAtivo(props) {

  function dropInfo(id) {
    //document.getElementById(`box--card--${id}`).classList.toggle("h-[270px]");
    document.getElementById(`verInfo${id}`).classList.toggle("hidden");
    document.getElementById(`card--${id}`).classList.toggle("hidden");
    document.getElementById(`card2--${id}`).classList.toggle("hidden");
    document.getElementById(`footer--${id}`).classList.toggle("hidden");
  }


    const item = props.item;

    function closeModalExcluirBonus() {
        document.getElementById("modalExcluirBonus").classList.toggle("hidden");
      }

    return (
        <>
                  <div
        id={`box--card--${item.id}`}
        key={item.id}
        className={`bg-[#323635] rounded-lg mt-3 mx-2 pb-3 duration-500 lg:w-[400px] w-[360px] h-[200px] ${item.status == 'Cancelado pelo usuário' ? `grayscale` : ``} `}
      >
        <div className="flex justify-between ">
          <div>
            <p className="p-2 ml-2 text-white text-2xl font-semibold uppercase">
              {item.code}
            </p>
          </div>

          <div
            id="info"
            onClick={() => dropInfo(item.id)}
            className={`rounded-full border-[1px] h-10 mt-3 mr-3 cursor-pointer  border-[#56F03D] bg-[#413B3B] hover:bg-[#312e2e] duration-700 ${item.status != 'Ativo' ? `grayscale` : ``}` }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="white"
              className="bi bi-info"
              viewBox="0 0 16 16"
            >
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </div>
        </div>

          <ConteudoCard item={props.item}></ConteudoCard>
          <InfoCard item={props.item}> </InfoCard>
          <FooterCard item={props.item} setCupomidModal={props.setCupomidModal}></FooterCard>



          


  





 
      </div>
        </>
    )

}

export default CardAtivo;