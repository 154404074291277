
import ContainerBlur from "./blur/container";
import ConteudoRegistro from "./registro/conteudo";



export default function RegistrarModal() {


  return (
    <>

    <ContainerBlur openmodal="modalRegistro">    {/* CONTAINER COM O BLUR ATIVADO*/}
      <ConteudoRegistro openmodal="modalRegistro"/>   {/* CONTEUDO DO MODAL */}   
    </ContainerBlur>
    </>

  )
};

