import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { io, Socket } from 'socket.io-client';
import Swal from "sweetalert2";
import DepositoSwetAlert from "../components/SwetAlert/Deposito";

export const DepositoContext = createContext({});

export const DepositoProvider = ({ children }) => {
    const [socket, setSocket] = useState();
    const [toke, setToke] = useState();
    const [Deposito, setDeposito] = useState();

    const [signed, setSigned] = useState(false);
    const [prefixoUrl] = useState("https://new-rockeetz-backend.seu.dev.br/api-v1");

    const ipServidor = "https://socket.rockeetz.com";
    const portaServidor = "443";
    const token = localStorage.getItem("user_token");


    function openModal() {
        var method = document.getElementById("DepositoConfirmado")
        method.classList.toggle("hidden");
    }




    function PagamentoAprovado() {

        socket.on("pagamentoAprovado", (dados) => {

            console.log("dados", dados)
            openModal()
            setDeposito(dados)

            //  sincronizarSaldoComBancoDeDados();
        });

        return () => {
            socket?.off("pagamentoAprovado", (dados) => {
                console.log("desligou")
            });
        };


    }




    useEffect(() => {
        if (socket) {

            PagamentoAprovado()     // atualizando botão para jogar


        }
    }, [socket]);



    useEffect(() => {
        const newSocket = io(`${ipServidor}:${portaServidor}`); // https://apcrash.rockeetz.com         http://bk.rockeetz.com:3051
        setSocket(newSocket);

        newSocket.emit("register", { token: token });
        setToke(token);
        return () => {
            newSocket.close();
        };
    }, [])





    //pega o token no local storage para fazer o login no servidor



    //  function sincronizarSaldoComBancoDeDados() {

    //     const Url = `${prefixoUrl}/cupom/bonus?token=${toke}`
    //     axios.get(Url)
    //       .then((response) => response.data)
    //       .then((result) => {
    //         const dados = result

    //         document.getElementById("varSaldo").innerHTML = dados.saldoreal;
    //         localStorage.setItem("Bonus", JSON.stringify(dados));
    //       })
    //       .catch((error) => console.log("error", error));
    //   }




    //   "https://new-rockeetz-backend.seu.dev.br/api-v1"       -- PRODUÇÂO --
    //   "https://test-new-rockeetz-backend.seu.dev.br/api-v1"  -- TESTE --
    //   "http://localhost:8000/api-v1")                        -- Beckend Local --




    useEffect(() => {


    }, []);

    return (

        <DepositoContext.Provider
            value={{
                Deposito,
                setDeposito,



            }}
        >

          


            {children}
        </DepositoContext.Provider>
    );
};
