export default function RegrasBonusEn(){
    return(
        <>

<div className="flex flex-col text-gray-400 text-[0.8em]">
    <div className="flex w-full justify-center">
        <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Privacy Policy</div>
    </div>
    <div className="text-white font-bold">BONUS COUPON</div>
    A rollover defines the multiplier at which the sum of your deposit and bonus needs to be played through.
    With our games, you can multiply your value by 1.1x, 2x, 5x, or even 100x upwards, depending on the game you are playing. We have set our rollover at an advantage of 4%. This means that if users play a game with a 1% advantage, it will be completed at a rate 4 times slower. Thus, the rollover requirement would be accumulated at a ratio of $0.25 for every $1 wagered. On the other hand, the higher the house edge of the game being played, the less wagering is required to complete the rollover. While having an active rollover, your account will be blocked until you fulfill the defined requirement or, alternatively, you lose your entire balance (in your relative bonus currency) and have no remaining active bets. During the active rollover, users cannot withdraw the bonus amount. Every time you win, you increase your bonus balance and decrease the % to complete the rollover. If you have a real balance, it will be deducted every time you lose a round.
    <br/><br/>
    <div className="text-white font-bold">REFERRAL BONUS</div>
    The referral bonus is granted for referring users who register and deposit the minimum amount for investment in Rockeetz's random system. The referral bonus consists of a bonus of R$10 per referred depositing user. The referral bonus is for a limited time at the discretion of the administrator. This campaign is subject to all rules described in Rockeetz's privacy policy.
</div>


            
        </>
    )
}