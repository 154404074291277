import Instagram from "../../assets/footer/instagram.png"
import logo from "../../assets/logo/rkt_branco.svg"
import Whatsapp from "../../assets/footer/whatsapp.png"
import Pix from "../../assets/footer/pix.png"
import Dz8 from "../../assets/footer/18.png"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import useRender from "../../hooks/useRender"


export default function Footer() {
  const [user, setUser] = useState({});
  const [whatsapp, setWhatsapp] = useState();
  const {openLogin, signed} = useRender();





  useEffect(() => {


    let resultado = localStorage.getItem("user");
    resultado = JSON.parse(resultado);
    if (resultado != null) {
      setUser(resultado.user);
    } else {

    }






  }, []);





  return (
    <div className="w-full mt-5 border-t-2 border-t-cinzaprimary bg-cinzasecondary">
      <div className="w-full flex pt-5 pl-3">
        <div className="lg:w-1/12 w-5/12 flex items-center"><img className="pl-5 lg:pl-0 w-10/12 lg:w-full" src={logo} /></div>
        <div className="lg:w-11/12 w-7/12 flex items-center"><div className="w-full h-[1px] bg-verdefourth"></div></div>
      </div>
      <div className="p-5  w-full flex flex-col lg:flex-row">
        <div className="lg:w-6/12 w-full flex items-center mb-2 ">
          <div className="w-6/12 flex lg:justify-around">
            <div className="flex-col flex justify-center">
              <div className="text-white text-sm">Redes Sociais</div>
              <div className="flex">
                <a href="https://www.instagram.com/rockeetz/">
                  <img className="w-[30px] h-[30px] lg:w-[40px] lg:h-[40px]" src={Instagram} />
                </a>
                <a href={`https://api.whatsapp.com/send?phone=5548988401734&text=Olá Rockeetz, meu e-mail de cadastro é: ${user.email} e meu cpf é: ${user.cpf}`}>
                  <img className="w-[30px] h-[30px] lg:w-[40px] lg:h-[40px]" src={Whatsapp} />
                </a>
              </div>
            </div>
            <div className="lg:flex flex-col justify-center hidden">
              <ul className="text-[0.6em] text-white">
                <li className="text-[0.5em]">SUPORTE:</li>
                <li>CONTATO@ROCKEETZ.COM</li>
                <li className="text-[0.5em]">PARCERIA:</li>
                <li>MARKETING@ROCKEETZ.COM</li>

              </ul>
            </div>
          </div>
          <div className="w-full lg:w-6/12">

            <div className="flex w-full lg:w-10/12  bg-[#01010180] rounded-xl items-center">
              <img className="w-full" src={Pix} />

            </div>
          </div>
        </div>
        <div className="lg:w-6/12 w-full flex justify-center lg:border-l-2 lg:border-l-cinzafourth">
          <div className="w-full flex justify-center">
            <div className="w-full flex justify-between lg:justify-around ">
              <div>
                <div className="uppercase font-bold text-green-400">Jogos</div>
                
                <ul className="text-[0.6em] text-white ">
                  {signed == true ? 
                  <>
                   <Link to="/runnerjelly">
                    <li className="hover:text-roxoprimary cursor-pointer">RUNNER JELLY</li>
                  </Link>
                  </>
                  :
                  <>
                   <div onClick={openLogin}>
                    <li className="hover:text-roxoprimary cursor-pointer">RUNNER JELLY</li>
                  </div>
                  </>
                  }
               
                  <a href="/mines">
                    <li className="hover:text-roxoprimary cursor-pointer">MINES</li>
                  </a>
                  <a href="/cyberspace">
                    <li className="hover:text-roxoprimary cursor-pointer">CYBER SPACE</li>
                  </a>
                  <Link to="/roulletplanet">
                    <li className="hover:text-roxoprimary cursor-pointer ">ROULLET PLANET</li>
                  </Link>
                  <a href="/gamefree">
                    <li className="hover:text-roxoprimary cursor-pointer">CRASH FREE</li>
                  </a>
                </ul>
              </div>
              <div>
                <div className="uppercase font-bold text-green-400 pr-10 lg:pr-0">Informações</div>
                <ul className="text-[0.6em] text-white ">
                  <Link to="/regras">
                    <li className="hover:text-roxoprimary cursor-pointer">REGRAS</li>
                  </Link>
                  <Link to="/centraldojogador/perguntasfrequentes">
                    <li className="hover:text-roxoprimary cursor-pointer ">PERGUNTAS FREQUENTES</li>
                  </Link>

                  <Link to="/suporte">
                    <li className="hover:text-roxoprimary cursor-pointer">SUPORTE</li>
                  </Link>
                </ul>
              </div>

            </div>
            <div className="flex items-center w-4/12">
              <img className="w-[50px] h-[50px]" src={Dz8} />
            </div>
          </div>


        </div>

      </div>
      <div className="w-full flex justify-center">
        <div className="w-full px-2 flex items-center flex-col">
          <div className="w-full h-[2px] bg-gray-800"></div>
          <div className="p-3 w-full lg:w-9/12 mt-3 font-semibold text-[0.7em] text-gray-300">
            
           <span className="text-green-300"> ROCKEETZ.COM is an online entertainment website that offers its users a unique sports betting experience. This website is operated by LAM GAMING GROUP B.V., a company registered in Curaçao, under number 163826, with registered office in Abraham de Veerstraat 9 – Curaçao, an entity duly authorized and licensed by the Government of Curaçao.
            By accessing, continuing to use, or browsing this website you agree that we may use certain browser cookies to improve your experience whilst using our site. We only use cookies to enhance your experience, and this does not interfere with your privacy.
            </span>
            <br /><br />
            ROCKEETZ.COM é um website de entretenimento online que oferece a seus usuários uma experiência única em apostas esportivas. Este website é operado por LAM GAMING GROUP B.V., uma empresa registrada em Curaçao, sob o número 163826, com sede em Abraham de Veerstraat 9 – Curaçao, devidamente autorizada e licenciada pelo Governo de Curaçao.
            <br /> <br />
            A Rockeetz aceita apenas clientes com mais de 18 anos de idade. Apostar pode ser viciante. Jogue responsavelmente.
          </div>
          <div className="w-full mt-5 flex justify-center">
          <iframe SRC="https://licensing.gaming-curacao.com/validator/?lh=c31dd945d36dfd4fca048819f4e49696&template=tseal" width="150" height="50" STYLE="border:none;"></iframe> 

      </div>
          <div className="w-full mt-3 h-[2px] bg-gray-800"></div>
          <div className="w-full justify-center flex text-[0.8em] mt-3 text-white mb-10">
            © 2023 Rockeetz.com Todos os direitos reservados.
          </div>
        </div>

      </div>
    </div>
  );
}
