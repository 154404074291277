import { useEffect, useState, useRef } from "react";
import useRender from "../../../hooks/useRender";
import Pagamento from "../../../components/modals/pagamento";
import Seta from '../../../assets/minhaConta/seta.png'
import Phone from '../../../assets/minhaConta/phone.png'
import Email from '../../../assets/minhaConta/email.png'
import Carteira from '../../../assets/minhaConta/carteira.png'
import CardRank from "./cardRank";
import Madeira from '../../../assets/minhaConta/shield_iniciante.png'
import Bronze from '../../../assets/minhaConta/shield_bronze.png'
import Prata from '../../../assets/minhaConta/shield_silver.png'
import Ouro from '../../../assets/minhaConta/shield_gold.png'
import Platina from '../../../assets/minhaConta/shield_platina.png'
import Diamante from '../../../assets/minhaConta/shield_diamente.png'
import Mestre from '../../../assets/minhaConta/shield_mestre.png'
import Mitico from '../../../assets/minhaConta/shield_gloriamitica.png'
import { Link } from "react-router-dom";


function MinhaConta({ user, level }) {


  const minhaContaRef = useRef(null)
  const carteiraRef = useRef(null)
  const carteiraBtnRef = useRef(null)
  const btnhandleeRef = useRef(null)
  const rewardsRef = useRef(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const totalSaldo = (Number(user?.saldoReal) + Number(user?.saldoBonus)).toFixed(2);
  const scrollRef = useRef(null);

  const rank = [
    {
      name: 'Madeira',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 0,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[0],
      next_level_apostasSlots: level?.array_level_apostasSlots[0],
      next_level_depositos: level?.array_level_depositos[0],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 0,
      streak_login: false
    },
    {
      name: 'Bronze',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 1,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[1],
      next_level_apostasSlots: level?.array_level_apostasSlots[1],
      next_level_depositos: level?.array_level_depositos[1],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 100,
      streak_login: false
    },
    {
      name: 'Prata',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 2,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[2],
      next_level_apostasSlots: level?.array_level_apostasSlots[2],
      next_level_depositos: level?.array_level_depositos[2],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 100,
      streak_login: true
    },
    {
      name: 'Ouro',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 3,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[3],
      next_level_apostasSlots: level?.array_level_apostasSlots[3],
      next_level_depositos: level?.array_level_depositos[3],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 200,
      streak_login: true
    },
    {
      name: 'Platina',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 4,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[4],
      next_level_apostasSlots: level?.array_level_apostasSlots[4],
      next_level_depositos: level?.array_level_depositos[4],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 600,
      streak_login: true
    },
    {
      name: 'Diamante',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 5,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[5],
      next_level_apostasSlots: level?.array_level_apostasSlots[5],
      next_level_depositos: level?.array_level_depositos[5],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 1000,
      streak_login: true
    },
    {
      name: 'Mestre',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 6,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[6],
      next_level_apostasSlots: level?.array_level_apostasSlots[6],
      next_level_depositos: level?.array_level_depositos[6],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 1500,
      streak_login: true
    },
    {
      name: 'Mitico',
      aposta_originais: 0,
      aposta_slot: 0,
      code: 1,
      depositos: 0,
      level: 7,
      next_level_apostasOriginais: level?.array_level_apostasOriginais[7],
      next_level_apostasSlots: level?.array_level_apostasSlots[7],
      next_level_depositos: level?.array_level_depositos[7],
      porcento_deposito: 0,
      porcento_originais: 0,
      porcento_slot: 0,
      retirada_dia: 2000,
      streak_login: true
    },]




  function handleeCarteira() {
    minhaContaRef.current.classList.add("text-[#31ED4F]")
    minhaContaRef.current.classList.remove("text-[#292828]")
    carteiraBtnRef.current.classList.add("text-[#292828]")
    btnhandleeRef.current.classList.add("translate-x-24")
    rewardsRef.current.classList.add("hidden")
    carteiraRef.current.classList.remove("hidden")
  }

  function handleeMinhaConta() {
    carteiraBtnRef.current.classList.add("text-[#31ED4F]")
    carteiraBtnRef.current.classList.remove("text-[#292828]")
    minhaContaRef.current.classList.add("text-[#292828]")
    btnhandleeRef.current.classList.remove("translate-x-24")
    carteiraRef.current.classList.add("hidden")
    rewardsRef.current.classList.remove("hidden")


  }

  function handleePerfil() {
    document.getElementById("handleePerfil").classList.toggle("hidden")
    document.getElementById("handleeMinhaConta").classList.toggle("hidden")


  }

  function methodPayment() {
    var method = document.getElementById("payment")
    method.classList.toggle("hidden");
    document.getElementById("modalPagamento").classList.remove("hidden");

  }


  const scroll = (scrollOffset) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollOffset, behavior: 'smooth' });
    }
  };




  const cardRef = useRef(null)
  const width = cardRef.current ? cardRef.current.offsetWidth : 0;
  const playerRank = level?.level;

  // Passo 1: Ordene o array de ranks
  const sortedRanks = [...rank].sort((a, b) => a.level - b.level);

  // Passo 2: Encontre o índice do level atual
  const index = sortedRanks.findIndex(r => r.level === playerRank);

  // Passo 3: Substitua o elemento no índice encontrado pelo level recebido via props
  if (index !== -1) {
    sortedRanks[index] = level;
  }

  // Rolar para o cartão de rank atual do jogador
  useEffect(() => {
    if (scrollRef.current) {
      const cardWidth = width;  // Largura estimada de um cartão, ajuste conforme necessário
      const offset = cardWidth * index;  // Deslocamento para rolar até o cartão de rank atual
      scrollRef.current.scrollTo({ left: offset, behavior: 'smooth' });
    }
  }, [index]);

  const handleScroll = () => {
    const scrollLeft = scrollRef.current.scrollLeft;
    const maxScrollLeft = scrollRef.current.scrollWidth - scrollRef.current.clientWidth;

    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < maxScrollLeft);
  };

  useEffect(() => {
    // Adicionar o evento de scroll ao iniciar o componente
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }

    // Remover o evento ao desmontar o componente
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);



  return (

    <div id="handleeMinhaConta" className="">

      <div className="bg-cinzafourth text-white text-[12px] font-semibold  rounded-lg px-3 py-6 lg:w-8/12 w-10/12 mx-auto my-6 relative hidden lg:block ">
        <div className="grid grid-cols-2">

          <div className="flex items-center gap-3">
            <div className="flex flex-col items-center leading-3">
              <img src={level?.level == 0 ? Madeira : level?.level == 1 ? Bronze : level?.level == 2 ? Prata : level?.level == 3 ? Ouro : level?.level == 4 ? Platina : level?.level == 5 ? Diamante : level?.level == 6 ? Mestre : level?.level == 7 ? Mitico : Madeira} alt="Rank" className="hover:scale-110 duration-500 lg:max-w-[110px]" />
              <p className="uppercase -mt-3 text-[10px] ">
                {level?.level == 0 ? "Madeira" : level?.level == 1 ? "Bronze" : level?.level == 2 ? "Prata" : level?.level == 3 ? "Ouro" : level?.level == 4 ? "Platina" : level?.level == 5 ? "Diamante" : level?.level == 6 ? "Mestre" : level?.level == 7 ? "Mitico" : "Madeira"}
              </p>
            </div>

            <div className="space-y-3 text-[10px] ">
              <p className="uppercase">{user?.apelido}</p>
              <p className="whitespace-nowrap">ID :  {75382 + user?.userid}</p>
            </div>

          </div>

          <div className="gap-3 grid  my-auto place-items-center lg:border-l lg:border-[#6A6666] text-[10px]">

            <div className="space-y-3">
              <span onClick={handleePerfil} className="cursor-pointer flex items-center gap-3">
                <img src={Phone} alt="" />
                {user?.cel_valid == 0 ? "NÃO VERIFICADO" : "VERIFICADO"}
              </span>


              <span className="flex items-center gap-3 ">
                <img src={Email} alt="" />
                {user?.email}
              </span>
            </div>



          </div>
        </div>

        <div onClick={handleePerfil} className="cursor-pointer absolute top-14 right-2">
          <img src={Seta} alt="arrow" className="hover:scale-110 duration-500 " />
        </div>



      </div> 

         {/* phone */}
      <div className="bg-cinzafourth text-white text-[12px] font-semibold  rounded-lg px-3 py-6 lg:w-8/12 w-10/12 mx-auto my-6 relative lg:hidden block ">
        <div className="grid grid-cols-3">

          <div className="flex items-center gap-3 col-span-1">
            <div className="flex flex-col items-center leading-3">
              <img src={level?.level == 0 ? Madeira : level?.level == 1 ? Bronze : level?.level == 2 ? Prata : level?.level == 3 ? Ouro : level?.level == 4 ? Platina : level?.level == 5 ? Diamante : level?.level == 6 ? Mestre : level?.level == 7 ? Mitico : Madeira} alt="Rank" className="hover:scale-110 duration-500 max-w-[110px]" />
              <p className="uppercase -mt-3 text-[10px] ">
                {level?.level == 0 ? "Madeira" : level?.level == 1 ? "Bronze" : level?.level == 2 ? "Prata" : level?.level == 3 ? "Ouro" : level?.level == 4 ? "Platina" : level?.level == 5 ? "Diamante" : level?.level == 6 ? "Mestre" : level?.level == 7 ? "Mitico" : "Madeira"}
              </p>
            </div>
          </div>

          <div className=" whitespace-nowrap  lg:border-l lg:border-[#6A6666] text-[10px] col-span-2 mx-auto ">

            <div className="space-y-2">
              <span className="flex items-center gap-2   ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-person" viewBox="0 0 16 16">
                  <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2v9.255S12 12 8 12s-5 1.755-5 1.755V2a1 1 0 0 1 1-1h5.5v2z" />
                </svg>
                <p className="uppercase">{user?.apelido}</p>
              </span>

              <span className="flex items-center gap-2   ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-journal-text" viewBox="0 0 16 16">
                  <path d="M5 10.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                  <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                  <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                </svg>
                <p className="whitespace-nowrap">ID :  {75382 + user?.userid}</p>
              </span>



              <span className="flex items-center gap-2 uppercase ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
                  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg>
                {user?.cel_valid == 0 ? "não verificado" : "verificado"}
              </span>


              <span className="flex items-center gap-2  ">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                </svg>
                {user?.email}
              </span>




            </div>



          </div>


        </div>

        <div onClick={handleePerfil} className="cursor-pointer absolute top-14 right-2">
          <img src={Seta} alt="arrow" className="hover:scale-110 duration-500 " />
        </div>



      </div>



      <div className="bg-cinzafourth text-white text-[12px] font-semibold  rounded-lg px-3 py-6 lg:w-8/12 w-10/12  mx-auto ">

        <div className="flex items-center justify-center pb-4 ">
          <div className="bg-[#292828] flex items-center  rounded-3xl relative   ">
            <button onClick={handleeMinhaConta} ref={minhaContaRef} className="rounded-3xl p-2    text-[#292828] duration-500 z-10 ">Minha Conta</button>
            <button onClick={handleeCarteira} ref={carteiraBtnRef} className="rounded-3xl p-2 px-6 text-[#31ED4F] duration-500 z-10 ">Carteira</button>
            <div ref={btnhandleeRef} className="rounded-3xl  bg-[#4BE431] text-[#292828] absolute w-[100px] h-full z-0 duration-500  "> </div>

          </div>
        </div>




        <div ref={rewardsRef} className=" ">


          <div className="relative">

            <button onClick={() => scroll(-width)} className={`absolute -left-10 top-0 bottom-0 z-10 ${!showLeftArrow ? 'hidden' : ''}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-caret-left" viewBox="0 0 16 16">
                <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
              </svg>
            </button>

            <div ref={scrollRef} className="overflow-x-auto  scroll-snap-x-mandatory ">
              <div className="inline-flex snap-start  lg:gap-3  ">





                {sortedRanks.map((r, index) => (
                  <div key={index} className={`card ${playerRank === r.level ? 'active' : ''}`}>
                    <CardRank level={r} cardRef={cardRef}  />
                  </div>
                ))}




              </div>
            </div>

            <button onClick={() => scroll(width)}  className={`absolute -right-10 top-0 bottom-0 z-10 ${!showRightArrow ? 'hidden' : ''}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-caret-right" viewBox="0 0 16 16">
                <path d="M6 12.796V3.204L11.481 8 6 12.796zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z" />
              </svg>
            </button>

          </div>







          <div className="text-sm flex items-center mt-4 gap-6 justify-center">
            <p className=" lg:hidden">_____</p>
            <p className="hidden lg:block">________________________</p>
            <p className="text-[10px] -mb-2 whitespace-nowrap">AVANCE PARA O PRÓXIMO NÍVEL</p>
            <p className="hidden lg:block">________________________</p>
            <p className=" lg:hidden">_____</p>


          </div>

        </div>


        <div ref={carteiraRef} className=" text-left space-y-2 hidden">
          <div className="bg-[#302f2f] rounded-lg flex items-center gap-3 p-3">
            <div className="flex items-center justify-between lg:w-3/12 w-5/12 whitespace-nowrap">
              <p>Saldo Total:</p>
              <p className="text-[17px]  ml-4 w-full ">{totalSaldo}</p>
            </div>
          </div>

          <div className="bg-[#302f2f] rounded-lg flex justify-between items-center gap-3 pl-3">
            <div className="flex items-center justify-between lg:w-3/12 w-5/12 whitespace-nowrap">
              <p>Saldo Real:</p>
              <p className="text-[17px]  ml-4 w-full ">{user?.saldoReal}</p>
            </div>


            <button onClick={methodPayment} className="bg-[#47FF1A] rounded-xl text-[#2E2929]  px-6 py-1 font-bold duration-500 border border-[#31ED4F] hover:bg-[#292828] hover:text-[#31ED4F] m-2 ">ADICIONAR</button>


          </div>


          <div className="bg-[#302f2f] rounded-lg flex items-center gap-3 p-3">
            <div className="flex items-center justify-between lg:w-3/12 w-5/12 whitespace-nowrap">
              <p>Saldo Bônus:</p>
              <p className="text-[17px]  ml-4 w-full   ">{user?.saldoBonus}</p>
            </div>
          </div>


          <div className="bg-[#302f2f] rounded-lg flex items-center gap-3 p-3">
            <div className="flex items-center justify-between lg:w-3/12 w-5/12 whitespace-nowrap">
              <p>Saldo RKTZ:</p>
              <p className="text-[17px]  ml-4 w-full ">{user?.saldorkzc}</p>
            </div>
          </div>

          <div className="bg-[#302f2f] rounded-lg  grid grid-cols-2">

            <div className="grid lg:grid-cols-2 grid-cols-4 bg-[#3B3B3B] rounded-lg m-4  min-h-[150px]">
              <div className="flex justify-center items-center col-span-4 lg:col-span-1"><img src={Carteira} alt="" /></div>
              <div className=" mx-auto lg:mx-0 col-span-4 lg:col-span-1 lg:my-auto">
                <p className="lg:text-[9px] text-[7px] whitespace-nowrap">Valor disponivel para saque</p>
                <p className="text-end mr-10 whitespace-nowrap">BRL <span className="text-2xl">{level?.retirada_dia}</span></p>
              </div>
            </div>

            <div className=" flex flex-col items-center justify-center space-y-6 mx-3">

              <Link to="/sacar">
                <button className="bg-[#47FF1A] rounded-xl text-[#2E2929]  lg:px-8 px-2 lg:py-2 py-1 font-bold duration-500 border border-[#31ED4F] hover:bg-[#292828] hover:text-[#31ED4F] mt-6 whitespace-nowrap ">RETIRAR FUNDOS</button>
              </Link>


              <div className="lg:flex items-center justify-between bg-[#572B2B] rounded-lg py-4 px-4 w-full whitespace-nowrap  grid sm:grid-cols-2 ">
                <p className="text-[9px] col-span-2 ">Valor pendente</p>
                <p className="text-end col-span-2  ">BRL <span className="text-2xl">{user?.saldoReal}</span></p>

              </div>
            </div>

          </div>




        </div>







      </div>

      <Pagamento />


    </div>

  );
}

export default MinhaConta;
