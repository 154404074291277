function ConteudoCard(props) {

    const item = props.item;

    return (
        <>


            <div id={`card--${item.id}`} className="flex justify-center mx-4  ">
                {item.status == 'Depósito pendente'  ? (
                          <div className="w-full">
                             <p className="text-[#797474] text-[14px]  font-semibold uppercase">Depósito necessário para ativar cupom.</p>
                          </div>
                ): (
                    <div className="  w-full   ">  
                    <div className="flex justify-between items-center">
                        <div className="">
                            <span className="text-[#797474] text-[14px]  font-semibold  ">
                                FALTA APOSTAR BRL {item.faltaapostar?.toFixed(2)}
                            </span>
                        </div>

                        <div className="">
                            <span className={`text-[#1AE270] text-[14px] ${item.status != 'Ativo' ? `grayscale` : ``}`}>
                                {item.status === "Ativo"
                                    ? item.porcentorollover
                                    : "0"}
                                %
                            </span>
                        </div>
                    </div>

                    <div className="pb-1 relative ">
                        <div className=" h-[5px] w-full bg-[#1C1717] absolute ">
                            <div
                                className={` h-[100%]  bg-[#36FB32] duration-1000 absolute  `} style={{ width: `${item.porcentorollover}%` }}></div>
                        </div>
                    </div>
                </div>
                )}
            </div>


            <div
          id={`card2--${item.id}`}
          className="flex justify-between mt-3 mx-4 text-[#797474] leading-none text-[14px] font-semibold"
        >
          {item.status == 'Depósito pendente' || item.status == 'Expirado' ? "" : (
                 <div>
                  <div className="text-[#FEFEFE]">RECEBIDO BRL{item.valor}</div>
                  <div className="">{item.recebido}</div>
                </div>
                   )}


        {/*--------------------------------------------------------------------*/}
        {item.status == "Cancelado pelo usuário" ? "" : (
            <div className="text-right ">
            <div className="text-[#B4A8A8]">{item.status == "Expirado" ? "EXPIRADO EM" : "EXPIRA EM"}</div>
            <div className="">{item.expira}</div>
          </div>
        )}          
        {/*--------------------------------------------------------------------*/}

        </div>
  

  


         


        </>


    );
}
export default ConteudoCard;
