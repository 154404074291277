import Topper from "../../../../components/Topper/Topper";
import Template from "../../../../layouts/template/template";
import CarrosselSalsa from "./carrossel";

export default function Pgsoft() {
    return (
        <>
            <Template>
                <div className="w-full min-h-[100vh]">
                    <CarrosselSalsa/>
                </div>
            </Template>

        </>
    )
}