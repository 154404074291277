function InfoCard(props) {

  const item = props.item;

  return (
    <>

      {/**  --------------------------------------------------------------------------------------------------------- */}
      {item.status == 'Cancelado pelo usuário' ?
        (
          <div
            id={`verInfo${item.id}`}
            className=" bg-[#323635] mx-4 hidden text-white  justify-center items-center p-2 text-left leading-5 "
          >
            <div className=" font-semibold leadind-none text-[14px]">
              Esse bônus foi cancelado pelo usuário.
            </div>


            <div className="text-[14px] text-white leadind-none ">
              Quando você exclui um cupom o saldo bônus é removido da conta e você não pode mais utilizar o mesmo cupom.
            </div>
          </div>
        ) : ""}
      {/**  --------------------------------------------------------------------------------------------------------- */}


      {/**  --------------------------------------------------------------------------------------------------------- */}
      {item.status == 'Depósito pendente' ?
        (
          <div
            id={`verInfo${item.id}`}
            className=" bg-[#323635] mx-4 hidden text-white  justify-center items-center p-2 text-left leading-5 "
          >
            <div className="text-[14px] text-white leadind-none ">
              {item.info}
            </div>
          </div>
        ) : ""}
      {/**  --------------------------------------------------------------------------------------------------------- */}





      {/**  --------------------------------------------------------------------------------------------------------- */}
      {item.status == 'Expirado' ?
        (
          <div
            id={`verInfo${item.id}`}
            className=" bg-[#323635] mx-4 hidden text-white  justify-center items-center p-2 text-left leading-5 "
          >
            <div className="text-[14px] text-white leadind-none ">
              Você não conseguiu atingir o rollover e o cupom foi expirado.
            </div>
          </div>
        ) : ""}
      {/**  --------------------------------------------------------------------------------------------------------- */}




      {/**  --------------------------------------------------------------------------------------------------------- */}
      {item.status == 'Ativo' ?
        (
          <div
            id={`verInfo${item.id}`}
            className=" bg-[#323635] mx-4 hidden text-white  justify-center items-center p-2 text-left leading-5 "
          >
            <div className=" font-semibold leadind-none text-[14px]">
              PARA RESGATAR SEU BÔNUS, VOCÊ DEVE COMPLETAR ROLLOVER DE:
            </div>
            <div className="text-[18px]   font-extrabold">

              BRL {item.faltaapostar?.toFixed(2)}
            </div>

            <div className="text-[10px] text-white leadind-none ">
              CASO VOCÊ EXCLUA ESSE BONUS, O VALOR ADQUIRIDO DURANTE AS
              RODADAS É EXCLUIDO E VOCÊ PERDE TODO SALDO DE BONUS ATUAL
            </div>
          </div>
        ) : ""}
      {/**  --------------------------------------------------------------------------------------------------------- */}


    </>


  );
}
export default InfoCard;



