import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import Player from '../../assets/cyberbomb/movedown.png'



function GameBoard() {

    return (
        <>
            <Header />

            <div className="w-full h-screen bg-[#260342]">

                <div className="bg-pink-600 h-24 meu-titulo">
                    <p className="meu-titulo"></p>
                    header
                </div>

                <div className="grid grid-cols-6">
                    <div className="col-span-1  h-screen p-2">
                        <div className="rounded-lg bg-[#651176]  border-[#260342] border-2 w-full h-24 flex justify-center items-center">
                            <div className="rounded-lg bg-[#C76EDF] min-w-[200px] min-h-[83px] ">menu 1</div>

                        </div>


                    </div>

                    <div className="bg-[#C76EDF]  col-span-5 border-4 border-[#530E6C]">
                        <div className="  p-3 bg-[#480063] m-4   ">
                            <div className="bg-[#924899] border-[#260342] border-[3px] ">
                                <div className="flex items-center ">
                                    <div className="bg-[#EAA0EE] text-[#9E56B8] uppercase font-bold w-full">titulo</div>
                                    <div className="bg-[#260342] text-[#D000E4] uppercase font-bold w-full">titulo</div>
                                </div>
                                <div className="grid grid-cols-6 p-2">
                                    <div className="col-span-1 flex justify-center ">
                                        <img src={Player} alt="" className="w-12 h-16 my-auto" /></div>
                                    <div className="col-span-4 space-y-1 text-[#F2A5F4] uppercase font-semibold">
                                        <div className="flex items-center  gap-2">
                                            <div className="bg-[#400062] w-full"> info 1</div>
                                            <div className="bg-[#400062] w-full"> info 1</div>

                                        </div>
                                        <div className="bg-[#400062]"> info 1</div>
                                        <div className="bg-[#400062]"> info 1</div>
                                        <div className="bg-[#400062]"> info 1</div>
                                    </div>
                                    <div className="col-span-1 flex justify-center ">
                                        <img src={Player} alt="" className="w-12 h-16 my-auto" /></div>

                                </div>
                                <div className="bg-[#EAA0EE] w-full h-2" ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GameBoard;
