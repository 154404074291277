
import { Link } from 'react-router-dom'
import {useState} from 'react'


export default function SubMenuHistoricos(){
    const [location] = useState(window.location.href)

    function DropSubMenuHistoricoDesktop() {
        document
          .getElementById("subMenuHistoricoDesktop")
          .classList.toggle("hidden");
        document.getElementById("subMenuAjudaDesktop").classList.add("hidden");
        document
          .getElementById("subMenuPrivacidadeDesktop")
          .classList.add("hidden");
      }
      
    return(
        <>
          {location.indexOf("historicodejogo") > -1 || location.indexOf("historicodesaque") > -1 || location.indexOf("historicodedeposito") > -1 ? (
             <div  className="border-t-2 border-[#221f1f] p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary" >
             <p className="text-white text-xl">Históricos</p>
           </div>
          ):  <div onClick={DropSubMenuHistoricoDesktop} className="border-t-2 border-[#221f1f] p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary" >
          <p className="text-white text-xl">Históricos</p>
        </div> }
         

          <div id="subMenuHistoricoDesktop" className="bg-[#181717] text-white p-4 hidden" >

            <Link to="/centraldojogador/historicodejogo">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"  >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Histórico De Jogo
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/historicodesaque">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"  >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Histórico De Saque
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/historicodedeposito">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"   >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Histórico De Depósito
                </p>
              </div>
            </Link>
          </div>

          {location.indexOf("historicodejogo") > -1 ? (
            
            <div id="subMenuHistoricoDesktop" className="bg-[#181717] text-white p-4" >
              <Link to="/centraldojogador/historicodejogo">
                <div className="hover:bg-[#131212] duration-700 rounded-lg border-r-2 bg-cinzathird border-verdeprimary hover:border-l-2 hover:border-verdeprimary m-1"  >
                  <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                    Histórico De Jogo
                  </p>
                </div>
              </Link>
              <Link to="/centraldojogador/historicodesaque">
                <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"  >
                  <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                    Histórico De Saque
                  </p>
                </div>
              </Link>
              <Link to="/centraldojogador/historicodedeposito">
                <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"   >
                  <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                    Histórico De Depósito
                  </p>
                </div>
              </Link>
            </div>
          ) : location.indexOf("historicodesaque") > -1 ? (
            <div id="subMenuHistoricoDesktop" className="bg-[#181717] text-white p-4" >

              <Link to="/centraldojogador/historicodejogo">
                <div className="hover:bg-[#131212] duration-700 rounded-lg  hover:border-l-2 hover:border-verdeprimary m-1"  >
                  <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                    Histórico De Jogo
                  </p>
                </div>
              </Link>
              <Link to="/centraldojogador/historicodesaque">
                <div className="hover:bg-[#131212] duration-700 rounded-lg border-r-2 bg-cinzathird border-verdeprimary hover:border-l-2 hover:border-verdeprimary m-1"  >
                  <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                    Histórico De Saque
                  </p>
                </div>
              </Link>
              <Link to="/centraldojogador/historicodedeposito">
                <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1"   >
                  <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                    Histórico De Depósito
                  </p>
                </div>
              </Link>
            </div>
          ): location.indexOf("historicodedeposito") > -1 ? (
            <div id="subMenuHistoricoDesktop" className="bg-[#181717] text-white p-4" >

            <Link to="/centraldojogador/historicodejogo">
              <div className="hover:bg-[#131212] duration-700 rounded-lg  hover:border-l-2 hover:border-verdeprimary m-1"  >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Histórico De Jogo
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/historicodesaque">
              <div className="hover:bg-[#131212] duration-700 rounded-lg  hover:border-l-2 hover:border-verdeprimary m-1"  >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Histórico De Saque
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/historicodedeposito">
              <div className="hover:bg-[#131212] duration-700 rounded-lg border-r-2 bg-cinzathird border-verdeprimary hover:border-l-2 hover:border-verdeprimary m-1"   >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Histórico De Depósito
                </p>
              </div>
            </Link>
          </div>
          ):""}
        </>
    )
}