
import { useState, useEffect } from "react";

import InputCriarConta from "../../../components/Input/InputCadastro/inputCadastro2023.js"
import ModalTermos from '../../../components/modals/TermosDeUso';
import useRender from "../../../hooks/useRender.js";
import Swal from "sweetalert2";
import VMasker from "vanilla-masker";
import ValidarConta from "./validarConta.js";
import CheckRegister from "./check.js";
import Pagamento from "./pagamento.js";


function ConteudoRegistro(props) {
    const Background = "../../../assets/register/fundo.png"
    const { prefixoUrl, tempo, setTempo } = useRender();
    const [validando, setValidando] = useState(false)
    const [steps, setSteps] = useState("step1")
    const [titulo, setTitulo] = useState("Faça seu cadastro")
    const [subTitulo, setSubtitulo] = useState("O cadastro leva menos de um minuto")
    const [ qrcodeactive, setQrcodeactive] = useState(false)

    /// INPUTS STATES
    const [senha, setSenha] = useState()
    const [verSenha, setVerSenha] = useState("password")
    const [email, setEmail] = useState(localStorage.register_email ? localStorage.register_email : "")
    const [apelido, setApelido] = useState(localStorage.register_apelido ? localStorage.register_apelido : "")
    const [nome, setNome] = useState(localStorage.register_nome ? localStorage.register_nome : "")
    const [sobrenome, setSobrenome] = useState(localStorage.register_sobrenome ? localStorage.register_sobrenome : "")
    const [cpf, setCpf] = useState((cpf) => {
        if (localStorage.register_cpf) {
            let cpfValue = localStorage.register_cpf;
            const cpfFormatado = cpfValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
            return cpf = cpfFormatado
        }
        return cpf = " "

    })
    const [tel, setTel] = useState((tel) => {
        if (localStorage.register_telefone) {
            let numero = localStorage.register_telefone
            const telefoneFormatado = numero.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1)$2-$3');
            return tel = telefoneFormatado
        }
        return tel = " "
    })
    const [telefone, setTelefone] = useState()
    const [dataNascimento, setDataNascimento] = useState(localStorage.register_dataNascimento ? localStorage.register_dataNascimento : " ")

    /*Maninipulando Bordas para validação */
    const [borderApelido, setBorderApelido] = useState("border-verdeseven")
    const [borderNome, setBorderNome] = useState("border-verdeseven")
    const [borderSobrenome, setBorderSobrenome] = useState("border-verdeseven")
    const [borderCpf, setBorderCpf] = useState("border-verdeseven")
    const [borderTel, setBorderTel] = useState("border-verdeseven")
    const [borderEmail, setBorderEmail] = useState("border-verdeseven")
    const [borderDataNascimento, setBorderDataNascimento] = useState("border-verdeseven")
    const [borderSenha, setBorderSenha] = useState("border-verdeseven")
    /* Erros */
    const [erroApelido, setErroApelido] = useState("")
    const [erroNome, setErroNome] = useState("")
    const [erroSobrenome, setErroSobrenome] = useState("")
    const [erroCpf, setErroCpf] = useState("")
    const [erroTel, setErroTel] = useState("")
    const [erroEmail, setErroEmail] = useState("")
    const [erroDataNascimento, setErroDataNascimento] = useState("")
    const [erroSenha, setErroSenha] = useState("")




    /// EMAIL VALIDACAO
    function validacaoEmail(value) {
        var usuario = value.substring(0, value.indexOf("@"));
        var dominio = value.substring(value.indexOf("@") + 1, value.length);

        if ((usuario.length >= 1) &&
            (dominio.length >= 3) &&
            (usuario.search("@") == -1) &&
            (dominio.search("@") == -1) &&
            (usuario.search(" ") == -1) &&
            (dominio.search(" ") == -1) &&
            (dominio.search(".") != -1) &&
            (dominio.indexOf(".") >= 1) &&
            (dominio.lastIndexOf(".") < dominio.length - 1)) {

            return true;
        }

        return false;
    }

    function ViewPassword() {
        if (verSenha == "password") setVerSenha("text")
        if (verSenha == "text") setVerSenha("password")
    }


    async function EmailCheck() {
        if (!email) {
            setBorderEmail("border-red-700")
            setErroEmail("Forneça o e-mail!")

            return false;
        }


        if (!validacaoEmail(email)) {
            setBorderEmail("border-red-700")
            setErroEmail("Insira um e-mail valido!")

            return false;
        }


        var myHeaders = new Headers();

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        return fetch(
            `${prefixoUrl}/user/register/validacampo?email=${email}`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let dados = JSON.parse(result);

                if (dados.resultValidate) {
                   
                    setBorderEmail("border-green-500")
                    localStorage.setItem("register_email", email);

                    setSteps("step2")
                    setTitulo("Informações Pessoais")

                    return true;

                } else {
                    setErroEmail(dados.resultMsg)
                    setBorderEmail("border-red-700")

                    return false
                }

            }).catch((error) => console.log("error", error));

    }
    async function ApelidoCheck() {
        if (!apelido) {
            setBorderApelido("border-red-700");
            setErroApelido("Campo vazio!");
            return false;
        }

        var myHeaders = new Headers();
        var raw = "";

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        return fetch(`${prefixoUrl}/user/register/validacampo?apelido=${apelido}`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let dados = JSON.parse(result);

                if (dados.resultValidate) {
                    localStorage.setItem("register_apelido", apelido);
                    setBorderApelido("border-green-500");
                    return true;
                } else {
                    setBorderApelido("border-red-700");
                    setErroApelido(dados.resultMsg);
                    return false;
                }
            })
            .catch((error) => console.log("error", error));
    }
    function NomeCheck() {
        if (!nome) {
            setBorderNome("border-red-700")
            setErroNome("Insira seu nome!")

            return false;
        }
        setBorderNome("border-green-500")
        localStorage.setItem("register_nome", nome);
        return true;
    }

    function SobrenomeCheck() {
        if (!sobrenome) {
            setBorderSobrenome("border-red-700")
            setErroSobrenome("Insira seu sobrenome!")
            return false;
        }
        setBorderSobrenome("border-green-500")
        localStorage.setItem("register_sobrenome", sobrenome);
        return true;
    }


    async function TelefoneCheck() {
        if (tel.length < 14) {
            setBorderTel("border-red-700")
            setErroTel("Insira um telefone valido!")

            return false;
        }

        let tel_formatted = tel.replace(/\D/g, "");
        setTelefone(tel_formatted)
        setBorderTel("border-green-500")
       
        var myHeaders = new Headers();
        var raw = "";

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        return fetch(`${prefixoUrl}/user/register/validacampo?telefone=${tel_formatted}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
            let dados = JSON.parse(result);

            if (dados.resultValidate) {
                localStorage.setItem("register_telefone", tel_formatted);
                setBorderTel("border-green-500");
                return true;
            } else {
                setBorderTel("border-red-700")
                setErroTel(dados.resultMsg);
                return false;
            }
        })
        .catch((error) => console.log("error", error));
    }

    function IdadeCheck() {
        if (!dataNascimento || dataNascimento == " ") {
            setBorderDataNascimento("border-red-700")
            setErroDataNascimento("Data de nascimento é obrigatória.")

            return false;
        }

        const dataNascimentoObj = new Date(dataNascimento);
        const diffMs = Date.now() - dataNascimentoObj.getTime();
        const idade = new Date(diffMs).getUTCFullYear() - 1970;

        if (idade < 18) {
            setBorderDataNascimento("border-red-700")
            setErroDataNascimento("Apenas maiores de 18 anos podem efetuar o cadastro.")

            return false;
        }

        setBorderDataNascimento("border-green-500")
        localStorage.setItem("register_dataNascimento", dataNascimento);

        return true;


    }

    function SenhaCheck() {


        if (!senha || senha.length < 8) {
            setErroSenha('A senha deve ter pelo menos 8 caracteres!')
            setBorderSenha("border-red-700")
            return false;
        }



        // Senhas válidas, 
        setBorderSenha("border-green-500")
        localStorage.setItem("register_password", senha);

        return true;
    }

    async function EnviarCodigoEmail() {

        let retorno_envio_email = null;
        let formData = new FormData();

        let apelido = localStorage.getItem("register_apelido");
        let email = localStorage.getItem("register_email");


        formData.append('name', apelido);
        formData.append('email', email);



        await fetch(`${prefixoUrl}/user/emailconfirmation`, {
            method: "POST",
            body: formData
        })
            .then((resposta) => resposta.json())
            .then((json) => {
                retorno_envio_email = json;
              
            }).catch(e => {
                console.log(e);
            })


        if (retorno_envio_email?.code != 1) {
            Swal.fire({
                icon: 'error',
                title: 'Opa...',
                text: retorno_envio_email.msg
            });
            setValidando(false);
            return false
        }

        return true
    }


    async function CpfCheck() {

        let list_validacoes = [
            !cpf,
            !valida_cpf(cpf)
        ];

        let list_msgs = [
            "Campo senha vázio!",
            "CPF inválido!",
        ];

        for (let index = 0; index < list_validacoes.length; index++) {
            const element = list_validacoes[index];

            if (element) {
                setErroCpf(list_msgs[index])
                setBorderCpf("border-red-700")
                return false;
            }
        }



        let cpf_query_formatted = cpf.replaceAll('.', '').replaceAll('-', '')

        var myHeaders = new Headers();
        var raw = "";

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        return fetch(
            `${prefixoUrl}/user/register/validacampo?cpf=${cpf_query_formatted}`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let dados = JSON.parse(result);

                if (dados.resultValidate) {
                    localStorage.setItem("register_cpf", cpf_query_formatted);
                    setBorderCpf("border-green-500")

                    return true;

                } else {
                    setErroCpf(dados.resultMsg)
                    setBorderCpf("border-red-700")

                    return false;
                }
            }).catch((error) => { console.log("error", error) });


    }
    function valida_cpf(cpf) {
        cpf = cpf.replace(/\D/g, '');
        if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
        var result = true;
        [9, 10].forEach(function (j) {
            var soma = 0, r;
            cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
                soma += parseInt(e) * ((j + 2) - (i + 1));
            });
            r = soma % 11;
            r = (r < 2) ? 0 : 11 - r;
            if (r != cpf.substring(j, j + 1)) result = false;
        });
        return result;
    }





    function TermosDeUso() {
        let method = document.getElementById("modalTermos")
        method.classList.toggle("hidden");
        let opacity = document.getElementById("TermosOpacity")
        opacity.classList.add("opacity-0");

        setTimeout(() => {
            opacity.classList.toggle("opacity-0");

        }, 100);

    }



    async function CriarConta() {
        EmailCheck()  

    }

    async function CaptureCode() {
        try {
            setValidando(true);

            const [apelidoValido, cpfValido, emailValido, telefoneValido] = await Promise.all([
                ApelidoCheck(),
                CpfCheck(),
                EmailCheck(),
                TelefoneCheck()
            ]);

            if (!apelidoValido) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Insira um apelido válido!",
                });
                setValidando(false);
                return;
            }

            if (!cpfValido) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Insira um CPF válido!",
                });
                setValidando(false);
                return;
            }

            if (!emailValido) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Insira um e-mail válido!",
                });
                setValidando(false);
                return;
            }
            if (!telefoneValido) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Telefone já cadastrado!",
                });
                setValidando(false);
                return;
            }

            if (
                NomeCheck() &&
                SobrenomeCheck() &&
                TelefoneCheck() &&
                IdadeCheck() &&
                SenhaCheck()
            ) {
                setValidando(false);
                if (EnviarCodigoEmail()) {
                    localStorage.setItem("validar_conta", true);
                    setSteps("step3")
                    setTitulo("Validação de Conta")
                    setSubtitulo("digite o código abaixo e ative sua conta")
                    setTempo(60);
                }
                return;
            }

            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Preencha os campos corretamente!",
            });
            setValidando(false);
        } catch (error) {
            console.error(error);
            setValidando(false);
        }
    }

    useEffect(() => {
        if (steps == "step2") {
            var docMask = ['999.999.999-99'];
            var doc = document.getElementById('inputCpf');
            VMasker(doc).maskPattern(docMask[0]);

            var docMaskNumber = ['(99)99999-9999'];
            var docTel = document.getElementById('inputTel');
            VMasker(docTel).maskPattern(docMaskNumber[0]);

            //  VerificandoSotare() // se existir dados no local store completa os inputs e verifica se são válidos
        }
    }, [steps])

    return (
        <>

            <div id="modalCriarConta" className="md:w-6/12 w-full bg-[#000000d1]  z-[6] backdrop-blur-md lg:rounded-xl border-2 flex flex-wrap border-green-700 h-screen lg:h-[80vh]">

                <div className="w-full h-full flex flex-col lg:flex-row">
                    <div className="bg-register w-full lg:w-6/12 h-[50px] lg:h-full bg-verdeseven lg:rounded-l-xl ">
               
                     <CheckRegister step={steps}/>
                    </div>
                    <div className="w-full lg:w-6/12 flex-col">
                        {/* STEP 1 */}
                      {qrcodeactive ? <></>
                       :
                       <>  <div className="w-full flex justify-center mt-5 flex-col items-center">
                       <div className="text-verdeseven montserrat font-bold text-xl">{titulo}</div>
                       <div className="relative top-[-5px] text-[0.6em] text-white">{subTitulo}</div>
                   </div></>}
                        <div>
                            <div className="w-full flex flex-col items-center space-y-1">

                                {steps == "step1" ?
                                    <>
                                        <div className="w-10/12">
                                            <p className="uppercase mb-2 text-verdeseven leading-3 pl-3 font-semibold ">email:</p>
                                            <InputCriarConta erro={erroEmail}  type="text" BorderColor={borderEmail} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                        <div className="w-10/12">

                                            <p className="text-white text-[10px]  ">
                                                Ao clicar em continuar eu concordo com todos os <strong onClick={TermosDeUso} className="text-[#2DE956] cursor-pointer">termos de uso</strong> e Certifico que sou maior de idade em meu país de residência e que não possuo nenhuma outra conta ativa na Rockeetz Games
                                            </p>

                                        </div>

                                        <div className="w-full flex justify-center ">
                                            <div className="w-10/12">
                                                <button disabled={validando} onClick={CriarConta} class="mt-5 disabled:opacity-50 w-full mb-4   bg-[#41DD3E] hover:text-white hover:bg-green-800 text-[#090808] font-bold py-1 text-sm lg:text-base uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-700">
                                                    {validando && <svg role="status" class="inline mr-2 w-6 h-6 text-green-200 whitespace-nowrap animate-spin dark:text-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                    </svg>}
                                                    {validando && 'Avançando...'}
                                                    {!validando && 'Avançar'}
                                                </button>
                                            </div>
                                        </div>

                                    </> : steps == "step2" ?

                                        <>
                                            <div className="w-10/12">
                                                <p className="uppercase mb-1 text-[0.6em] text-verdeseven leading-3 pl-3 font-semibold ">apelido:</p>
                                                <InputCriarConta erro={erroApelido} onBlur={ApelidoCheck} BorderColor={borderApelido} type="text" value={apelido} onChange={(e) => { setApelido(e.target.value) }} />
                                            </div>

                                            <div className="w-10/12 flex gap-2  justify-between">
                                                <div className="w-6/12">
                                                    <p className="uppercase text-[0.6em] mb-1 text-verdeseven leading-3 pl-3 font-semibold ">primeiro nome:</p>
                                                    <InputCriarConta erro={erroNome} onBlur={NomeCheck} type="text" BorderColor={borderNome} value={nome} onChange={(e) => { setNome(e.target.value) }} />
                                                </div>
                                                <div className="w-6/12">
                                                    <p className="uppercase text-[0.6em] mb-1 text-verdeseven leading-3 pl-3 font-semibold ">ultimo nome:</p>
                                                    <InputCriarConta erro={erroSobrenome} onBlur={SobrenomeCheck} type="text" BorderColor={borderSobrenome} value={sobrenome} onChange={(e) => { setSobrenome(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="w-10/12">
                                                <p className="uppercase mb-1 text-[0.6em]  text-verdeseven leading-3 pl-3 font-semibold ">cpf:</p>
                                                <InputCriarConta erro={erroCpf} onBlur={CpfCheck} id="inputCpf" type="text" BorderColor={borderCpf} value={cpf} onChange={(e) => { setCpf(e.target.value) }} />
                                            </div>
                                            <div className="w-10/12 flex gap-2  justify-between">
                                                <div className="w-6/12">
                                                    <p className="uppercase text-[0.6em] mb-1 text-verdeseven leading-3 pl-3 font-semibold ">Telefone:</p>
                                                    <InputCriarConta erro={erroTel} onBlur={TelefoneCheck} type="text" id="inputTel" BorderColor={borderTel} value={tel} onChange={(e) => { setTel(e.target.value) }} />
                                                </div>
                                                <div className="w-6/12">
                                                    <p className="uppercase text-[0.6em] mb-1 text-verdeseven leading-3 pl-3 font-semibold ">nascimento:</p>
                                                    <InputCriarConta erro={erroDataNascimento} onBlur={IdadeCheck} id="inputDia" BorderColor={borderDataNascimento} placeHolder="DD/MM/YYYY" type="date" value={dataNascimento} onChange={(e) => { setDataNascimento(e.target.value) }} />
                                                </div>
                                            </div>

                                            <div className="w-10/12">
                                                <div className="relative">
                                                    <p className="uppercase text-[0.6em] mb-1 text-verdeseven leading-3 pl-3 font-semibold ">password:</p>
                                                    <InputCriarConta erro={erroSenha} type={verSenha} value={senha} BorderColor={borderSenha} onChange={(e) => { setSenha(e.target.value) }} />
                                                    <div onClick={ViewPassword} className="absolute  right-[20px] top-[25px] flex items-center cursor-pointer">

                                                        {verSenha == "password" ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="white" class="bi bi-eye-slash-fill hover:scale-125 duration-300" viewBox="0 0 16 16">
                                                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="white" class="bi bi-eye hover:scale-125 duration-300" viewBox="0 0 16 16">
                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                            </svg>
                                                        )}



                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full flex justify-center ">
                                                <div className="w-10/12">
                                                    <div className="gap-2 flex">
                                                        <div onClick={() => { setSteps("step1") }} className="mt-5 disabled:opacity-50 w-full mb-4    hover:text-white hover:bg-green-800 text-white font-bold py-1 text-sm lg:text-base uppercase border-t-3 border-2 border-verdeseven hover:border-green-500 rounded-xl text-center duration-700">VOLTAR</div>
                                                        <button disabled={validando} onClick={() => { CaptureCode()}} className="mt-5 disabled:opacity-50 w-full mb-4   bg-[#41DD3E] hover:text-white hover:bg-green-800 text-[#090808] font-bold py-1 text-sm lg:text-base uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl duration-700">
                                                            {validando && <svg role="status" class="inline mr-2 w-6 h-6 text-green-200 whitespace-nowrap animate-spin dark:text-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                                                            </svg>}
                                                            {validando && 'Avançando...'}
                                                            {!validando && 'Avançar'}
                                                        </button>
                                                    </div>

                                                </div>
                                            </div>

                                        </>

                                        : steps == "step3" ?
                                        <>
                                            <ValidarConta titulo={titulo} setTitulo={setTitulo} subTitulo={subTitulo} setSubtitulo={setSubtitulo} step={steps} setSteps={setSteps} />
                                        </>
                                        : steps == "step4" &&
                                        <>
                                         <Pagamento qrcodeactive={qrcodeactive} setQrcodeactive={setQrcodeactive}/>
                                        </>}


                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <ModalTermos />


        </>
    );
}

export default ConteudoRegistro;