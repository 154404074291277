import useRender from "../../../hooks/useRender";
import Swal from "sweetalert2";

function ModalDepositoCupom() {

    const{  prefixoUrl  } = useRender();

    function closeModalDepositoPendente() {
        document.getElementById("modalDepositoPendente").classList.toggle("hidden");
        document.getElementById("modalCupom").classList.toggle("hidden");
      }


      function methodPayment(btn) {
        var method = document.getElementById("payment")
        method.classList.toggle("hidden");
        document.getElementById("modalPagamento").classList.remove("hidden");
        document.getElementById("modalCupom").classList.toggle("hidden");
        handlerAtivarCupom(btn)
      }

      function handlerAtivarCupom(btn){
        let svg = btn.target;
        svg = svg.getElementsByTagName('svg');
        let cupom = document.getElementById('cupom').value;

        var requestOptions = {
          method: 'GET',
        };
        
        fetch(`${prefixoUrl}/cupom/utilizar/${cupom}?token=${localStorage.getItem("user_token")}`, requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => {
            console.log('error', error);
      

          })
          
          .finally(()=> {
            svg[0].classList.remove('animate-spin')
          });
      }
    
    return ( 

        <>
        
        <div id="modalDepositoPendente" className="flex justify-center absolute top-0 bottom-0 left-0 right-0 items-center duration-1000 hidden">
                <div onClick={closeModalDepositoPendente} className="bg-black fixed top-0 bottom-0 left-0 right-0 opacity-75 "></div>

                <div className="z-[6]">
                    <div className=" bg-white w-[350px]  rounded-lg p-5 space-y-3">
                        <div className="text-black font-extrabold text-xl">
                            Depósito necessário!
                        </div>

                        <div className="text-[12px] font-bold text-black ">
                           para ativar esse cupom é necessário realizar um deposito!
                        </div>

                        <div className="flex justify-between ">
                            <div onClick={closeModalDepositoPendente} className="bg-[#797474] hover:bg-[#BB0C0C] uppercase duration-700   cursor-pointer rounded-full text-white px-4 py-1 font-bold">
                                Cancelar
                            </div>

                            <div onClick={(e) => {methodPayment(e)}}  className="bg-[#1AE270] uppercase hover:bg-[#11a04f] hover:text-white duration-700   cursor-pointer rounded-full text-[#373F38] px-4 py-1 font-bold">
                                Depositar
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
     );
}

export default ModalDepositoCupom;