import Header from '../../components/Header/header.js'
import Footer from '../../components/Footer/index.js';
import Pagamento from '../../components/modals/pagamento.js';
import Wincrash from '../../components/crash/game/wincrash.js';
import Winroullet from '../../components/Roleta/winroleta.js';
import Atalhos from '../../components/crash/atalhos.js';
import Cupom from '../../components/modals/cupom.js';
import PopUp from '../../components/modals/popup/popup1/PopUp.js';
import ConvertRkt from '../../components/modals/convertRkt.js';
import SelectGame from '../../components/modals/selectGame.js';
import WinCrashFree from '../../components/crashFree/game/wincrash.js';
import { useEffect } from 'react';

import Manutencao from '../../components/Manutencao/index.js';
import Blocked from '../blocked.js';
import RegistrarModal from '../../components/modals/registrar.js';
import DepositoSwetAlert from '../../components/SwetAlert/Deposito.js';
import PopUp2 from '../../components/modals/popup/popup2/PopUp2.js';
import PopUp3 from '../../components/modals/popup/popup3/PopUp3.js';
import ModalValidarCel from '../../components/modals/ModalValidarCel/ModalValidarCel.js';


function Template(props) {
  
  

  return (
    <>
      {/* Header */}
      <Header />

      {/* Conteudo */}
      <div className="grid grid-cols-1 justify-items-center">
        {props.children}
      </div>

      <DepositoSwetAlert />
      {/* MENSAGEM BANIMENTO */}
      <Blocked/>
      {/* MODAL'S */}
      <WinCrashFree />
      <Wincrash />
      <Winroullet />
      <Pagamento />
      <Atalhos />
      <Cupom />
      <PopUp />
      <PopUp2/>
      <PopUp3/>
      <ConvertRkt />
      <SelectGame />
      <RegistrarModal/>


      {/* Footer */}
      <Footer />

    </>);
}

export default Template;