import ConteudoBonusFinalStreak from "./SteakLogin/conteudoBonusFinal";



function BonusFinalStreak () {
    return ( 
        <>
        
          {/* CONTAINER COM O BLUR ATIVADO*/}

        <div id="BonusFinalStreak" className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center" >
        <div className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
          <div className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "></div>
            <div className="w-full flex justify-center items-center">
                 <ConteudoBonusFinalStreak openmodal="BonusFinalStreak"/>   {/* CONTEUDO DO MODAL */}  
            </div>
    
       
       
       </div>
      </div>






  
       
        </>
     );
}

export default BonusFinalStreak ;