import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useRender from "../../../hooks/useRender";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";
import Ticket from "./Ticket/index.js";
import IMGheader from "../../../assets/sorteio/sorteio.jpg"

export default function UserPromocoes() {
  const location = useLocation();
  const [numeros, setNumeros] = useState({});
  const { prefixoUrl, setLoading } = useRender();
  const [isParticipando, setParticipando] = useState(true);

  useEffect(() => {
    getNotifications();

  
  }, []);

  useEffect(()=>{
    if(numeros.length > 0){
      setParticipando(true);
    }else{
      setParticipando(false);
    }
  },[numeros])


  function Regras(){
    if(!isParticipando){
      setParticipando(true);
    }else{
      setParticipando(false);
    }
  }

  const getNotifications = () => {
    var myHeaders = new Headers();
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/obternumerosorteio?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        if (dados.code == 1) {
          setNumeros(dados.data);
        }

      })
      .catch((error) => console.log("error", error));

  }

  useEffect(() => { }, []);

  return (
    <TemplateCentralDoJogador title="informação da conta">
      <div className="text-white text-justify m-4 ">
        <div>
          <img src={IMGheader} />
        </div>

        {numeros.length > 0 &&<>
        
        
          <div class="columns-1 text-center ">
          <p className="uppercase text-center text-verdeprimary text-4xl py-8 font-bold">
            Você já está participando!
          </p>
          <div>MEUS TICKETS</div>
          <div className="bg-zinc-900 border border-separate border-zinc-800 rounded-md p-2 justify-center flex flex-wrap">

            {numeros.map((item, index) => {
              return <Ticket number={item} />;
            })}
          </div>

        </div>

        
        
        </>

        }
         
        {isParticipando == false ? <>
          <RegrasSorteio/>
        </>
        :
        <>
        <div onClick={Regras} className="w-full flex justify-center"> 
          <button className="bg-red-600 mt-3 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-600 hover:border-red-700 rounded">
           Ver Regras
          </button>
        </div>
        </>
        }
      



        {numeros.length > 0 ? <></> :

         <p className="uppercase text-center text-red-600 text-4xl py-8 font-bold">
          Você ainda não está participando!

        </p>}
      </div>
    </TemplateCentralDoJogador>
  );
}








function RegrasSorteio(){
  return(
    <>
      <p className="uppercase text-center text-verdeprimary text-4xl py-8 font-bold">
          Sorteio do <strong>iPhone 15</strong> - Como Participar e Regras
        </p>

        <div className="px-6">
          <p>
            Você terá uma grande chance de ganhar um Iphone 5 NOVO!
            <br></br>
            <br></br>
            Veja como é simples, para entrar no sorteio do{" "}
            <strong>iPhone 15</strong>, siga estas etapas fáceis:
          </p>

          <li className="py-2">
            Use o cupom "<span className="text-verdesixtyn font-bold">QUEROIPHONE15</span>" durante a sua compra.  Esses números serão seus bilhetes da sorte para o sorteio
            emocionante.
          </li>
          <li className="py-2">
            Faça um depósito no valor
            mínimo de 100 reais.
          </li>
          <li className="py-2">
            Após usar o cupom e depositar qualquer valor acima de 100 reais, você receberá
            automaticamente 5 números aleatórios exclusivos, variando de 0000 a
            9999.
          </li>
          <li className="py-2">
            Apenas os 2mil primeiros que usarem o cupom e depositarem estarão concorrendo.
          </li>
          <li className="py-2">
            Obrigatoriamente, é necessário o uso do cupom. não irá participar quem não utilizar o cupom.
          </li>
          <div className="text-2xl font-bold pt-5">Usei o cupom ganhei o bônus e saquei, continuo participando ?</div>
          <li className="py-2">
            Você participará, ganhando ou perdendo no periodo que utilizou o cupom.
          </li>
          <div className="text-2xl font-bold pt-5">Usei o cupom e depois cancelei, ainda participo ?</div>
          <li className="py-2">
            Você precisa jogar no minimo 2x do valor depositado em qualquer jogo! caso você use o cupom e cancele sem ter ativa-lo, não receberá os números.
          </li>


          <li className="py-2">
            O sorteio do <strong>iPhone 15</strong> será realizado em
            06/12/2023, às 20 horas (horário de Brasília).
          </li>

          <div className="text-2xl font-bold pt-5">Como os Números Serão Sorteados:</div>

          <li className="py-3"> Os números vencedores serão
            selecionados com total transparência. Usaremos os 4 primeiros
            números sorteados na  <a className="text-green-500 border-b border-verdesixtyn " href="https://loterias.caixa.gov.br/Paginas/Super-Sete.aspx"> Loteria Federal do jogo Super Sete</a> , garantindo
            um processo justo e imparcial.
          </li>

          <br></br>
          <br></br>
          <span className="text-xl">
            Agora você está pronto para participar do sorteio e ter a chance de
            ganhar o tão cobiçado <strong>iPhone 15</strong>. Boa sorte a todos
            os participantes!
          </span>
          <br></br>
          <br></br>
        </div>
    
    
    </>
  )
}