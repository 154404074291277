import useRender from "../../../hooks/useRender";
import { useState, useEffect } from "react";
import Loading from '../../Loading'
import Close from '../../../assets/icons/close.svg'
function ConteudoHistoricoRodadasRoleta(props) {

  const {prefixoUrl} = useRender()
  const [rodadas, setRodadas] = useState();

  function apiHistoricoDeRodadasRoleta() {
    
    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

fetch(`${prefixoUrl}/roleta/historicopartidas/190`, requestOptions)
  .then(response => response.text())
  .then(result => {
   
    setRodadas(JSON.parse(result))
    
  })
  .catch(error => console.log('error', error));
  }

  useEffect(()=>{
    apiHistoricoDeRodadasRoleta()
  },[])
  function closeHistorico() {
    document.getElementById(props.openmodal).classList.toggle("hidden");
  }

  return (
    <>


<div className="flex justify-center">
      <div className=" drop-shadow-lg  bg-[#424242]  z-[6] backdrop-blur-md  p-6 relative ">
        <div className="flex justify-end lg:hidden ">
            <img className="w-5" onClick={closeHistorico} src={Close} alt="" />
        </div>
        <div className="text-verdesixtyn text-center font-['Orbitron']  lg:text-4xl text-2xl whitespace-nowrap border-b-2 border-verdesixtyn pb-5">
            Histórico Rodadas
        </div>

        <div
          id="historicoRoletaModal" onClick={apiHistoricoDeRodadasRoleta}
          className="bg-[#2f2f2f] rounded-lg border border-[#242424] mt-3 p-4 grid lg:grid-cols-6 grid-cols-4 space-y-1 items-center overflow-y-scroll overflow-x-hidden max-h-[550px] "
        >
                    {rodadas != undefined ? rodadas.result.map((item, index) => {
                       
                    return (
                        <div key={index} className="ml-1 mr-1">
                        {item.cor == "branco" && (
                                <div className="grid justify-items-center">
                                    <div className="max-w-[100%]  bg-white rounded-md w-14 h-auto">
                                        <img className="w-full " src="./img/roleta/astro.png" />
                                    </div>  
                                        <p className="text-center text-white lg:text-sm text-[10px]">
                                            {item.hora}
                                        </p>
                                </div>
                            
                        )}
                        {item.cor == "azul" && (
                                            
                                            <div className="grid justify-items-center">
                                            <div className="max-w-[100%] w-14 h-auto relative flex justify-center items-center">
                                                <div className="absolute border-white border-2 text-[0.6em] font-bold bg-azulthird rounded-xl flex justify-center items-center w-[20px] h-[20px]">{item.cor_numero}</div>
                                                <img className="w-full " src="./img/roleta/planet.png" />
                                                </div>
                                                <p className="text-center text-white lg:text-sm text-[10px]">
                                                   {item.hora}
                                               </p>
                                       </div>

                        )}
                        {item.cor == "preto" && (

                                 <div className="grid justify-items-center">
                                      <div className="max-w-[100%] w-14 h-auto relative flex justify-center items-center">
                                        <div className="absolute border-white border-2 text-[0.6em] font-bold bg-cinzafourth rounded-xl flex justify-center items-center w-[20px] h-[20px]">{item.cor_numero}</div>
                                            <img className="w-full " src="./img/roleta/planet-cinza.png" />
                                        </div>
                                            <p className="text-center text-white lg:text-sm text-[10px]">
                                                {item.hora}
                                            </p>
                                </div>    )}
                    
                        
                        </div>
                    )
                    }) : <Loading/>}
        </div>
      </div>
    </div>
        
    
    </>
  );
}

export default ConteudoHistoricoRodadasRoleta;
