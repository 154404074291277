import ConteudoPopUp from './conteudo'
import ContainerBlur from "../../blur/container";
import { useState } from 'react';



export default function PopUp2() {

  const [modal, setModal] = useState();


  function openpopup2() {
    document.getElementById('modalPopUp2').classList.toggle("hidden");   
    openIndicacaoPP() 
  }

  function openIndicacaoPP() {
    let method = document.getElementById("modalPopUp3")
    method.classList.toggle("hidden");
    let opacity = document.getElementById("popup--3")
    opacity.classList.add("opacity-0");

    setTimeout(() => {
        opacity.classList.toggle("opacity-0");

    }, 100);
}


  return (
    <>

<div
        id="modalPopUp2"
        className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center"
      >
        <div className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
          <div  onClick={openpopup2} className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "  ></div>


          <ConteudoPopUp onClick={modal} openmodal="modalPopUp2" />   {/* CONTEUDO DO MODAL */}
        </div>
      </div>
    </>

  )
};

