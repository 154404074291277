import { useState, memo, useEffect } from "react";
import Pci_dss from "../../../assets/pagamento/pci_dss.png";
import Pix from "../../../assets/pagamento/pix.png"
import PixHeader from '../../../assets/pagamento/pix100.png'
import X from "../../../assets/pagamento/x.png"
import Swal from 'sweetalert2';
import BtnCancelar from '../../BtnCancelar';
import BtnConfirmar from "../../BtnConfirmar";
import useRender from '../../../hooks/useRender'

 function ConteudoPagamento(props) {

const {prefixoUrl} = useRender()

  useEffect(() => {

    
    let dados = localStorage.getItem("paymentInfo");
    dados = JSON.parse(dados);
    if(dados){     
      setCpf(dados.cpf)
      setNome(dados.name)
      setCep(dados.cep)
      setEndereco(dados.rua)
      setBairro(dados.bairro)
      setCidade(dados.cidade)
      setNum(dados.numero)
      setEstado(dados.uf)
      setDdd(dados.telefone.substring(0, 2))
      setTel(dados.telefone.substring(2))}
  }, []);

  const [quantia, setQuantia] = useState(30);
  const [Cpf, setCpf] = useState();
  const [nome, setNome] = useState();
  const [Cep, setCep] = useState();
  const [ddd, setDdd] = useState();
  const [tel, setTel] = useState();
  const [endereco, setEndereco] = useState();
  const [num, setNum] = useState();
  const [bairro, setBairro] = useState();
  const [cidade, setCidade] = useState();
  const [estado, setEstado] = useState();
  const [qrCode, setQrCode] = useState()


  
function apiQrCode() {
  let usuario = localStorage.getItem("user");
  usuario = JSON.parse(usuario);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "valor": quantia,
    "cpf": Cpf,
    "name": nome,
    "email": usuario.user.email,
    "uf": estado,
    "cidade": cidade,
    "cep": Cep,
    "numero": num,
    "rua": endereco,
    "bairro": bairro,
    "telefone": ddd+tel
  });

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${prefixoUrl}/qrcode?token=${localStorage.user_token}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    localStorage.setItem("paymentInfo", raw)
    let dados = JSON.parse(result)
    setQrCode(dados.qr_code)
  })
  .catch(error => console.log('error', error));
}
  

  function closemethodPayment() {
    document.getElementById(props.openmodal).classList.toggle("hidden");
 
  }

  function cancelPayment(){
    document.getElementById("qrCodeModal").classList.add("hidden")
    document.getElementById("bluPixQrCode").classList.add("hidden")
    closemethodPayment()
    setQrCode("")
  }

  function limpa_formulário_cep() {
    //Limpa valores do formulário de cep.
    document.getElementById('rua').value = ("");
    document.getElementById('bairro').value = ("");
    document.getElementById('cidade').value = ("");
    document.getElementById('uf').value = ("");

  }

function handleeQrCode() {
  if (!quantia || !Cpf || !nome || !ddd || !tel || !endereco || !num || !bairro || !cidade || !estado || !Cep){
    Swal.fire(
      'Ops..',
      'Preencha todos os campos!',
      'error'
    );

  }else if(quantia < 20){
    Swal.fire(
      'Ops..',
      'Valor mínimo para depósito é de R$ 20,00.',
      'error'
    );
  } 
  else{
     apiQrCode()
    document.getElementById("modalPagamento").classList.toggle("hidden");
    document.getElementById("qrCodeModal").classList.remove("hidden")
    document.getElementById("bluPixQrCode").classList.remove("hidden")
  }
  
}

// função que copia o qrCode
function cpQrCode() {
let qrCodeReplace = qrCode.replace(' ""')
navigator.clipboard.writeText(qrCodeReplace);
Swal.fire(
  'Sucesso',
  'QR Code copiado!',
  'success'
);
}


  function pesquisacep() {

    //Nova variável "cep" somente com dígitos.
    var cep = Cep.replace(/\D/g, '');

    //Verifica se campo cep possui valor informado.
    if (cep != "") {

      //Expressão regular para validar o CEP.
      var validacep = /^[0-9]{8}$/;

      //Valida o formato do CEP.
      if (validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        document.getElementById('rua').value = "...";
        document.getElementById('bairro').value = "...";
        document.getElementById('cidade').value = "...";
        document.getElementById('uf').value = "...";


        //Cria um elemento javascript.


        //Sincroniza com o callback.
        var Teste = fetch('https://viacep.com.br/ws/' + cep + '/json')
          .then((response) => response.json())
          .then((json) => {
            meu_callback(json);
          })






      } //end if.
      else {
        //cep é inválido.
        limpa_formulário_cep();

        Swal.fire(
          'Ops..',
          'O Cep Digitado é invalido.',
          'error'
        );
      }
    } //end if.
    else {
      //cep sem valor, limpa formulário.
      limpa_formulário_cep();
    }
  };
  function meu_callback(conteudo) {
    if (!("erro" in conteudo)) {
      //Atualiza os campos com os valores.
      setEndereco(conteudo.logradouro)
      setBairro(conteudo.bairro)
      setCidade(conteudo.localidade)
      setEstado(conteudo.uf)
    //document.getElementById('rua').value = (conteudo.logradouro);
     //document.getElementById('bairro').value = (conteudo.bairro);
     //document.getElementById('cidade').value = (conteudo.localidade);
     // document.getElementById('uf').value = (conteudo.uf);

    } //end if.
    else {
      //CEP não Encontrado.
      limpa_formulário_cep();

      Swal.fire(
        'Ops..',
        'CEP não encontrado.',
        'error'
      );
    }
  };



  function valida_cpf(cpf) {
    var cpf = Cpf.replace(/\D/g, '');
    if (cpf.toString().length != 11 || /^(\d)\1{10}$/.test(cpf)) return false;
    var result = true;
    [9, 10].forEach(function (j) {
      var soma = 0, r;
      cpf.split(/(?=)/).splice(0, j).forEach(function (e, i) {
        soma += parseInt(e) * ((j + 2) - (i + 1));
      });
      r = soma % 11;
      r = (r < 2) ? 0 : 11 - r;
      if (r != cpf.substring(j, j + 1)) result = false;

      if (!result) {
        Swal.fire(
          'Ops..',
          'SEU CPF É INVALIDO.',
          'error'
        );
      }
    });



    return result;
  }


  return (
    <>


      <div id="modalPagamento" className="md:w-8/12 w-11/12 bg-[#000000d1]  z-[6] backdrop-blur-md rounded-xl border-2 flex flex-wrap border-green-700 h-[95vh]">
       <div className="absolute right-[10px] top-[20px] text-white">
          <img onClick={closemethodPayment} className="w-6/12 cursor-pointer" src={X} />
        </div>
        <div className="w-full md:w-6/12 rounded-tr-xl rounded-tl-xl md:rounded-bl-xl bg-[#000000d1]">
          <div className="w-full flex-wrap flex text-center justify-center">
            <div className="w-full pl-3 justify-start md:justify-center">
              <div className="w-8/12 mb-5 text-white text-left text-[0.6em] font-bold pt-5">
                
              </div>
            </div>
            {/* INPUT QUANTIA*/}
            <div className="w-full bg-cinzaprimary py-2 flex justify-center">
              <div className="flex w-10/12 justify-between">
                <div className="text-white py-1 w-3/12 flex justify-start">
                  Quantia:
                </div>
                <div className="w-9/12">
                  <input id="valorDepositoPagsile" type="number" placeholder="Digite o valor do deposito" value={quantia} onChange={(event) => setQuantia(event.target.value)} className="text-sm text-white text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>
                </div>

              </div>
            </div>
            <div className="w-full bg-cinzaprimary py-2 mb-2 flex justify-center">
              <div className="flex w-10/12 lg:w-4/12 text-[0.6em] justify-start text-purple-400">
               Depósito minimo BRL 20

              </div>
            </div>
            {/* INPUT CPF*/}
            <div className="w-full bg-cinzaprimary py-2 flex mb-2 justify-center">
              <div className="flex w-10/12 justify-between">
                <div className="text-white py-1 w-3/12 flex justify-start">
                  CPF:
                </div>
                <div className="w-9/12">
                  <input onBlur={valida_cpf} value={Cpf} onChange={e => (setCpf(e.target.value))} id="cpfDeposito" type="number" placeholder="Digite o CPF do depositante" className="text-sm text-white text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>
                </div>

              </div>
            </div>
            {/* INPUT NAME*/}
            <div className="w-full bg-cinzaprimary py-2 mb-2 flex justify-center">
              <div className="flex w-10/12 justify-between">
                <div className="text-white py-1 w-3/12 flex justify-start">
                  Nome:
                </div>
                <div className="w-9/12">
                  <input type="text" id="nameDeposito" value={nome} onChange={(e) => setNome(e.target.value)} placeholder="Digite o Nome do depositante" className="text-sm text-white text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>
                </div>

              </div>
            </div>

            <div className="w-full bg-cinzaprimary py-2 mb-2 flex justify-center">
              <div className="flex w-10/12 text-[0.6em] justify-center text-purple-400">
                Caso o CPF informado sejá diferente da conta bancaria pagante o saldo ficará bloqueado e será necessário entrar em contato com o suporte.

              </div>
            </div>


            {/* INPUT CEP E TELEFONE*/}
            <div className="w-full bg-cinzaprimary py-2  flex justify-center">
              <div className="flex w-10/12 justify-between">
                <div className="w-5/12">
                  <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">CEP</div>
                  <input onBlur={pesquisacep} value={Cep} onChange={e => (setCep(e.target.value))} name="cep" type="text" id="cep" size={9} maxLength={9} placeholder="Digite o CEP" className="text-sm text-white text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>

                </div>
                <div className="w-6/12">
                  <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Telefone</div>
                  <div className="flex justify-between w-full">
                    <input id="codigoTelefonePagamento" type="text" placeholder="DDD" value={ddd} onChange={(e) => setDdd(e.target.value)} maxLength={2} className="text-sm text-white text-center text-sm font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-4/12 mr-2"></input>
                    <input id="telefonePagamento" placeholder="Telefone" value={tel} onChange={(e) => setTel(e.target.value)} maxLength={9} className="text-sm text-white text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-8/12"></input>
                  </div>

                </div>

              </div>
            </div>

            {/* endereço e numero*/}
            <div className="w-full bg-cinzaprimary py-2  flex justify-center">
              <div className="flex w-10/12 justify-between">
                <div className="w-8/12">
                  <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Endereço</div>
                  <input name="rua" type="text" id="rua" size={60} value={endereco} onChange={(e) => setEndereco(e.target.value)} placeholder="Digite o endereço" className="text-white  text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                </div>
                <div className="w-3/12">
                  <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Numero</div>

                  <input name="numero" id="numero" type="text" placeholder="Nº" value={num} onChange={(e) => setNum(e.target.value)} maxLength={5} className="text-white text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>



                </div>

              </div>
            </div>

            {/* bairro cidade e uf*/}
            <div className="w-full bg-cinzaprimary py-2 flex justify-center">
              <div className="flex w-10/12 justify-between">
                <div className="w-4/12">
                  <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Bairro</div>
                  <input name="bairro" id="bairro" size={60} type="text" placeholder="Digite o bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} className="text-white text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                </div>
                <div className="w-4/12">
                  <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Cidade</div>
                  <input name="cidade" type="text" id="cidade" size={40} placeholder="Digite a cidade" value={cidade} onChange={(e) => setCidade(e.target.value)} className="text-white text-sm text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-full"></input>
                </div>
                <div className="w-3/12">
                  <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">Estado</div>

                  <input name="uf" type="text" id="uf" maxLength={2} value={estado} onChange={(e) => setEstado(e.target.value)} className="text-sm text-white text-center font-bold px-2 bg-cinzasecondary border-2 focus:outline-none focus:border-verdeprimary border-roxothird rounded-md py-1 placeholder:text-center w-11/12"></input>



                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="w-full md:w-6/12 ">
          {/* metodo pagamento*/}
          <div className="w-full pt-0 md:pt-20 bg-cinzaprimary py-2 flex justify-center">
            <div className="flex w-10/12 justify-between">
              <div className="w-full">
                <div className="flex pl-3 text-[0.6em] text-verdeprimary justify-start">RECOMENDADO</div>
                <div onClick={handleeQrCode}  className="cursor-pointer hover:text-black flex justify-between text-sm text-center font-bold px-2 text-white bg-verdesecondary hover:bg-verdeprimary border-2 focus:outline-none focus:border-verdeprimary border-b-[5px] border-b-verdethird border-verdethird rounded-xl py-1 placeholder:text-center w-full">
                  <div className="text-xl pl-5">PIX</div>
                  <div className="w-1/12">
                    <img className="w-full  bg-white rounded-md" src={Pix} />
                  </div>

                </div>
              </div>


            </div>
          </div>




          <div className="w-full flex justify-center">
            <img className="w-4/12" src={Pci_dss} />
          </div>

        </div>
       
      




      </div>


    {/* qrCode */}
    <div id="bluPixQrCode" className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center">

<div  className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
  <div onClick={cancelPayment}  className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "></div>
      <div id="qrCodeModal" className="hidden md:w-4/12 w-11/12  h-[95vh]  bg-[#000000d1]  z-[6] backdrop-blur-md rounded-xl border-2 flex flex-wrap border-green-700">
            <div className="bg-[#000000d1] rounded-xl  w-full flex flex-col items-center">
            <div className="absolute right-[10px] top-[20px] text-white">
              <img onClick={cancelPayment} className="w-6/12 cursor-pointer" src={X} />
            </div>
              <div className="md:w-8/12 w-10/12 mb-2 border-b-2 border-verdesixtyn ">
                <img className="w-full" src={PixHeader} alt="logo pix" />
              </div>

              <div className="bg-white md:w-8/12 w-11/12 h-[45vh] flex justify-center items-center">
                {!qrCode ? (
              <svg role="status" className="inline mr-2 md:w-4/12 md:h-[25vh] h-6 text-green-200 animate-spin dark:text-[#717171]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#303030"/>
            </svg>
                ) : (
                  <img className="" src={`https://chart.googleapis.com/chart?chs=280x280&cht=qr&chl=${qrCode}`} alt="" />
                )}
           
              </div>

              <div className="text-white text-sm mt-2 md:pl-20 px-6">

       
                <p>
                    1 - Leia o código QR abaixo no aplicativo Pix
                </p>

                <p>
                  2 - Conclua o depósito com seu banco
                </p>

                <p>
                    3 - Seu saldo em R$ serão creditados em até imediatamente
                </p>
              </div>

              <div className="flex justify-center items-center text-white mt-3 gap-16">
          

              <BtnCancelar 
              onClick={cancelPayment}
                texto="Cancelar"
                altura="py-1"
                largura="px-2"
                radius="rounded-lg"
                sizeText="text-md"/>

                <BtnConfirmar
                onClick={cpQrCode}
                texto="Copiar Código"
                altura="py-1"
                largura="px-2"
                radius="rounded-lg"
                sizeText="text-md"/>
                
              </div>
            </div>
      
        </div>
 </div>
</div>



    </>

  )
};

export default memo(ConteudoPagamento);

