function ProgressBar(props) {
    return (<>

        <div className="w-full col-span-3 text-[10px]  ">
            <div className="flex justify-between items-center   ">
                <div className="">
                    <span className="   font-semibold uppercase whitespace-nowrap text-[8px] lg:text-md    "> 
                        {props.texto}
                    </span>
                </div>

                <div className="whitespace-nowrap text-[8px] lg:text-md  ">
                    <span className={` `}>
                        {props.status}
                    </span>
                </div>
            </div>
            <div className="pb-1 relative  ">
                <div className=" h-[5px] w-full bg-[#1C1717] absolute ">
                    <div className={` h-[100%]  bg-[#8331C3] duration-1000 absolute  `} style={{ width: `${props.porcent}%` }}></div>
                </div>
            </div>
        </div>
    </>);
}

export default ProgressBar;