export default function AmlKyc() {
    return (
        <>


            <div className="flex flex-col text-gray-400 text-[0.8em]">
                <div className="flex w-full justify-center">
                    <div className="uppercase text-xl text-white montserrat mt-2 font-semibold">Política AML de LAM Gaming Group B.V.</div>
                </div>
                <div className="text-white mt-5"> Última atualização: 1º de Junho de 2023</div>


                <br /><br /><br />
                (política AML Anti-Lavagem de Dinheiro de rockeetz.com
                <br /><br /> Introdução:  rockeetz.com é operado pela LAM Gaming Group B.V. com escritório em Abraham de Veerstraat 9, Willemstad, Curaçao. Número de registro da empresa 163826.
                <br /> <br />Objetivo da Política AML: Procuramos oferecer a maior segurança a todos os nossos usuários e clientes em www.rockeetz.com para que seja feita uma verificação de conta em três etapas para garantir a identidade de nossos clientes. A razão por trás disso é provar que os dados da pessoa cadastrada estão corretos e os métodos de depósito utilizados não são roubados ou usados por outra pessoa, o que é para criar o quadro geral de combate à lavagem de dinheiro. Também levamos em consideração que, dependendo da nacionalidade e origem, a forma de pagamento e para saque devem ser tomadas diferentes medidas de segurança.
                <br /><br />
                www.rockeetz.com também implementa medidas razoáveis para controlar e limitar o risco de ML, incluindo a disponibilização dos meios apropriados.
                <br /><br />
                www.rockeetz.com está comprometida com altos padrões de combate à lavagem de dinheiro (AML) de acordo com as diretrizes e conformidade da UE e exige que a administração e os funcionários apliquem esses padrões na prevenção do uso de seus serviços para fins de lavagem de dinheiro.

                <br /> <br />
                O programa AML de www.rockeetz.com é projetado para ser compatível com:
                <br /> <br />
                UE: “Diretiva 2015/849 do Parlamento Europeu e do Conselho de 20 de maio de 2015 sobre a prevenção da utilização do sistema financeiro para fins de branqueamento de capitais”.
                <br /> <br />
                UE: “Regulamento 2015/847 sobre informações que acompanham as transferências de fundos”.
                <br /> <br />
                UE: Vários regulamentos que impõem sanções ou medidas restritivas contra pessoas e embargos a certos bens e tecnologias, incluindo todos os bens de dupla utilização.
                <br /> <br /> UE: “Lei de 18 de setembro de 2017 sobre a prevenção da lavagem de dinheiro, limitação do uso de dinheiro.

                <div className="text-white mt-2 font-semibold">Definição de lavagem de dinheiro:</div>

                •	Entende-se por Lavagem de Dinheiro:
                <br />•	A conversão ou transferência de bens, especialmente dinheiro, sabendo que tais bens provêm de atividades criminosas ou de participação em tais atividades, com o objetivo de ocultar ou dissimular a origem ilícita dos bens ou de ajudar qualquer pessoa que esteja envolvida no crime comissão de tal atividade para evitar as consequências legais da ação dessa pessoa ou empresa.
                <br />•	A ocultação ou disfarce da verdadeira natureza, fonte, localização, disposição, movimento, direitos com relação a, ou propriedade de, propriedade, sabendo que tal propriedade é derivada de atividade criminosa ou de um ato de participação em tal atividade.
                <br />•	A aquisição, posse ou uso de propriedade, sabendo, no momento do recebimento, que tal propriedade foi derivada de atividade criminosa ou de assistência em tal atividade.
                <br />•	Participação, associação para cometer, tentativas de cometer e ajudar, incitar, facilitar e aconselhar a perpetração de qualquer das ações referidas nos pontos anteriores.
                <br /><br />
                O branqueamento de capitais é considerado como tal mesmo quando as atividades que deram origem aos bens a branquear tenham sido exercidas no território de outro Estado-Membro ou de um país terceiro.
                <div className="text-white mt-2 font-semibold">Organização da AML para www.rockeetz.com:</div>
                De acordo com a legislação AML, www.rockeetz.com nomeou o “nível mais alto” para a prevenção do BC: A gestão total da LAM Gaming Group B.V. está a cargo.
                <br /><br />Além disso, um AMLCO (Anti Money Laundering Compliance Officer) supervisiona a aplicação da política e procedimentos AML dentro do Sistema.
                <br /><br />
                <div className="text-white  text-md mt-2 font-semibold">A AMLCO fica sob a responsabilidade direta da Direção Geral:</div>
                <div className="text-white mt-2 font-semibold">Mudanças na política AML e requisitos de implementação:</div>
                
                Cada grande mudança de www.rockeetz.com A política AML está sujeita à aprovação da administração geral da LAM Gaming Group B.V. e do Diretor de Compliance Anti-Lavagem de Dinheiro.
                <div className="text-white mt-2 font-semibold">Verificação em três etapas:</div>
                A verificação da primeira etapa deve ser feita por todos os usuários e clientes para retirar. Em relação à escolha do pagamento, o valor do pagamento, o valor do saque, a escolha do saque e a nacionalidade do usuário/cliente primeiro passo deve ser feita a verificação. A verificação da primeira etapa é um documento que deve ser preenchido pelo próprio usuário/cliente. As seguintes informações devem ser preenchidas: primeiro nome, segundo nome, data de nascimento, país de residência habitual, sexo e endereço completo.
                <div className="text-white mt-2 font-semibold">Verificação da segunda etapa:</div>
                A verificação da etapa dois deve ser feita por todo usuário que depositar mais de 2.000$ (dois mil dólares) ou retirar mais de 2.000$ (dois mil dólares). Até que a verificação da etapa dois seja concluída, o saque ou depósito ficará em espera. A etapa de verificação levará o usuário ou cliente a uma subpágina onde deverá enviar seu ID. O utilizador/cliente deverá tirar uma fotografia do seu documento de identificação. Enquanto um clipe de papel com um número aleatório de seis dígitos está ao lado de seu ID: Somente um ID oficial pode ser usado para verificação de ID, dependendo do país, a variedade de IDs aceitos pode ser diferente. Também haverá uma verificação eletrônica se os dados preenchidos na verificação da etapa um estão corretos. O cheque eletrônico verificará através de dois bancos de dados diferentes para garantir que as informações fornecidas coincidam com o documento preenchido e o nome do RG: Se o teste eletrônico falhar ou não for possível, o usuário/cliente é obrigado a enviar uma confirmação de seu atual residente. Um certificado de registro pelo governo ou um documento similar é necessário.
                <div className="text-white mt-2 font-semibold">Verificação da terceira etapa:</div>
                A verificação da terceira etapa deve ser feita por todo usuário que depositar mais de 5.000$ (cinco mil dólares) ou retirar mais de 5.000$ (cinco mil dólares). Até que a verificação da etapa três seja concluída, o saque ou depósito ficará em espera. Para a etapa 3, um usuário/cliente será solicitado a fornecer uma fonte de riqueza.
                <div className="text-white mt-2 font-semibold">Identificação e verificação do cliente (KYC)</div>
                A identificação formal dos clientes no início das relações comerciais é um elemento vital, tanto para os regulamentos relativos ao branqueamento de capitais como para a política KYC.
Esta identificação assenta nos seguintes princípios fundamentais:
Uma cópia do seu passaporte, carteira de identidade ou carteira de habilitação, cada um mostrado ao lado de uma nota manuscrita mencionando seis números gerados aleatoriamente. Além disso, é necessária uma segunda foto com o rosto do usuário/cliente. O usuário/cliente poderá borrar todas as informações, inclusive data de nascimento, nacionalidade, sexo, nome, segundo nome e a foto. Para proteger sua privacidade.
Observe que todos os quatro cantos do ID devem estar visíveis na mesma imagem e todos os detalhes devem ser claramente legíveis além do mencionado acima. Podemos pedir todos os detalhes, se necessário.
Um funcionário pode fazer verificações adicionais, se necessário, com base na situação.


<div className="text-white mt-2 font-semibold">Prova de endereço:</div>
A comprovação de endereço será feita por meio de diferentes cheques eletrônicos, que utilizam dois bancos de dados diferentes. Se um teste eletrônico falhar, o usuário/cliente tem a opção de fazer uma prova manualmente.
Uma conta de serviços públicos recente enviada para o seu endereço registrado, emitida nos últimos 3 meses ou um documento oficial feito pelo governo que comprove seu estado de residência.
Para tornar o processo de aprovação o mais rápido possível, certifique-se de que o documento seja enviado com uma resolução clara, onde todos os quatro cantos do documento estejam visíveis e todo o texto seja legível.
Por exemplo: Uma conta de luz, conta de água, extrato bancário ou qualquer postagem governamental endereçada a você.
Um funcionário pode fazer verificações adicionais, se necessário, com base na situação.

<div className="text-white mt-2 font-semibold">Fonte de renda</div>
Se um jogador depositar mais de cinco mil euros, há um processo de entendimento da fonte de riqueza (SOW)<br/><br/>

Exemplos de SOW são:<br/>
•	Propriedade do negócio<br/>
•	Emprego<br/>
•	Herança<br/>
•	Investimento<br/>
•	Família<br/>
<br /><br />
É fundamental que a origem e a legitimidade dessa riqueza sejam claramente compreendidas. Se isso não for possível, o funcionário pode solicitar um documento adicional ou comprovante.<br /><br />
A conta será congelada se o mesmo usuário depositar esse valor de uma só vez ou várias transações que cheguem a isso. Um e-mail será enviado a eles manualmente para passar pelo acima e uma informação no próprio site.<br /><br />
www.rockeetz.com também solicita uma transferência bancária/cartão de crédito para garantir ainda mais a identidade do usuário/cliente. Também fornece informações adicionais sobre a situação financeira do usuário/cliente.<br /><br />

<div className="text-white mt-2 font-semibold">Documento básico para a primeira etapa:</div>
O documento básico estará acessível através da página de configuração em www.rockeetz.com. Todo usuário deve preencher as seguintes informações:
<br /><br />

•	Primeiro nome<br />
•	Segundo nome<br />
•	Nacionalidade<br />
•	Gênero<br />
•	Data de nascimento<br />
O documento será salvo e criado por uma IA; um funcionário pode fazer verificações adicionais, se necessário, com base na situação.


<div className="text-white mt-2 font-semibold">Gerenciamento de riscos:</div>

Para lidar com os diferentes riscos e diferentes estados de riqueza em diferentes regiões da terra, www.rockeetz.com irá categorizar cada nação em três diferentes regiões de risco.
<br/><br/>Região um: baixo risco<br/>
Para cada nação da região um, a verificação em três etapas é feita conforme descrito anteriormente.
<br/><br/>Região dois: risco médio<br/>
Para cada nação da região dois, a verificação em três etapas será feita em valores de depósito, saque e gorjeta mais baixos. O primeiro passo será feito normalmente. O segundo passo será feito após depositar 1000$ (mil dólares), retirar 1000$ (mil dólares). A terceira etapa será realizada após o depósito de $ 2.500 (dois mil e quinhentos dólares), a retirada de $ 2.500 (dois mil e quinhentos dólares). clientes de uma região de médio risco.
<br/><br/>Região três: alto risco<br/>
Regiões de alto risco serão banidas. As regiões de alto risco serão atualizadas regularmente para acompanhar o ambiente em mudança de um mundo em rápida mudança.

<div className="text-white mt-2 font-semibold">Medições adicionais.</div>
Além disso, uma IA supervisionada pelo responsável pela conformidade da AML procurará qualquer comportamento incomum e o reportará imediatamente a um funcionário da www.rockeetz.com. 
De acordo com uma experiência geral e baseada em riscos, os funcionários humanos verificarão novamente todas as verificações feitas antes pela IA ou por outros funcionários e podem refazer ou fazer verificações adicionais de acordo com a situação.
Além disso, um cientista de dados apoiado por sistemas analíticos eletrônicos modernos procurará comportamentos incomuns como: Depositar e sacar sem sessões de apostas mais longas. Tentativas de usar uma conta bancária diferente para depósito e retirada, mudanças de nacionalidade, mudanças de moeda, comportamento e mudanças de atividade, bem como cheques, se uma conta for usada por seu proprietário original.
Além disso, um usuário deve usar o mesmo método para retirada que usou para depósito, para o depósito inicial para evitar qualquer lavagem de dinheiro.

<div className="text-white mt-2 font-semibold">Avaliação de risco em toda a empresa</div>
Como parte de sua abordagem baseada em risco, www.rockeetz.com conduziu uma AML “Avaliação de risco em toda a empresa” (EWRA) para identificar e compreender os riscos específicos de www.rockeetz.com e suas linhas de negócios. A política de risco AML é determinada após identificação e documentação dos riscos inerentes às suas linhas de negócio como os serviços que o website oferece. Os Usuários a quem os serviços são oferecidos, as transações realizadas por esses Usuários, os canais de entrega utilizados pelo banco, as localizações geográficas das operações do banco, clientes e transações e outros riscos qualitativos e emergentes.
A identificação das categorias de risco AML é baseada em www.rockeetz.com compreensão dos requisitos regulatórios, expectativas regulatórias e orientação do setor. Medidas de segurança adicionais são tomadas para cuidar dos riscos adicionais que a rede mundial de computadores traz consigo.
O EWRA é reavaliado anualmente.


<div className="text-white mt-2 font-semibold">Monitoramento de transações em andamento</div>
O AML-Compliance garante que um “monitoramento contínuo de transações” seja realizado para detectar transações incomuns ou suspeitas em comparação com o perfil do cliente. 
Esse monitoramento de transações é realizado em dois níveis:<br/><br/>
1) A primeira Linha de Controle:<br/>
www.rockeetz.com trabalha apenas com provedores de serviços de pagamento confiáveis, todos com políticas AML eficazes para evitar a maioria dos depósitos suspeitos em www.rockeetz.com ocorra sem a execução adequada dos procedimentos KYC para o cliente em potencial.
<br/><br/>
2) A segunda Linha de Controle:<br/>
www.rockeetz.com informa a sua rede de modo que qualquer contato com o cliente ou jogador ou representante autorizado deve dar lugar ao exercício de devida diligência sobre as transações na conta em questão. Esses incluem:<br/><br/>
•	Solicitações para a execução de transações financeiras na conta.<br/>
•	Pedidos relativos a meios de pagamento ou serviços na conta.<br/><br/>
Além disso, a verificação em três etapas com gerenciamento de risco ajustado deve fornecer todas as informações necessárias sobre todos os clientes de www.rockeetz.com  em todos os momentos.
<br/><br/>Além disso, todas as transações devem ser supervisionadas por funcionários supervisionados pelo responsável pela conformidade AML, que é supervisionado pela administração geral.
<br/><br/>As transações específicas submetidas ao gerente de suporte ao cliente, possivelmente por meio de seu Gerente de Compliance, também devem estar sujeitas à devida diligência.
<br/><br/>A determinação da natureza atípica de uma ou mais transações depende essencialmente de uma avaliação subjetiva, relativamente ao conhecimento do cliente (KYC), do seu comportamento financeiro e da contraparte da transação.
<br/><br/>Essas verificações serão feitas por um sistema automatizado, enquanto um funcionário as verifica para segurança adicional.
<br/><br/>As movimentações observadas em contas de clientes para as quais é difícil obter uma compreensão adequada das atividades lícitas e da origem dos fundos devem, portanto, ser rapidamente consideradas atípicas (por não serem diretamente justificáveis).
<br/><br/>Qualquer colaborador da www.rockeetz.com deve comunicar à divisão AML quaisquer operações atípicas que observe e que não possam atribuir a atividade lícita ou fonte de rendimento conhecida do cliente.
<br/><br/>
3) A terceira Linha de Controle:<br/>
Como última linha de defesa contra AML, www.rockeetz.com fará verificações manuais de todos os usuários suspeitos e de alto risco para prevenir totalmente a lavagem de dinheiro.
<br/><br/>Se for constatada fraude ou lavagem de dinheiro, as autoridades serão informadas.

<div className="text-white mt-2 font-semibold">Denúncia de transações suspeitas em ROCKEETZ.COM</div>
Em seus procedimentos internos, www.rockeetz.com descreve de forma precisa, à atenção dos seus colaboradores, quando é necessário reportar e como proceder para tal reporte.
<br/><br/>As denúncias de transações atípicas são analisadas pela equipe AML de acordo com a metodologia precisa e detalhadamente descrita nos procedimentos internos.
<br/><br/>Consoante o resultado deste exame e com base na informação recolhida, a equipa AML:<br/>
<br/>•	decidirá se é necessário ou não enviar um relatório à UIF, de acordo com as obrigações legais previstas na Lei de 18 de setembro de 2017.
<br/>•	decidirá se é necessário encerrar as relações comerciais com o cliente.

<div className="text-white mt-2 font-semibold">Procedimentos</div>
As regras AML, incluindo os padrões mínimos de KYC, serão traduzidas em orientações ou procedimentos operacionais que estão disponíveis no site da Intranet em www.rockeetz.com. 

<div className="text-white mt-2 font-semibold">Manutenção de registros</div>
Os registros dos dados obtidos para fins de identificação devem ser mantidos por pelo menos dez anos após o término da relação comercial.
<br/><br/>Os registros de todos os dados das transações devem ser mantidos por pelo menos dez anos após a realização das transações ou o término da relação comercial.
<br/><br/>Esses dados serão armazenados com segurança e criptografados offline e online.


<div className="text-white mt-2 font-semibold">Treinamento:</div>
Os empresados da www.rockeetz.com farão controles manuais em uma aprovação baseada em risco para a qual recebem treinamento especial.
<br/><br/>O programa de formação e sensibilização traduz-se na sua utilização:
<br/>- Um programa obrigatório de formação AML de acordo com as últimas evoluções regulamentares, para todos os que têm contacto com finanças
<br/>- Sessões de aprendizado AML acadêmico para todos os novos funcionários
<br/><br/>O conteúdo deste programa de formação deve ser definido de acordo com o tipo de negócio em que os formandos se encontram e os cargos que ocupam. Essas sessões são ministradas por um especialista em AML que trabalha na equipe AML da LAM Gaming Group B.V.

<div className="text-white mt-2 font-semibold">Auditoria:</div>
A auditoria interna estabelece regularmente missões e relatórios sobre atividades AML.



<div className="text-white mt-2 font-semibold">Segurança de dados</div>
Todos os dados fornecidos por qualquer usuário/cliente serão mantidos em segurança, não serão vendidos ou cedidos a terceiros. Somente se forçado por lei, ou para prevenir a lavagem de dinheiro, os dados podem ser compartilhados com a autoridade AML do estado afetado.
<br/><br/>rockeetz.com seguirá todas as diretrizes e regras da diretiva de proteção de dados (oficialmente Diretiva 95/46/EC).

<div className="text-white mt-2 font-semibold">Contate-nos:</div>
Se você tiver alguma dúvida sobre nossa política AML e KYC, entre em contato conosco:<br/>
•	Por e-mail: datasecurity@rockeetz.com   
<br/><br/>
Se você tiver alguma reclamação sobre nossa Política AML e KYC ou sobre as verificações feitas em sua Conta e sua Pessoa, entre em contato conosco:
<br/><br/>
•	Por e-mail: datasecurity@rockeetz.com   


            </div>



      

        </>
    )
}