
import SuporteConteudo from "./conteudo";
import TemplateCentralDoJogador from "./../../../layouts/CentralDojogador/template"
function Suporte() {
 

  return (
    <TemplateCentralDoJogador title="Suporte">
    <SuporteConteudo/>
  </TemplateCentralDoJogador>
  );
}

export default Suporte;
