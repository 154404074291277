import { motion, useDragControls } from "framer-motion";
import { useEffect, useState, } from 'react';
import useRender from "../../hooks/useRender";
import $ from "jquery"

export default function OwlCarroseel() {
    const {rodadas, setRodadas, saldo, _setSaldo,updateSaldos} = useRender()
    const [start,setStart] = useState()
    const [vel, setVel] = useState()
    const [position, setPosition] = useState()
    
    const parant = {
        testeA: { x: start},
       
    }



   
   
    const [carrossel, Setcarrossel] = useState(()=>{
        var listaCarrossel = [
            { img: "./img/roleta/astro.png",  number: "", cor: "bg-white", border: "", ref:"", rotating: ""},            
            { img: "./img/roleta/planet.png", number: "11", cor: "bg-[#0042631a]", rotating: "rotating" , border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet-cinza.png", number: "5", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet.png", number: "10", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet-cinza.png", number: "6", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet.png", number: "9", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet-cinza.png", number: "7", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet.png", number: "8", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet-cinza.png", number: "1", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet.png", number: "14", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet-cinza.png", number: "2", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet.png", number: "13", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet-cinza.png", number: "3", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet.png", number: "12", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},
            { img: "./img/roleta/planet-cinza.png", number: "4", cor: "bg-[#0042631a]", rotating: "rotating" ,border: "rounded-3xl h-10 w-10 flex justify-center items-center font-black bg-[#06151e68] border-2 p-3", ref:""},




        ]
      
        for (let index = 0; index < 6; index++) {          
            listaCarrossel = [...listaCarrossel, ...listaCarrossel] 
        }
    
        return listaCarrossel
    })
    
   

    
    window.c_Roullet.socket.on("PararJogadaAtualizarsaldo", (value) => {
   
        let result = parseFloat(saldo) + parseFloat(value)
        
    
    
        window.c_Roullet.ganhou =  value
       
    
     });

    useEffect(()=>{

   
   
        function limparTabela(){
           resetRoleta()
            /*zerar tabela de player*/
            window.c_Roullet.listaJogadores.azul = [];
            window.c_Roullet.listaJogadores.preto = [];
            window.c_Roullet.listaJogadores.branco = [];
            window.c_Roullet.ganhou = 0
        
            document.getElementById('roletaBarra').innerHTML = `-`;
     
            /*zerar tabelas*/
            /*azul*/
            document.getElementById('planet_blue_desc_totalapostas').innerHTML = " - ";
            document.getElementById('planet_blue_valor_totalapostas').innerHTML = "R$ - ";
     
          //  document.getElementById('planet_black_nome_10').innerHTML = "&nbsp;";
          //  document.getElementById('planet_black_valor_10').innerHTML = "&nbsp;";
     
     
            /*preto*/
            document.getElementById('planet_gray_desc_totalapostas').innerHTML = " - ";
            document.getElementById('planet_gray_valor_totalapostas').innerHTML = "R$ - ";
     
     
     
            /*branco*/
            document.getElementById('planet_astro_desc_totalapostas').innerHTML = " - ";
            document.getElementById('planet_astro_valor_totalapostas').innerHTML = "R$ - ";
     
     
     
            for (let index = 1; index <= 10; index++) {
     
                document.getElementById(`planet_gray_nome_${index}`).innerHTML = " - ";
                document.getElementById(`planet_gray_valor_${index}`).innerHTML = "R$ - ";
                document.getElementById(`planet_blue_nome_${index}`).innerHTML = " - ";
                document.getElementById(`planet_blue_valor_${index}`).innerHTML = "R$ - ";
                document.getElementById(`planet_astro_nome_${index}`).innerHTML = " - ";
                document.getElementById(`planet_astro_valor_${index}`).innerHTML = "R$ - ";
     
            }
     
     }

     window.c_Roullet.socket.on('createProgressBar', (newroundtime) => {
        limparTabela();
        
        document.getElementById('roletaBarra').innerHTML = `Girando em ${newroundtime} segundos...`;
        //muda o status do girando
    });


   
    
   

  
 /**Recebe o comando para parar a roleta em uma determinada pos.*/
window.c_Roullet.socket.on('pararRoletaem', (index) => {
    iniciarRoleta(index + 300);

    let historico = $("#historicoRoleta")
   
      
    setTimeout(() => {
        switch (index) {
            case 1: case 3: case 5: case 7: case 9: case 11: case 13: case 16: case 18: case 20: case 22: case 24: case 26: case 28:
                    historico.prepend(`<div class="ml-1 mr-1">
                    <div class="max-w-[100%] w-8 h-auto relative flex justify-center items-center">
                    <div class="absolute border-white border-2 text-[0.6em] font-bold bg-azulthird rounded-xl flex justify-center items-center w-[20px] h-[20px]"> ${carrossel[index].number} </div>
                    <img class="w-full " src="./img/roleta/planet.png" />
                    </div>
                   </div>`)
                 
               
                break;

            case 0: case 15:
                historico.prepend(`<div class="ml-1 mr-1">
                     <div class="max-w-[100%] bg-white rounded-md w-8 h-auto">                   
                    <img class="w-full " src="./img/roleta/astro.png" />
                   </div>
                   </div>`)
                
              
                break;
            
                        
             case 2: case 4: case 6: case 8: case 10: case 12: case 14: case 17: case 19: case 21: case 23: case 25: case 27: case 29:
                historico.prepend(`<div class="ml-1 mr-1">
                <div class="max-w-[100%] w-8 h-auto relative flex justify-center items-center">
                <div class="absolute border-white border-2 text-[0.6em] font-bold bg-cinzathird rounded-xl flex justify-center items-center w-[20px] h-[20px]">${carrossel[index].number} </div>
                <img class="w-full " src="./img/roleta/planet-cinza.png" />
                </div>
               </div>`)
             
                 
            default:
                break;
        }
      }, 12500)


 // HISTORICO ROLETA MODAL
 let historicoModal = $("#historicoRoletaModal")
 const date = new Date().toLocaleTimeString(); // pega hora atual do pc
      setTimeout(() => {
        switch (index) {
            case 1: case 3: case 5: case 7: case 9: case 11: case 13: case 16: case 18: case 20: case 22: case 24: case 26: case 28:
                historicoModal.prepend(`<div class="ml-1 mr-1">
                <div class="grid justify-items-center">
                <div class="max-w-[100%] w-14  h-auto relative flex justify-center items-center">
                <div class="absolute border-white border-2 text-[0.6em] font-bold bg-azulthird rounded-xl flex justify-center items-center w-[20px] h-[20px]"> ${carrossel[index].number} </div>
                <img class="w-full " src="./img/roleta/planet.png" />
                </div>
                <p class="text-center text-white lg:text-sm text-[10px]">
                ${date}
              </p>
                 </div>

               </div>`)
        
                break;

            case 0: case 15:
                historicoModal.prepend(`<div class="ml-1 mr-1">
                <div class="grid justify-items-center">
                     <div class="max-w-[100%] bg-white rounded-md w-14  h-auto">                   
                    <img class="w-full " src="./img/roleta/astro.png" />
                   </div>
                   <p class="text-center text-white lg:text-sm text-[10px]">
                   ${date}
                 </p>
                   </div>
                   </div>`)
          
             
                break;
            
                        
             case 2: case 4: case 6: case 8: case 10: case 12: case 14: case 17: case 19: case 21: case 23: case 25: case 27: case 29:
                historicoModal.prepend(`<div class="ml-1 mr-1">
                <div class="grid justify-items-center">
                <div class="max-w-[100%] w-14  h-auto relative flex justify-center items-center">
                <div class="absolute border-white border-2 text-[0.6em] font-bold bg-cinzathird rounded-xl flex justify-center items-center w-[20px] h-[20px]">${carrossel[index].number} </div>
                <img class="w-full " src="./img/roleta/planet-cinza.png" />
                </div>
                <p class="text-center text-white lg:text-sm text-[10px]">
                ${date}
              </p>
                 </div>

               </div>`)
         
              
            default:
                break;
        }
      }, 12500)
       
     
});



 function playerWin(){
       
     if (window.c_Roullet.ganhou > 0) {

        //var saldoJogador = document.getElementById("varSaldo");
      //  saldoJogador.innerHTML =  ((parseFloat(saldoJogador.innerHTML) + parseFloat(window.c_Roullet.ganhou)).toFixed(2));
        
        Mensagem()
       

      


     }else{
     
     }

 }
 function Mensagem(){
  document.getElementById("modalWinRoullet").style.display = "flex";
  document.getElementById("moneyWinModalRoullet").innerHTML = "R$" + " " + window.c_Roullet.ganhou
  setTimeout(function() { 
    document.getElementById("modalWinRoullet").style.display = "none";
      }, 5000);   
 
 }
 
window.c_Roullet.socket.on('setProgressBar', (newroundtime) => {
    if (newroundtime == 0) {
   

      
      

        document.getElementById('roletaBarra').innerHTML = `Girando...`;
        
       
       
 

        setTimeout(() => {
            playerWin()
          }, 12500)

    } else {
       
        document.getElementById('roletaBarra').innerHTML = `Girando em ${newroundtime} segundos...`;
    
    }
});

function resetRoleta(){
    setStart(0);
    setVel(1);
}
 
function iniciarRoleta(pos){  
   
    var carrossel = document.querySelectorAll('.owl-carroussel'); var canvas = document.getElementById('canvasroullet');  var posPlaneta = carrossel[pos].getBoundingClientRect().x     
    var posCanvas = canvas.getBoundingClientRect().x; var widthCanvas = canvas.getBoundingClientRect().width   
    let result = posPlaneta - (widthCanvas /2) - posCanvas + 50
    setStart(-result)
    setVel(10)

  }

  // ================================ ATUALIZA O SALDO ===========

  window.c_Roullet.socket.on('atualizaSaldo', (saldoAtualizado) => {
    updateSaldos(saldoAtualizado);
  });

  // ===================================================


    },[])
 
   
   


    return (

        <>

  
            <motion.div  id="list" transition={{
                
                x: { duration: vel },
                default: { ease: "linear" }
            }}  variants={parant} animate="testeA" className="owl-carousel  flex space-x-3 ml-[-110px]">

        
                {carrossel.map((item,index) => {
                    return (
                        <div key={index}  className={`owl-carroussel w-[100px] duration-1000 relative h-[100px] inline-block rounded-lg ${item.cor}`}>
                            <div className="absolute flex justify-center w-full h-[100%] items-center">
                                <div className={`z-[1] ${item.border}`}>{item.number}</div>
                            </div>
                            <img  className={`max-w-[100%] h-auto ${item.rotating} p-1`} src={item.img} alt="" />
                        </div>

                    )
                })}





            </motion.div>

        </>
    )
}