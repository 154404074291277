import useRender from "../../../hooks/useRender";
import { useState, useEffect } from "react"; 
import Close from '../../../assets/icons/close.svg'

function ConteudoHistoricoRodadas(props) {
  const { prefixoUrl } = useRender();
  const [historicoRodadas, setHistoricoRodadas] = useState();

  function apiHistoricoDeRodadas() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${prefixoUrl}/crash/historicopartidas/190`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        setHistoricoRodadas(dados);
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    apiHistoricoDeRodadas();
  }, []);

  function closeHistorico() {
    document.getElementById(props.openmodal).classList.toggle("hidden");
  }
  return (
    <div className="flex justify-center">
      <div className="w-11/12 md:w-8/12 md:h-8/12  drop-shadow-lg  bg-[#424242]  z-[6] backdrop-blur-md  p-6 relative ">
      <div className="flex justify-end lg:hidden ">
            <img className="w-5" onClick={closeHistorico} src={Close} alt="" />
        </div>
        <div className="text-verdesixtyn text-center font-['Orbitron']  lg:text-4xl text-2xl whitespace-nowrap border-b-2 border-verdesixtyn pb-5">
          Histórico das Explosões
        </div>

        <div
          id="historicoExplosions" onClick={apiHistoricoDeRodadas}
          className="bg-[#2f2f2f] rounded-lg border border-[#242424] mt-3 p-4 grid lg:grid-cols-6 grid-cols-4 space-y-1 items-center overflow-y-scroll overflow-x-hidden max-h-[550px] "
        >
          {historicoRodadas != undefined ? (
            historicoRodadas.result.map((item, index) => {
              return (
                <div key={index} className="">
                  <div
                    className={`${
                      item.multiplicador > 2
                        ? "card-multiply-on text-multiply-win"
                        : " card-multiply-off text-multiply-loss "
                    }`}
                  >
                    {item.multiplicador.toFixed(2)}
                  </div>
                  <p className="text-center text-white lg:text-sm text-[10px]">
                    {item.hora}
                  </p>
                </div>
              );
            })
          ) : (
            <div className="text-verdesixtyn text-center font-['Orbitron']  lg:text-4xl text-2xl whitespace-nowrap border-b-2 border-verdesixtyn pb-5">
              Carregando...
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConteudoHistoricoRodadas;
