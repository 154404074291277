import BtnConfirmar from "../../../components/BtnConfirmar";
import { useEffect, useState } from "react";
import useRender from "../../../hooks/useRender";
import VMasker from "vanilla-masker";
import Swal from 'sweetalert2'



export default function SuporteConteudo(){
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const [descricao, setDescricao] = useState('');
    const { prefixoUrl, signed } = useRender();
    const [validando, setValidando] = useState(false);
  
    useEffect(() => {
  
    if (signed == true){
    let storage = JSON.parse(localStorage.getItem("user"));
    setEmail(storage.user.email);  
    var docMask = ['99-99999-9999'];
    var doc = document.getElementById('celular');    
    VMasker(doc).maskPattern(docMask[0]);
    setValidando(false);
  }
    },[])
  
    function handleClick() {

      if(descricao == '' || email == ''){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Preencha todos os campos para enviar a menssagem!',
        })
      } else{
        let user_token = localStorage.getItem("user_token");
  
        var formdata = new FormData();
    
        formdata.append("email", email);
        formdata.append("celular", celular);
        formdata.append("mensagem", descricao );
        formdata.append("token",  user_token);
      
        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        setValidando(true);
      
        fetch(`${prefixoUrl}/centraldojogador/suporte`, requestOptions)
        .then((resposta) => resposta.json())
        .then((json) => {
          setValidando(false);
            Swal.fire(
              "",
              json.msg,
              'success'
            )
          })
          .catch((error) => {
            Swal.fire(
              "",
              "Erro ao enviar e-mail, tente novamente mais tarde",
              'error'
            )
            console.log("error", error);
          });
      }

    }
    return(
        <main className="w-full">
     

        <div className="lg:px-[350px]">
          <div className="lg:flex lg:items-center lg:gap-3 px-5 py-10">

          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="white"
              className="bi bi-headset hidden lg:block"
              viewBox="0 0 16 16"
            >
              <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
            </svg>
            <p className="text-center text-white text-3xl ">
              Entre em contato conosco
            </p>
          </div>
  
          <div className="bg-[#1c1d22] rounded-lg py-4">
            <div className="flex flex-col px-6 text-white">
              <label className="py-1">E-mail</label>
              <input
                type="email"
                required
                className="outline-none bg-transparent border border-[#2b3553] rounded-lg py-2 px-4"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
  
            <div className="flex flex-col px-6 text-white pt-4">
              <label className="py-1">Celular</label>
              <input
                type="tel"
                required
                value={celular}
                id="celular"
                onChange={(e) => setCelular(e.target.value)}
                className="outline-none bg-transparent border border-[#2b3553] rounded-lg py-2 px-4"
              />
            </div>
  
            <div className="flex flex-col px-6 text-white pt-4">
              <label className="py-1">Como podemos ajuda-lo?</label>
              <textarea
                type="text"
                required
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                className="outline-none bg-transparent border border-verdeprimary rounded-lg h-[100px] py-2 px-4"
              />
  
              
            </div>
          </div>
  
          <div className="flex justify-center items-center mt-5 pb-5">
            <BtnConfirmar
              onClick={handleClick}
              texto={validando ? 'Enviando...' : 'Enviar' }
              disabled={validando}
              altura="py-1"
              largura="w-[350px]"
              radius="rounded-full"
              sizeText="text-xl"
            />
          </div>
        </div>
      </main>
    )
}