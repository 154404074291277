import Pix from "./Pix";
import Template from "../../layouts/template/template";

function Sacar() {


  return (
    <Template>

    <main className="text-white px-10 w-full h-screen lg:px-[300px] lg:mt-10 ">
      <div className="pt-4">
      <p className="text-center">
        Escolha uma chave pix abaixo para efetuar o <b>saque.</b>
        </p>
      </div>

        <Pix />
    </main>

    </Template>

  );
}

export default Sacar;
