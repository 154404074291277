function FooterCard(props) {

    const item = props.item;

    return (
        <div id={`footer--${item.id}`}>


            {item.status == 'Ativo' || item.status == "Depósito pendente" ?
                (
                    <div onClick={() => { props.setCupomidModal(item.id) }} className="flex justify-center ">
                        <div className="bg-[#BB0C0C] hover:bg-[#830f0f]  duration-500  text-white rounded-md px-20 py-1 cursor-pointer text-[16px] mt-3" >
                            DESATIVAR BÔNUS
                        </div>
                    </div>
                ) : ""}



            {item.status == 'Cancelado pelo usuário' ?
                (
                    <div className="flex justify-center ">
                        <div className="text-white rounded-md px-10 py-1  text-l mt-3" >
                            CANCELADO PELO USUÁRIO
                        </div>
                    </div>
                ) : ""}




            {item.status == 'Expirado' ?
                (
                    <div className="flex justify-center ">
                        <div className="text-white rounded-md px-10 py-1  text-l mt-3" >
                            CUPOM EXPIRADO
                        </div>
                    </div>
                ) : ""}







        </div>


    );
}
export default FooterCard;
