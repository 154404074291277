import Search from "./../../../../assets/icons/search.png";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Template from "../../../../layouts/template/template";
import Topper from "../../../../components/Topper/Topper";
import useRender from "../../../../hooks/useRender";
import Backbutton from "../../../../assets/slots/backbutton.png"

export default function SlotGame() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [gameReference, setGameReference] = useState(searchParams.get("game"));
  const [gameFree, setGameFree] = useState(searchParams.get("free"));
  const [gameUrl, setGameUrl] = useState("");
  const { prefixoUrl } = useRender();

  useEffect(() => {
    getToken();
  });

  function getToken() {
    const userToken = localStorage.getItem("user_token");

    if (userToken) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        game_reference: gameReference,
        token: localStorage.user_token,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${prefixoUrl}/slot/getOrCreateToken`, requestOptions)
        .then((response) => response.json())
        .then((result) => {

          if (gameFree) {
            setGameUrl(result.data.url_game_demo)

          } else {
            setGameUrl(result.data.url_game)

          }

          // localStorage.setItem("paymentInfo", raw)
          // let dados = JSON.parse(result)
          // setQrCode(dados.qr_code)
        })
        .catch((error) => console.log("error", error));
    }
  }
  return (
    <>
    
        {/* <div className="hidden lg:block w-full">
          <Topper />
        </div> */}
        <div className="w-full h-screen relative flex justify-center ">
         <Link to="/home">
         <div className="cursor-pointer hover:bg-green-500 duration-300 absolute left-[10px] top-[10px] p-2 rounded-xl bg-[#dad0d073]">
            <img src={Backbutton} className="w-8" />
          </div>
         </Link>
         
            <iframe
              src={gameUrl}
              className="w-full h-full"


              style={{ border: "1px", "solid": "black" }}

            >              
            </iframe>
         
        </div>
     
    </>
  );
}
