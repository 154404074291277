import Check from "../../../../assets/icons/checkLogin.svg";
import Polygon from "../../../../assets/icons/polygon.svg";

function NoCheckComponent({text, onClick}) {
  return (
    <>
      <div onClick={onClick} className="cursor-pointer relative flex justify-center items-center w-full ">
        <img className="h-[21px] w-[21px] absolute " src={Polygon} alt="" />
        <img className="h-[21px] w-[21px] absolute   " src={Check} alt="" />
        <p className="mt-12 uppercase text-white text-[11px]">{text}</p>
      </div>
    </>
  );
}

export default NoCheckComponent;
