import X from "../../../assets/pagamento/x.png"
import Gamefree from "../../../assets/home/gamefree.png"
import Crash from "../../../assets/home/crash.png"
import Roleta from "../../../assets/home/roleta.png"
import Mines from "../../../assets/home/mines.png"
import Jelly from "../../../assets/home/jelly.gif"
import useRender from "../../../hooks/useRender"



export default function SelectGameConteudo(props) {
    const {signed, openLogin} = useRender();

    function closeSelectGame() {
        document.getElementById(props.openmodal).classList.toggle("hidden");
    }


    return (
        <>
            <div id="selectGame" className="w-full lg:hidden pt-10 z-[6] mt-[30px] rounded-xl flex flex-wrap  overflow-y-scroll h-[100vh]  ">


                <div className="w-full relative top-0 pb-10">
                    <div className="w-full absolute top-[-40px] lg:top-[-100px] mt-3">
                        <div onClick={closeSelectGame} className="w-full  animate-ping absolute font-black text-verdeprimary  text-3xl lg:text-6xl  flex justify-center items-center">ESCOLHA O JOGO</div>
                        <div onClick={closeSelectGame} className="w-full  animate-pulse  font-black text-verdeprimary  text-3xl lg:text-6xl  flex justify-center items-center">ESCOLHA O JOGO</div>
                    </div>
                    <div className="w-full flex p-4 flex-col lg:flex-row pb-32 ">
                        {signed == true ? 
                        <>
                         <a href="/runnerjelly" className="cursor-pointer  duration-150 hover:scale-110 w-full lg:w-4/12">
                            <img src={Jelly} />
                        </a>
                        </> :
                        <>
                         <div onClick={openLogin} className="cursor-pointer  duration-150 hover:scale-110 w-full lg:w-4/12">
                            <img src={Jelly} />
                        </div>
                        </>
                        }
                       


                        <a href="/mines" className="cursor-pointer  duration-150 hover:scale-110 w-full lg:w-4/12">
                            <img src={Mines} />
                        </a>

                        <a href="/cyberspace" className="cursor-pointer duration-150 hover:scale-110 w-full lg:w-4/12">
                            <img src={Crash} />
                        </a>


                        <a href="/roulletplanet" className="cursor-pointer duration-150 hover:scale-110 w-full lg:w-4/12">
                            <img src={Roleta} />
                        </a>
                        <a href="/gamefree" className="cursor-pointer  duration-150 hover:scale-110 w-full lg:w-4/12">
                            <img src={Gamefree} />
                        </a>
                    </div>

                </div>








            </div>
        </>
    )
}