import {useState, useEffect} from 'react';
import Close from '../../../assets/icons/close.svg'
import RktzCoin from '../../../assets/icons/RKT_COIN.svg'
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import useRender from "../../../hooks/useRender";

function RewardsLoginFalse(props) {
  const { saldorkzc } = useRender();

    const [diaLogin, setDiaLogin] = useState()

    useEffect(() => {
        let semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
        let day = new Date();
        let diaHoje = semana[day.getDay()];
        setDiaLogin(diaHoje)
    }, []);




  function closeRewards() {
    document.getElementById("rewardsLoginFalse").classList.toggle("hidden"); }



    return ( 
        <>
        
          {/* CONTAINER COM O BLUR ATIVADO*/}

        <div  id="rewardsLoginFalse" className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center " >
        <div   className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
          <div onClick={closeRewards}  className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "></div>
            <div className="flex justify-center items-center  w-full">
        
   

                <div id="rewardsOpacityFalse" className="w-11/12 md:w-5/12 opacity-0 duration-700  drop-shadow-lg  bg-[#424242]  z-[6] backdrop-blur-md  p-6 relative ">

                 
                       <div  className="flex justify-end items-center ">
                            <img onClick={closeRewards}  className="h-7 w-5 cursor-pointer hover:animate-spin duration-1000  " src={Close} alt="" />
                        </div>
                        <div className="grid lg:grid-cols-2 grid-cols-1  ">
                        <div className="grid justify-items-center">
                           <p className="text-verdesixtyn whitespace-nowrap text-left   font-['Orbitron']  text-4xl" >
                         Você Perdeu
                           </p>

                           
                        
                           <div className="bg-[#1E1E1E] w-[215px] border-x-[3px] border-verdesixtyn my-4 ">
                            <div className="w-full h-[150px] leading-3 text-center text-white mt-4">
                            <div className="flex justify-center">
                                <img className="w-16 rktzCoin" src={RktzCoin} alt="" />
                            </div>
                            <div className="mt-3">
                                <p id="recompensaStreakFalse" className="font-['Orbitron'] text-3xl "></p>
                                <p>RKT coins</p>
                            </div>
                            </div>
                        </div>
                        <p id="diaLoginFalse" className="text-white uppercase  lg:text-2xl text-xl"> </p>
                        </div>



                        <div className="lg:mt-20 mt-5 px-4 flex flex-col items-center text-white gap-4  ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" fill="currentColor" className="bi bi-emoji-frown" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                              <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                            </svg>
                            <p className=" text-sm   ">
                
                            Esperamos você amanhã!
                          
                            </p>

                          
                 
                         
              
                       
                        </div>
                        </div>

                
                 
                </div>
             

            </div>
    
       
       
       </div>
      </div>






  
       
        </>
     );
}

export default RewardsLoginFalse;