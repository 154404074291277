import { useContext } from "react";
import { RenderContext } from "../contexts/render";

const useRender = () => {
  const context = useContext(RenderContext);

  return context;
};

export default useRender;
