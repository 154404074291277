export default function WildScreenStreak(props){
    return(
        <>
                 <div id="cardSteakBonusFinal" className="bg-[#1c0c31e0] text-white flex justify-center items-center fixed margin-0 top-0 bottom-0 left-0 right-0 w-full h-screen z-[20]  " >
                <div className="w-full absolute flex justify-center z-[3]">
                 
                </div>
                {/* ANIMAÇAO DE BACKGRUND */}
                <div className="flex w-full absolute h-[100%]">
                    <div className="w-6/12  flex justify-start">
                        <div id="bg-left" className="w-full"></div>
                    </div>
                    <div className="w-6/12 flex justify-end">
                        <div id="bg-right" className="w-full"></div>
                    </div>
                </div>
                 {/* FIM ANIMAÇAO DE BACKGRUND */}
                <div className="w-full h-[100%] z-[2] top-[20px] absolute flex justify-center items-center ">
                  {props.children}
                </div>
                <div className='pow w-full z-[1]'>
  <div className='squares'>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
    <div className='square'></div>
  </div>
</div>
<div className='grey'></div>
               
            </div>
        </>
    )
}