
import Template from "../../layouts/template/template";
import Topper from '../../components/Topper/Topper.js'
import Game from '../../components/crash/game/game.js'
import Atalhos from '../../components/crash/atalhos.js'


function Home() {
  return (
    <>
   
    <Template>
        <Topper/>
        <Game/>
        <Atalhos/>
    </Template>

    </>
  );
}

export default Home;

