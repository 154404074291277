import Rules from "../../assets/icons/rules.svg";
import Realgame from "../../assets/icons/realgame.svg"
import Freegame from "../../assets/icons/gamefree.svg"
import Faq from "../../assets/icons/faq.svg"
import Indicacoes from "../../assets/icons/indicacoes.svg"
import Shop from "../../assets/icons/shop.svg"
import { useState } from "react";
import { Link } from "react-router-dom";




export default function Atalhos(){

  const [location] = useState( window.location.href)

    function openAtalhos() {
        document.querySelector(".atalho__modal").classList.toggle("mr-[-300px]");

        document.querySelector(".atalho__modal").classList.toggle("opacity-0");
        document.querySelector(".atalho--opacidade").classList.toggle("hidden");
        
        
        
      }



    return(
        <>

        <div  onClick={openAtalhos} className="atalho--opacidade hidden fixed top-0 bottom-0 right-[0px] left-0 bg-black z-50 opacity-75 duration-[2000ms]  hidden "></div>
        <div
          onClick={openAtalhos}
          className="atalho__modal  fixed top-20 h-5/6 atalhos_gradiente  rounded-tl-3xl rounded-bl-3xl right-0 overflow-y-auto mr-[-300px]  p-2 w-[200px] text-center text-[#42aa2f] bg-[#141414] z-[99] opacity-0 duration-1000"
        >
          <div className="flex justify-between">
   
            <div className="text-white font-medium  mx-auto text-2xl font-['Orbitron']">Atalhos</div>
          </div>
          <hr className="w-full mt-3" />
          {/* HOME */}
          {location.indexOf("gamefree") > -1 ? (
            <a href="/cyberspace">
             <div className="pl-2 mt-3 flex items-center rounded-md bg-verdefiftyn duration-500 cursor-pointer  hover:bg-[hsl(202,74%,10%)]  scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] "> 
                 <img src={Realgame} />
               </div>
            </a>
           
          ) : (
            <a href="/gamefree">
              <div className="pl-2 mt-3 flex items-center rounded-md bg-verdefiftyn duration-500 cursor-pointer  hover:bg-[hsl(202,74%,10%)]  scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] "> 
                <img src={Freegame} />
              </div>
            </a>
          ) }
      


        

          {/* REGRAS */}
         <Link to="/regras">
            <div className="pl-2 mt-3 flex items-center rounded-md bg-verdefiftyn duration-500 cursor-pointer  hover:bg-[hsl(202,74%,10%)]  scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
              <img src={Rules} />
            </div>
          </Link>

          {/* INDICAR AMIGOS */}
          <Link to="/centraldojogador/indicacao">
            <div className="pl-2 mt-3 flex items-center rounded-md bg-verdefiftyn duration-500 cursor-pointer  hover:bg-[hsl(202,74%,10%)]  scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">
               <img src={Indicacoes} />
            </div>
          </Link>
          {/* SHOP */}
          <div className="relative ">
            <div className="grayscale pl-2 mt-3 flex items-center rounded-md bg-verdefiftyn duration-500 cursor-pointer  hover:bg-[hsl(202,74%,10%)]  scale-90 hover:border-l-2 hover:border-[#46FF27] ">
              <img src={Shop} />
          </div>
          <div className="absolute right-0 bottom-0  ">
          <p className="text-[8px] font-['Orbitron']  text-white font-semibold mr-3 mb-1 uppercase animate-pulse bg-red-600 rounded-full px-2 "  >Em Breve!</p>
          </div>
          </div>
    

          {/* FAQ */}
          <Link to="/centraldojogador/perguntasfrequentes">
              <div className="pl-2 mt-3 flex items-center rounded-md bg-verdefiftyn duration-500 cursor-pointer  hover:bg-[hsl(202,74%,10%)] scale-90 hover:scale-100 hover:border-l-2 hover:border-[#46FF27] ">     
                <img src={Faq} />
              </div>
          </Link>
          {/* SUPORTE */}
        

          {/* SAIR */}
  
        </div>
      

          
     
          
     
             

    
        </>
       
    )
}