import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { RenderProvider } from "./contexts/render";
import { SocketProvider } from "./contexts/socket";
import registerServiceWorker from 'react-service-worker';
import { DepositoProvider } from "./contexts/deposito";
import ModalValidarCel from "./components/modals/ModalValidarCel/ModalValidarCel";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
 
  <SocketProvider>
    <DepositoProvider>
    <RenderProvider>
    <ModalValidarCel />
      <App />

    </RenderProvider>
    </DepositoProvider>
  </SocketProvider>

  

);
registerServiceWorker();






//<React.StrictMode> 
//</React.StrictMode>