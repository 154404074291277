import Template from "../../layouts/template/template";
import Topper from "../../components/Topper/Topper";
import MinesGame from "../../components/Mines/Mines";


function Mina() {
  return (
    <>

    <Template>
      <div className="hidden lg:block w-full">
      <Topper/>
      </div>
    
      <MinesGame/>
    </Template>

    </>
  );
}

export default Mina;
