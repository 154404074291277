
import { Link } from 'react-router-dom'
import {useState} from 'react'

export default function MenuPromocao() {
  const [location] = useState(window.location.href);
  return (
    <Link to="/centraldojogador/promocao">
      <div
        className={
          location.indexOf("perfil") > -1
            ? `border-r-4 border-r-verdeprimary hover:text-verdeprimary hover:bg-cinzasecondary bg-cinzathird p-4 cursor-pointer`
            : `p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary`
        }
      >
          <p className="text-green-400 flex items-center  text-xl">Sorteio Iphone 15<div className="ml-4 rounded-md bg-green-400 text-black px-3 py-1 animate-pulse text-[0.6em]">new</div></p>
      </div>
    </Link>
  );
}