import { Link } from "react-router-dom";
import Voltar from "../../../assets/icons/voltar.gif";
import Title from "../../../components/centraldojogador/titulo";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";

function PerguntasFrequentes() {
  function dropPergunta1() {
    document.getElementById("Pergunta1").classList.toggle("hidden");
    document.getElementById("arrowPergunta1").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta1")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta2() {
    document.getElementById("Pergunta2").classList.toggle("hidden");
    document.getElementById("arrowPergunta2").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta2")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta3() {
    document.getElementById("Pergunta3").classList.toggle("hidden");
    document.getElementById("arrowPergunta3").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta3")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta4() {
    document.getElementById("Pergunta4").classList.toggle("hidden");
    document.getElementById("arrowPergunta4").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta4")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta5() {
    document.getElementById("Pergunta5").classList.toggle("hidden");
    document.getElementById("arrowPergunta5").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta5")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta6() {
    document.getElementById("Pergunta6").classList.toggle("hidden");
    document.getElementById("arrowPergunta6").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta6")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta7() {
    document.getElementById("Pergunta7").classList.toggle("hidden");
    document.getElementById("arrowPergunta7").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta7")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta8() {
    document.getElementById("Pergunta8").classList.toggle("hidden");
    document.getElementById("arrowPergunta8").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta8")
      .classList.toggle("text-verdeprimary");
  }

  function dropPergunta9() {
    document.getElementById("Pergunta9").classList.toggle("hidden");
    document.getElementById("arrowPergunta9").classList.toggle("rotate-90");
    document
      .getElementById("textPergunta9")
      .classList.toggle("text-verdeprimary");
  }
  return (
  <TemplateCentralDoJogador title="Perguntas Frequentes">
      <main className="w-full mb-10">
     

      <div>
        <p className="text-center text-white text-4xl py-6 font-semibold">
          Perguntas <br></br> Frequentes (FAQ)
        </p>
      </div>

      <div
        onClick={dropPergunta1}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta1" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta1">
            NÃO RECEBI MEU E-MAIL DE CONFIRMAÇÃO, OQUE EU FAÇO?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta1" className="text-white p-5 hidden">
          Verifique se você cadastrou seu e-mail correto na plataforma, feito
          isso procure na caixa de spam ou lixo eletrônico. Caso não tenha
          chegado, vá até a plataforma, haverá uma barra vermelha na parte
          superior da tela com um botão "Reenviar Link", basta clicar nele e
          verificar se a mensagem chegou no seu e-mail.
        </p>
      </div>
      <div className="border border-verdeprimary"></div>

      <div
        onClick={dropPergunta2}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta2" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta2">
            CADASTREI O E-MAIL ERRADO NA PLATAFORMA E AGORA?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta2" className="text-white p-5 hidden">
          Basta você entrar em contato com o suporte e solicitar a alteração do
          seu e-mail.
        </p>
      </div>
      <div className="border border-verdeprimary"></div>

      <div
        onClick={dropPergunta3}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta3" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta3">
            QUAL O DEPÓSITO MÍNIMO?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta3" className="text-white p-5 hidden">
          O depósito mínimo é de R$40,00
        </p>
      </div>
      <div className="border border-verdeprimary"></div>

      <div
        onClick={dropPergunta4}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta4" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta4">
            QUAL O SAQUE MÍNIMO?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta4" className="text-white p-5 hidden">
          O saque mínimo é de R$100,00
        </p>
      </div>
      <div className="border border-verdeprimary"></div>

      <div
        onClick={dropPergunta5}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta5" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta5">
            DEPOIS QUE SOLICITEI O SAQUE QUANTO TEMPO DEMORA PARA CAIR EM MINHA
            CONTA?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta5" className="text-white p-5 hidden">
          O saque é depositado em média de 2 a 3 dias úteis caso a solicitação
          seja na modalidade PIX. Em caso de solicitação por TED/DOC o prazo
          para depósito de estende em até 10 dias úteis.
        </p>
      </div>
      <div className="border border-verdeprimary"></div>

      <div
        onClick={dropPergunta6}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta6" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta6">
            FIZ UMA INDICAÇÃO E NÃO RECEBI NADA, POR QUÊ?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta6" className="text-white p-5 hidden">
          Você apenas irá receber o numerário da indicação caso o usuário que
          você indicou realize o <b> PRIMEIRO </b> depósito. Acesse o painel de
          indicação clicando <b className="text-verdeprimary">AQUI</b>
        </p>
      </div>
      <div className="border border-verdeprimary"></div>

      <div
        onClick={dropPergunta7}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta7" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta7">
            REALIZEI UM DEPÓSITO E AINDA NÃO CAIU NA MINHA CONTA OQUE FAZER?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta7" className="text-white p-5 hidden">
          Espere de 10 à 15 minutos, caso o saldo não tenha creditado em sua
          conta, envie uma mensagem para o suporte com o comprovante de
          pagamento.
        </p>
      </div>
      <div className="border border-verdeprimary"></div>

      <div
        onClick={dropPergunta8}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta8" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta8">
            MEU SAQUE FOI CANCELADO POR DADOS DIVERGENTES, OQUE FAZER?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta8" className="text-white p-5 hidden">
          A Rockeetz realiza pagamentos apenas para contas bancárias cadastradas
          no <b> MESMO CPF </b> do titular, refaça o saque com uma conta
          bancária com o mesmo <b> CPF </b> do titular da plataforma.
        </p>
      </div>
      <div className="border border-verdeprimary"></div>
      <div
        onClick={dropPergunta9}
        className="flex items-center text-white gap-4 p-3 cursor-pointer"
      >
        <div id="arrowPergunta9" className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#86ff40"
            class="bi bi-caret-right-fill"
            viewBox="0 0 16 16"
          >
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path>
          </svg>
        </div>

        <div>
          <p className="" id="textPergunta9">
            QUERO SER PARCEIRO DA ROCKEETZ COMO ENTRAR EM CONTATO?
          </p>
        </div>
      </div>
      <div>
        <p id="Pergunta9" className="text-white p-5 hidden">
          Você pode mandar sua proposta de parceria para o e-mail do
          suporte(suporte@rockeetz.com) com os seguintes dados: <br></br>
          Nome: <br></br>
          Telefone:<br></br>
          Rede Social: <br></br>
          Exemplo(de sua preferencia): Youtube, instagram..
        </p>
      </div>
      <div className="border border-verdeprimary"></div>
    </main>
  </TemplateCentralDoJogador>
  );
}

export default PerguntasFrequentes;
