
import { useState, useRef } from "react";

import useRender from "../../../../hooks/useRender";
import CarouselitemDeslogadoOriginais from '../originais/carouselitemDeslogadoOriginais'
import CarouselitemOriginais from '../originais/carouselitemOriginais'


function CarouselRow({ provedor }) {
  const carouselRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [oppened, setOppened] = useState(false);

  const scroll = (direction) => {
    const node = carouselRef.current;
    const scrollAmount = direction === 'left' ? -1400 : 1400;
    node.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });

    // Após a rolagem suave, verifique se estamos perto do final e redefina a posição
    setTimeout(() => {
      if (node.scrollLeft >= node.scrollWidth / 2) {
        //node.scrollLeft = 0;
      }
      // Se a rolagem for maior que 0, exiba o botão esquerdo
      setShowLeftButton(node.scrollLeft > 0);
    }, 200); // Supondo 500ms para a rolagem suave, ajuste se necessário
  };

  function verMais() {
    if (oppened) {
      carouselRef.current.classList.add("whitespace-nowrap")
      setOppened(false)
    } else {
      carouselRef.current.classList.remove("whitespace-nowrap")
      setOppened(true)
    }

  }

  return (
    <div className="relative w-full space-y-2">
      {provedor.games.length > 0 && (
        <>
          <div className="absolute top-[-40px]  text-[0.8em] lg:text-normal right-[10px] lg:right-[40px] flex justify-end">
            <div onClick={verMais} className="cursor-pointer w-[100px] text-white bg-cinzafourth flex justify-center items-center py-1 px-2 rounded-md">{oppened ? <>Ver menos</> : <>Ver mais</>} </div>
          </div>

          <button id="btnLeft" onClick={() => scroll('left')} className="absolute left-0 lg:left-[25px] bottom-[15px] top-0 h-full  w-[60px]  z-[2] text-3xl text-white bg-gradient-to-r from-[#110e14] ...  ">
            {showLeftButton && (
              <div className="bg-black rounded-xl p-2 relative flex justify-center right-[20px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-caret-left-fill scale-90 hover:scale-125 duration-500" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z" />
                </svg>

              </div>
            )}
          </button>

          <div ref={carouselRef} className="overflow-hidden whitespace-nowrap overflow-x-scroll hide-scrollbar w-[95%] bg-cinzasecondary mx-auto">
            {provedor.games.map((item) => (
              <div key={provedor} className="inline-block">      
                {localStorage.user_token ?
                  <CarouselitemOriginais  {...item} />
                  : <CarouselitemDeslogadoOriginais  {...item} />

                }
              </div>
            ))}
          </div>
          <button onClick={() => scroll('right')} className="absolute right-0 lg:right-[25px] bottom-[15px] top-0 h-full  w-[60px]  z-[2] text-3xl text-white bg-gradient-to-l from-[#110e14] ...  ">
            <div className="bg-black rounded-xl p-2 relative flex just left-[20px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor" className="bi bi-caret-right-fill  scale-90 hover:scale-125 duration-500" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z" />
              </svg>
            </div>
          </button>
        </>
      )}
    </div>
  );
}




export default function CarrosselPg() {
  const { prefixoUrl } = useRender();
  const [itemsSelecteds, setItemsSelecteds] = useState([]);
  const [items, setItems] = useState([]);
  const { loading, setLoading } = useRender();




  const [slotDeslogado, setSlotDeslogado] = useState([
    {
      "name": "Jogos Originais",
      "games": [
        {
          "id": 1,
          "nome": "Runner Jelly",
          "provedor_id": 1,
          "imagem_base64": "teste",
          "game_reference": "prag-slot-spaceman",
          "prioridade": "100",
          "status": 1,
          "created_at": "2023-08-01T19:37:01.000000Z",
          "updated_at": "2023-08-01T19:37:01.000000Z",
          "nome_provedor": "Rockeetz Games",
          "link": "/runnerjelly"
        },
        {
          "id": 1,
          "nome": "Mines",
          "provedor_id": 1,
          "imagem_base64": "teste",
          "game_reference": "prag-slot-fruitParty",
          "prioridade": "90",
          "status": 1,
          "created_at": "2023-08-01T19:39:56.000000Z",
          "updated_at": "2023-08-01T19:39:56.000000Z",
          "nome_provedor": "Rockeetz Games",
          "link": "/mines"
        },
        {
          "id": 1,
          "nome": "Cyber Space",
          "provedor_id": 1,
          "imagem_base64": "teste",
          "game_reference": "prag-tb-mega-baccarat",
          "prioridade": "80",
          "status": 1,
          "created_at": "2023-08-01T20:35:14.000000Z",
          "updated_at": "2023-08-01T20:35:14.000000Z",
          "nome_provedor": "Rockeetz Games",
          "link": "/cyberspace"
        },
        {
          "id": 1,
          "nome": "Roullet Planet",
          "provedor_id": 1,
          "imagem_base64": "teste",
          "game_reference": "prag-tb-mega-baccarat",
          "prioridade": "80",
          "status": 1,
          "created_at": "2023-08-01T20:35:14.000000Z",
          "updated_at": "2023-08-01T20:35:14.000000Z",
          "nome_provedor": "Rockeetz Games",
          "link": "/roulletplanet"
        },
        {
          "id": 1,
          "nome": "Game Free",
          "provedor_id": 1,
          "imagem_base64": "teste",
          "game_reference": "prag-tb-mega-baccarat",
          "prioridade": "80",
          "status": 1,
          "created_at": "2023-08-01T20:35:14.000000Z",
          "updated_at": "2023-08-01T20:35:14.000000Z",
          "nome_provedor": "Rockeetz Games",
          "link": "/gamefree"
        }
      ]
    },


  ])




  return (
    <>




      {slotDeslogado.map((provedor) => (
        <div key={provedor}>
          <div className="text-left w-full bg-green mt-4  flex justify-center">
            <div className="w-11/12 flex justify-between">
              <div className="w-6/12 text-white text-2xl font-bold hover:font-extrabold whitespace-nowrap">
                {provedor.name}
              </div>

            </div>
          </div>

          <CarouselRow provedor={provedor} />
        </div>
      ))


      }



    </>
  );
}


