import {useState, useEffect} from 'react';
import Close from '../../../assets/icons/close.svg'
import RktzCoin from '../../../assets/icons/RKT_COIN.svg'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import useRender from "../../../hooks/useRender";
import { HashLink as Link } from 'react-router-hash-link';

function RewardsLoginNull(props) {
  const { saldorkzc } = useRender();

    const [diaLogin, setDiaLogin] = useState()

    useEffect(() => {
        let semana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
        let day = new Date();
        let diaHoje = semana[day.getDay()];
        setDiaLogin(diaHoje)
    }, []);




  function closeRewards() {
    document.getElementById("rewardsLoginNull").classList.toggle("hidden"); }


    return ( 
        <>
        
          {/* CONTAINER COM O BLUR ATIVADO*/}

        <div  id="rewardsLoginNull" className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center " >
        <div   className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
          <div onClick={closeRewards}  className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "></div>
            <div className="flex justify-center items-center  w-full">
        
   

                <div id="rewardsOpacityNull" className="w-11/12 md:w-4/12 opacity-0 duration-700  drop-shadow-lg  bg-[#424242]  z-[6] backdrop-blur-md  p-6 relative ">

                 
                       <div  className="flex justify-end items-center ">
                            <img onClick={closeRewards}  className="h-7 w-5 cursor-pointer hover:animate-spin duration-1000  " src={Close} alt="" />
                        </div>
                        <div className="grid  grid-cols-1  ">
                        <div className="grid justify-items-center">
                           <p className="text-verdesixtyn whitespace-nowrap text-left   font-['Orbitron']  lg:text-4xl text-3xl" >
                         Resgate e Receba
                           </p>

                           
                        
                           <div className="bg-[#1E1E1E] w-[215px] border-x-[3px] border-verdesixtyn my-4 ">
                            <div className="w-full h-[150px] leading-3 text-center text-white mt-4">
                            <div className="flex justify-center">
                                <img className="w-16 rktzCoin" src={RktzCoin} alt="" />
                            </div>
                            <div className="mt-3">
                                <p id="recompensaStreakNull" className="font-['Orbitron'] text-3xl "></p>
                                <p>RKT coins</p>
                            </div>
                            </div>
                        </div>
                        <p id="diaLoginNull" className="text-white uppercase  lg:text-2xl text-xl"> </p>


                        <Link  to="/home#streakLoginHome" >
                                <button className="cursor-pointer lg:mt-2 mt-5 bg-verdesixtyn hover:bg-[#0c6d40]  hover:text-white duration-1000  font-bold text-sm px-16 py-2 rounded-md items-center flex justify-center gap-1 ">
                             <div className="animate-pulse flex items-center gap-1 whitespace-nowrap"><AddCircleRoundedIcon fontSize="small"/> SAIBA MAIS</div>  
                            </button>
            
                                </Link>
                        </div>



    
                        </div>

                
                 
                </div>
             

            </div>
    
       
       
       </div>
      </div>






  
       
        </>
     );
}

export default RewardsLoginNull;