import ConteudoPopUp from './conteudo'
import ContainerBlur from "../../blur/container";
import { useState } from 'react';



export default function PopUp3() {

  const [modal, setModal] = useState();


  function openpopup3() {
    document.getElementById('modalPopUp3').classList.toggle("hidden");   
  
  }

 


  return (
    <>

<div
        id="modalPopUp3"
        className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center"
      >
        <div className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
          <div  onClick={openpopup3} className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "  ></div>


          <ConteudoPopUp onClick={modal} openmodal="modalPopUp3" />   {/* CONTEUDO DO MODAL */}
        </div>
      </div>
    </>

  )
};

