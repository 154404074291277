import AstroLoss from '../../../assets/personagem/astroloss.png'

export default function LossComAposta(){
    return(
        <>
        <div id="modal-loss-com-aposta" className="bg-cinzasecondary h-[70%] lg:h-[50%] flex justify-center w-11/12 lg:w-6/12 rounded-xl z-[1]" style={{display: "none"}}>
                  <div className="w-full flex items-center flex-col">
                    <div className="w-full flex py-3 px-4 lg:px-5 lg:py-6">
                    <div className="w-8/12 text-base lg:text-xl">
                    <p>VOCÊ NÃO SALVOU O <b className="text-red-700 font-black text-2xl">ASTRONAUTA</b>!</p>
                    </div>
                    <div className="w-4/12 relative">
                     <img className="absolute w-10/12 top-[-40px] lg:top-[-50px] right-0" src={AstroLoss}/>
                    </div>
                    </div>
                    <div className="w-11/12 lg:mt-4 z-[2] flex p-2 justify-center items-center uppercase font-bold bg-red-700 rounded-md">
                    <p>Explodiu em <span id="multiplicadorLossModal">..</span></p>
                    
                    </div>
                  </div>
                
                 
                </div>
        </>
    )
}