import { Link } from "react-router-dom";
import Voltar from "../../../assets/icons/voltar.gif";
import Title from "../../../components/centraldojogador/titulo";

function MenuPhone() {
  function DropSubMenuHistorico() {
    document.getElementById("subMenuHistorico").classList.toggle("hidden");
    document.getElementById("subMenuAjuda").classList.add("hidden");
    document.getElementById("subMenuPrivacidade").classList.add("hidden");
  }

  function DropSubMenuAjuda() {
    document.getElementById("subMenuAjuda").classList.toggle("hidden");
    document.getElementById("subMenuPrivacidade").classList.add("hidden");
    document.getElementById("subMenuHistorico").classList.add("hidden");
  }

  function DropSubMenuPrivacidade() {
    document.getElementById("subMenuPrivacidade").classList.toggle("hidden");
    document.getElementById("subMenuAjuda").classList.add("hidden");
    document.getElementById("subMenuHistorico").classList.add("hidden");
  }

  function VoltarHome() {
    window.location.href = '/home';
  }
  return (
    <main className=" min-h-[850px]">
      {/* menu celular */}
      <div className="lg:hidden">
        <div className="flex justify-between items-center py-4 px-2">

            <div onClick={VoltarHome}>
              <img className="w-[110px] cursor-pointer" src={Voltar} alt="" />
            </div>
    

          <Title title="Central do Jogador"/>
          
        </div>
        <Link to="/centraldojogador/perfil">
          <div className="border-t-2 border-[#221f1f] p-4 cursor-pointer">
            <p className="text-white text-xl">Minha Conta</p>
          </div>
        </Link>

        <Link to="/centraldojogador/promocao">
          <div className="border-t-2 border-[#221f1f] p-4 cursor-pointer">
            <p className="text-green-400 flex items-center  text-xl">Sorteio Iphone 15 <div className="ml-4 rounded-md bg-green-400 text-black px-3 py-1 animate-pulse text-[0.6em]">new</div></p>
          </div>
        </Link>

       
        <div
          onClick={DropSubMenuHistorico}
          className="border-t-2 border-[#221f1f] p-4 cursor-pointer"
        >
          <p className="text-white text-xl">Históricos</p>
        </div>

        <div
          id="subMenuHistorico"
          className="bg-[#181717] text-white p-4 hidden"
        >
          <Link to="/centraldojogador/historicodejogo">
            <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1">
              <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                Histórico De Jogo
              </p>
            </div>
          </Link>

          <Link to="/centraldojogador/historicodesaque">
            <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1">
              <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                Histórico De Saque
              </p>
            </div>
          </Link>

          <Link to="/centraldojogador/historicodedeposito">
            <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1">
              <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                Histórico De Depósito
              </p>
            </div>
          </Link>
        </div>

        <Link to="/centraldojogador/indicacao">
          <div className="border-t-2 border-[#221f1f] p-4 cursor-pointer">
            <p className="text-white text-xl">Indicações</p>
          </div>
        </Link>
    

        <div
          onClick={DropSubMenuAjuda}
          className="border-t-2 border-[#221f1f] p-4 cursor-pointer"
        >
          <p className="text-white text-xl">Ajuda</p>
        </div>

        <div id="subMenuAjuda" className="bg-[#181717] text-white p-4 hidden ">
          <Link to="/centraldojogador/suporte">
            <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1">
              <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                Suporte
              </p>
            </div>
          </Link>

          <Link to="/centraldojogador/perguntasfrequentes">
            <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1">
              <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                Perguntas Frequentes
              </p>
            </div>
          </Link>

          <Link to="/centraldojogador/regras">
            <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1">
              <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                Regras
              </p>
            </div>
          </Link>
        </div>

        <div
          onClick={DropSubMenuPrivacidade}
          className="border-t-2 border-[#221f1f] p-4 cursor-pointer"
        >
          <p className="text-white text-xl">Privacidade e Segurança</p>
        </div>

        <div
          id="subMenuPrivacidade"
          className="bg-[#181717] text-white p-4 hidden "
        >
          <Link to="/centraldojogador/excluirconta">
            <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1">
              <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                Excluir Conta
              </p>
            </div>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default MenuPhone;
