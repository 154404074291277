import ConteudoPopUp from './conteudo'
import ContainerBlur from "../../blur/container";
import { useState } from 'react';



export default function PopUp() {

  const [modal, setModal] = useState();


  function openpopup1() {
    document.getElementById('modalPopUp').classList.toggle("hidden");    

    openRedesSociais()
  }
  function openRedesSociais() {
    let method = document.getElementById("modalPopUp2")
    method.classList.toggle("hidden");
    let opacity = document.getElementById("popup--2")
    opacity.classList.add("opacity-0");

    setTimeout(() => {
        opacity.classList.toggle("opacity-0");

    }, 100);
}

 
  return (
    <>



      <div
        id="modalPopUp"
        className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center"
      >
        <div className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-center justify-center">
          <div  onClick={openpopup1} className="cursor-pointer  backdrop-blur-sm absolute w-full h-screen top-0 bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "  ></div>


          <ConteudoPopUp onClick={modal} openmodal="modalPopUp" />   {/* CONTEUDO DO MODAL */}
        </div>
      </div>

    </>

  )
};

