import ConteudoTermos from './TermosDeUso/conteudo.js'



export default function TermosDeUso() {


  return (
    <>

      <div id="modalTermos"
        className="fixed  hidden top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  z-[6]  items-center justify-center">
        <div className="fixed  top-0 bottom-0 left-[0px] right-0 w-full h-[100vh]  flex items-start pt-10 justify-center">
          <div className=" backdrop-blur-sm absolute w-full h-screen top-[0px] bottom-0 left-[0px] right-0 bg-[#00000021]  duration-[2000ms] "></div>
          <ConteudoTermos openmodal="modalTermos" />   {/* CONTEUDO DO MODAL */}
        </div>
      </div>


    </>

  )
};

