import Menos from "../../assets/buttons/menos.png";
import Mais from "../../assets/buttons/mais.png";
import buttonSong from "../../assets/audio/button2.mp3";
import React, { useState, useRef, useEffect } from "react";
import Swal from 'sweetalert2';
import useRender from "../../hooks/useRender";

export default function ControlesRoleta() {
  const [quantia, setQuantia] = useState(1);
  const [autoretirarValue, setAutoretirarValue] = useState(2);
  const [comeca, setComeca] = useState("INVESTIR");
  const audioRef = useRef();
  const { signed } = useRender();

  function openLogin() {
    document.querySelector(".login__modal").classList.toggle("mt-[-600px]");
    document.querySelector(".login__modal").classList.toggle("opacity-0");
    document.querySelector(".login--opacidade").classList.toggle("hidden");
    document.getElementById("login").focus();
  }




  var btnIniciar = document.getElementById("btnInvest"); 
  
  function selectCor(corIndex, idbutton) {
    window.c_Roullet.corRoleta = corIndex;
    
    let buttons = document.getElementById(idbutton)
    let btnBlue = document.getElementById('btnBlue')
    let btnWhite = document.getElementById('btnWhite')
    let btnBlack = document.getElementById('btnBlack')
    // Escala e opacidade das cores btn
    btnBlue.classList.add('opacity-60')
    btnBlue.classList.remove('click__select__cor', 'scale-[1.2]', 'border-2', 'z-[1]', 'opacity-100')
    btnWhite.classList.remove('click__select__cor', 'scale-[1.2]', 'border-2', 'z-[1]', 'opacity-100')
    btnBlack.classList.remove('click__select__cor', 'scale-[1.2]', 'border-2', 'z-[1]', 'opacity-100')
    btnBlue.classList.add('opacity-60')
    btnBlack.classList.add('opacity-60')
    btnWhite.classList.add('opacity-60')
    buttons.classList.remove('opacity-60')
    buttons.classList.add('click__select__cor', 'scale-[1.2]', 'border-2', 'z-[1]', 'opacity-100')

  
  }


   
     //iniciar jogada, entrando na fila
     window.c_Roullet.token = localStorage.getItem("user_token");


    function handleeChangeBtn() {
   
      if (window.c_Roullet.botao.status != "open") {
        return;
    }

   

    if (signed != true){
      openLogin()
    }else{

      if (quantia <= 0 || quantia == "") {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Digite um valor para iniciar a partida.',
        })
      
        return;
      }
      
      if (window.c_Roullet.corRoleta == "") {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Escolha uma cor para iniciar a partida.',
        })
      
        return;
      }
  
      if (window.c_Roullet.saldo < quantia && window.c_Roullet.botao.status == "naoIniciado") {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Saldo insuficiente.',
        })
      }
        if (window.c_Roullet.saldo === 0 && window.c_Roullet.botao.status == "naoIniciado" ){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Saldo insuficiente.',
          })
        }
  
  
  
  
  
     setComeca("Investindo..")
     btnIniciar.style.opacity = 0.4;
     setTimeout(() => {
      setComeca("Investir")
         btnIniciar.style.opacity = 1;
     }, 500)
  
     document.getElementById("moneyExit").classList.remove("hidden")
     setTimeout(() => {
       document.getElementById("moneyExit").classList.add("hidden")
     }, 1000);
  
  
     
     let Aposta = { 'status': window.c_Roullet.botao.status,'valor': quantia,'cor': window.c_Roullet.corRoleta,'token': window.c_Roullet.token}
     
     window.c_Roullet.socket.emit('EntrarNaFila', Aposta);
     window.c_Roullet.aposta = quantia;
     window.c_Roullet.fila = true;
  

    }
    
   


    
    }

    
  
    
    




   useEffect(()=>{

    window.c_Roullet.socket.on('btnStatus', (status) => {
      
      btnIniciar = document.getElementById("btnInvest"); 
      
      window.c_Roullet.botao.status = status.status;
      
      setComeca(status.value)
  
      if (status.opacity > 0) {
        btnIniciar.style.opacity = status.opacity;
       
      }
      if (status.block == true) {
          document.getElementById("apostaForm").disabled = false;
      }
  
  })
   },[])

   
   




    //iniciar jogada, entrando na fila

   
  function dobrarQuantia() {
    setTimeout(async () => {
      let audio = audioRef.current;
      audio.currentTime = 0;
      audio.play();
    }, 1);

    function decimalInt() {
      var resto = dobrar - parseInt(dobrar);
      if (resto == 0) {
        setQuantia(dobrar.toFixed(0));
      } else {
        setQuantia(dobrar.toFixed(2));
      }
    }
    if (quantia <= 2) {
      if (quantia >= 1.5) {
        var dobrar = 0.5 + parseFloat(quantia);

        decimalInt();
      } else {
        var dobrar = 0.25 + parseFloat(quantia);

        decimalInt();
      }
    } else {
      var dobrar = 2 * quantia;

      decimalInt();
    }
  }

  function dividirQuantia() {
    setTimeout(async () => {
      let audio = audioRef.current;
      audio.currentTime = 0;
      audio.play();
    }, 1);

    var dividir;
    function decimalInt() {
      var resto = dividir - parseInt(dividir);
      if (resto == 0) {
        setQuantia(dividir.toFixed(0));
      } else {
        setQuantia(dividir.toFixed(2));
      }
    }
    if (quantia <= 2.5) {
      if (quantia == 1.5) {
        dividir = parseFloat(quantia) - 0.25;
        decimalInt();
      } else if (quantia <= 1) {
        setQuantia(1);
      } else {
        if (quantia <= 1.5) {
          setQuantia(1);
        } else {
          dividir = parseFloat(quantia) - 0.5;

          decimalInt();
         
        }
      }
    } else {
      dividir = quantia / 2;
      decimalInt();
    }
  }

  return (
    <>
      <audio ref={audioRef} src={buttonSong}></audio>
      <div className="w-12/12 flex flex-wrap justify-center ">


        <div className="w-11/12 mt-2 flex rounded-xl flex-wrap bg-cinzaprimary">
          {/* AUTO RETIRAR */}
          <div className="w-full flex justify-between bg-gradient-to-r from-[#133247] to-[#161220] rounded-tl-lg rounded-tr-lg h-[40px]">
            <div className="w-full mt-2 flex justify-center uppercase Oswald ">
             Faça seu investimento
            </div>

          </div>
          {/* BUTTONS */}
          <div className="w-5/12 mt-3 pb-3 flex relative justify-center">
            <button onClick={handleeChangeBtn} style={{opacity : "1"}}  id="btnInvest" action="naoIniciado" className="bg-azulprimary active:top-[-2px] font-xs font-bold top-[-6px] h-[70px] rounded-lg uppercase absolute text-black flex items-center cursor-pointer justify-center w-11/12">
              {comeca}
            </button>
            <div className="bg-cinzafourth text-black rounded-lg  h-[70px] flex justify-center w-11/12"></div>
            {/* valor apostado na rodada   */}
            <div id="moneyExit" className="absolute text-[1em] rounded-[10px] px-[10px] font-bold bg-azulsecondary duration-1000 hidden  leave_money_roullet">
              <p>R$ -{quantia}</p>
            </div>

            {/* Valor a ser ganho */}
            <div id="arrowBalao" className="absolute top-[65px] z-10  " style={{ display: "none" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#06ff7b" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
              </svg>
            </div>
            <div className="absolute top-[75px] bg-verdesixtyn text-black text-[1em] rounded-[10px] px-[10px] font-bold ">
              <p id="moneyGanho" className="whitespace-nowrap"></p>
            </div>

          </div>

          <div className="w-7/12  flex  relative items-center  justify-center">
            <div className="w-11/12 bg-cinzaprimary z-[2] rounded-lg top-[-6px] relative py-[10px] border-2 border-azulsecondary h-[70px]">
              <div
                onClick={dobrarQuantia}
                className="absolute  right-0 px-1 cursor-pointer z-[2] text-black rounded-tr-md rounded-br-md active:top-[2px]  active:h-[96.8%] top-0 h-full  text-xl flex justify-center items-center font-bold bg-gradient-to-r from-cinzathird to-cinzafourth"
              >
                <div className="flex items-center relative">
                  <img className="w-4 py-3" src={Mais} alt="" />
                </div>
              </div>

              <div className="absolute  right-0 h-full w-[24px] rounded-sm top-0 bg-azulfourth rounded-tr-md rounded-br-md"></div>

              <div
                onClick={dividirQuantia}
                className="absolute left-0  px-1 cursor-pointer z-[2] h-full text-black rounded-tl-md rounded-bl-md active:top-[2px] active:bg-verdeprimary active:h-[96.8%] active:top-[18px] drop-shadow-xl top-0 text-xl flex justify-center items-center font-bold bg-gradient-to-r from-cinzafourth to-cinzathird"
              >
                <div className="flex items-center relative ">
                  <img className="w-4  py-3" src={Menos} alt="" />
                </div>
              </div>

              <div className="absolute  left-0 h-full w-[24px] rounded-tl-md rounded-bl-md top-0 bg-azulfourth"></div>

              <div className="text-black flex h-full justify-center text-3xl items-center">
                <div className="text-xs text-white absolute top-0">
                  investimento
                </div>
                <div className="text-azulprimary Oswald absolute text-[0.3em] left-[28px] top-[16px]">
                  BRL
                </div>
                <input
                  maxLength="4"
                  id="apostaForm"
                  type="number"
                  disabled={false}
                  value={quantia}
                  onChange={(e) => setQuantia(e.target.value)}
                  className="outline-none ml-3 disabled:opacity-20  appearance-none text-left appearance-textfield text-[0.8em] overflow-hidden scroll-hidden text-azulprimary placeholder:text-azulprimary bg-cinzaprimary text-center w-8/12 Oswald"
                />
              </div>
              
            </div>
            <div className="w-11/12 rounded-lg top-[12px] absolute py-[10px] border-2 border-azulthird bg-azulthird h-[70px]"></div>
          </div>
             
             <div className="w-full bg-cinzasecondary m-3 rounded-md">
              <div className="flex justify-center items-center  ">
                <p className="font-semibold py-3 text-md tracking-tight">ESCOLHA UMA OPÇÃO</p>
              </div>
              <div className="flex space-x-2  items-center px-4 mb-3">
                <button id="btnBlue" onClick={()=>{selectCor('azul', 'btnBlue')}} className="  border-white duration-100 opacity-60  btn__select__cor bg-[#1b323a] cursor-pointer  rounded-[0.2em] overflow-hidden  outline-none relative w-full min-w-[4.75rem] h-8 flex items-center ">
                  <img className="max-w-[100%] w-12 h-auto translate-y-1 -translate-x-1 " src="./img/roleta/planet.png" alt="" />
                  <p className="ml-auto mr-2 font-semibold">2x</p>
                </button>

                <button id="btnWhite" onClick={()=>{selectCor('branco', 'btnWhite')}} className="  border-azulprimary  duration-100 opacity-60 btn__select__cor bg-white cursor-pointer  rounded-[0.2em] overflow-hidden  outline-none relative w-full min-w-[4.75rem] h-8 flex items-center ">
                  <img className="max-w-[100%] w-12 h-auto translate-y-1 -translate-x-1 " src="./img/roleta/astro.png" alt="" />
                  <p className="text-[#1385B5] ml-auto mr-2 font-semibold">14x</p>
                </button>

                <button  id="btnBlack" onClick={()=>{selectCor('preto', 'btnBlack')}} className=" border-white  duration-100 opacity-60  btn__select__cor bg-[#131011] cursor-pointer  rounded-[0.2em] overflow-hidden  outline-none relative w-full min-w-[4.75rem] h-8 flex items-center ">
                  <img className="max-w-[100%] w-12 h-auto translate-y-1 -translate-x-1 " src="./img/roleta/planet-cinza.png" alt="" />
                  <p className="ml-auto mr-2 font-semibold">2x</p>
                </button>
              </div>
              </div>
           
        </div>
      </div>
    </>
  );
}
