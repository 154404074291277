function BtnCancelar({ texto, largura, altura, radius, sizeText, idBtn, onClick, disabled  }) {
  return (
        <>
        
        <button
         id={idBtn}
         disabled={disabled}
         onClick={onClick}//executa funação 
          className={` bg-red-600  uppercase px-4  ${largura} ${altura} ${radius} ${sizeText}   font-semibold scale-95 hover:scale-100 hover:bg-red-700 hover:text-white  duration-700`}
        >
          {texto}
        </button>

        </>
      
  
  );
}

export default BtnCancelar;
