import {useEffect, useState} from "react"
import { motion,  } from "framer-motion";
import Numero0 from "./img/0.png"
import Numero1 from "./img/1.png"
import Numero2 from "./img/2.png"
import Numero3 from "./img/3.png"
import Numero4 from "./img/4.png"
import Numero5 from "./img/5.png"
import Numero6 from "./img/6.png"
import Numero7 from "./img/7.png"
import Numero8 from "./img/8.png"
import Numero9 from "./img/9.png"
import Virgula from "./img/virgula.png"
import Moeda from "./../../../../assets/moedas/moeda.gif"
import useRender from "../../../../hooks/useRender";

export default function Premium(){
    const { diasArray } = useRender()
    const [premiacao, setPremiacao] = useState(diasArray[6].recompensafull);
    const [valor, setValor] = useState([1,6,4,0]);
    const [number, setNumber] = useState(()=>{
        var listaNumeros = [
        {num:0, img:`${Numero0}`, wid: `w-[100px] h-[100px]`},
        {num:1, img:`${Numero1}`, wid: `w-[75px] h-[100px]`},
        {num:2, img:`${Numero2}`, wid: `w-[100px] h-[100px]`},
        {num:3, img:`${Numero3}`, wid: `w-[100px] h-[100px]`},
        {num:4, img:`${Numero4}`, wid: `w-[100px] h-[100px]`},
        {num:5, img:`${Numero5}`, wid: `w-[100px] h-[100px]`},
        {num:6, img:`${Numero6}`, wid: `w-[100px] h-[100px]`},
        {num:7, img:`${Numero7}`, wid: `w-[100px] h-[100px]`},
        {num:8, img:`${Numero8}`, wid: `w-[100px] h-[100px]`},
        {num:9, img:`${Numero9}`, wid: `w-[100px] h-[100px]`},
        {num:"virgula", img:`${Virgula}`, wid: `w-[30px] flex items-end h-[100px]`},

    ]
   return listaNumeros
});


   function Premy(){    
    setValor(premiacao.toString().split(""))
   
   }
    


    useEffect(()=>{
     Premy()
    },[])


    return(
        <>
        <div id="$Premium" className="scalePremium w-full hidden justify-center items-center" >
            <div className="w-full flex  items-center flex-col justify-center">
                <div className="text-4xl font-black scalePremium2 ">VOCÊ GANHOU</div>
              
                <div className="flex scalePremium">
                      {valor.map((item,index) => {
                    return (
                        <motion.div  key={index}
                         transition={{ duration: 300, type: "spring",  stiffness: 260, damping: 20 }}
                         initial={{ scale: 0 }} 
                         animate={{ x: 0, y: 0, scale: 1.1, rotate: 0, }} >
                            <div className={number[item].wid} key={index}>                  
                            <img className="w-full" src={number[item].img} alt="" />
                            </div>
                        </motion.div>
 
                    )
                })}
                </div>
                <div className="flex mt-10 items-center scalePremium3 p-2 border-4 border-yellow-400 rounded-2xl">
                    <div className="  w-[50px] h-[50px]">
                        <img src={Moeda} />
                    </div>
                    <div className=" w-[70px] h-[70px]">
                        <img src={Moeda} />
                    </div>
                <div className="text-6xl  text-yellow-400 font-black">RKT</div>
                  <div className="  w-[70px] h-[70px]">
                        <img src={Moeda} />
                    </div>
                    <div className="w-[50px] h-[50px]">
                        <img src={Moeda} />
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}