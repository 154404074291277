import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

import Bonus from "./pages/Bonus";
import Perfil from "./pages/CentralDoJogador/Perfil/perfil";
import Indicacao from "./pages/CentralDoJogador/Indicacao/indicacao";
import ExcluirConta from "./pages/CentralDoJogador/ExcluirConta";
import Suporte from "./pages/CentralDoJogador/Suporte";
import PerguntasFrequentes from "./pages/CentralDoJogador/PerguntasFrequentes";
import Regras from "./pages/CentralDoJogador/Regras";
import HistoricoDeJogo from "./pages/CentralDoJogador/HistoricoDeJogo";
import HistoricoDeSaque from "./pages/CentralDoJogador/HistoricoDeSaque";
import HistoricoDeDeposito from "./pages/CentralDoJogador/HistoricoDeDeposito";
import RecoveryPassword from "./pages/RecoveryPassword";
import Sacar from "./pages/Sacar";
import useRender from "./hooks/useRender";
import Roullet from "./pages/Roullet/Roullet";
import CrashFree from './pages/CrashFree/CrashFree'
import CentraldoJogador from "./pages/CentralDoJogador";
import Principal from "./pages/Principal/home";
import RegrasHome from "./pages/Regras";
import SuporteHome from './pages/Suporte'
import Mines from "./pages/Mines/Mines";
import CadastroStep1 from "./pages/Cadastro/cadastroStep1";
import PageError from "./pages/Error/Error";
import ImagensUpload from "./pages/Images/images";
import CyberBomb from './components/CyberBomb/cyberBomb.js'
import RunnerJelly from './components/RunnerJelly/GameBoard.js'
import Pgsoft from "./pages/Jogos/PGSOFT/pgsoft";
import SlotGame from  "./pages/Principal/jogos/slot/game";
import Download from './components/Download'
// import SlotGame from  "./pages/Principal/jogos/slot/game";
import SlotGameList from  "./pages/Principal/jogos/slot/list";
import Private from "./pages/Private";
import UserPromocoes from "./pages/CentralDoJogador/Promocoes/UserPromocoes";

function RoutesApp() {
  // const {  signed } = useRender();
  // const Private = ({ Item }) => {

  //   return signed == true ? <Item /> : <Principal />;
  // };

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/home" element={<Principal />} />
        <Route exact path="/" element={<Principal />} />

        <Route exact path="/cadastrar" element={<CadastroStep1 />} />
        <Route exact path="/register" element={<CadastroStep1 />} />
        <Route exact path="/recovery" element={<RecoveryPassword />} />

        <Route
          exact
          path="/bonus"
          element={
            <Private>
              {" "}
              <Bonus />
            </Private>
          }
        />
        <Route exact path="/roulletplanet" element={<Roullet />} />
        <Route exact path="/mines" element={<Mines />} />
        <Route exact path="/cyberspace" element={<Home />} />

        <Route exact path="/gamefree" element={<CrashFree />} />

        <Route exact path="/secret-cyberbomb" element={<CyberBomb />} />
        <Route
          exact
          path="/runnerjelly"
          element={
            <Private>
              {" "}
              <RunnerJelly />
            </Private>
          }
        />
        <Route exact path="/secret-pgsoft" element={<Pgsoft />} />
        <Route exact path="/slot" element={<SlotGame />} />
        <Route exact path="/slot/list" element={<SlotGameList />} />

        <Route exact path="/download" element={<Download />} />
        <Route
          exact
          path="/centraldojogador"
          element={
            <Private>
              {" "}
              <CentraldoJogador />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/historicodejogo"
          element={
            <Private>
              {" "}
              <HistoricoDeJogo />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/historicodesaque"
          element={
            <Private>
              {" "}
              <HistoricoDeSaque />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/historicodedeposito"
          element={
            <Private>
              {" "}
              <HistoricoDeDeposito />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/excluirconta"
          element={
            <Private>
              {" "}
              <ExcluirConta />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/perguntasfrequentes"
          element={
            <Private>
              {" "}
              <PerguntasFrequentes />
            </Private>
          }
        />
        <Route
          exact
          path="/sacar"
          element={
            <Private>
              {" "}
              <Sacar />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/indicacao"
          element={
            <Private>
              {" "}
              <Indicacao />
            </Private>
          }
        />
        <Route exact path="/regras" element={<RegrasHome />} />
        <Route exact path="/suporte" element={<SuporteHome />} />
        <Route exact path="/centraldojogador/regras" element={<Regras />} />
        <Route
          exact
          path="/centraldojogador/perfil"
          element={
            <Private>
              {" "}
              <Perfil />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/promocao"
          element={
            <Private>
              {" "}
              <UserPromocoes />
            </Private>
          }
        />
        <Route
          exact
          path="/centraldojogador/suporte"
          element={
            <Private>
              {" "}
              <Suporte />
            </Private>
          }
        />
        <Route exact path="/convite/:link" element={<Principal />} />
        <Route
          exact
          path="/images/secretimagesupload"
          element={<ImagensUpload />}
        />
        <Route path="*" element={<PageError />} />

        {/*
                  Paginas para avisar Manutenção
        <Route exact path="/" element={<ManutencaoPage />} />
        <Route path="*" element={<ManutencaoPage />} />

*/}
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesApp;
