import X from "../../../assets/pagamento/x.png"
import RKTZ from '../../../assets/icons/RKT_COIN.svg'
import useRender from "../../../hooks/useRender";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {useState} from 'react';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import Swal from "sweetalert2";


export default function ConteudoConvertRkt(props) {
    const { prefixoUrl, saldorkzc, sincronizarSaldoComBancoDeDados } = useRender();
    const [rktConvert, setRktConvert] = useState(0);
    const [saldoReal, setSaldoReal] = useState(0);


    function closeConversor() {
        document.getElementById(props.openmodal).classList.toggle("hidden");
    }

    function openRules(){
        document.getElementById("regras--convert--rkt").classList.toggle("hidden");
    }

    function tudoConvert(){
        setRktConvert(saldorkzc)
    }

    function handleeConvert(btn){
        if(saldorkzc >= 1000){
            let svg = btn.target;
            svg = svg.getElementsByTagName('svg');
            svg[0].classList.add('animate-spin');
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
              };
              
              fetch(`${prefixoUrl}/rktz/troca/${rktConvert}?token=${localStorage.user_token}`, requestOptions)
                .then(response => response.json())
                .then(result =>{
                    if(result.code == 1){
                        sincronizarSaldoComBancoDeDados()
                        Swal.fire(
                            "Parabéns",
                            "Seus RKT'S foram convertidos com sucesso.",
                            'success'
                          )
                    }
                })
                .catch(error => console.log('error', error))
    
                .finally(()=> {
                    let convert = document.getElementById("Convert")
                    convert.classList.toggle("hidden");
                    svg[0].classList.remove('animate-spin');
                });

        }else{
            Swal.fire(
                "Ops",
                " RKT'S insuficiente, seu saldo RKTZ precisa ser de pelo menos 1000.",
                'error'
              )
        }

    }

    return (
        <>
            <div id="Convert" className="md:w-6/12 w-11/12 bg-cinzathird  z-[6] backdrop-blur-md rounded-xl border-2 flex flex-wrap border-green-700 py-4">
                <div className="absolute right-[10px] top-[20px] text-white">
                    <img onClick={closeConversor} className="w-6/12 cursor-pointer" src={X} />
                </div>
                <div className="w-full flex-col flex items-center">
                    <div className="w-11/12 pt-4">
                        <div className="text-white font-bold text-xl">SEUS RKT'S</div>
                    </div>
                    <div className="flex justify-center w-11/12 text-white text-sm mt-3">
                        Ganhe <b className="mx-1">RKT</b> Coin e troque-os por dinheiro real
                    </div>
                    <div className="w-11/12 p-2 flex flex-col items-center rounded-lg bg-cinzasecondary text-white text-[0.6em] mt-3">
                        <div className="w-11/12 text-lg font-bold">SALDO RKT</div>
                        <div className="w-full bg-cinzaprimary px-2 py-1 rounded-md flex justify-between">
                            <img src={RKTZ} />
                            <div className="text-3xl ml-2 font-bold Orbitron">{saldorkzc}</div>
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className="w-11/12 flex mt-2 px-4 items-center justify-between">
                      <div className="text-[0.7em] text-white">Leia as regras de Acumulação de RKT</div>
                      <div onClick={openRules} className="bg-white flex justify-center items-center px-2 py-1 text-[0.6em] font-black rounded-md cursor-pointer hover:bg-verdeprimary hover:text-white">Abrir Regras</div>
                    </div>
                    <div id="regras--convert--rkt" className="hidden w-11/12 bg-cinzafourth rounded-md py-2 mt-2 px-4 items-center justify-between ">
                      <div className="text-[0.7em] text-white">As RKT coins são concedidas para joga no GameFree, de acordo com sua moeda.
                      Após coletar uma quantidade mínima de RKT coins, você pode trocá-las por dinheiro real. As quantidades mínimas e o valor do RKT podem ser alteradas a qualquer momento pela plataforma.</div>
                      
                    </div>

                    <div className="w-11/12  mt-3 flex flex-col">
                        <div className="w-full text-white text-[0.6em] px-2 flex justify-between">
                            <div>SALDO RKT</div>
                            <div>DINHEIRO REAL</div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-4/5 p-1 px-2 flex bg-cinzasecondary text-white Orbitron rounded-md">

                                <input id="saldo--convert--rkt"  type="number" onChange={(e)=> setRktConvert((e.target.value))}  value={rktConvert} className="focus:border-cinzasecondary focus:border focus:outline-none bg-cinzasecondary number w-8/12" />
                                <div onClick={tudoConvert} className="cursor-pointer w-4/12 rounded-md my-0.5 flex justify-center items-center text-[0.6em] bg-verdeprimary hover:bg-green-400 text-black font-bold">TUDO</div>
                            </div>
                            <div className="w-1/5 text-white flex justify-center items-center">
                                <CompareArrowsIcon />
                            </div>
                            <div className="w-4/5 p-1 px-2 flex bg-cinzasecondary text-white Orbitron rounded-md">

                                <input id="saldo--convert--real"type="number" value={rktConvert / 1000} onChange={(e)=> setSaldoReal((e.target.value))} className="bg-cinzasecondary number w-8/12" />
                                <div className="w-4/12 border-l border-l-cinzafourth my-0.5 flex justify-center items-center text-[0.6em]  text-white font-bold">R$</div>
                            </div>
                        </div>
                    </div>
                    {rktConvert < 1000 ? (<button disabled className="disabled:opacity-50 w-11/12 mt-3 bg-cinzafourth text-2xl flex justify-center items-center font-bold py-3 rounded-md"> CONVERTER </button>):(<button onClick={(e) => handleeConvert(e)}   className="disabled:opacity-50 w-11/12 mt-3 bg-verdeprimary text-2xl flex justify-center items-center font-bold py-3 rounded-md gap-2"> <CurrencyExchangeRoundedIcon/>  CONVERTER </button>)}
                    
                </div>








            </div>
        </>
    )
}