import React, { useEffect, useState } from 'react';
import FooterMenu from '../Footer/footerMenu';
import useRender from '../../hooks/useRender';

function ScrollDetector({ children }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const { signed } = useRender();
  const [location] = useState( window.location.href)

  useEffect(() => {

    if(location.indexOf("runnerjelly")){
      setVisible(false)
      return
    }
    function handleScroll() {
      const currentScrollPos = window.pageYOffset;  // posição atual do eixo Y
    
      setVisible(prevScrollPos > currentScrollPos); // verifica se a posição passada é maior que a posição atual
      setPrevScrollPos(currentScrollPos);  //seta a posição atual para outro state

    }

    window.addEventListener('scroll', handleScroll);  // adiciona o efeito de scroll e passa a função para verificar se o usuario está rolando a pagina para baixo

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {

    if (!visible) {
      document.getElementById("hoverFooterMenu").classList.add("opacity-0")
      setTimeout(() => {
        document.getElementById("hoverFooterMenu").classList.add("hidden")

      }, 100);
    } else{
      document.getElementById("hoverFooterMenu").classList.remove("hidden")

      setTimeout(() => {
        document.getElementById("hoverFooterMenu").classList.remove("opacity-0")

      }, 100);
    }

  }, [visible]);

  return (
    <>
      {children}

      <div id="hoverFooterMenu" className={`lg:hidden duration-700 `} >
        {signed == true && <FooterMenu />}
      </div>

    </>
  );
}

export default ScrollDetector;
