import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import astro5 from "../../assets/icons/astro5.png";
import Voltar from "../../assets/icons/voltar.gif";
import useRender from "../../hooks/useRender";
import Swal from 'sweetalert2';

function RecoveryPassword() {
  
  const {prefixoUrl, setLoading} = useRender();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [cdParams, setCdParams] = useSearchParams();

  useEffect(() => {
    
    document.getElementById("inputCadastro").classList.remove("ml-[100vh]");
    document.getElementById("inputCadastro").classList.remove("opacity-0");
    document.getElementById("inputCadastro2").classList.remove("mr-[100vh]");
    document.getElementById("inputCadastro2").classList.remove("opacity-0");
    document.getElementById("imgCadastro").classList.remove("opacity-0");
    document.getElementById("imgCadastro").classList.remove("scale-50");
    document.getElementById("textoCadastro").classList.remove("opacity-0");
    document.getElementById("textoCadastro").classList.remove("scale-50");
  }, []);

  async function confirmStep () {
    let user_token = localStorage.getItem("user_token");
    let code = cdParams.get("cd");

    let list_validacoes = [
      !password,
      !passwordConfirm,
      !(password == passwordConfirm),
      password.length < 8
    ];

    let list_msgs = [
      "Campo senha vázio!",
      "Confirmação de senha necessária!",
      "Senhas devem ser iguais!",
      "Senha deve possuir no mínimo 8 caracteres" 
    ];

    for (let index = 0; index < list_validacoes.length; index++) {
      const element = list_validacoes[index];

      if( element ){ 
        alert(list_msgs[index]);     
        
        return false;
      }
      
    }    
    var raw = JSON.stringify({
      
      "newPassword": password,
      "newPasswordConfirmed": passwordConfirm,
      "code": code
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };
    console.log(requestOptions, 'teste')
    
    fetch(`${prefixoUrl}/user/recuperar/senha/alterar`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let dados = JSON.parse(result);
        if( dados.code == 1 ) {
          Swal.fire({
            icon: 'success',
            title: '',
            text: "Senha alterada com sucesso!",
          }).then(result => {
            if( result.isConfirmed || result.isDismissed) {
              window.location.href = '/home';
            }
            
          })
          
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: dados.msg,
          })
        }          
      })
      .catch(error => {
        console.log('error', error);
      
      });

    return true;
    
  }

  return (
    <main className="w-full h-screen text-white bg-[#212529]">
      <div>
        <p className="text-center text-sm">Segurança</p>
      </div>

      <div class="pb-1">
        <div class="loading--register--step7  w-full  ">
          <div class="progress-bar--register--step7   "></div>
        </div>
      </div>

      <Link to="/home">
        <div className="flex justify-end">
          <img className="w-[110px] cursor-pointer" src={Voltar} alt="" />
        </div>
      </Link>

      <div className="">
        <div className="mt-10 px-16 lg:flex lg:justify-center">
          <p
            id="textoCadastro"
            className="text-left font-bold text-2xl opacity-0 scale-50 duration-1000"
          >
            Escolha uma nova senha para recuperar a conta.
          </p>
        </div>

        <div className="flex justify-center mt-10">
          <input
            id="inputCadastro"
            placeholder="Digite a nova senha..."
            value={password}
            onChange={(value) => setPassword(value.target.value)}       
            type="password"
            className="border border-green-800 outline-none rounded-full py-1 px-2 w-[270px] bg-[#2e3033] ml-[100vh] duration-1000 opacity-0"
          />
        </div>
        <div className="flex justify-center mt-3">
          <input
            id="inputCadastro2"
            value={passwordConfirm}
            onPaste={(e) => e.preventDefault()}
            onChange={(value) => setPasswordConfirm(value.target.value)}
            placeholder="Confirme a nova senha..."
            type="password"
            className="border border-green-800 outline-none rounded-full py-1 px-2 w-[270px] bg-[#2e3033] mr-[100vh] duration-1000 opacity-0"
          />
        </div>
        <div>
          <img
            id="imgCadastro"
            src={astro5}
            alt=""
            className="w-40 mx-auto duration-1000 opacity-0 scale-50"
          />
        </div>

       
        <div className="flex justify-center mt-5 mb-5 12/12">
          <button onClick={confirmStep}  class="bg-verdeprimary duration-700 border-green-500 hover:bg-green-800 text-white font-bold py-1 px-20 uppercase border-t-3 border-2 border-green-900 hover:border-green-500 rounded-xl">
            Continuar
          </button>
        </div>
       
      </div>
    </main>
  );
}

export default RecoveryPassword;
