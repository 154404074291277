import { useContext } from "react";
import { DepositoContext } from "../contexts/deposito";

const useDeposito = () => {
  const context = useContext(DepositoContext);

  return context;
};

export default useDeposito;
