import React from 'react';
import Header from '../Header/header';
import { useEffect, useState, useRef } from 'react';
import Gif from '../../assets/runnerJelly/phone.png'
import { Link } from 'react-router-dom';


function GameBoard() {


  const [width, setWidth] = useState(70)
  const [height, setHeight] = useState(80)
  const iframeRef = useRef(null);
  const refreshRef = useRef(null);

  useEffect(() => {

    setInterval(() => {
      document.getElementById("gifPhone").classList.toggle("rotate-90")

    }, 2000);
    const handleOrientationChange = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        document.getElementById("bgRetrato").classList.add("hidden")
        document.getElementById("headerRunnerJelly").classList.add("hidden")
        document.getElementById("hoverFooterMenu").classList.add("hidden")
       // document.getElementById("contentIframe").classList.add("relative")
        document.getElementById("refreshButton").classList.remove("hidden")

        setWidth(95)
        setHeight(100)
      } else if (window.orientation === 0 || window.orientation === 180) {
        document.getElementById("bgRetrato").classList.remove("hidden")
        document.getElementById("headerRunnerJelly").classList.remove("hidden")
        document.getElementById("contentIframe").classList.remove("relative")
        document.getElementById("refreshButton").classList.add("hidden")

        setWidth(80)
        setHeight(90)
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      // Limpa o event listener quando o componente é desmontado
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  function refreshPage() {
    window.location.reload();
    if (refreshRef.current) {
      refreshRef.current.classList.add("rotate-180");
     
  }
}




  return (
    <>

      <div id="headerRunnerJelly" className="">
        <Header />

      </div>


      <div className="overflow-x-auto overflow-y-auto  " style={{ width: '100%', height: '100%' }}>

        <div id="bgRetrato" className="bg-cinzaprimary z-[9999] text-white text-2xl w-full h-screen absolute flex justify-center items-center font-semibold lg:hidden">
          <div className="flex flex-col items-center">

            <p className="animate-bounce uppercase text-center text-3xl   ">vire o celular! </p>
            <img id="gifPhone" src={Gif} alt="" className="duration-500 w-40  " />
            <p className=" uppercase text-center text-sm text-white  ">Para melhor experiência baixe nosso app   </p>


            <Link className="w-full flex justify-center" to="/download">
              <button className="animate-pulse bg-purple-600 font-bold w-6/12 p-2 rounded-xl text-lg mt-2 mb-10 text-center uppercase flex items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
                download!</button>
            </Link>

          </div>
        </div>
        <div id="contentIframe" className="relative flex justify-center items-center h-screen ">



          <iframe
            ref={iframeRef}
            className='rounded-lg'
            src={`${process.env.PUBLIC_URL}/SecretRunnerJelly/index.html?v=2`}
            width={`${width}%`}
            height={`${height}%`}
            frameBorder="0"
          ></iframe>

          <button id="refreshButton" onClick={refreshPage} className="text-white text-xl z-[20] flex items-center gap-2 uppercase bg-purple-600 rounded-lg px-2 font-semibold absolute translate-x-[50%] right-[70px] border-b-[3px] border-purple-800   ">
            <svg ref={refreshRef} xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-repeat duration-500" viewBox="0 0 16 16">
              <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z" />
            </svg>
          </button>

        </div>

      </div>
    </>
  );
}
export default GameBoard;



