import Template from "../../layouts/template/template";
import Topper from "../../components/Topper/Topper";
import Roleta from '../../components/Roleta/Roleta'


function Roullet() {
  return (
    <>

    <Template>
      <Topper/>
      <Roleta/>
    </Template>

    </>
  );
}

export default Roullet;


