
import { Link } from 'react-router-dom'
import {useState} from 'react'


export default function SubMenuAjuda(){
    const [location] = useState(window.location.href)

    function DropSubMenuAjudaDesktop() {
        document.getElementById("subMenuAjudaDesktop").classList.toggle("hidden");
        document
          .getElementById("subMenuPrivacidadeDesktop")
          .classList.add("hidden");
        document.getElementById("subMenuHistoricoDesktop").classList.add("hidden");
      }
      
    return(
        <>
          {location.indexOf("suporte") > -1 || location.indexOf("perguntasfrequentes") > -1 || location.indexOf("regras") > -1 ? (<div className="border-t-2 border-[#221f1f] p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary" >
            <p className="text-white text-xl">Ajuda</p>
          </div>): <div onClick={DropSubMenuAjudaDesktop} className="border-t-2 border-[#221f1f] p-4 cursor-pointer hover:text-verdeprimary hover:bg-cinzasecondary" >
            <p className="text-white text-xl">Ajuda</p>
          </div>}

          <div id="subMenuAjudaDesktop" className="bg-[#181717] text-white p-4 hidden "  >
            <Link to="/centraldojogador/suporte">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Suporte
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/perguntasfrequentes">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Perguntas Frequentes
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/regras">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Regras
                </p>
              </div>
            </Link>
          </div>
          
          {location.indexOf("suporte") > -1 ? (
           <div id="subMenuAjudaDesktop" className="bg-[#181717] text-white p-4  "  >
           <Link to="/centraldojogador/suporte">
             <div className="hover:bg-[#131212] border-r-2 bg-cinzathird border-verdeprimary duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
               <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                 Suporte
               </p>
             </div>
           </Link>
           <Link to="/centraldojogador/perguntasfrequentes">
             <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
               <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                 Perguntas Frequentes
               </p>
             </div>
           </Link>
           <Link to="/centraldojogador/regras">
             <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
               <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                 Regras
               </p>
             </div>
           </Link>
         </div>
          ): location.indexOf("perguntasfrequentes") > -1 ?(
            <div id="subMenuAjudaDesktop" className="bg-[#181717] text-white p-4  "  >
            <Link to="/centraldojogador/suporte">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Suporte
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/perguntasfrequentes">
              <div className="hover:bg-[#131212] border-r-2 bg-cinzathird border-verdeprimary duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Perguntas Frequentes
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/regras">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Regras
                </p>
              </div>
            </Link>
          </div>
          ): location.indexOf("regras") > -1 ?(
            <div id="subMenuAjudaDesktop" className="bg-[#181717] text-white p-4 "  >
            <Link to="/centraldojogador/suporte">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Suporte
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/perguntasfrequentes">
              <div className="hover:bg-[#131212] duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Perguntas Frequentes
                </p>
              </div>
            </Link>
            <Link to="/centraldojogador/regras">
              <div className="hover:bg-[#131212] border-r-2 bg-cinzathird border-verdeprimary duration-700 rounded-lg hover:border-l-2 hover:border-verdeprimary m-1" >
                <p className="hover:text-verdeprimary duration-700 cursor-pointer p-3">
                  Regras
                </p>
              </div>
            </Link>
          </div>
          ): ""}

        </>
    )
}