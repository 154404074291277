import FundoRegister from '../../assets/cadastro/fundoregister.png'
import InputCriarConta from "../../components/Input/InputCadastro/InputCadastro.js";
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import useRender from '../../hooks/useRender';
import ModalTermos from '../../components/modals/TermosDeUso';
import VMasker from "vanilla-masker";
import ValidarConta from './validarConta';
import ConteudoRegistro from '../../components/modals/registro/conteudo';



function CadastroStep1() {

    const background = {
        backgroundImage: `url(${FundoRegister})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundAttachment: 'fixed',
      };



    return (<>



        <div style={background} className="w-full flex justify-center items-center min-h-screen">
         <ConteudoRegistro/>
        </div>


        <ModalTermos />
    </>);
}

export default CadastroStep1;