import ConteudoCupom from '../modals/Cupom/conteudo'
import ContainerBlur from "./blur/container";



export default function Cupom() {





  return (
    <>

    <ContainerBlur openmodal="modalCupom">    {/* CONTAINER COM O BLUR ATIVADO*/}
      <ConteudoCupom openmodal="modalCupom"/>   {/* CONTEUDO DO MODAL */}   
    </ContainerBlur>
    </>

  )
};

