import { useState } from "react";
import OwlCarroseel from "./OwlCarrossel";
import Buttons from "./Controles";
import Tabela from "./Tabela";
import RoundRoullet from "./RoundRoleta";
import HistoricoRodadasRoleta from "../modals/historicoRodadasRoleta";
import IcoHistorico from '../../assets/buttons/historico.svg'





function Roleta() {
  

  const [quantia, setQuantia] = useState(1);
 

  function dobrarQuantia() {
    var dobrar = 2 * quantia;
    dobrar = dobrar.toString().replace(",", ".");
    setQuantia([dobrar]);
  }
  function dividirQuantia() {
    var dividir = quantia / 2;
    dividir = dividir.toString().replace(",", ".");
    setQuantia([dividir]);
  }
 

  function handleeHistoricoRoleta(){    
    document.getElementById("historicoRodadasRoleta").classList.toggle("hidden");


  }
 

  return (
    <>
    <HistoricoRodadasRoleta/>
      <div className="w-full flex-col flex justify-center items-center">
        <main className="bg-[#191818] w-11/12 md:w-9/12 text-white   rounded-3xl  mx-4 border border-[#1385B5] mt-5 ">
          <div className="w-full flex justify-between bg-cinzaprimary border-b border-b-[#1385b5]  overflow-x-hidden rounded-tr-3xl rounded-tl-3xl">
            <div className="w-10/12 md:w-11/12  ">
              <div className="pl-4 bg-gradient-to-r from-[#0e789f]">
                <h2 className="hidden md:block text-2xl text-white">
                  Rodadas Anteriores
                </h2>
              </div>
              {/* Rounds */}
              <div id="card-historico-roulllet" className="flex p-1 pr-5 mb-2 border-t-[#1385B5] border-t border-r rounded-tr-xl rounded-br-3xl  border-r-[#30cff7] border-b border-b-[#1385B5] bg-cinzaprimary  justify-end w-full overflow-hidden ">
            <RoundRoullet/>

          
              </div>
          
            </div>
            <div onClick={handleeHistoricoRoleta} className="lg:hidden md:mt-8 flex justify-center items-center ">
              <div className="bg-[#344646] w-[35px] h-[35px] group-hover:bg-green-800 m-1 rounded-lg mr-3">
                  <img className="w-8  " src={IcoHistorico}  alt="" />
              </div>
           </div>
            <div className="w-2/12  md:w-1/12 bg-cinzasecundary">

            </div>
          </div>
          <div className="flex flex-wrap">


            {/* DIV DAD OF CANVAS */}
            <div id="canvasroullet" className="w-full relative md:w-8/12 lg:border-r-4 border-r-0 lg:border-r-azulfourth">

            <div className="w-full h-[80%] flex justify-center mt-3 items-start z-[1] absolute">
            <div id="Regua"className=" opacity-80 h-[60%] md:h-[50%] bottom-0 absolute w-[50px]">
            <img src={require("../../assets/roleta/arrow.png")} />
           </div>
           
            </div>
             
             <div className="w-full h-[100%] flex-col   overflow-x-hidden justify-center flex items-center">
              
             <div className="w-full  flex items-center m-5 h-[70%] bg-gradient-to-r from-[#2e32331a] via-[#1385b50d] to-[#2e32331a]">
              <div className="overflow-x-scroll flex w-full justify-center">
             <OwlCarroseel/>
             </div>
             </div>
             <div  id="roletaBarra" className="w-full flex justify-center h-[80px] items-center text-xl md:mt-1 lg:text-3xl font-bold ">
              Girando...
             </div>
             </div>


            </div>


            <div className="w-full md:w-4/12">

              <Buttons />
            </div>
          </div>

        </main>
        <Tabela />
      </div>
    </>
  );
}

export default Roleta;
