import { Link } from "react-router-dom";
import Voltar from "../../../assets/icons/voltar.gif";
import BtnConfirmar from "../../../components/BtnConfirmar";
import { useState, useEffect, useRef } from "react";
import useRender from "../../../hooks/useRender";
import Loading from "../../../components/Loading";
import Swal from "sweetalert2";
import Title from "../../../components/centraldojogador/titulo";
import TemplateCentralDoJogador from "../../../layouts/CentralDojogador/template";
import refresh from "../../../assets/icons/refresh.png"
import Paginate from 'react-paginate';
import TodosPagantes from "./todospagantes";
import TodosCadastrados from "./todosCadastrados";




function Indicacao() {
  const { prefixoUrl, SairApi, saldo, _setSaldo, saldoReal, saldoBonus } = useRender();
  const [indicacao, setIndicacao] = useState("");
  const [numeroTotalDeIndicados, setNumeroTotalDeIndicados] = useState();
  const [indicadosQueDepositaram, setIndicadosQueDepositaram] = useState();
  const [saldoTotalResgate, setSaldoTotalResgate] = useState();
  const [user, setUser] = useState({});

  const [numberRandons, setNumbersRandons] = useState([]);
  const spin = useRef()

  const [currentPage, setCurrentPage] = useState(0); // Página inicial é 0 (baseada em zero)
  const itemsPerPage = 10;

  ///// CONVITES
  const [isManual, setIsManual] = useState(true);
  const [isAutomatico, setIsAutomatico] = useState(false);

  const [isPagantes, setIsPagantes] = useState(true)
  const [isCadastrados, setIsCadastrados] = useState(false)


  function openPagantes(){
   setIsPagantes(true)
   setIsCadastrados(false)
   IndicadosQueDepositaramApi()
  }
  function openCadastrados(){
    setIsCadastrados(true)
    setIsPagantes(false)
    NumeroTotalIndicadosApi()
   
  }

  async function IndicacaoLocalStorage() {
    let dados = localStorage.getItem("indicacao");
    dados = JSON.parse(dados);

    if (dados === null) {
      await IndicacaoApi()
    }

    else if (dados) {
      if (dados.code === 1099) { // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }
      setIndicacao(dados);
    }
  }

  /* Saldo Total Para Resgate */
  async function SaldoTotalParaResgate() {
    let dados = localStorage.getItem("saldoTotalParaResgate");
    dados = JSON.parse(dados);

    if (dados === null) {
      await SaldoTotalParaResgateApi()
    } else if (dados) {
      if (dados.code === 1099) { // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }
      setSaldoTotalResgate(dados);
    }

  }

  /* Número Total Indicados */
  async function NumeroTotalIndicados() {
    let dados = localStorage.getItem("numeroTotalIndicados");
    dados = JSON.parse(dados);

    if (dados === null) {
      await NumeroTotalIndicadosApi()
    } else if (dados) {
      if (dados.code === 1099) { // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }
      setNumeroTotalDeIndicados(dados);
    }


  }

  /* Indicados Que Depositaram */
  async function IndicadosQueDepositaram() {
    let dados = localStorage.getItem("indicadosQueDepositaram");
    dados = JSON.parse(dados);

    if (dados === null) {
      await IndicadosQueDepositaramApi()
    } else if (dados) {
      if (dados.code === 1099) { // Verifica se usuário está autenticado, se não estiver desloga e redireciona para home
        SairApi()
      }
      setIndicadosQueDepositaram(dados);
    }



  }

  async function IndicadosQueDepositaramApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao/indicadosquedepositaram/200?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        console.log(result);
        localStorage.setItem("indicadosQueDepositaram", JSON.stringify(dados));
        setIndicadosQueDepositaram(dados);
      })
      .catch((error) => {
        console.log("error", error);
        setIndicadosQueDepositaram([]);
      });
  }

  /* API NÚMERO TOTAL DE INDICADOS */
  async function NumeroTotalIndicadosApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao/numerototaldeindicados/200?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
        console.log(result);
        localStorage.setItem("numeroTotalIndicados", JSON.stringify(dados));
        setNumeroTotalDeIndicados(dados);
      })
      .catch((error) => {
        console.log("error", error);
        setNumeroTotalDeIndicados([]);
      });
  }

  /* USER */

  function User() {
    let resultado = localStorage.getItem("user");
    resultado = JSON.parse(resultado);
    setUser(resultado.user);
  }

  function dropSaque() {
    document.getElementById("menuSaque").classList.toggle("hidden");
    document.getElementById("arrowSaque").classList.toggle("invisible");
    document.getElementById("menuIndicado").classList.add("hidden");
    document.getElementById("arrowIndicado").classList.add("invisible");
    document.getElementById("menuDeposito").classList.add("hidden");
    document.getElementById("arrowDeposito").classList.add("invisible");

    SaldoTotalParaResgate();
  }

  function dropIndicado() {
    document.getElementById("menuIndicado").classList.toggle("hidden");
    document.getElementById("arrowIndicado").classList.toggle("invisible");
    document.getElementById("menuSaque").classList.add("hidden");
    document.getElementById("arrowSaque").classList.add("invisible");
    document.getElementById("menuDeposito").classList.add("hidden");
    document.getElementById("arrowDeposito").classList.add("invisible");

    NumeroTotalIndicados();
  }

  function dropDeposito() {
    document.getElementById("menuDeposito").classList.toggle("hidden");
    document.getElementById("arrowDeposito").classList.toggle("invisible");
    document.getElementById("menuSaque").classList.add("hidden");
    document.getElementById("arrowSaque").classList.add("invisible");
    document.getElementById("menuIndicado").classList.add("hidden");
    document.getElementById("arrowIndicado").classList.add("invisible");

    IndicadosQueDepositaram();
  }

  useEffect(() => {
    IndicacaoLocalStorage();
    NumeroTotalIndicados();
    SaldoTotalParaResgate();
    IndicadosQueDepositaram();
   
    User();
  }, []);



  function handleResgatar() {
    Swal.fire({
      title: "Deseja realizar o saque do saldo de indicações?",
      icon: "question",
      showCancelButton: true,
      dangerMode: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then(function (events) {
      if (events.isConfirmed) {

        let user_token = localStorage.getItem("user_token");

        var formdata = new FormData();
        formdata.append("token", user_token);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        fetch(`${prefixoUrl}/centraldojogador/indicacao/saque`, requestOptions)
          .then((resposta) => resposta.json())
          .then((json) => {
            if (json.code == 1) {
              Swal.fire("", json.msg, "success").then((result) => {

                IndicacaoApi();

              });
            } else {
              Swal.fire("", json.msg, "error").then((result) => {

                IndicacaoApi();

              });
            }
          })
          .catch((error) => {
            Swal.fire(
              "",
              "Erro ao tenta resgatar o saldo, tente novamente mais tarde",
              "error"
            ).then((result) => {

              SaldoTotalParaResgateApi();

            });

            console.log("error", error);
          });

      }
    });
    return;
  }
  function CopiarLink() {
    let textoCopiado = "https://rockeetz.com/convite/" + `${user.tokenIndicacao}`
   
    console.log("textoCopiado é ?", textoCopiado)
   
    if (navigator.clipboard) {
      navigator.clipboard.writeText(textoCopiado).then(() => {
          Swal.fire(
              'Sucesso',
              'Link de indicação copiado!',
              'success'
          );
      }).catch((error) => {
          console.error('Erro ao copiar:', error);
          Swal.fire(
              'Erro',
              'Falha ao copiar o link de indicação!',
              'error'
          );
      });
  }
    
  }

  /* API INDICAÇÃO */
  async function IndicacaoApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);

        localStorage.setItem("indicacao", JSON.stringify(dados));
        setIndicacao(dados);
      })
      .catch((error) => {
        console.log("error", error);
        setIndicacao([]);
      });
  }

  /* API INDICAÇÃO SALDO TOTAL PARA RESGATE */
  async function SaldoTotalParaResgateApi() {
    var myHeaders = new Headers();

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${prefixoUrl}/centraldojogador/indicacao/saldototalpararesgate/200?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dados = JSON.parse(result);
       
        localStorage.setItem("saldoTotalParaResgate", JSON.stringify(dados));
        setSaldoTotalResgate(dados);
      })
      .catch((error) => {
        console.log("error", error);
        setSaldoTotalResgate([]);
      });


  }

  function openConviteAutomatico() {
    setIsAutomatico(true)
    setIsManual(false)
    fetchNumberRandoms()
  }

  function openConviteManual() {
    setIsManual(true)
    setIsAutomatico(false)
  }


  ////////////// ENVIAR WHATSAPP AUTOMATICO

  function SpinNow() {
    let Giro = spin.current
    Giro.classList.add("animate-spin")
    fetchNumberRandoms()

    setTimeout(() => {
      Giro.classList.remove("animate-spin")
    }, 2000);
  }

  const [riscos, setRiscos] = useState(Array(32).fill("---------"))


  function fetchNumberRandoms() {
    var requestOptions = {
      method: "GET",
    };

    fetch(
      `${prefixoUrl}/numerosrandons/7?token=${localStorage.getItem(
        "user_token"
      )}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.code == 1) {

          setNumbersRandons(riscos);

          setTimeout(() => {
            setNumbersRandons(result.data);
          }, 1000)
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => { });
  }


  function sendWhatsapp() {
    let length = numberRandons.length;

    let numberRandom = Math.floor(Math.random() * length);
    let numberSelected = numberRandons[numberRandom];

    atualizarItem(numberSelected.numero);

   


    var encodedMessage = encodeURIComponent("Jogue agora! e ganhe 200% de bônus no seu primeiro depósito. Link para cadastro: https://rockeetz.com/convite/" + `${user.tokenIndicacao}`);
    var whatsappURL = "https://api.whatsapp.com/send?phone=" + numberSelected.numero + "&text=" + encodedMessage;


    window.open(whatsappURL, "_blank");
  }

  const atualizarItem = (numero) => {
    // Crie uma nova cópia do array com as alterações desejadas
    const novoArray = numberRandons.map(item => {
      if (item.numero === numero) {
        return { ...item, selected: true }; // Atualize o item específico
      }
      return item; // Mantenha os outros itens inalterados
    });

    // Atualize o estado com a nova cópia do array
    setNumbersRandons(novoArray);

    console.log(novoArray);
  };

  return (
    <TemplateCentralDoJogador title="Indicação">
      <main className="w-full mb-10">
      
     
      

        <article className="lg:px-16 px-0">
          <div className="space-y-5">
            <p className="uppercase text-verdeprimary font-bold text-xl p-2">
              bem vindo, {user.name}{" "}
            </p>

            <section className="flex pl-3 gap-x-3">
              <div className="flex-col w-5/12 flex text-white">
                <div className="text-[0.5em] text-center uppercase">saldo para resgate</div>
                <div className="bg-purple-700 font-bold text-center rounded-md p-2"> {indicacao?.saldoTotal || 0}</div>
                <div onClick={handleResgatar} className="bg-green-400 font-bold text-black text-center rounded-md mt-2">Resgatar</div>
              </div>

              <div className="flex w-full h-[50px] mt-2 justify-between rounded-l-md bg-cinzathird py-2 px-3">
                <div className="flex-1">
                  <div className="uppercase text-[0.5em] text-white">saldo total</div>
                  <div className="text-[0.8em] font-bold text-green-500">R$ {saldo}</div>
                </div>
                <div className="flex-none mr-4">
                  <div className="uppercase text-[0.5em] text-white">saldo real</div>
                  <div className="text-[0.8em] font-semibold text-white">R$ {saldoReal} </div>
                </div>
                <div className="flex-none">
                  <div className="uppercase text-[0.5em] text-white">saldo bônus</div>
                  <div className="text-[0.8em] text-white font-semibold">R${saldoBonus}</div>
                </div>
              </div>
            </section>



            <div className="text-center">
              <div className="font-bold text-white">Regras de indicação</div>
              <div className="text-gray-400 px-3 text-[0.8em]">
                O valor da indicação (BRL 10) só é liberado, quando o indicado faz seu primeiro depósito. Se o valor do depósito for ≥ 30.
                <br /> Você ganha BRL 18
              </div>
            </div>

            <p className="text-center uppercase text-3xl text-white">
              CONVIDE E GANHE <br /> NA ROCK<b className="text-verdeprimary">EE</b>TZ
            </p>


            <section className="w-full px-2 flex justify-center">
              <div className="w-full rounded-md p-2 flex gap-x-1 bg-cinzathird">
                <div onClick={openConviteManual} className={`cursor-pointer text-center font-bold py-2 text-white uppercase w-6/12 text-[0.8em] ${isManual ? `active--indicacao` : ``}`}>Convidar amigo</div>
                <div onClick={openConviteAutomatico} className={`cursor-pointer text-center font-bold py-2 text-white uppercase w-6/12 text-[0.8em] ${isAutomatico ? `active--indicacao` : ``}`} >Convite automático</div>
              </div>
            </section>

            {isManual ?
              <>
                {/* MANUAL */}
                <section>
                  <div className="w-full px-2 flex justify-center">
                    <div className="w-full border border-gray-600 rounded-md p-2 flex gap-x-1 bg-cinzaprimary">
                      <div className="text-center font-bold py-2 uppercase w-6/12 text-[0.8em] text-purple-500">rockeetz.com/convite/</div>
                      <div className="w-6/12 py-1 font-bold text-center uppercase  text-[#FEECA4] text-[1em]" >{user.tokenIndicacao}</div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center gap-1 items-center">

                    <div className="mt-1" onClick={CopiarLink}>
                      <BtnConfirmar
                        idBtn="cpindicacao"
                        texto="COPIAR LINK"
                        altura="py-3"
                        largura="w-[300px]"
                        radius="rounded-lg"
                        sizeText="text-xl"
                      />
                    </div>
                  </div>

                </section>
              </>
              :
              <>
                {/* AUTOMATICO */}
                <section>
                  <div className="w-full px-2 flex justify-center">
                    <div className="w-full flex-col border  border-gray-600 rounded-md flex items-center justify-center bg-cinzaprimary">

                      <div className="text-white relative rounded-t-md lg:h-[40px] h-[20px] bg-[#bcea02] flex justify-center w-full">
                        <img onClick={SpinNow} ref={spin} src={refresh} className="cursor-pointer hover:scale-110 duration-300 lg:w-14 w-10 absolute mb-2" />
                      </div>
                      <div className="flex justify-center items-center">

                        <div class="grid grid-cols-4 lg:gap-x-10 p-2 pt-7 gap-3 h-full w-full  rounded-md">
                          {numberRandons.map((el) => {
                            return (
                              <div className={`${el.selected ? "text-green-600" : "text-white"} text-[0.6em] lg:text-2xl uppercase`}>
                                {el.numero}
                              </div>
                            );
                          })}
                        </div>
                      </div>

                    </div>
                  </div>

                  <div className="flex flex-col justify-center gap-1 items-center">

                    <div className="mt-1" onClick={sendWhatsapp}>
                      <BtnConfirmar
                        idBtn="cpindicacao"
                        texto="ENVIAR WHATSAPP"
                        altura="py-3"
                        largura="w-[300px]"
                        radius="rounded-lg"
                        sizeText="text-xl"
                      />
                    </div>


                  </div>

                </section>

              </>}



          </div>

          <section className="flex justify-center mt-5  px-2 w-full">
            <div className="bg-cinzasixtyn rounded-md p-3 text-white w-full">
              <div className="font-bold pl-1">Análise das indicações</div>

              <div className=" bg-cinzafourth mt-2 grid grid-cols-3 rounded-md p-4">

                <div className="flex flex-col justify-center items-center">
                  <div className="text-center text-[0.6em]">Indicações totais</div>
                  <div className="text-xl text-teal-400 font-bold"> {indicacao?.numeroTotal || 0}</div>
                </div>

                <div className="flex border-l border-r border-gray-300 flex-col justify-center items-center">
                  <div className="text-center text-[0.6em]">Indicações de hoje</div>
                  <div className="text-xl text-teal-400 font-bold">+{indicacao?.numeroTotalHoje || 0}</div>


                </div>

                <div className="flex flex-col justify-center items-center">
                  <div className="text-center text-[0.6em]">Valor ganho hoje</div>
                  <div className="text-xl text-teal-400 flex items-center font-bold"><span className="mr-2 text-[0.6em]">R$</span> {indicacao?.saldoTotalHoje || 0} </div>


                </div>

              </div>
            </div>
          </section>

          <section className="flex flex-col items-center justify-center mt-5  px-3 w-full">
            <div className="w-full flex justify-between">
              <div className="text-white font-bold">Ultímos resgates</div>


            </div>

            <div className="w-full">

              {saldoTotalResgate != undefined ? (
                saldoTotalResgate.saldoTotalResgate.map((item, index) => {
                  return (
                    <>
                      <div className="bg-cinzaprimary h-[1px] w-full"></div>
                      <div key={index} className="flex  justify-between w-full  gap-x-2 lg:text-base text-sm lg:gap-x-40 gap-y-2 grid-cols-2 items-center px-2 py-2 lg:px-5 lg:py-5 text-white">

                        <div className=" flex flex-col relative justify-center">
                          <p className="text-gray-400 text-[0.7em]">
                            Valor
                          </p>
                          <p className="text-white relative top-[-5px] whitespace-nowrap w-6/12 text-left fold-bold">
                            R$ {item.valor}
                          </p>
                        </div>

                        <div className="flex flex-col items-end justify-end">
                          <p className="text-gray-400 text-[0.7em]">
                            Data
                          </p>
                          <p className="text-white relative top-[-5px] text-left fold-bold">
                            {item.data}
                          </p>
                        </div>

                      </div>

                    </>

                  );
                })
              ) : (
                <Loading />
              )}

            </div>

          </section>


      <section className="w-full flex px-2 mt-10"> 
      <div onClick={openPagantes} className={`cursor-pointer p-2 rounded-t-md w-6/12 text-white text-center ${isPagantes ? ` bg-cinzasixtyn font-bold` : ``}` }>Todos Usúarios Pagantes</div>
      <div onClick={openCadastrados} className={` p-2 rounded-t-md cursor-pointer w-6/12 text-white text-center ${isCadastrados ? `bg-cinzasixtyn font-bold` : ``}` }>Todos Usúarios Cadastrados</div>
      </section>

      <TodosPagantes
      indicacao={indicacao}
      indicadosQueDepositaram={indicadosQueDepositaram}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      itemsPerPage={itemsPerPage}
      isPagantes={isPagantes}

      />
      <TodosCadastrados
      indicacao={indicacao}
      numeroTotalDeIndicados={numeroTotalDeIndicados}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      itemsPerPage={itemsPerPage}
      isCadastrados={isCadastrados}

      />

       
       



        

        

    
        </article>
      </main>
    </TemplateCentralDoJogador>
  );
}

export default Indicacao;












function TodosCadastro(){
  return(
    <>
    </>
  )
}
